var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-tabs" }, [
    _c(
      "div",
      { staticClass: "dashboard-tabs-navigation nav-tabs-navigation" },
      [
        _c("div", { staticClass: "dashboard-tabs-wrapper nav-tabs-wrapper" }, [
          _c(
            "ul",
            { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
            _vm._l(_vm.dashboardsModel, function (dashboard, index) {
              return _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.selectedDashboardIndex === index },
                },
                [
                  _c("div", { staticClass: "nav-link-container" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { active: _vm.selectedDashboardIndex === index },
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectDashboard(index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(dashboard.title))]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "tab-content" },
      [
        _vm.selectedDashboard
          ? [
              _c("vue-df-dashboard", {
                attrs: {
                  dashid: _vm.selectedDashboard.dashid,
                  maximizable: false,
                  categories: _vm.selectedDashboard.categories,
                  model: _vm.selectedDashboard,
                  "default-mapper": _vm.defaultMapper,
                  collapsible: false,
                  editable: false,
                  "continuous-edit-mode": false,
                  "edit-mode": false,
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }