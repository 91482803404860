const functionDiscoveryMixin = {
  methods:{
    lookupMethodOnParentsInContext(methodStr, remaining, next) {
      const methodOnParent = this.lookupMethodOnParents(this, methodStr, remaining);
      if (methodOnParent)
        methodOnParent(next);
      return methodOnParent;
    },
    lookupMethodOnParents(context, methodStr, remaining) {
      let methodStrFirst = methodStr;
      if (methodStr.indexOf('.') > -1) {
        const splitted = methodStr.split('.');
        methodStrFirst = splitted[0];
        remaining = methodStr.substring(methodStrFirst.length + 1);
      }

      if (context[methodStrFirst]) {
        const foundContext = context[methodStrFirst];
        if (remaining){
          return foundContext[remaining];
        }
        return foundContext;
      } else {
        if (context.$parent) {
          return this.lookupMethodOnParents(context.$parent, methodStrFirst, remaining);
        } else {
          return null;
        }
      }
    },
  }
}

export default functionDiscoveryMixin
