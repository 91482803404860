const builderComponentMixins = {
  props: {
    schema: {type: Object},
    form: {type: Array},
    mergedForm: {type: Array, default: function(){ return []}},
    value: {type: null},
    parentIndex: {type: Number},
    parentForm: {type: Object},
    parentComponent: { type: Object},
    isDroppable: {
      type: Boolean,
      default: function(){
        return false;
      }
    }
  },
  methods: {

  }
}

export default builderComponentMixins
