<template>
  <div>
    <component-base v-bind:field-form="fieldForm">
      <input v-bind:placeholder="resolvePlaceholder(fieldForm)" class="form-control"
             :required="fieldForm.required" type="number" :class="[isValid ? '': 'is-invalid']"
             :readonly="fieldForm.readonly"
             v-model.number="localValue">
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
    </component-base>
  </div>
</template>

<script>
  import componentMixin from './componentMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'

  export default {
    name: "SfNumber",
    mixins: [componentMixin, simpleComponentMergeInMixin]
  }
</script>

<style scoped>

</style>
