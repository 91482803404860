import VueDfIntegrationWidgetView from './VueDfIntegrationWidgetView'
import VueDfIntegrationWidgetEdit from './VueDfIntegrationWidgetEdit'
import VueDfIntegrationChart from './components/VueDfIntegrationChart'
import VueDfIntegrationComponent from "./components/VueDfIntegrationComponent";
import VueDfIntegrationTemplateComponent from "./components/VueDfIntegrationTemplateComponent";
import VueLighthouseViewer from "vue-lighthouse-viewer";

let widget = {
  type: 'VueDfIntegrationWidget',
  title: 'Integration Widget',
  description: 'Displays Integrations',
  category: 'Integrations',
  editComponent: 'VueDfIntegrationWidgetEdit',
  viewComponent: 'VueDfIntegrationWidgetView',
  editableSettings:{
    editable: true
  },
  style:{

  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component("VueLighthouseViewer", VueLighthouseViewer)

    Vue.component(VueDfIntegrationWidgetView.name, VueDfIntegrationWidgetView)
    Vue.component(VueDfIntegrationWidgetEdit.name, VueDfIntegrationWidgetEdit)
    Vue.component(VueDfIntegrationChart.name, VueDfIntegrationChart)
    Vue.component(VueDfIntegrationTemplateComponent.name, VueDfIntegrationTemplateComponent)
    Vue.component(VueDfIntegrationComponent.name, VueDfIntegrationComponent);

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
