<template>
  <div class="integration-container">
    <component :dashboardEditMode="dashboardEditMode"
               :data-model="dataModel"
               :id="id"
               :maximizedArea="maximizedArea"
               :is="componentType" :is-maximized="isMaximized" :model="model" :ui-definition="uiDefinition"></component>
  </div>
</template>

<script>
  import commonIntegrationMixin from "./commonIntegrationMixin";

  export default {
    name: "VueDfIntegrationComponent",
    mixins: [commonIntegrationMixin],
    data() {
      return {
        localUiDefinition: this.uiDefinition,
      }
    },
    computed: {
      componentType: function () {
        if (this.uiDefinition.componentType) {
          switch (this.uiDefinition.componentType) {
            case 'chart':
              return 'VueDfIntegrationChart';
            case 'template':
              return 'VueDfIntegrationTemplateComponent';
          }
        } else {
          return 'VueDfIntegrationChart';
        }
      }
    },
    methods: {}
  }
</script>

<style scoped>
.integration-container {
  height: 100%;
}
</style>
