<template>
  <div v-if="(isDroppable) || (!isDroppable && !fieldForm.hidden)">
    <div v-if="showHideEnabled" class="fieldset-hide">
      <a class="btn btn-sm btn-primary" href @click.prevent="toogleShowHide()">{{showHideTitle}}</a>
    </div>
    <fieldset v-if="showHide" :class="fieldForm.htmlClass" :disabled="fieldForm.readonly" class="schema-form-fieldset">
      <legend>{{fieldForm.title || fieldSchema.title}}</legend>
      <small class="text-muted help-block" v-if="fieldForm.description"><span v-html="fieldForm.description"></span></small>
      <builder-component :field-mapper="fieldMapper" :form="fieldForm.items"
                         @fullModelChanged="onFullModelChanged"
                         :schema-form-service="schemaFormService"

                         :full-model="fullModel" :is-droppable="isDroppable" :merged-form="fieldForm.items"
                         :schema="fieldSchema.schema" @internalSchemaChanged="onInternalSchemaChanged" @schemaChanged="onChildSchemaChanged"
                         v-model="fieldSetValue"></builder-component>
    </fieldset>
  </div>
</template>

<script>
  import componentMixin from './componentMixins'
  import droppableMixins from './droppableMixins'
  import containerMixin from './containerMixin'
  import utils from '../../utils/utils'
  import vueUtils from '../../utils/vueUtils'
  import BuilderComponent from './BuilderComponent'
  import schemaFormElements from './schemaFormElements'
  import forEach from 'lodash/forEach'
  import showHideMixin from './showHideMixin'
  export default {
    name: "SfFieldSet",
    mixins: [componentMixin, droppableMixins, containerMixin, showHideMixin],
    components: {
      BuilderComponent
    },
    data() {
      return {
        fieldSetValue: this.value
      }
    },
    created() {
      if (this.isDroppable) {
        const that = this;
        this.$bus.$on('selectedItemModelApplied', that.onSelectedItemModelApplied);
      }
    },
    beforeDestroy() {
      if (this.isDroppable) {
        const that = this;
        this.$bus.$off('selectedItemModelApplied', that.onSelectedItemModelApplied);
      }
    },
    methods: {

      onSelectedItemModelApplied: function (item) {
        /*console.log('Received fieldset onSelectedItemModelApplied', this.fieldForm, item);
        const that = this;
        that.mergeInContainerLeafItem(item, '.');*/

      },
      onInternalSchemaChanged: function (schemaArray) {
        console.log('onInternalSchemaChanged', schemaArray, utils.clone(this.fieldForm.items));

        if (!schemaArray || schemaArray.length === 0) {
          this.fieldForm.schema.properties = {};
          this.fieldForm.items = [];
        } else {

          const schemaAndForm = this.updateFirstItemsKeyRecursively(this.fieldForm, schemaArray, '.');

          this.fieldForm.schema.properties = schemaAndForm.schema.properties;
          this.fieldForm.items = schemaAndForm.form;
        }
        console.log('onInternalSchemaChanged - end', schemaArray, utils.clone(this.fieldForm));

        this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm});

      },
      onChildSchemaChanged: function (schemaArray) {
        console.log('onChildSchemaChanged', schemaArray);
        let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);
        let builderFormArray = schemaAndForm.form;
        let builderSchema = schemaAndForm.schema;
        const that = this;
        if (builderSchema.properties) {

          this.fieldForm.schema.properties = builderSchema.properties;

          // update child items keys

          forEach(builderFormArray, function (formItem) {
            formItem.key = that.fieldForm.key + '.' + formItem.key;
          });
        } else {
          // this is case when child doesn't have schema and items property contains other controls
          forEach(builderFormArray, function (formItem) {
            if (formItem.items && formItem.items.length > 0) {
              forEach(formItem.items, function (item) {
                item.key = that.fieldForm.key + '.' + item.key;
              });
            }
          });
        }
        this.fieldForm.items = builderFormArray;

        this.$emit('schemaChanged', {schemaFormComponent: this.fieldForm});
      }
    },
    watch: {
      fieldSetValue: {
        handler(newVal, oldVal) {
          vueUtils.vueMerge(this.fullModel, newVal);
        },
        deep: true
      }
    }
  }
</script>

<style scoped>

</style>
