<template>
  <div class="cart-types-container">
    <ul class="chart-list list-group list-group-horizontal list-unstyled">
      <li @click.prevent="onContextChanged(chartType)" :class="{active: currentChart.name===chartType.name}"
          class="list-group-item chart-item" v-for="chartType in chartTypes" :key="chartType.name">
        <div class="chart-type-content text-center">
          <span>{{ chartType.title }}</span>
          <img v-if="chartType.imgSrc" :src="chartType.imgSrc" height="15px" width="15px">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import isEqual from 'lodash/isEqual'
  export default {
    name: "ChartTypes",
    props:{
      currentType: Object
    },
    data() {
      return {
        chartTypes: [
          {
            name: 'valueChart',
            title: 'Value Chart',
            data:{},
            config:{
              top:{
                type: 'object',
                title: 'Top',
                properties:{
                  imgUrl:{
                    type: 'string',
                    title: 'Image Url'
                  },
                  text: {
                    type: 'string',
                    title: 'Text'
                  },
                  size:{
                    type: 'integer',
                    title: 'Size'
                  }
                }
              },
              body:{
                type: 'object',
                title: 'Body',
                properties:{
                  imgUrl:{
                    type: 'string',
                    title: 'Image Url'
                  },
                  text: {
                    type: 'string',
                    title: 'Text'
                  },
                  size:{
                    type: 'integer',
                    title: 'Size'
                  }
                }
              },
              footer:{
                type: 'object',
                title: 'Footer',
                properties:{
                  imgUrl:{
                    type: 'string',
                    title: 'Image Url'
                  },
                  text: {
                    type: 'string',
                    title: 'Text'
                  },
                  size:{
                    type: 'integer',
                    title: 'Size'
                  }
                }
              }

            }
          },
          {
            name: 'barChart',
            title: 'Bar Chart',
            imgSrc: 'https://user-images.githubusercontent.com/35218826/37317756-151ad090-26aa-11e8-9fa0-74f9d1029bd9.png',
            data:{},
            config:{
              stacked:{
                type: 'boolean',
                title: 'Is Stacked'
              },
              columnBar:{
                type:'boolean',
                title:'Is Column'
              }
            }
          },
          {
            name: 'lineChart',
            title: 'Line Chart',
            data:{},
            config:{
              area:{
                type: 'boolean',
                title: 'Is Area'
              },
              stacked:{
                type: 'boolean',
                title: 'Is Stacked'
              },
              columnBar:{
                type:'boolean',
                title:'Is Column'
              }
            }
          },
          {
            name: 'pieChart',
            title: 'Pie Chart',
            data:{},
            config:{
              pieType: {
                type: 'string',
                title: 'Pie Type',
                enum:['Simple', 'Doughnut', 'Rose']
              }
            }
          },
          {
            name: 'tableChart',
            title: 'Table Chart',
            data:{},
            config:{}
          },
          {
            name: 'radarChart',
            title: 'Radar Chart',
            data:{},
            config:{
              area:{
                type: 'boolean',
                title: 'Is Area'
              },
              shape:{
                type:'string',
                title:'Shape',
                enum:['circle', 'polygon'],
                default: 'circle'
              }
            }
          },
          {
            name: 'scatterChart',
            title: 'Scatter Chart',
            data:{},
            config:{
              columnBar:{
                type:'boolean',
                title:'Is Column'
              }
            }
          },
          {
            name: 'funnelChart',
            title: 'Funnel Chart',
            data:{},
            config:{
              sort:{
                type:'string',
                title:'Sort',
                enum:['descending', 'ascending', 'none'],
                default: 'descending'
              },
              orient:{
                type:'string',
                title:'Shape',
                enum:['horizontal', 'vertical'],
                default: 'vertical'
              },
              align:{
                type:'string',
                title:'Align',
                enum:['left', 'center', 'right'],
                default: 'center'
              }
            }
          },
          {
            name: 'geoChart',
            title: 'Geo Chart',
            data:{},
            config:{}
          },
          {
            name: 'boxPlotChart',
            title: 'Boxplot Chart',
            data:{},
            config:{}
          },
          {
            name: 'heatMapChart',
            title: 'Heatmap Chart',
            data:{},
            config:{}
          },
          {
            name: 'treeMapChart',
            title: 'Treemap Chart',
            data:{},
            config:{}
          },
          {
            name: 'gaugeChart',
            title: 'Gauge Chart',
            data:{},
            config:{}
          },
          {
            name: 'sankeyChart',
            title: 'Sankey Chart',
            data:{},
            config:{}
          }
        ],
        currentChart: {}
      }
    },
    watch:{
      currentType:{
        handler(newVal, oldVal){
          if (!isEqual(newVal, this.currentChart)){
            this.currentChart = newVal;
          }
        },
        deep:true
      }
    },
    methods:{
      onContextChanged: function (context) {
        console.log('contextchanged', context);
        this.currentChart = context;
        this.$emit('contextchanged', { type: 'chartType', context: context });
      }
    },
    created() {
      this.onContextChanged(this.chartTypes[1]);
    }
  }
</script>

<style scoped>
/*.chart-item {
  min-width: 150px;
}*/
.chart-list {
  overflow-x: auto;
}
</style>
