var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-up-down", class: [_vm.placement] },
    [
      _vm.isFirst
        ? _c(
            "a",
            {
              staticClass: "small",
              attrs: { title: "UP", href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onUp.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fas fa-arrow-up" })]
          )
        : _vm._e(),
      _vm.isLast
        ? _c(
            "a",
            {
              staticClass: "small",
              attrs: { title: "DOWN", href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onDown.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fas fa-arrow-down" })]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }