import HttpRestApiHandler from './http/httpRestApiHandler'

const httpMixinService = {
  name: 'REST_API',
  handlers:{},
  _init(componentThis){
    //console.log('Init Rest API: componentThis', componentThis, componentThis.id);
    //this.handler = new HttpRestApiHandler({ ref: componentThis })
    this.handlers[componentThis.id] = new HttpRestApiHandler({ ref: componentThis })
  },
  async onCreated(componentThis){
    this._init(componentThis);
  },
  async onDestroyed(componentThis){

  },
  async onComponentEvent(eventName, eventData){

  },
  async loadMetaContextObject(context){
    await this.handlers[context.id].loadMetaContextObject(context);
  },
  async executeService(def, eventContext) {
    await this.handlers[eventContext.source.id].executeService(def, eventContext);
  }
}

const httptMixinServicePlugin = {
  install(Vue, options) {
    httpMixinService.vue = Vue;

    Vue.viewMixinServices.add(httpMixinService.name, httpMixinService);
  }
}

export default httptMixinServicePlugin;
