var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("pre", [_vm._v("      " + _vm._s(_vm.value) + "\n    ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }