<template>
  <div></div>
</template>

<script>
  import componentMixin from './componentMixins'

  export default {
    name: "SfRadioButtons",
    mixins: [componentMixin]
  }
</script>

<style scoped>

</style>
