<template>
  <div class="dashboard-theme-chooser">
    <schema-form :schema="schema" :form="form" v-model="value"></schema-form>
  </div>
</template>

<script>
  export default {
    name: "VueDfDashboardTheme",
    props: {
      value: Object
    },
    data(){
      return {
        schema:{
          type: 'object',
          properties:{
            dashboardTheme:{
              type: 'string',
              title: 'Dashboard Theme',
              description: 'Select dashboard theme and combine with widget theme',
              default: 'simple'
            },
            widgetTheme:{
              type: 'string',
              title: 'Widget Theme',
              description: 'Select widget theme and combine with dashboard theme',
              default: 'simple'
            }
          }
        },
        form:[
          {
            type:'section',
            sectionContainerClass: 'row',
            sectionChildClass: 'col',
            items:[
              {
                key: 'dashboardTheme',
                type: 'select',
                titleMap:[
                  {
                    name: 'Simple',
                    value: 'simple'
                  }
                ]
              },
              {
                key: 'widgetTheme',
                type: 'select',
                titleMap:[
                  {
                    name: 'Simple',
                    value: 'simple'
                  },
                  {
                    name: 'Card',
                    value: 'card'
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  }
</script>

<style scoped>

</style>
