import functionDiscoveryMixin from './functionDiscoveryMixin'
import groupedMixin from './groupedMixin'
import groupBy from "lodash/groupBy";
import titleMapBaseMixin from './titleMapBaseMixin'
import isEqual from 'lodash/isEqual'

const titleMapMixins = {
  data() {
    return {
      childrenKey: this.fieldForm.childrenKey || 'children',
      internalTitleMap: [],
      branchImage: this.fieldForm.branchImage || 'branchImage',
      leafImage: this.fieldForm.leafImage || 'leafImage',
      tmpTitleMap: []
    }
  },
  mixins: [functionDiscoveryMixin, groupedMixin, titleMapBaseMixin],
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && !isEqual(newVal, this.internalValue)) {
          this.internalValue = newVal;
        }
      }
    },
    titleMap: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          //console.log('Title map changed', newVal);
          this.resolveInternalTitleMap(newVal);
          this.tmpTitleMap = [];
        }
      },
      deep: true
    }
  },
  methods: {
    resolveInternalTitleMap(newTitleMap) {
      const that = this;
      let tMap = newTitleMap;
      if (that.fieldForm.groupBy) {
        tMap = that.groupByTitleMap(newTitleMap, that.fieldForm.groupBy);
      }
      that.$set(that, 'internalTitleMap', tMap);
    },
    groupByTitleMap(titleMap, groupByKey) {
      const grouped = groupBy(titleMap, groupByKey);
      const tmp = [];
      for (const p in grouped) {
        const obj = {};
        obj[this.valueKey] = p;
        obj[this.labelKey] = p;
        obj[this.childrenKey] = grouped[p];
        if (grouped[p] && grouped[p].length && grouped[p][0]) {
          obj[this.branchImage] = grouped[p][0][this.branchImage];
        }
        tmp.push(obj);
      }
      return tmp;
    }
  }
}


export default titleMapMixins
