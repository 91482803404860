var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expression-function-container" }, [
    _c("div", { staticClass: "function d-inline-flex" }, [
      _c("div", { staticClass: "function-name colored" }, [
        _vm._v(_vm._s(_vm.value.name)),
        _c("span", { staticClass: "colored" }, [_vm._v("(")]),
      ]),
      _c(
        "div",
        { staticClass: "function-parameters" },
        [
          _c(
            "draggable",
            {
              class: ["droppable-container d-flex", _vm.allowedDropClass],
              attrs: { group: { name: "els", put: true }, tag: "div" },
              model: {
                value: _vm.paramPlaceholders,
                callback: function ($$v) {
                  _vm.paramPlaceholders = $$v
                },
                expression: "paramPlaceholders",
              },
            },
            _vm._l(_vm.paramPlaceholders, function (param, index) {
              return _c("div", { key: param.id, class: _vm.allowedDropClass }, [
                _c(
                  "div",
                  { staticClass: "param-placeholder d-inline-flex" },
                  [
                    _c(_vm.resolveComponent(param), {
                      tag: "component",
                      attrs: { id: param.id, value: param },
                      on: {
                        internalValueChanged: function ($event) {
                          return _vm.onInternalParamValueChanged(
                            $event,
                            param,
                            index
                          )
                        },
                      },
                    }),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: param.id,
                          triggers: "hover",
                          variant: "warning",
                          placement: "top",
                        },
                      },
                      [
                        _c("element-toolbar", {
                          attrs: { element: param },
                          on: {
                            onAction: function ($event) {
                              return _vm.onToolbarAction($event, param)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.paramPlaceholders.length - 1 > index
                      ? _c("span", [_vm._v(", ")])
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c("span", { staticClass: "colored" }, [_vm._v(")")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }