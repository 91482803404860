import SfActions from './SfActions'
import SfButton from './SfButton'
import SfSubmitButton from './SfSubmitButton'
import SfResetButton from './SfResetButton'

import SfArray from './SfArray'
import SfCheckBox from './SfCheckBox'
import SfDate from './SfDate'
import SfDateTime from './SfDateTime'
import SfFieldSet from './SfFieldSet'
import SfHelp from './SfHelp'
import SfNumber from './SfNumber'
import SfSfRadios from './SfRadios'
import SfSection from './SfSection'
/*import SfSelect from './SfSelect'*/
import SfMultiSelect from './SfMultiSelect'
import SfTabs from './SfTabs'
import SfTabsArray from './SfTabArray'
import SfText from './SfText'
import SfFile from './SfFile'
import SfTextArea from './SfTextArea'
import SfRadiosInline from './SfRadiosInline'
import SfRadioButtons from './SfRadioButtons'
import SfCheckBoxes from './SfCheckBoxes'
import SfTagsInput from "./SfTagsInput";
import SfSelectTree from "./SfSelectTree";
import SfDateTimeRange from './SfDateTimeRange';
import SfSlider from './SfSlider';
//import SfVueSchemaFormEditor from '../schemaFormEditor/SfVueSchemaFormEditor'

import SfTemplateList from "./SfTemplateList";
import SfTemplate from "./SfTemplate";

const fieldMapper = {
  text: SfText,
  file: SfFile,
  password: SfText,
  number: SfNumber,
  integer: SfNumber,
  textarea: SfTextArea,
  tabarray: SfTabsArray,
  tabs: SfTabs,
  radiobuttons: SfRadioButtons,
  radiosinline:SfRadiosInline,
  checkboxes: SfCheckBoxes,
  select: SfSelectTree,
  multiselect: SfMultiSelect,
  help: SfHelp,
  section: SfSection,
  radios: SfSfRadios,
  fieldset: SfFieldSet,
  datetime: SfDateTime,
  daterange: SfDateTimeRange,
  date: SfDate,
  checkbox: SfCheckBox,
  array: SfArray,
  button: SfButton,
  submit: SfSubmitButton,
  reset: SfResetButton,
  actions: SfActions,
  tags: SfTagsInput,
  templatelist: SfTemplateList,
  template: SfTemplate,
  slider: SfSlider
};
// schemaFormEditor: SfVueSchemaFormEditor

const formatMapper = {
  /*select: SfSelect*/
};

export  default   { fieldMapper, formatMapper };
