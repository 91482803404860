import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=187134f0&"
import script from "./Content.vue?vue&type=script&lang=js&"
export * from "./Content.vue?vue&type=script&lang=js&"
import style0 from "./Content.vue?vue&type=style&index=0&id=187134f0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('187134f0')) {
      api.createRecord('187134f0', component.options)
    } else {
      api.reload('187134f0', component.options)
    }
    module.hot.accept("./Content.vue?vue&type=template&id=187134f0&", function () {
      api.rerender('187134f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework-app-components/src/components/VueDfApp/layouts/Content.vue"
export default component.exports