var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-df-dashboard-container",
    {
      ref: "dashboardContainer",
      attrs: {
        navigationPosition: _vm.navigationSettings.position,
        "use-provided-model": _vm.useProvidedModel,
        dashboardsContentClass: "tab-content",
        dashboardsContainerClass: "dashboard-tabs",
        guid: _vm.guid,
        "input-context": _vm.inputContext,
        model: _vm.dashboardsModel,
        structures: _vm.structures,
        "crud-service-name": _vm.crudServiceName,
        "include-widgets": _vm.includeWidgets,
        "default-mapper": _vm.defaultMapper,
        "dashboard-name": _vm.dashboardName,
        "disable-add": _vm.disableAdd,
        "disable-edit": _vm.disableEdit,
        permissions: _vm.permissions,
        "selected-dashboard": _vm.selectedDashboard,
        "selected-dashboard-index": _vm.selectedDashboardIndex,
        "dashboard-service-instance": _vm.dashboardServiceInstance,
        "parent-widget-id": _vm.parentWidgetId,
        "parent-dashid": _vm.parentDashid,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dashboard-tabs-navigation nav-tabs-navigation",
          attrs: { slot: "navigation" },
          slot: "navigation",
        },
        [
          _c(
            "div",
            { staticClass: "dashboard-tabs-wrapper nav-tabs-wrapper" },
            [
              _c(
                "draggable",
                {
                  class: [
                    "nav",
                    _vm.navigationSettings.navTypeClass,
                    _vm.navigationSettings.navClass,
                  ],
                  attrs: {
                    tag: "ul",
                    group: { name: "dashboardTabs" },
                    disabled: _vm.disableAdd,
                    list: _vm.dashboardsModel,
                    role: "tablist",
                    "aria-orientation": _vm.navigationSettings.areaOrientation,
                  },
                  on: { end: _vm.onDashboardDropped },
                },
                [
                  _vm._l(_vm.dashboardsModel, function (dashboard, index) {
                    return _c(
                      "li",
                      {
                        key: dashboard.dashid,
                        staticClass: "nav-item",
                        class: { active: _vm.selectedDashboardIndex === index },
                      },
                      [
                        !_vm.disableAdd
                          ? _c(
                              "a",
                              {
                                staticClass: "small remove",
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeDashboard(dashboard, index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-times" })]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "nav-link-container" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: {
                                active: _vm.selectedDashboardIndex === index,
                              },
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectDashboard(index)
                                },
                              },
                            },
                            [_vm._v(_vm._s(dashboard.title))]
                          ),
                        ]),
                      ]
                    )
                  }),
                  !_vm.disableAdd
                    ? _c(
                        "vue-df-dropdown",
                        {
                          staticClass: "nav-item btn-rotate dropdown last1",
                          attrs: {
                            icon: "fas fa-cog",
                            tag: "li",
                            position: "left",
                            direction: "none",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "nav-link",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function ({ isOpen }) {
                                      return _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-toggle",
                                          attrs: {
                                            "data-toggle": "dropdown",
                                            "aria-haspopup": "true",
                                            "aria-expanded": isOpen,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "nc-icon nc-bell-55",
                                          }),
                                          _c("p", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-lg-none d-md-block",
                                              },
                                              [_vm._v("Export Dashboard")]
                                            ),
                                          ]),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                3480969726
                              ),
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addDashboard()
                                    },
                                  },
                                },
                                [_vm._v("Add Dashboard")]
                              ),
                              _c("a", { attrs: { href: "#" } }, [_c("hr")]),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.exportAllDashboard()
                                    },
                                  },
                                },
                                [_vm._v("Export Dashboards")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.exportSelectedDashboard()
                                    },
                                  },
                                },
                                [_vm._v("Export selected Dashboard")]
                              ),
                              _c("a", { attrs: { href: "#" } }, [_c("hr")]),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.importDashboard()
                                    },
                                  },
                                },
                                [_vm._v("Import Dashboard")]
                              ),
                              _c("a", { attrs: { href: "#" } }, [_c("hr")]),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.shareDashboard()
                                    },
                                  },
                                },
                                [_vm._v("Share Dashboard")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }