var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
    _c(
      "div",
      { class: [_vm.isValid ? "" : "is-invalid"] },
      [
        _c(
          "datetime",
          {
            staticClass: "input-group",
            attrs: {
              auto: true,
              "input-class": {
                "form-control": true,
                "bg-white": true,
                "is-invalid": !_vm.isValid,
              },
              readonly: _vm.fieldForm.readonly,
              required: _vm.fieldForm.required,
              type: "date",
            },
            model: {
              value: _vm.localDate,
              callback: function ($$v) {
                _vm.localDate = $$v
              },
              expression: "localDate",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "input-group-append",
                attrs: { slot: "after" },
                slot: "after",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary",
                    attrs: { title: "Clear date", type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clearInput()
                      },
                    },
                  },
                  [_vm._v("Clear\n        ")]
                ),
              ]
            ),
          ]
        ),
        !_vm.isValid
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }