var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      tag: "component",
      staticClass: "dropdown",
      class: [{ show: _vm.isOpen }],
      attrs: { "aria-haspopup": "true", "aria-expanded": _vm.isOpen },
    },
    [
      _vm._t(
        "title",
        function () {
          return [
            _c(
              "a",
              {
                staticClass: "dropdown-toggle nav-link",
                class: { "no-caret": _vm.hideArrow },
                attrs: { "data-toggle": "dropdown" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleDropDown.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { class: _vm.icon }),
                _c("span", { staticClass: "no-icon" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]
            ),
          ]
        },
        { isOpen: _vm.isOpen }
      ),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          class: [
            { "dropdown-menu-right": _vm.position === "right" },
            { show: _vm.isOpen },
          ],
          attrs: { "x-placement": _vm.directionAttribute },
        },
        [
          _vm._t("default", function () {
            return [
              _c(
                "li",
                [
                  _c("schema-form", {
                    attrs: { schema: _vm.schema, form: _vm.form },
                    model: {
                      value: _vm.data,
                      callback: function ($$v) {
                        _vm.data = $$v
                      },
                      expression: "data",
                    },
                  }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }