import SchemaForm from './SchemaForm'
import ComponentBase from './ComponentBase'


import SfActions from './SfActions'
import SfArray from './SfArray'
import SfCheckBox from './SfCheckBox'
import SfDate from './SfDate'
import SfDateTime from './SfDateTime'
import SfDateTimeRange from './SfDateTimeRange'

import SfFieldSet from './SfFieldSet'
import SfHelp from './SfHelp'
import SfNumber from './SfNumber'
import SfRadios from './SfRadios'

import SfSection from './SfSection'
import SfSelectTree from "./SfSelectTree";
import SfMultiSelect from './SfMultiSelect'
import SfTabs from './SfTabs'
import SfTabsArray from './SfTabArray'
import SfText from './SfText'
import SfFile from './SfFile'
import SfTextArea from './SfTextArea'
import SfTagsInput from './SfTagsInput'

import SfRadiosInline from './SfRadiosInline'
import SfRadioButtons from './SfRadioButtons'
import SfCheckBoxes from './SfCheckBoxes'

import SfButton from './SfButton'
import SfSubmitButton from './SfSubmitButton'
import SfResetButton from './SfResetButton'

import SfTemplateList from "./SfTemplateList";
import SftTemplate from './SfTemplate'
import SfSlider from "./SfSlider";

import defaultMapper from './defaultMapper'

const schemaFormPlugin = {
  defaultMapper: defaultMapper,
  registerComponents (Vue, options){
    Vue.component(SchemaForm.name, SchemaForm)
    Vue.component(ComponentBase.name, ComponentBase)


    Vue.component(SfActions.name, SfActions)
    Vue.component(SfButton.name, SfButton)
    Vue.component(SfSubmitButton.name, SfSubmitButton)
    Vue.component(SfResetButton.name, SfResetButton)

    Vue.component(SfArray.name, SfArray)
    Vue.component(SfCheckBox.name, SfCheckBox)
    Vue.component(SfDate.name, SfDate)
    Vue.component(SfDateTime.name, SfDateTime)
    Vue.component(SfDateTimeRange.name, SfDateTimeRange)

    Vue.component(SfFieldSet.name, SfFieldSet)

    Vue.component(SfHelp.name, SfHelp)
    Vue.component(SfNumber.name, SfNumber)
    Vue.component(SfRadios.name, SfRadios)
    Vue.component(SfSection.name, SfSection)
    Vue.component(SfTabs.name, SfTabs)
    Vue.component(SfTabsArray.name, SfTabsArray)

    Vue.component(SfText.name, SfText)
    Vue.component(SfFile.name, SfFile)
    Vue.component(SfTextArea.name, SfTextArea)
    Vue.component(SfTagsInput.name, SfTagsInput)

    /*Vue.component(SfSelect.name, SfSelect)*/
    Vue.component(SfSelectTree.name, SfSelectTree)
    Vue.component(SfMultiSelect.name, SfMultiSelect)

    Vue.component(SfCheckBoxes.name, SfCheckBoxes)
    Vue.component(SfRadiosInline.name, SfRadiosInline)
    Vue.component(SfRadioButtons.name, SfRadioButtons)
    Vue.component(SfTemplateList.name, SfTemplateList)
    Vue.component(SftTemplate.name, SftTemplate);
    Vue.component(SfSlider.name, SfSlider);

  },
  registerAdditionalComponents(Vue, options){
    if (options && options.components) {
      options.components.forEach(function (element) {
        Vue.component(element.name, element.component);
      });
    }

    if (options && options.componentMapper) {
      const that = this;
      if (options.componentMapper.fieldMapper) {
        options.componentMapper.fieldMapper.forEach(function (item) {
          Vue.component(item.component.name, item.component)
          that.defaultMapper.fieldMapper[item.name] = item.component;
        });
      }
      if (options.componentMapper.formatMapper) {
        options.componentMapper.formatMapper.forEach(function (item) {
          Vue.component(item.component.name, item.component)
          that.defaultMapper.formatMapper[item.name] = item.component;
        });
      }
    }
  },
  install(Vue, options) {

    if (!Vue.__schemaFormRegistered) {
      this.registerComponents(Vue, options);
      this.registerAdditionalComponents(Vue, options);

      Vue.defaultMapper = schemaFormPlugin.defaultMapper;

      Object.defineProperty(Vue.prototype, '$defaultMapper', {
        get() {
          return schemaFormPlugin.defaultMapper;
        }
      })
      Vue.__schemaFormRegistered = true;
    }
  }
}

export default schemaFormPlugin
