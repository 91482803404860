<template>
  <vue-df-dashboard-container ref="dashboardContainer"
                              :navigationPosition="navigationSettings.position"
                              :use-provided-model="useProvidedModel"
                              :dashboardsContentClass="'tab-content'"
                              :dashboardsContainerClass="'dashboard-tabs'"
                              :guid="guid"
                              :input-context="inputContext"
                              :model="dashboardsModel"
                              :structures="structures"
                              :crud-service-name="crudServiceName"
                              :include-widgets="includeWidgets"
                              :default-mapper="defaultMapper"
                              :dashboard-name="dashboardName"
                              :disable-add="disableAdd"
                              :disable-edit="disableEdit"
                              :permissions="permissions"
                              :selected-dashboard="selectedDashboard"
                              :selected-dashboard-index="selectedDashboardIndex"
                              :dashboard-service-instance="dashboardServiceInstance"
                              :parent-widget-id="parentWidgetId"
                              :parent-dashid="parentDashid">
    <div slot="navigation" class="dashboard-tabs-navigation nav-tabs-navigation">
      <div class="dashboard-tabs-wrapper nav-tabs-wrapper">
        <draggable tag="ul" :class="['nav', navigationSettings.navTypeClass, navigationSettings.navClass]"
                   :group="{ name: 'dashboardTabs'}" :disabled="disableAdd"
                   @end="onDashboardDropped" :list="dashboardsModel"
                   role="tablist" :aria-orientation="navigationSettings.areaOrientation">
          <li v-for="(dashboard, index) in dashboardsModel" :key="dashboard.dashid"
              class="nav-item " :class="{'active':selectedDashboardIndex===index}">
            <a v-if="!disableAdd" href="" @click.prevent="removeDashboard(dashboard, index)" class="small remove">
              <i class="fas fa-times"></i>
            </a>
            <div class="nav-link-container">
              <a href="" @click.prevent="selectDashboard(index)" :class="{'active':selectedDashboardIndex===index}"
                 class="nav-link">{{ dashboard.title }}</a>
            </div>
          </li>
          <vue-df-dropdown icon="fas fa-cog" tag="li" v-if="!disableAdd"
                           position="left"
                           direction="none"
                           class="nav-item btn-rotate dropdown last1">
            <div class="nav-link">
              <a slot="title"
                 slot-scope="{isOpen}"
                 class="dropdown-toggle"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 :aria-expanded="isOpen">
                <i class="nc-icon nc-bell-55"></i>
                <p>
                  <span class="d-lg-none d-md-block">Export Dashboard</span>
                </p>
              </a>
              <a class="dropdown-item" href="" @click.prevent="addDashboard()">Add Dashboard</a>
              <a class="" href="#">
                <hr/>
              </a>
              <a class="dropdown-item" href="" @click.prevent="exportAllDashboard()">Export Dashboards</a>
              <a class="dropdown-item" href="" @click.prevent="exportSelectedDashboard()">Export selected Dashboard</a>
              <a class="" href="#">
                <hr/>
              </a>
              <a class="dropdown-item" href="" @click.prevent="importDashboard()">Import Dashboard</a>
              <a class="" href="#">
                <hr/>
              </a>
              <a class="dropdown-item" href="" @click.prevent="shareDashboard()">Share Dashboard</a>

            </div>
          </vue-df-dropdown>
        </draggable>
      </div>
    </div>
  </vue-df-dashboard-container>
</template>

<script>
import VueDfDashboardContainer from '../VueDfDashboardContainer'
import dashboardContainerMixin from '../dashboardContainerMixin'
import dashboardSharedMixin from '../dashboardSharedMixin'
import VueDfDropdown from '../../components/VueDfDropdown'
import draggable from "vuedraggable";

export default {
  name: "VueDfDashboardTabs",
  props: {
    navigationSettings: {
      type: Object,
      default: function () {
        return {
          position: 'top',
          navClass: '',
          areaOrientation: '',
          navTypeClass: 'nav-tabs'
        }
      }
    },
    dashboardEditMode: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  mixins: [dashboardContainerMixin, dashboardSharedMixin],
  components: {
    VueDfDropdown,
    VueDfDashboardContainer,
    draggable
  },
  methods: {
    onDashboardDropped: function (evt) {
      this.$refs.dashboardContainer.saveCurrentDashboard();//({model: this.dashboardsModel});
    }
  }
}
</script>

<style scoped>

</style>
