var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "element-toolbar" }, [
    _c("i", {
      staticClass: "fas fa-trash",
      attrs: { title: "Remove element" },
      on: {
        click: function ($event) {
          return _vm.removeElement()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }