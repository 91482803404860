const commonEditMixin = {
  methods:{
    loadSources: async function () {
      const that = this;
      const url = this.$appConfig.appConfig.apiBase +'/v1/integrationdashboard/strategy';
      const promise = new Promise(function (resolve, reject) {
        that.$http.get(url).then((res) => {
          resolve(res.data);
        }).catch((err) => {
          reject(err);
        });
      });
      return promise;
    },
  }
}

export default commonEditMixin;
