<template>
  <div>
    <component-base :field-form="fieldForm">
      <div :class="[isValid ? '': 'is-invalid']">
        <div class="radio" v-for="opt in internalTitleMap">
          <label>
            <input :key="internalValue" type="radio" @change="onChangeEvent($event)" @click="onClick($event)"
                   :class="fieldForm.fieldHtmlClass"
                   :value="opt[valueKey]" :required="fieldForm.required"
                   :disabled="fieldForm.readonly || fieldForm.disabled"
                   :checked="opt[valueKey]===internalValue">
            <span v-html="opt[labelKey]"></span>
          </label>
        </div>
      </div>
    </component-base>
  </div>
</template>

<script>
import componentMixin from './componentMixins'
import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
import titleMapMixins from "./titleMapMixins";

export default {
  name: "SfRadios",
  mixins: [componentMixin, simpleComponentMergeInMixin, titleMapMixins],
  watch: {
    internalValue: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== this.localValue) {
          this.localValue = newVal;
        }
      }
    }
  },
  methods: {
    onClick: function (event) {
      if (this.internalValue === event.target.value) {
        this.internalValue = undefined;
      }
    },
    onChangeEvent: function (event) {
      this.internalValue = event.target.value;
    }
  }
}
</script>

<style scoped>

</style>
