<template>
  <component-base  v-bind:field-form="fieldForm">
    <input class="form-control"
           :required="fieldForm.required" type="file" :class="[isValid ? '': 'is-invalid', fieldForm.fieldHtmlClass]"
           @change="onLocalFileChanged" :readonly="fieldForm.readonly">
    <div class="form-control file-control"><span>{{placeholder}}</span><i class="fas fa-upload float-right"></i></div>
    <small class="text-danger" v-if="!isValid">{{ validationMessage }}</small>
  </component-base>
</template>

<script>
import componentMixin from './componentMixins'
import droppableMixins from './droppableMixins'
import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
export default {
  name: "SfFile",
  mixins: [componentMixin, droppableMixins, simpleComponentMergeInMixin],
  data(){
    return {
      localFileValue: null,
      localFileName: this.localValue,
      placeholder: this.resolvePlaceholder(this.fieldForm),
      size: 0,
      allowedSize: (this.fieldForm.allowedSize?this.fieldForm.allowedSize:10)*1024*1024,
      validationMessage: this.fieldForm.validationMessage
    }
  },
  watch:{
    localFileValue: {
      handler(newVal, oldVal){
        console.log('localFileValue changed', newVal);
        if (newVal){
          this.placeholder = newVal.name;
          this.localFileName = newVal.name
          this.localValue = newVal
        }else{
          this.placeholder = this.resolvePlaceholder(this.fieldForm);
          this.localFileName = null;
          this.localValue = null;
        }
      },
      deep: true
    },
    localValue: {
      handler(newVal, oldVal){
        console.log('localVal changed', newVal);

      }
    },
    size:{
      handler(newVal, oldVal){
        console.log('size changed', newVal, this.allowedSize);

        if (newVal>this.allowedSize){
          this.errorFileSize();
        }
      }
    }
  },
  methods: {
    errorFileSize(){
      this.validationMessage = `Allowed size is greater then ${this.allowedSize/(1024*1024)}MB`;
      this.isValid = false;
      this.localFileValue = null
    },
    onLocalFileChanged($evt){
      console.log("changed", $evt);
      const file = $evt.target.files[0];
      console.log('file', file);
      this.localFileValue = file;
      if (file)
        this.size = file.size;
    },
    resolveLocalFileName(){

    },
    resolveType: function(){

    }
  }
}
</script>

<style scoped>

.file-control {
  height: calc(1.5em + 0.75rem);
}

</style>
