var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-sidebar",
      attrs: {
        "data-active-color": _vm.activeColor,
        "data-color": _vm.backgroundColor,
      },
    },
    [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _c(
            _vm.homeComponent,
            {
              tag: "component",
              staticClass: "simple-text logo-mini",
              attrs: { to: _vm.homeLink },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onHomeClick.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "logo-img" }, [
                _c("img", { attrs: { src: _vm.logo, alt: "" } }),
              ]),
            ]
          ),
          _c(
            _vm.homeComponent,
            {
              tag: "component",
              staticClass: "simple-text logo-normal",
              attrs: { to: _vm.homeLink },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onHomeClick.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _vm._t("default"),
          _c(
            "ul",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.resolveItems(), function (item, index) {
                  return _c(
                    "vue-df-app-sidebar-item",
                    {
                      key: item.title + index,
                      attrs: {
                        "app-config": _vm.appConfig,
                        config: item,
                        parent: null,
                        "selected-route-config": _vm.selectedRouteConfig,
                      },
                      on: { selected: _vm.onSelect },
                    },
                    _vm._l(
                      _vm.resolveSubItems(item),
                      function (subItem, index) {
                        return _c("vue-df-app-sidebar-item", {
                          key: subItem.title + index,
                          attrs: {
                            "app-config": _vm.appConfig,
                            config: subItem,
                            parent: item,
                            "selected-route-config": _vm.selectedRouteConfig,
                          },
                          on: { selected: _vm.onSelect },
                        })
                      }
                    ),
                    1
                  )
                })
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }