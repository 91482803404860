<template>
  <div class="kanban-view"  v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <vue-kanban v-model="kanbanModel"></vue-kanban>
  </div>
</template>

<script>
  import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'
  const VueKanban = ()=>import("./components/VueKanban")
  export default {
    name: "VueDfKanbanView",
    mixins:[widgetComponentViewMixins],
    components:{
      VueKanban
    },
    props:{
      model: {
        type: Object
      },
      kanbanName: {
        type: String
      },
      dashid: {
        type: String
      }
    },
    created(){

    },
    computed:{
      kanbanModel: function(){
        return this.model.config.data;
      }
    },
    methods:{

    }
  }
</script>

<style scoped>

</style>
