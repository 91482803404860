var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            { attrs: { title: "DataSources" } },
            [_c("data-source-designer")],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Chart Designer" } },
            [_c("chart-designer")],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Visual Designer" } },
            [_c("visual-designer-editor")],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Accordion Designer" } },
            [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      ++_vm.count
                    },
                  },
                },
                [_vm._v("Render")]
              ),
              _c(
                "vertical-accordion",
                { key: _vm.count },
                [
                  _c("accordion-item", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [_c("span", [_vm._v("Query Definition List")])]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n            Klipfolio uses pre-created DataSources for each SourceType (Facebook, Twitter) - they uses direct HTTPs requests (not libraries) - and each DataSource has it's visualisation\n          "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("accordion-item", {
                    attrs: { visible: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [_c("span", [_vm._v("Data Loader")])]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n            Some accordion text 2\n          "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("accordion-item", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [_c("span", [_vm._v("Post Processor")])]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n            Some accordion text 3\n          "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Expression Designer" } },
            [_c("expression-designer")],
            1
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("Explanation")]),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n    Data source designer:"),
      _c("br"),
      _vm._v("\n    - "),
      _c("strong", [_vm._v("Select a Query")]),
      _vm._v(" - Query should be already defined "),
      _c("br"),
      _vm._v(
        "\n       - Option to Load Data (execute Query - e.g. if Query already defined it will execute Post Processor) "
      ),
      _c("br"),
      _vm._v("\n    - There could be one or more queries for the Data source "),
      _c("br"),
      _vm._v(
        "\n       - If Data Source combines multiple queries from different SourceTypes it will be executed each separately (for loading experience) "
      ),
      _c("br"),
      _vm._v(
        "\n       - Loaded data can be Tabular or Hierarchical (find appropriate Component to show the data) "
      ),
      _c("br"),
      _vm._v("\n    - From Loaded data prepare "),
      _c("strong", [_vm._v("Post Processor")]),
      _vm._v(" (expression language as for Query API Post Processor) "),
      _c("br"),
      _vm._v(
        "\n       - Loaded data can be Tabular or Hierarchical (find appropriate Component to show the data) "
      ),
      _c("br"),
      _vm._v("\n    - "),
      _c("strong", [_vm._v("Create Visualisation")]),
      _vm._v("\n       - Choose appropriate "),
      _c("strong", [_vm._v("Visualisation Type")]),
      _vm._v(" or "),
      _c("br"),
      _vm._v(
        "\n           - Visualisation Types should be already defined - each Visualisation Type define it's own Interface - e.g. template, configurable series, xAxis, yAxis and other properties "
      ),
      _c("br"),
      _vm._v("\n       - Create Visualisation in "),
      _c("strong", [_vm._v("Advanced Mode")]),
      _vm._v(" as Javascript (as currently in Query definition) "),
      _c("br"),
      _vm._v("\n    - There could be multiple "),
      _c("strong", [_vm._v("Visualisation Types")]),
      _vm._v(
        " defined for this DataSource so User can choose when configuring the Widget and Dashboard "
      ),
      _c("br"),
      _vm._v("\n    - "),
      _c("strong", [_vm._v("Filters")]),
      _c("br"),
      _vm._v("\n       - Filter can be: "),
      _c("br"),
      _vm._v("\n        - in Dashboard context (global to dashboard) "),
      _c("br"),
      _vm._v("\n        - in Widget context "),
      _c("br"),
      _vm._v(
        '\n       - User should be able to choose from "allowed" filters to be shown on Dashboards '
      ),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }