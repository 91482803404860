var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group checkbox-group" }, [
    _c(
      "div",
      {
        key: _vm.resolveStringifiedKey(_vm.fieldForm) + _vm.localValue,
        staticClass: "checkbox form-check",
        class: [_vm.isValid ? "" : "is-invalid", _vm.fieldForm.fieldHtmlClass],
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          staticClass: "form-check-input",
          attrs: {
            disabled: _vm.fieldForm.readonly || _vm.fieldForm.disabled,
            required: _vm.fieldForm.required,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.localValue)
              ? _vm._i(_vm.localValue, null) > -1
              : _vm.localValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.localValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.localValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.localValue = $$c
              }
            },
          },
        }),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            class: _vm.fieldForm.labelHtmlClass,
          },
          [_c("span", { domProps: { innerHTML: _vm._s(_vm.fieldForm.title) } })]
        ),
      ]
    ),
    _vm.fieldForm.description
      ? _c(
          "div",
          {
            staticClass: "text-muted small help-block",
            class: _vm.fieldForm.descriptionHtmlClass,
          },
          [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.fieldForm.description) },
            }),
          ]
        )
      : _vm._e(),
    !_vm.isValid
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
        ])
      : _vm._e(),
    _vm.validationError
      ? _c("div", { staticClass: "invalid-feedback error-text" }, [
          _vm._v(_vm._s(_vm.validationError)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }