<template>
  <div id="dashboard-simple">
    <h4>DA LI SE OVO KORISTI???</h4>
    <div class="pull-right" style="width: 400px;">
      <router-link :to="{ name:'neka proba' }">Neka proba</router-link>
    </div>

    <router-view></router-view>

    <vue-df-dashboard-simple :categories="selectedDashboard.categories"
                             :collapsible="false"
                             :continuous-edit-mode="false"
                             :dashid="selectedDashboard.dashid"
                             :default-mapper="defaultMapper"
                             :edit-mode="false"
                             :editable="false"
                             :maximizable="false"
                             :model="selectedDashboard">
    </vue-df-dashboard-simple>
  </div>
</template>

<script>
  import VueDfDashboardSimple from "vue-dashboard-framework/src/components/vuedf/containers/VueDfDashboardSimple";

  export default {
    name: "VueSingleDashboard",
    components: {VueDfDashboardSimple},
    data() {
      return {
        defaultMapper: {},
        selectedDashboard: {},
        crudServiceName: 'http'
      }
    },
    created() {
      if (this.$auth.isAuthenticated()){
        this.$attrs.getUser('etl')
        .then(function(user){
          this.$userService.setCurrentUser(user);
          this.loadSingleDashboard();
        });

      }

    },
    methods: {
      loadSingleDashboard: function () {
        const dashboardKey = this.$route.params.id || 'vueDfViewDashboard';
        const that = this;

        this.$services[this.crudServiceName].loadModels(dashboardKey, function (models) {
          const tmpModels = models?models.value:null;
          if (tmpModels && tmpModels.length > 0) {
            const dashboard = tmpModels[0]; //tmpModels[11];
            that.selectedDashboard = dashboard;
            that.loadRoutes(dashboard);
          }
        });
      },
      createRoute: function (itemConf) {
        //console.log('Current route: ' + currentRoute.path, currentRoute);
      },
      addRoutes: function (router, items, parentPath) {
        const that = this;
        items.forEach(function (item) {
          console.log('Route item config', item);
          if (item.itemType === 'DROP_DOWN') {
            const parentRoute = item.link;
            if (item.itemDropDownSettings && item.itemDropDownSettings.items) {
              that.addRoutes(router, item.itemDropDownSettings.items, parentRoute);
            }
          } else if (item.itemType === 'LINK' || !item.itemType) {
            let routes = router.options.routes;
            let currentRoute = routes.find(r => r.path === that.$route.path);

       //     console.log('adding to current route', currentRoute);
          }
        });
      },
      createRoutesDefinition: function (items, parentPath, prefix) {
        const that = this;
        const routes = [];
        items.forEach(function (item) {
       //   console.log('Route item config', item);
          if (item.itemType === 'DROP_DOWN') {
            const parentRoute = item.link;
            /*if (item.itemDropDownSettings && item.itemDropDownSettings.items) {
              that.addRoutes(router, item.itemDropDownSettings.items, parentRoute);
            }*/
          } else if (item.itemType === 'LINK' || !item.itemType) {
            const itemPath = prefix + (parentPath ? parentPath + item.link : item.link);

            const template = '<h3 class="pull-right">hello1 ' + item.title + '</h3>';
        //    console.log('Template: ' + template);

            const routeDef = {
              path: itemPath,
              name: item.title,
              component: {template: template},
              meta: {requiresAuth: false},
              item: item
            };
            routes.push(routeDef);

          }
        });
        return routes;
      },
      loadRoutes: function (dashboard) {
       // console.log('Loaded dashboard', dashboard);

        const widget = this.$jsulator.evaluate('rows[0].columns[0].widgets[0]', dashboard);
      //  console.log('Jsulator resolved widget', widget);

        if (widget.type === 'VueDfApp') {
       //   console.log('Loaded VueDfApp widget', widget);

          const sidebarItems = this.$jsulator.evaluate('config.data.layoutSettings.sidebarSettings.items', widget);
          const navBarItems = this.$jsulator.evaluate('config.data.layoutSettings.navBarSettings.items', widget);

          let routes = [];
          const prefix = '/dfs';
          if (sidebarItems) {
            const tmp = this.createRoutesDefinition(sidebarItems, null, prefix);
            routes = routes.concat(tmp);
          }

          let currentRoute = this.$router.options.routes.find(r => r.path === this.$route.path);
         // console.log('currentRoute', currentRoute);
          if (currentRoute && currentRoute.children && currentRoute.children[0]) {
            const firstChild = currentRoute.children[0];
            if (firstChild.name === 'dfs') {
              if (!firstChild.children) {
                firstChild.children = [];
              }
              firstChild.children = firstChild.children.concat(routes);
console.log('adding routes');
              this.$router.addRoutes([currentRoute]);
            }
          }
        //  console.log('Newly created router', this.$router);
        }
      }
    }
  }
</script>

<style scoped>
  #dashboard-simple .app-container .app-sidebar {
    position: fixed;
  }

  #dashboard-simple .dashboard .main-simple.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  #dashboard-simple .dashboard-simple .row.main-simple .col {

  }


</style>
