<template>
  <!--v-if="fieldFormArray"-->

  <div class="schema-form-array" >
    <component-base v-bind:field-form="fieldForm">
      <ol class="list-group">
        <li v-for="(fieldValue, index) in arrayValues" class="list-group-item list-group-item-action"
            :class="fieldForm.fieldHtmlClass" :key="resolveStringifiedArrayValueKey(fieldValue, index)">
          <a href="" v-show="!(fieldForm.readonly || fieldForm.remove === null)"
             @click.prevent="deleteFromArray(fieldValue, index)"
             :disabled="resolveMinItems(fieldForm) >= arrayValues.length || isDroppable"
             style="position: relative; z-index: 20;"
             type="button" class="close pull-right">
            <span aria-hidden="true" v-if="!isDroppable">&times;</span><span class="sr-only">Close</span>
          </a>
          <builder-component @fullModelChanged="onFullModelChanged"
                             @schemaChanged="onSchemaArrayChanged" @internalSchemaChanged="onInternalSchemaChanged"
                             :full-model="fullModel"
                             :schema-form-service="schemaFormService"
                             :parent-index="index" :parent-form="resolveParentForm(index)" :merged-form="resolveMergedForm(index)" :is-droppable="isDroppable"
                             :schema="resolveFieldSchema(index)" :form="resolveMergedForm(index)" :value="fieldValue"
                             :field-mapper="fieldMapper"></builder-component>


          <div v-sf-key="{ 'fieldForm': fieldForm, 'index': index, 'fieldValue':fieldValue }"></div>
        </li>
      </ol>
      <div class="clearfix" style="padding: 10px;">
        <a href="" @click.prevent="appendToArray(resolveMaxItems(fieldForm))"
           v-show="!(fieldForm.readonly || fieldForm.add === null)"
           :disabled="resolveMaxItems(fieldForm) <= arrayValues.length || isDroppable"
           class="btn pull-right"
           :class="fieldForm.btnStyle && fieldForm.btnStyle.add || 'btn-primary'">
          <i class="glyphicon glyphicon-plus"></i>
          {{ fieldForm.add || 'Add'}}
        </a>
      </div>
    </component-base>
  </div>
</template>

<script>

  import componentMixin from './componentMixins'
  import droppableMixins from './droppableMixins'
  import containerMixin from './containerMixin'
  import BuilderComponent from './BuilderComponent'
  import utils from '../../utils/utils'
  import vueUtils from "../../utils/vueUtils";
  import schemaFormElements from './schemaFormElements'
  import forEach from 'lodash/forEach'
  import merge from 'lodash/merge'
  import ObjectPath from "../../utils/ObjectPath";

  export default {
    name: "SfArray",
    components: {BuilderComponent},
    mixins: [componentMixin, droppableMixins, containerMixin],
/*    props: {
      fullModel: {type: Object}
    },*/
    data() {
      return {
        arrayItem: null,
        arrayValues: [{}],
        fieldFormArray: []
      }
    },
    watch: {
      arrayValues: {
        handler(newVal, oldVal) {
          // this.localValue = newVal;
          this.updateFormItems();
        },
        deep: true
      }
    },
    created() {
      if (this.isDroppable) {
        const that = this;
        this.$bus.$on('selectedItemModelApplied', that.onSelectedItemModelAppliedInArray);
      }
    },
    beforeDestroy() {
      if (this.isDroppable) {
        const that = this;
        this.$bus.$off('selectedItemModelApplied', that.onSelectedItemModelAppliedInArray);
      }
    },
    mounted() {
      let key = utils.clone(this.fieldForm.key);
      let vals = utils.getValueFromModel(this.fullModel, key);
      if(vals)
        this.arrayValues = vals;

      this.updateFormItems();
    },
    methods: {
      resolveParentForm: function (index) {
        return {
          parentForm: this.parentForm,
          arrayIndex: this.parentForm?this.parentForm.arrayIndex:null
        }
      },
      onFullModelChangedArray: function(changedModel){
        console.log('ARRAY fullModelChanged emit field: '+this.fieldForm.schemaFieldKey, this.fieldForm);
        this.$emit('fullModelChanged', changedModel);
      },
      resolveStringifiedArrayValueKey: function(arrayValue, index){
      //  console.log('SfArray resolveStringifiedKey: '+this.fieldForm.key+' - ' +this.fieldForm.guid, this.fieldForm);
        return index;
      },
      resolveMaxItems: function(fieldForm){
        let maxItems = 1000000;
        if (fieldForm.schema && fieldForm.schema.maxItems){
          maxItems = fieldForm.schema.maxItems;
        }else if (fieldForm.maxItems){
          maxItems = fieldForm.maxItems
        }
        return maxItems;
      },
      resolveMinItems: function(fieldForm){
        let minItems = 0;
        if (fieldForm.schema && fieldForm.schema.minItems){
          minItems = fieldForm.schema.minItems;
        }else if (fieldForm.minItems){
          minItems = fieldForm.minItems;
        }
        return minItems;
      },
      resolveFieldSchema: function(index){
        const schemaItems = this.fieldSchema.schema.items;
      //  console.log('Resolved schema items at index: '+index, schemaItems, this.fieldSchema.schema);
        return schemaItems;
      },
      resolveMergedForm: function(index){
         const component = this.fieldFormArray[index];
         //console.log('Resolved array component at index: '+index, component, this.fieldFormArray);
         return component;
      },
      onSelectedItemModelAppliedInArray: function (item) {
        /*console.log('Received array onSelectedItemModelApplied', this.fieldForm, item);
        const that = this;
        this.$nextTick(function () {
          that.mergeInContainerLeafItem(item, '[].');
        })*/
      },
      onInternalSchemaChanged: function (schemaArray) {

        console.log('onInternalSchemaChanged - start', schemaArray, utils.clone(this.fieldForm.items));
        const that = this;
        if (!schemaArray || schemaArray.length === 0) {
          this.fieldForm.schema.items = {};
          this.fieldForm.items = [];
        } else {
          const schemaAndForm = this.updateFirstItemsKeyRecursively(this.fieldForm, schemaArray, '[].');

          this.fieldForm.schema.items = schemaAndForm.schema;
          this.fieldForm.items = schemaAndForm.form;
        }
        console.log('onInternalSchemaChanged - end', schemaArray, utils.clone(this.fieldForm));

        this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm, componentType: 'array '});
        this.$nextTick(function () {
          that.updateFormItems();
        });
      },
      onSchemaArrayChanged: function (schemaArray) {

        console.log('onSchemaArrayChanged Array', schemaArray, this.fieldForm);

        let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);
        let builderFormArray = schemaAndForm.form;
        let builderSchema = schemaAndForm.schema;

        this.fieldForm.schema.items = builderSchema.properties;

        // update child items keys
        const that = this;
        forEach(builderFormArray, function (formItem) {
          formItem.key = that.fieldForm.key + '[].' + formItem.key;
        });

        this.fieldForm.items = builderFormArray;

        this.$emit('schemaChanged', {schemaFormComponent: this.fieldForm});
      },
      updateArrayIndexRecursively: function(index, eachForm, fieldForm){
        const that = this;
        let items = eachForm.items || [];
        if (eachForm.type==='tabs'){
          items = eachForm.tabs;
        }
        const clonedItems = utils.clone(items);
        let tmpItems = [];
        forEach(clonedItems, function(item){
          if (item.key) {
            item = that.resolveSingleComponentCopyWithIndex(index, item, fieldForm);
          }
          if (item.type==='section' || item.type==='tabs'){
            item.arrayIndex = index;
          }
          if (item.items && item.items.length>0){
            const updatedItems = that.updateArrayIndexRecursively(index, item, fieldForm);
            item.items = utils.clone(updatedItems);
          }else if (item.tabs && item.tabs.length>0){
            const updatedTabs = that.updateArrayIndexRecursively(index, item, fieldForm);
            item.tabs = utils.clone(updatedTabs);
          }
          tmpItems.push(item);
        });
        return tmpItems;
      },
      resolveSingleComponentCopyWithIndex: function(index, eachForm, fieldForm){
        let slice = Array.isArray(eachForm.key) ? eachForm.key.slice(fieldForm.key.length) : eachForm.key;
        let eachFormCopy = utils.clone(eachForm);
        eachFormCopy.key = slice;
        const copy = this.copyWithIndex(eachFormCopy, index);
        copy.key = fieldForm.key.concat(copy.key);
        return copy;
      },
      updateFormItems: function () {
        const fieldArray = [];
        const that = this;

        for (let i = 0; i < this.arrayValues.length; i += 1) {

          const clonedItems = merge([], utils.clone(this.fieldForm.items));
        //  console.log('Cloned fielfForm Items in array', clonedItems);
          const newFieldFormItems = clonedItems.map((eachForm, index) => {
            if (eachForm.key && that.fieldForm.key) {
              return that.resolveSingleComponentCopyWithIndex(i, eachForm, that.fieldForm);
            } else if (eachForm.type === 'section') {
              const sectionItems = that.updateArrayIndexRecursively(i, eachForm, that.fieldForm);
              that.$set(eachForm, 'items', sectionItems);
              that.$set(eachForm, 'arrayIndex', i)
              return eachForm;
            } else if (eachForm.type === 'tabs') {
              const tabsItems = that.updateArrayIndexRecursively(i, eachForm, that.fieldForm);
              forEach(tabsItems, function(item){
                delete item.key;
              });
              that.$set(eachForm, 'tabs', tabsItems);
              that.$set(eachForm, 'arrayIndex', i)
              return eachForm;
            } else {
              return utils.clone(eachForm);
            }

          });
          //console.log('Push to FieldArray New Field Form Items for index: '+i, newFieldFormItems, clonedItems, this.fieldForm.items);
          if (newFieldFormItems && newFieldFormItems.length>0)
            fieldArray.push(newFieldFormItems);
        }
        this.fieldFormArray = fieldArray;
      },
      copyWithIndex: function (field, index) {
        const that = this;
        const copy = utils.clone(field);
        copy.arrayIndex = index;
        utils.traverseForm(copy, function () {
          that.setIndex(copy, index);
        });
        return copy;
      },
      findFirstIndexOfType: function(arr, type){
        if (!arr)
          return -1;
        for (let i=0;i<arr.length;i++){
          if (typeof arr[i]===type)
            return i;
        }
        return -1;
      },
      setIndex: function (field, index) {
        field.arrayIndex = index;
        if (field.key) {
          // todo fix mutable object
          // eslint-disable-next-line no-param-reassign
          let tmpKey = field.key;
          if (!Array.isArray(field.key)) {
            tmpKey = ObjectPath.parse(tmpKey);
          }
          let i = tmpKey.indexOf("");
          if (i > -1){
            tmpKey[i]= index;
          }

          field.key = tmpKey;
        }
      },
      deleteFromArray: function (fieldValue, index) {
        console.log('deleteFromArray', fieldValue, index);
        vueUtils.vueSplice(this.fullModel, utils.clone(this.fieldForm.key), index, 1);
        this.arrayValues.splice(index, 1);
        //this.updateFormItems();
      },
      resolveArrayItem: function () {
        //let toAppend = utils.selectOrSet() // TODO resolve defaults
      //  console.log('resolveArrayItem', this.fieldSchema.schema.items);
        switch (this.fieldSchema.schema.items.type) {
          case 'object':
            this.arrayItem = {};
            break;
          case 'array':
            this.arrayItem = [];
            break;
          case 'number':
          case 'integer':
            this.arrayItem = 0;
            break;
          default:
            this.arrayItem = '';
        }

        return this.arrayItem;
      },
      appendToArray: function (maxItems) {
        if (maxItems && maxItems <= this.arrayValues.length || this.isDroppable)
          return;

        this.arrayValues.push(this.resolveArrayItem());
      }
    }
  }
</script>

<style scoped>

</style>
