var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.baseComponent,
    {
      tag: "component",
      class: { active: _vm.isActive },
      attrs: { to: _vm.elementTo(_vm.config), exact: "", tag: "li" },
    },
    [
      _vm.isMenu
        ? _c(
            "a",
            {
              attrs: { "data-toggle": "collapse", href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.collapseMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { class: _vm.config.icon }),
              _c("p", [
                _vm._v(_vm._s(_vm.config.title) + "\n      "),
                _c("b", {
                  staticClass: "caret",
                  class: { rotated: !_vm.collapsed },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.$slots.default || _vm.isMenu
        ? _c("collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.collapsed,
                    expression: "!collapsed",
                  },
                ],
                staticClass: "collapse-menu",
              },
              [_c("ul", { staticClass: "nav" }, [_vm._t("default")], 2)]
            ),
          ])
        : _vm._e(),
      _vm.children.length === 0 && !_vm.$slots.default && _vm.config.link
        ? _vm._t("title", function () {
            return [
              _c(
                _vm.elementType(_vm.config.link, false),
                {
                  tag: "component",
                  attrs: {
                    to: _vm.elementTo(_vm.config),
                    exact: "",
                    target:
                      _vm.config.linkTarget === "NEW_WINDOW"
                        ? "_blank"
                        : "_self",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onItemClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.parent
                    ? [
                        _vm.config.icon
                          ? _c("i", { class: _vm.config.icon })
                          : _vm._e(),
                        !_vm.config.icon
                          ? _c("span", { staticClass: "sidebar-mini-icon" }, [
                              _vm._v(_vm._s(_vm.config.title.substring(0, 1))),
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "sidebar-normal" }, [
                          _vm._v(_vm._s(_vm.config.title)),
                        ]),
                      ]
                    : [
                        _c("i", { class: _vm.config.icon }),
                        _c("p", [_vm._v(_vm._s(_vm.config.title))]),
                      ],
                ],
                2
              ),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }