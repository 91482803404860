var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "help-container" }, [
    _vm.shouldShowTitle
      ? _c(
          "label",
          { staticClass: "control-label", class: _vm.fieldForm.labelHtmlClass },
          [_vm._v(_vm._s(_vm.fieldForm.title))]
        )
      : _vm._e(),
    _c("div", {
      staticClass: "helpvalue schema-form-helpvalue small",
      class: _vm.fieldForm.fieldHtmlClass,
      style: _vm.fieldForm.style,
      domProps: { innerHTML: _vm._s(_vm.helpValue) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }