var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chart-builder-context-container p-2 mt-2 mr-1" },
    [
      _c("strong", [_vm._v("Config")]),
      _c("hr"),
      _vm.currentComponentEditor && _vm.currentConfig
        ? _c(_vm.currentComponentEditor, {
            tag: "component",
            attrs: { value: _vm.currentConfig },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }