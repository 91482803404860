var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "charting-container" },
    [
      _vm.renderChart && _vm.chartDataset
        ? _c("vue-df-e-chart-wrapper", {
            ref: "chartWrapper",
            attrs: {
              allowedResizeDirections: _vm.allowedResizeDirections,
              "chart-config": _vm.chartConfig,
              group: _vm.group,
              height: _vm.contentHeight,
              width: "100%",
              "manual-update": _vm.manualUpdate,
              resizable: _vm.resizable,
              theme: _vm.theme,
            },
            on: { onresize: _vm.onResize },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }