var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-df-dashboard-container",
    {
      key: _vm.dashboardName,
      ref: "dashboardContainer",
      attrs: {
        "crud-service-name": _vm.crudServiceName,
        "dashboard-name": _vm.dashboardName,
        "dashboard-service-instance": _vm.dashboardServiceInstance,
        dashboardsContainerClass: "dashboard-tabs",
        dashboardsContentClass: "tab-content",
        "default-mapper": _vm.defaultMapper,
        "disable-add": _vm.disableAdd,
        "disable-edit": _vm.disableEdit,
        guid: _vm.guid,
        "include-widgets": _vm.includeWidgets,
        "input-context": _vm.inputContext,
        model: _vm.dashboardsModel,
        navigationPosition: _vm.navigationSettings.position,
        "parent-dashid": _vm.parentDashid,
        "parent-widget-id": _vm.parentWidgetId,
        permissions: _vm.permissions,
        "selected-dashboard-index": _vm.selectedDashboardIndex,
        structures: _vm.structures,
        "use-provided-model": _vm.useProvidedModel,
      },
    },
    [
      _vm.dashboardEditMode
        ? _c(
            "div",
            {
              staticClass: "dashboard-tabs-navigation nav-tabs-navigation",
              attrs: { slot: "navigation" },
              slot: "navigation",
            },
            [
              _c(
                "div",
                { staticClass: "dashboard-tabs-wrapper nav-tabs-wrapper" },
                [
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vue-df-dropdown",
                          {
                            staticClass: "nav-item btn-rotate dropdown last1",
                            attrs: {
                              direction: "none",
                              icon: "fas fa-cog",
                              position: "left",
                              tag: "li",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "nav-link",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function ({ isOpen }) {
                                        return _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-toggle",
                                            attrs: {
                                              "aria-expanded": isOpen,
                                              "aria-haspopup": "true",
                                              "data-toggle": "dropdown",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "nc-icon nc-bell-55",
                                            }),
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-lg-none d-md-block",
                                                },
                                                [_vm._v("Export Dashboard")]
                                              ),
                                            ]),
                                          ]
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1268195838
                                ),
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addDashboard()
                                      },
                                    },
                                  },
                                  [_vm._v("Add Dashboard")]
                                ),
                                _c("a", { attrs: { href: "#" } }, [_c("hr")]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.importDashboard()
                                      },
                                    },
                                  },
                                  [_vm._v("Import Dashboard")]
                                ),
                                _c("a", { attrs: { href: "#" } }, [_c("hr")]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.shareDashboard()
                                      },
                                    },
                                  },
                                  [_vm._v("Share Dashboard")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }