var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _vm.fieldForm.isLazy
          ? _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.lazy",
                  value: _vm.localValue,
                  expression: "localValue",
                  modifiers: { lazy: true },
                },
              ],
              staticClass: "form-control",
              class: [_vm.isValid ? "" : "is-invalid"],
              style: _vm.fieldForm.style,
              attrs: {
                required: _vm.fieldForm.required,
                placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
                rows: _vm.fieldForm.rows,
                cols: _vm.fieldForm.cols,
                readonly: _vm.fieldForm.readonly,
              },
              domProps: { value: _vm.localValue },
              on: {
                change: function ($event) {
                  _vm.localValue = $event.target.value
                },
              },
            })
          : _vm._e(),
        !_vm.fieldForm.isLazy
          ? _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localValue,
                  expression: "localValue",
                },
              ],
              staticClass: "form-control",
              class: [_vm.isValid ? "" : "is-invalid"],
              style: _vm.fieldForm.style,
              attrs: {
                required: _vm.fieldForm.required,
                placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
                rows: _vm.fieldForm.rows,
                cols: _vm.fieldForm.cols,
                readonly: _vm.fieldForm.readonly,
              },
              domProps: { value: _vm.localValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.localValue = $event.target.value
                },
              },
            })
          : _vm._e(),
        !_vm.isValid
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }