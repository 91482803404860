<template>
  <div>
    <component-base v-bind:field-form="fieldForm">
      <treeselect
        v-if="toShow"
        :required="fieldForm.required"
        :class="[isValid ? '': 'is-invalid', fieldForm.fieldHtmlClass]"
        :always-open="fieldForm.alwaysOpen"
        :disabled="fieldForm.readonly || fieldForm.disabled"
        :default-expand-level="fieldForm.defaultExpandLevel || 1"
        :disable-branch-nodes="fieldForm.branchNodesDisabled===undefined?true:fieldForm.branchNodesDisabled"
        :multiple="fieldForm.multiSelect || false"
        :flat="fieldForm.flat || false"
        :normalizer="normalizer"
        :sort-value-by="fieldForm.sortSelectedBy || 'INDEX'"
        :options="internalTitleMap"
        :show-count="fieldForm.showCount || true"
        :placeholder="fieldForm.placeholder"
        @open="onOpen"
        v-model="internalValue"
      >
        <label :class="labelClassName" slot="option-label"
               slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }">
          <span v-if="node.isBranch"><img :src="node.raw[branchImage]" class="mr-2" height="15"
                                          v-if="node.raw[branchImage]"
                                          width="15">{{ node.label }}</span>
          <span v-if="!node.isBranch"><img :src="node.raw[leafImage]" class="mr-2" height="15"
                                           v-if="node.raw[leafImage]"
                                           width="15">{{ node.label }}</span>

          <span :class="countClassName" v-if="shouldShowCount">({{ count }})</span>
        </label>
        <label slot="value-label" slot-scope="{node}">
          <span v-if="node.isBranch"><img :src="node.raw[branchImage]" class="mr-2" height="15"
                                          v-if="node.raw[branchImage]"
                                          width="15">{{ resolveNodeLabel(node) }}</span>
          <span v-if="!node.isBranch"><img :src="node.raw[leafImage]" class="mr-2" height="15"
                                           v-if="node.raw[leafImage]"
                                           width="15">{{ resolveNodeLabel(node) }}</span>
        </label>
      </treeselect>
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage }}</small>
    </component-base>
  </div>
</template>

<script>

// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import componentMixin from './componentMixins'
import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
import utils from "../../utils/utils";
import titleMapMixins from "./titleMapMixins"

export default {
  name: "SfSelectTree",
  components: {Treeselect},
  mixins: [componentMixin, simpleComponentMergeInMixin, titleMapMixins],
  data() {
    return {
      toShow: true,
      treeValue: this.fieldForm.multiSelect ? [] : '',

      fullNodeAsKey: this.fieldForm.fullNodeAsKey || false, // TODO - to be removed
      copyFullObject: this.fieldForm.copyFullObject || false
    }
  },
  methods: {
    findRecursive: function (val, keyName, childrenKey, titleMap) {
      let o;
      if (titleMap) {
        titleMap.some(function iter(a) {
          if (a[keyName] == val) {
            o = a;
            return true;
          } else {
            return Array.isArray(a[childrenKey]) && a[childrenKey].some(iter);
          }
        });
      }
      return o;
    },
    _findByProperty: function (val, keyName, childrenKey, titleMap, propName) {
      const foundObject = this.findRecursive(val, keyName, childrenKey, titleMap);
      //console.log('Resolving by property name', foundObject, propName, titleMap);
      if (foundObject) {
        return foundObject[propName];
      }
    },
    _resolveValueToCopyOnChange: function (val, item) {
      let targetKey = item.target || item;
      const that = this;
      let prop = item.property || 'valueKey'; // can be: valueKey, labelKey, childrenKey
      let copyFull = item.full || this.copyFullObject;

      let obj = val;
      if (copyFull) {
        obj = this.findRecursive(val, this.valueKey, this.childrenKey, this.internalTitleMap);
      } else {
        obj = this._findByProperty(val, this.valueKey, this.childrenKey, this.internalTitleMap, prop);
      }
      return obj;
    },
    recursivelyResolveNodeLabel: function (node, label) {
      label = node.label;
      if (node.parentNode) {
        return this.recursivelyResolveNodeLabel(node.parentNode, label) + " - " + label;
      } else {
        return node.label;
      }
    },
    resolveNodeLabel: function (node) {
      //  console.log('Resolving node value label', node);
      let res = node.label;
      if (res.indexOf('(unknown)') > -1) {
        return this.fieldForm.placeholder;
      }
      res = this.recursivelyResolveNodeLabel(node);
      return res;
    },
    normalizer(node) {
      return this.resolveNormalizedNode(node);
    },
    resolveNormalizedNode(node) {
      return {
        id: node[this.valueKey],//this.resolveValueNodeKey(node),
        label: node[this.labelKey],
        children: node[this.childrenKey]
      }
    },
    resolveValueNodeKey: function (node) {

      if (this.fullNodeAsKey && !node[this.childrenKey]) {
        let val = {};
        val[this.valueKey] = node[this.valueKey];
        val[this.childrenKey] = node[this.childrenKey];
        val[this.labelKey] = node[this.labelKey];
        console.log('Normalized full key returned value: ', val);
        return val;
      } else {
        let val1 = node[this.valueKey];
        console.log('Normalized returned value: ', val1);
        return val1;
      }
    },
    onOpen: function (instanceId) {
      // console.log('instanceId: ', instanceId);
      this.onFilter();
    },
    onFilter: function () {
      if (this.tmpTitleMap && this.tmpTitleMap.length === 0) {
        this.tmpTitleMap = this.titleMap || [];
      } else {
        if (this.tmpTitleMap !== this.titleMap) {
          this.titleMap = this.tmpTitleMap;
        }
      }
      if (this.fieldForm.options && this.fieldForm.options.filter) {
        const res = utils.safeFilter(this.fieldForm.options.filter, this.titleMap, this.fullModel, this.fieldForm);
        console.log('Filtered sfSelect', res, this.titleMap);
        this.$set(this, 'titleMap', res);
      }
    },
  }
}
</script>

<style scoped>
.vue-treeselect {
  margin-top: 1px;
}

.vue-treeselect ::v-deep label {
  margin-bottom: 0px;
}
</style>
