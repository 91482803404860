<template>
  <div class="charting-container">
    <vue-df-e-chart-wrapper
      :allowedResizeDirections="allowedResizeDirections"
      :chart-config="chartConfig"
      :group="group"
      :height="contentHeight"
      :width="'100%'"
      :manual-update="manualUpdate"
      :resizable="resizable"
      :theme="theme"
      @onresize="onResize"
      ref="chartWrapper"
      v-if="renderChart  && chartDataset"></vue-df-e-chart-wrapper>
  </div>
</template>

<script>
const VueDfEChartWrapper = () => import("vue-dashboard-framework-components/src/components/vueDfComponents/shared/VueDfEChartWrapper");
import chartingUtils from 'vue-dashboard-framework-components/src/components/vueDfComponents/VueDfCharting/chartingUtils'
import commonIntegrationMixin from "./commonIntegrationMixin";
import isEmpty from 'lodash/isEmpty'

export default {
  name: "VueDfIntegrationChart",
  mixins: [commonIntegrationMixin],
  components: {VueDfEChartWrapper},
  data() {
    return {
      chartConfig: null,
      group: undefined,
      manualUpdate: false,
      theme: undefined,
      resizable: false,
      renderChart: true
    }
  },
  created() {
    this.$on('dashboardupdated', this.onDashboardUpdate)
  },
  beforeDestroy() {
    this.$off('dashboardupdated', this.onDashboardUpdate)
  },
  methods: {
    onDashboardUpdate: function () {
      this.resizeChart();
    },
    resizeChart: function () {
      const that = this;
      this.renderChart = false;
      this.$nextTick(function () {
        that.renderChart = true;
      });
    },
    onResize: function (data) {
    //  console.log('On Resized', data, this.model, this.dashboardEditMode);
      this.model.config.contentStyle = {
        defaultId: data
      };
    },
    applyChartConfig: function () {
      if (this.uiDefinition && this.uiDefinition.chartDefinition) {
        const that = this;
        this.$nextTick(function () {
          const context = {
            dataModel: that.chartDataset,
            dimensions: that.chartDimensions
          }
          const res = chartingUtils.safeEval(that.uiDefinition.chartDefinition, context, that.chart, {
            http: that.$http,
            jsulator: that.$jsulator
          });

          this.$set(this, 'chartConfig', res);
        });
      }

    }
  },
  mounted() {
    this.applyChartConfig();
  },
  watch: {
    dashboardEditMode: {
      handler(newVal, oldVal) {
        this.resizable = newVal;
        const that = this;
        this.renderChart = false;
        this.$nextTick(function () {
          that.renderChart = true;
        });
      }
    }
  },
  computed: {
    contentHeight: function () {
      let height = 300;

      const contentStyle = this.model.config.contentStyle;
      if (contentStyle && contentStyle.defaultId && contentStyle.defaultId.height) {
        height = contentStyle.defaultId.height;
      }
      if (this.isMaximized && this.maximizedArea) {
        height = this.maximizedArea.height-140;
      }
      //console.log('Content Height integration chart', this.isMaximized, this.maximizedArea, height);

      return height;
    },
    allowedResizeDirections: function () {
      return ['b'];
    },
    chart: function () {
      return this.$refs.chartWrapper ? this.$refs.chartWrapper.$refs.eChart : {};
    },
    chartDimensions: function () {
      const dim = !isEmpty(this.model.config.data.configuration.dimensions) ? this.model.config.data.configuration.dimensions : null;
      if (dim) {
        return dim.split(',').map(function (item) {
          return item.trim()
        });
      } else {
        return null;
      }
    },
    chartDataset: function () {
      return this.dataModel && this.dataModel.result ? this.dataModel.result : undefined;
    },
    /* chartConfig: function () {
       if (this.uiDefinition && this.uiDefinition.chartDefinition) {
         const context = {
           dataModel: this.chartDataset,
           dimensions: this.chartDimensions
         }
         const res = chartingUtils.safeEval(this.uiDefinition.chartDefinition, context, this.chart, {jsulator: this.$jsulator});
         return res;
       }
       return undefined;
     }*/
  }
}
</script>

<style scoped>
.charting-container {
  height: 100%;
}
</style>
