import Vue from 'vue'

class VertxWebSocketHandler {
  constructor(options) {
    this.ref = options.ref;
/*<<<<<<< HEAD
   // console.log('Set ref: ', options.ref);
=======
//    console.log('Set ref: ', options.ref);
>>>>>>> 66dc70c52b5b12e1b6863b55bf8a65ea988b4d09*/
    this.dataModel = options.dataModel;
    this.topicPrefix = 'pipeline.ws.eb.topic.';
  }

  _resolveTenantName(){
    const tenantName = Vue.userService.getCurrentUser().tenantName.toLowerCase().split(' ').join('_');
    return tenantName;
  }
  _isAdminTopic(topic){
    if (topic && (topic.indexOf('pipeline.ws.application.admin.')>-1 || topic.indexOf('pipeline.ws.system.admin.')>-1)){
      return true;
    }
    return false;
  }
  _resolveWsFullAddress(topic){
    const tenantName = this._resolveTenantName();
    let topicAddress = this.topicPrefix + tenantName + '.' + topic;// TODO Add Prefix to configuration
    if (this._isAdminTopic(topic)){
      // check User roles and throw exception
      topicAddress = topic;
    }
    return topicAddress;
  }
  subUnsubEventBus(action, isSubscription) {
    const that = this;
    Vue.eventBus.instance(true)
      .then(state => {
        console.log('EB State', state, action, isSubscription, Vue.userService.getCurrentUser());

        const topic = that.ref.resolveContextObject(action.topicName, {
          data: {},
          definition: action
        }, 'string');

        let fullAddress = that._resolveWsFullAddress(topic);
        const isAdminTopic = that._isAdminTopic(topic);

        if (isAdminTopic) {
          fullAddress = fullAddress+'=='+that.ref.dashid + '::' + that.ref.id;
        }

        console.log('Is isSubscription: '+isSubscription+' isAdminTopic: '+isAdminTopic+' - Full Address: action.topicName: ' + topic, action, fullAddress, that.ref);

        const resolvedHeaders = that.ref.resolveContextObject(action.headers, {data:{}, definition: action})

        console.log('Resolved headers: ', resolvedHeaders, action.headers);

        const handler = {
          address: fullAddress,
          headers: resolvedHeaders || {},
          callback: function (err, message) {
            console.log('Received WS message', message);
            if (!err) {
              if (action.publishEvents && action.publishEvents.events && action.publishEvents.events.length > 0) {
                that.ref.executePublishEvents(action.publishEvents, message.body);
              }
            } else {
              console.error('Error from sender', err);
            }
          }
        }
        if (isSubscription) {
          Vue.eventBus.regHandlers(handler, that.ref.id)
        } else {
          Vue.eventBus.unRegHandlers(handler, that.ref.id)
        }
      }).catch(err => {
      console.error('Error getting WS instance', err);
    });
  }
  async executeService(connection, eventContext){
    const that = this;
    if (connection && connection.actions && connection.actions.length > 0) {
      connection.actions.forEach(function (action) {
        const topicAddress = that.ref.resolveContextObject(action.topicName, {
          data: that.ref.resolveDataFromObject(eventContext),
          definition: action
        }, 'string');

        const fullTopicAddress = that._resolveWsFullAddress(topicAddress);
        console.log('WS Send/Publish Resolving topicAddress: ' + action.topicName, action, eventContext)
        const valueObject = that.ref.resolveContextObject(action.contextObject, {
          data: that.ref.resolveDataFromObject(eventContext),
          definition: action
        });
        console.log('WS Resolving valueObject: ' + action.topicName, action, eventContext, valueObject);
        const headers = {
          'tenant.name': that._resolveTenantName(),
          'header.pipeline.user': Vue.userService.getCurrentUser().username
        }

        switch (action.actionType) {
          case 'SEND':
            // send - will return result
            Vue.eventBus.instance(true)
              .then(eventBus => {
                if (action.eventPublish && action.eventPublish.events) {
                  eventBus.send(fullTopicAddress, valueObject, headers, function (err, message) {
                    if (err) {
                      console.error('Error on topic: ' + action.topicName, err);
                    } else {
                      that.ref.executePublishEvents(action.eventPublish, message);
                    }
                  });
                } else {
                  eventBus.send(fullTopicAddress, valueObject, headers);
                }
              })
              .catch(err => {
                console.error("Error getting WS instance", err);
              });
            break;
          case 'PUBLISH':
            // publish - will not return result
            Vue.eventBus.instance(true)
              .then(eventBus => {
                eventBus.publish(fullTopicAddress, valueObject, headers);
              })
              .catch(err => {
                console.error("Error getting WS instance", err);
              });
            break;
        }
      });
    }
  }
}

export default VertxWebSocketHandler;
