var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.internalWidgets && _vm.internalWidgets.length > 0
      ? _c("div", { staticClass: "text-danger text-center p-5" }, [
          _vm._v("\n    There is no widgets to select\n  "),
        ])
      : _vm._e(),
    _vm.internalWidgets
      ? _c("div", [
          _c(
            "div",
            { staticClass: "widget-group-container" },
            _vm._l(_vm.groupWidgets, function (groupArray) {
              return _c(
                "div",
                { staticClass: "row mb-4" },
                _vm._l(groupArray, function (value, type) {
                  return _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("strong", [_vm._v(_vm._s(type))]),
                      _c("hr"),
                      _vm._l(value, function (widget, key) {
                        return [
                          _c("dt", [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addWidget(widget)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(widget.title))]
                            ),
                          ]),
                          widget.description
                            ? _c("dd", [_vm._v(_vm._s(widget.description))])
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }