const showHideMixin = {
  data() {
    return {
      showHide: true,
      showHideEnabled: false
    }
  },
  created() {
    if (this.fieldForm.showHide !== undefined) {
      this.showHideEnabled = true;
      this.showHide = false;
    }
  },
  computed: {
    shouldShow: function () {
      if (this.fieldForm.showHide) {
        return this.showHide;
      }
      return true;
    },
    showHideTitle: function () {
      if (this.showHide) {
        return '- ';
      } else {
        return '+ ' + this.fieldForm.title || this.fieldSchema.title;
      }
    }
  },
  methods: {
    toogleShowHide: function () {
      this.showHide = !this.showHide;
    }
  }
}
export default showHideMixin
