<template>
  <component :is="baseComponent"
             :to="elementTo(config)"
             :class="{active: isActive}"
             exact
             tag="li">
    <a v-if="isMenu"
       data-toggle="collapse"
       href="#"
       @click.prevent="collapseMenu">
      <i :class="config.icon"></i>
      <p>{{config.title}}
        <b class="caret" :class="{rotated: !collapsed}"></b>
      </p>
    </a>
    <collapse-transition v-if="$slots.default || isMenu">
      <div v-show="!collapsed" class="collapse-menu">
        <ul class="nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>
    <slot name="title" v-if="children.length === 0 && !$slots.default && config.link">
      <component
        :to="elementTo(config)"
        exact
        :is="elementType(config.link, false)"
        :target="config.linkTarget==='NEW_WINDOW'?'_blank':'_self'"
        @click.prevent="onItemClick">
        <template v-if="parent">
          <i v-if="config.icon" :class="config.icon"></i>
          <span v-if="!config.icon" class="sidebar-mini-icon">{{config.title.substring(0, 1)}}</span>
          <span class="sidebar-normal">{{config.title}}</span>
        </template>
        <template v-else>
          <i :class="config.icon"></i>
          <p>{{config.title}}</p>
        </template>
      </component>
      <!--:class="{active: config.active}"-->
    </slot>
  </component>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import componentMixin from '../../componentMixin';

  export default {
    name: 'VueDfAppSidebarLinkItem',
    mixins:[componentMixin],
    components: {
      CollapseTransition
    },
    props: {
      parent: Object
    },
    created(){
    //  console.log('Created SidebarLinkItem', this.config);
    },
    provide() {
      return {
      }
    },
    inject: {
      autoClose: { default: false }
    },
    data() {
      return {
        collapsed: true
      }
    },
    computed: {
      baseComponent() {
        return this.isMenu || this.config.isRoute ? 'li' : this.appConfig.isComponentMode?'li':'router-link'
      },
      isMenu() {
        return this.children.length > 0;// || this.config.itemType === 'DROP_DOWN'
      },
      dropDownSettings(){
        return this.config.itemDropDownSettings || {};
      },
      children(){
        const res = this.dropDownSettings && this.dropDownSettings.items?this.dropDownSettings.items:[];
        return res;
      },
      isActive() {
      //  console.log('Calculating isActive', this.selectedRouteConfig, this.config, this.homeRouteName);

        if (this.selectedRouteConfig &&
          (

            (this.selectedRouteConfig.item && this.selectedRouteConfig.item.name === this.config.name)
            ||
            (this.selectedRouteConfig.parent && this.selectedRouteConfig.parent.name===this.config.name)
          )
        ){
          return true;
        }
        return false
      }
    },
    methods: {
      elementTo: function(config){
        if (this.appConfig.isComponentMode===true){
          return '#';
        }
        if (config.isRoute===false){
          return config.link;
        }else{
          const that = this;
          let currentRoute = this.$router.currentRoute;//.options.routes.find(r => r.path === that.$route.path);
   //       console.log('Current route ', currentRoute, this.config);

          const def = currentRoute.meta.def;
          const name = (this.parent?this.parent.name:'')+'/'+this.config.name;
       //   console.log('Route nanme', name, def.routeKey.split('=').join('/'));

          const newRoute = { name:  name};
       //   console.log('New Route: ', newRoute);
          return newRoute;
        }
      },
      elementType(link, isParent = true) {
        if (this.appConfig.isComponentMode===true){
          return  isParent ? 'li' : 'a';
        }
        let itemType = 'router-link';
        if (link.isRoute === false) {
          itemType = isParent ? 'li' : 'a'
        } else {
          itemType = 'router-link';
        }
      //  console.log('itemType link elementType: ', isParent, link, itemType);
        return itemType;
      },
      collapseMenu() {
        this.collapsed = !this.collapsed
      },
      onItemClick() {
      //  console.log('Clicked item', this.config, this.parent, this.selectedRouteConfig);
        this.$emit('selected', { item: this.config, parent: this.parent});
        if(this.autoClose) {
          this.$vueDfAppSidebar.showSidebar = false;
        }
      }
    },
    mounted() {
      if (this.addLink) {
        this.addLink(this)
      }
      if (this.config.collapsed !== undefined) {
        this.collapsed = this.config.collapsed
      }
      if (this.isActive && this.isMenu) {
        this.collapsed = false
      }
    },
    destroyed() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      if (this.removeLink) {
        this.removeLink(this)
      }
    }
  }
</script>
<style scoped>
  .caret.rotated {
    transform: rotate(180deg);
  }
</style>
