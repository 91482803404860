<template>
  <div class="form-group checkbox-group"  >
    <div :key="resolveStringifiedKey(fieldForm)+localValue" class="checkbox form-check"
         :class="[isValid ? '': 'is-invalid', fieldForm.fieldHtmlClass]">
      <input  :disabled="fieldForm.readonly || fieldForm.disabled"
              :required="fieldForm.required"
             class="form-check-input" type="checkbox" v-model="localValue">
      <label :class="fieldForm.labelHtmlClass" class="form-check-label"><span v-html="fieldForm.title"></span></label>
    </div>
    <div :class="fieldForm.descriptionHtmlClass" class="text-muted small help-block" v-if="fieldForm.description">
      <span v-html="fieldForm.description"></span>
    </div>
    <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
    <div v-if="validationError" class="invalid-feedback error-text">{{ validationError }}</div>
  </div>

</template>

<script>
  import componentMixin from './componentMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'

  export default {
    name: "SfCheckBox",
    mixins: [componentMixin, simpleComponentMergeInMixin]
  }
</script>

<style scoped>
  .help-block {
    margin-top: -5px;
  }
</style>
