<template>
  <div class="expression-element-wrapper">
    <div class="expression-element-container">
      <label>Tokens</label>
      <draggable :group="{ name: 'els', pull: 'clone', put: false }" :move="(related) => onElementMove('expressionElementsDraggable',related)"
                 class="list-group" :clone="clone"
                 id="expressionElementsDraggable" ref="elements" tag="ul" v-model="tokens">

        <transition-group :name="'flip-list'" type="transition">
          <li :key="token.name" class="list-group-item" v-for="token in tokens">
            <div :title="token.name" class="">
<!--                    <span class="mr-2  text-muted" style="font-size: 0.7rem;">
                    <i class="fas fa-circle-notch"></i>
                    </span>-->
              <span>{{ token.symbol }}</span>
            </div>
          </li>
        </transition-group>
      </draggable>
      <label>Functions</label>
      <draggable :group="{ name: 'els', pull: 'clone', put: false }"  :move="(related) => onElementMove('expressionElementsDraggable',related)"
                 class="list-group" :clone="clone"
                 id="expressionElementsDraggable" ref="elements" tag="ul" v-model="functions">

        <transition-group :name="'flip-list'" type="transition">
          <li :key="func.name" class="list-group-item" v-for="func in functions">
            <div :title="func.name" class="">
<!--                    <span class="mr-2  text-muted" style="font-size: 0.7rem;">
                    <i class="fas fa-circle-notch"></i>
                    </span>-->
              <span>{{ func.name }}</span>
            </div>
          </li>
        </transition-group>
      </draggable>
      <label>Operators</label>
      <draggable :group="{ name: 'expressionElements', pull: 'clone', put: false }"  :move="(related) => onElementMove('expressionElementsDraggable',related)"
                 class="list-group" :clone="clone"
                 id="expressionElementsDraggable" ref="elements" tag="ul" v-model="operators">

        <transition-group :name="'flip-list'" type="transition">
          <li :key="operator.name" class="list-group-item" v-for="operator in operators">
            <div :title="operator.name" class="">
<!--                    <span class="mr-2  text-muted" style="font-size: 0.7rem;">
                    <i class="fas fa-circle-notch"></i>
                    </span>-->
              <span>{{ operator.symbol }}</span>
            </div>
          </li>
        </transition-group>
      </draggable>

    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import dropMixins from "./dropMixins";

export default {
  name: "ExpressionElements",
  mixins: [dropMixins],
  data() {
    return {
      tokens: [
        {
          name: 'LITERAL',
          symbol: "'string'",
          type: 'TOKEN'
        },
        {
          name: 'LOOKUP_LITERAL',
          symbol: '$variable$',
          type: 'TOKEN'
        },
        {
          name: 'NUMBER',
          symbol: 'number',
          type: 'TOKEN'
        },
        {
          name: 'OPEN_BRACKET',
          symbol: '(',
          type: 'TOKEN'
        },
        {
          name: 'CLOSE_BRACKET',
          symbol: ')',
          type: 'TOKEN'
        }
      ],
      functions: [],
      operators: [],
      metaData: {}
    }
  },
  created() {
    console.log('jsulator', this.$jsulator.jsulator.getMetadata());
    this.metaData = this.$jsulator.jsulator.getMetadata();
    this.functions = Object.values(this.metaData.functions);
    this.operators = Object.values(this.metaData.operators).map((item) => {
      return item[0]
    });
  },
  methods: {

    clone: function (evt) {
      const cloned = cloneDeep(evt); //Object.assign({}, evt);
      this.$set(cloned, 'id', this.generateUUID());
      return cloned;
    },
    generateUUID: function () {
      let d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
      }
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
  }
}
</script>

<style scoped>

.expression-element-container {
  height: 400px;
  overflow-y: scroll;
}
</style>
