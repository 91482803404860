import ElementToolbar from "./support/ElementToolbar";

const elementsMixin = {
  components: {ElementToolbar},
  props:{
    value: Object
  },
  data(){
    return {
      internalValue: null
    }
  }
}

export  default elementsMixin
