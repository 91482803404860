<template>
  <div :class="fieldForm.htmlClass" class="schema-form-tabs">
    <div :class="{'tabs-scroll': scrollTabs }" class="tabs-container">
      <span v-if="scrollTabs" class="scroll-left tab-scroll" @click.prevent="scroll_left"><i class="fa fa-chevron-circle-left"></i></span>
      <ul :id="guid" :class="[fieldForm.tabListHtmlClass, {'main-tabs': scrollTabs }]" class="nav nav-tabs sf-tabs">
        <li :class="[{'active':selectedTab===index},fieldForm.tabHtmlClass, fieldForm.selectedTabHtmlClass]"
            :disabled="fieldForm.readonly" :key="tab.title"
            @click.prevent="selectTab(tab, index, 'true')"
            class="nav-item nav-link"
            v-for="(tab, index) in fieldForm.tabs"
            v-if="isVisible(tab, index)">
          <a href="#">{{ tab.title }}</a>
        </li>
      </ul>
      <span v-if="scrollTabs" class="scroll-right tab-scroll" @click.prevent="scroll_right"><i class="fa fa-chevron-circle-right"></i></span>
    </div>
    <div :class="fieldForm.fieldHtmlClass" class="tab-content">
      <builder-component :field-mapper="fieldMapper"
                         :form="tabForm" :full-model="fullModel" :is-droppable="isDroppable" :key="count"
                         :merged-form="tabForm"
                         :parent-index="selectedTab"
                         :schema="tabSchema"
                         :schema-form-service="schemaFormService" :value="resolveValue(value, tabForm)"
                         @fullModelChanged="onFullModelChanged"
                         @internalSchemaChanged="onInternalSchemaChanged" @schemaChanged="onChildSchemaChanged"
                         is="builder-component"
                         v-if="tabForm">
      </builder-component>

    </div>
  </div>
</template>

<script>
import componentMixin from './componentMixins'
import droppableMixins from './droppableMixins'
import schemaFormElements from './schemaFormElements'
import BuilderComponent from './BuilderComponent'
import utils from '../../utils/utils'
import vueUtils from '../../utils/vueUtils'
import isEmpty from 'lodash/isEmpty'

export default {
  name: "SfTabs",
  mixins: [componentMixin, droppableMixins],
  components: {
    BuilderComponent
  },
  props: {
    parentIndex: {type: Number}
  },
  data() {
    return {
      selectedTab: 0,
      tabForm: [],
      tabSchema: {},
      tabValue: {},
      count: 0,
      visibleTabs: {},
      scrollTabs: false
    }
  },
  mounted() {
    const that = this;
    const indexToSet = this.getFirstVisibleTab() || this.fieldForm.selectedIndex || 0;
    const tab = that.fieldForm.tabs[indexToSet];
    that.selectTab(tab, indexToSet);
  },
  created() {
   /* if (this.isDroppable) {
      const that = this;
      this.$bus.$on('selectedItemModelApplied', that.selectedItemModelApplied);
    }*/
  },
  beforeDestroy() {
    /*if (this.isDroppable) {
      const that = this;
      this.$bus.$off('selectedItemModelApplied', that.selectedItemModelApplied);
    }*/
  },
  watch: {
    tabValue: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal);
      },
      deep: true
    },
    fieldForm: {
      handler(newVal, oldVal) {
        // if (this.isDroppable) {
        if (newVal !== oldVal) {
          const currentTab = this.fieldForm.tabs[this.selectedTab];
          this.selectTab(currentTab, this.selectedTab);
        }
        // }
      },
      deep: true
    }
  },
  methods: {
    scroll_left(){
      let content = document.querySelector('#'+this.guid);
      console.log("content", this.guid, content)
      content.scrollLeft -= 50;
    },
    scroll_right(){
      let content = document.querySelector('#'+this.guid);
      console.log("content", this.guid, content)
      content.scrollLeft += 50;
    },
    onFullModelChanged: function (changedModel) {
      this.$emit('fullModelChanged', changedModel);
    },
    extractSchemaRecursively: function (schemaProps, item) {
      const that = this;
      if (item.schema) {
        schemaProps[item.schemaFieldKey] = utils.clone(item.schema);
      } else if (item.items) {
        item.items.forEach(function (itemInTabItems) {
          that.extractSchemaRecursively(schemaProps, itemInTabItems);
        });
      } else if (item.tabs) {
        item.tabs.forEach(function (tab) {
          if (tab.items) {
            tab.items.forEach(function (itemInTabItems) {
              that.extractSchemaRecursively(schemaProps, itemInTabItems);
            });
          }
        });
      }
    },
    selectedItemModelApplied: function (item) {
      console.log('Received selectedItemModelApplied', this, item);
      /*console.log('Received selectedItemModelApplied', this, item);
      const that = this;
      if (this.fieldForm.guid === item.guid) {
        console.log('Received in Tabs leaf item', item, this.fieldForm);


        if (!item.schema) {
          const schema = {
            type: 'object',
            properties: {}
          }
          this.extractSchemaRecursively(schema.properties, item);
          item.schema = schema;
        }

        for (const prop in this.fieldForm) {
          delete this.fieldForm[prop];
        }
        vueUtils.vueMerge(this.fieldForm, item);

        console.log('Received in leaf item - After Merge', item, this.fieldForm);

        this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm});
      }*/
    },
    mergeExistingSchemaArrays: function (schemaArray, tabs) {
      const mergedArrays = [];
      const that = this;
      tabs.forEach(function (tab) {
        if (tab.items) {
          tab.items.forEach(function (item) {
            mergedArrays.push(item);
          });
        }
      });
      if (schemaArray) {
        schemaArray.forEach(function (item) {
          mergedArrays.push(item);
        });
      }
      return mergedArrays;
    },
    updateCurrentTab: function (schemaArray) {
      if (!schemaArray || schemaArray.length === 0) {
        this.fieldForm.tabs[this.selectedTab].items = [];
      }
    },
    onInternalSchemaChanged: function (schemaArray) {
      //  console.log('onInternalSchemaChanged', schemaArray, utils.clone(this.fieldForm.tabs));
      //  console.log('Current tab index: ' + this.selectedTab, this.fieldForm.tabs[this.selectedTab]);

      /*
      schemaArray is related to the currently selected Tab
      if schemaArray is empty - means there is no items on the tab, but there could be items on other tabs
      we should first remove all that is missing at the tab
       */

      this.updateCurrentTab(schemaArray);
      const mergedArrays = this.mergeExistingSchemaArrays(schemaArray, this.fieldForm.tabs);

      if (!mergedArrays || mergedArrays.length === 0) {
        const currentTab = this.fieldForm.tabs[this.selectedTab];
        currentTab.items = [];
        this.fieldForm.schema = {};
      } else {
        let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(mergedArrays);
        let builderFormArray = schemaAndForm.form;
        let builderSchema = schemaAndForm.schema;

        //  console.log('onInternalSchemaChanged-aftermerge', mergedArrays, utils.clone(schemaAndForm));

        const currentTab = this.fieldForm.tabs[this.selectedTab];
        currentTab.items = currentTab.items || [];
        // set schema for current tab
        currentTab.items = schemaArray;

        // set total schema
        this.fieldForm.schema = this.fieldForm.schema || {};
        this.fieldForm.schema.properties = builderSchema.properties;

        delete this.fieldForm.key;
        delete this.fieldForm.schemaKey;
        delete this.fieldForm.schemaFieldKey;

        this.selectTab(currentTab, this.selectedTab);
      }

      //  console.log('onInternalSchemaChanged - end', mergedArrays, utils.clone(this.fieldForm));

      this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm, componentType: 'tabs'});

    },
    onChildSchemaChanged: function (schemaArray) {
      //console.log('onChildSchemaChanged', schemaArray);
      let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);
      let builderFormArray = schemaAndForm.form;
      let builderSchema = schemaAndForm.schema;

      this.fieldForm.schema.properties = builderSchema.properties;

      // update child items keys
      const that = this;
      forEach(builderFormArray, function (formItem) {
        formItem.key = that.fieldForm.key + '.' + formItem.key;
      });

      this.fieldForm.items = builderFormArray;

      this.$emit('schemaChanged', {schemaFormComponent: this.fieldForm});
    },
    selectPreviousIfVisible: function (tab, index) {

      for (let prop in this.visibleTabs) {
        if (this.visibleTabs[prop]) {
          const i = parseInt(prop);
          if (i !== NaN || i !== undefined) {
            this.selectTab(this.fieldForm.tabs[i], i);
            return;
          }
        }
      }

      //this.selectTab({}, 0);
      this.selectedTab = null;
      this.tabForm = null;
      this.tabSchema = null;
      this.fieldForm.selectedIndex = null;

    },
    isVisible: function (tab, index) {
      if (isEmpty(tab.condition) || this.isDroppable) {
        this.visibleTabs[index] = true;
        return true;
      }
      let isVisible = this.resolveCondition(tab);
      this.visibleTabs[index] = isVisible;


      if (!isVisible) {
        if (index === this.selectedTab) {
          this.selectTab(this.fieldForm.tabs[0], 0);
        }
        //  this.selectPreviousIfVisible(tab, index);
      }

      return isVisible;
    },
    getFirstVisibleTab: function () {
      // console.log('Visible tabs', this.visibleTabs);
      if (!this.visibleTabs) {
        return 0;
      }
      for (let prop in this.visibleTabs) {
        if (this.visibleTabs[prop]) {
          const i = parseInt(prop);
          if (i !== NaN || i !== undefined) {
            return i;
          }
        }
      }
      return 0;
    },
    resolveCondition: function (field, parentForm) {
//        console.log('Tabs Resolving condition: ' + field.condition);
      if (field.condition) {
        const shouldKeep = utils.safeEval(field.condition, {
          model: this.fullModel,
          form: field,
          parentIndex: this.parentIndex,
          parentForm: parentForm
        });
        if (!shouldKeep) {
          let key = utils.clone(field.key);
          const val = utils.getValueFromModel(this.fullModel, key);
          if (val) {
            this.show = false;
            this.$nextTick(function () {
              key = utils.clone(field.key);
              vueUtils.vueDelete(this.fullModel, key);
              this.show = true;

            });
          }
        } else {

        }
        return shouldKeep;
      }
      return true;
    },
    selectTab: function (tab, index) {
      // console.log('Selected tab index' + index, tab);
      if (!tab)
        return;

      if (this.isDroppable && tab.items && tab.items.length > 0) {
        this.count = this.count + 1;
      }

      this.selectedTab = index;
      this.tabForm = tab.items;
      this.tabSchema = tab;
      this.fieldForm.selectedIndex = index;
    },
    resolveTabForm: function () {
      // console.log('TabForm: ', utils.clone(this.tabForm));
      return this.tabForm;  //utils.clone(this.tabForm);
    },
    resolveValue: function (value, field) {
      if (!field.key){
        return value;
      }

      const key = utils.clone(field.key);
      if (key && key.length) {
        const firstKey = field.key[0];
        if (!this.fullModel[firstKey]) {
          return undefined;
        } else {
          const val = utils.getValueFromModel(this.fullModel, key);
          return val;
        }
      }
      if (this.fullModel === undefined || this.fullModel === null) {
        return;
      }
      const val = this.fullModel[field.schemaFieldKey];
      return val;
    }
  }
}
</script>

<style scoped>

.main-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
}

.tabs-scroll {
  display: flex;
  align-items: center;
}
.schema-form-tabs {
  flex-wrap: nowrap;
}
.scroll-left {
  /*float: left;*/
}

.scroll-right {
  /*float: right;*/
}
.tab-scroll {
  padding: 3px 4px 3px 4px;
  display: inline-flex;
}

</style>
