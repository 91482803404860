<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>

<script>
import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'

export default {
  name: "VueDfDashboardEdit",
  mixins: [widgetComponentEditMixins],
  props: {
    dashboardName: String
  },
  data() {
    return {
      schemaFormCustom: {
        schemaProperties: {
          name: {
            type: 'string',
            title: 'Name',
            description: 'Enter dashboard name'
          },
          isPersonal: {
            type: 'boolean',
            title: 'Is Personal?',
            description: 'Select to enable Personal dashboards. Dashboard will be saved separately and loaded form database',
            default: false
          },
          isDashboardTypeEditable: {
            type: 'boolean',
            title: 'Is Dashboard Type Editable?',
            description: 'Select to enable dashboard type editable',
            default: true
          },
          isContainer: {
            type: 'boolean',
            title: 'Is Container',
            description: 'Select if container, otherwise define the edit UI',
            default: true
          },
          dashboardContext:{
            type: 'string',
            title: 'Dashboard Context',
            description: 'Enter context for the dashboard that will be applied to dataModel. If parent contains the dataModel, it will be merged'
          }
        },
        formItem: {
          title: 'Dashboard Definition',
          items: [
            {
              type: 'section',
              sectionContainerClass: "row",
              sectionChildClass: "col",
              items: [
                {
                  type: 'section',
                  sectionType: 'fieldset',
                  title: 'Common',
                  items: [
                    {
                      type: 'section',
                      sectionContainerClass: "row",
                      sectionChildClass: "col-4",
                      items: [
                        {
                          key: 'isPersonal'
                        },
                        {
                          key: 'isDashboardTypeEditable'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: 'section',
              items:[
                {
                  key: 'dashboardContext',
                  type: 'ace',
                  style: 'min-height:300px;',
                  config: {
                    lang: 'json'
                  }
                }
              ]
            }

          ]
        }
      }
    }
  },
  beforeMount() {
    this.mergeSchemaFormWithCustom();
  },
  methods: {
    mergeSchemaFormWithCustom: function () {
      const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();

      for (const prop in this.schemaFormCustom.schemaProperties) {
        schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
      }
      schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);

      this.filterCustomEditable(schemaFormSourceDestination.form[0].tabs);

      this.applyCustomUIDefinition(schemaFormSourceDestination.schema.properties, schemaFormSourceDestination.form[0].tabs);

      this.schemaForm = schemaFormSourceDestination;

    },
    getCustomAdvancedUIDefinition: function (properties, formTabs, advancedDefinition) {
      if (advancedDefinition && advancedDefinition.schemaForm) {
        return advancedDefinition.schemaForm;
      }
    },
    getCustomCopyFromWidgetUIDefinition: function (properties, formTabs, sourceWidgetDefinition) {

    },
    applyPrefixToFormKeys: function(prefix, form){
      form.forEach(function(item){
        if (!item.key.startsWith(prefix)) {
          item.key = prefix + '.' + item.key;
          if (item.items) {
            // handle recursive
          }
          if (item.tabs) {
            // handle recursive
          }
        }
      });
      return form;
    },
    applyCustomUIDefinition: function (properties, formTabs) {
      console.log('applyCustomUIDefinition', this.model, formTabs);

      if (this.model.ui && this.model.ui.tabs) {
        const that = this;
        const revTabs = this.model.ui.tabs.reverse();
        revTabs.forEach(function (tab) {
          if (tab.sourceType) {
            const tabTitle = tab.title;
            let schemaForm = null;
            switch (tab.sourceType) {
              case 'ADVANCED':
                schemaForm = that.getCustomAdvancedUIDefinition(properties, formTabs, tab.advanced);
                break;
              case 'COPY_FROM_WIDGET':
                schemaForm = that.getCustomCopyFromWidgetUIDefinition(properties, formTabs, tab.sourceWidget);
                break;
            }
            if (schemaForm && schemaForm.schema) {

              const objectName = 'widgetDataModel';
              const cleanForm = that.applyPrefixToFormKeys(objectName, schemaForm.form);

              const newTab = {
                title: tabTitle,
                items: cleanForm
              }

              properties[objectName] = schemaForm.schema;
              formTabs.unshift(newTab);
            }
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
