import Vue from "vue";

function hasOwnProperty(object, property) {
  return Object.prototype.hasOwnProperty.call(object, property)
}

function isObjectLike(object) {
  const res = typeof object === 'object' && object !== null && typeof object !== 'function';
  return res;
}

function getObject(obj) {
  if (!obj)
    return obj;

  if (Array.isArray(obj)) {
    let tmpArr = [];
    for (let i = 0; i < obj.length; i += 1) {
      const o = getObject(obj[i]);
      tmpArr.push(o);
    }
    return tmpArr;

  } else if (isObjectLike(obj)) {
    let tmpO = {};
    for (const prop in obj) {
      const o = getObject(obj[prop]);
      tmpO[prop] = o;
    }
    return tmpO;
  } else {
    const c = obj;
    return c;
  }
}

function vueJsonMerge(targetObj, obj) {
  let objS = getObject(obj);//JSON.parse(JSON.stringify(obj));
  vueMerge(targetObj, objS);
  //targetObj = Vue.observable(targetObj);
  return targetObj;
}

function resolveDefaultValue(obj){
  return Array.isArray(obj) ? [] : isObjectLike(obj)?{}:'';
}

function mergeItem(targetObj, o){
  if (Array.isArray(o) || isObjectLike(o)) {
    targetObj = Vue.observable(resolveDefaultValue(o));
    vueMerge(targetObj, o);
  }else{
    const c = o;
    targetObj = c;//Vue.observable(c);
  }
  return targetObj;
}

function vueMerge(targetObj, obj) {
  if (!obj)
    return;
  if (!targetObj) {
    targetObj = Vue.observable(resolveDefaultValue(obj));
  } else {
    targetObj = Vue.observable(targetObj);
  }

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i += 1) {
      const o = obj[i];
      let tO = targetObj[i];
      tO = mergeItem(tO, o);
      Vue.set(targetObj, i, tO);
    }
  } else if (isObjectLike(obj)) {
    for (const prop in obj) {
      const o = obj[prop];

      let targetO = targetObj[prop];
      if (!targetO) {
        const k = o;
        targetO = mergeItem(targetO, k);
        Vue.set(targetObj, prop, targetO);

      } else {
        if (Array.isArray(o)) {
          for (let i = 0; i < o.length; i += 1) {
            const innerO = o[i];
            let targetInnerO = targetO[i];
            targetInnerO = (mergeItem(targetInnerO, innerO));

            Vue.set(targetO, i, targetInnerO);
          }
        } else if (isObjectLike(o)) {
          vueMerge(targetO, Vue.observable(Object.assign({}, o)));
        } else {
          const k = o;
          Vue.set(targetObj, prop, k);
        }
      }
    }
  } else {
    const t = obj;
    targetObj = t;
  }
}

export default {vueMerge, vueJsonMerge, getObject}
