
const geoMapLoaderUtils = {
  __mapJson: {},
  __http: null,
  addHttp: function(http){
    this.__http = http;
  },
  getMap: function(url, next){
    if (this.__mapJson[url]){
      next(this.__mapJson[url]);
    }else{
      const that = this;
      this.__http.get(url)
        .then(function(res){
          if (res.data){
            that.__mapJson[url] = res.data;
          }
          next(that.__mapJson[url]);
        });
    }
  }

}

export default geoMapLoaderUtils
