<template>
  <div class="card1 chart-panel-container">
    <div class="card1-header">
      <h6 class="card1-title">Chart</h6>
      <small>
        <p class="size1 text-muted card-category">Chart generated from data and properties.</p>
      </small>
      <hr/>
    </div>
    <div class="card1-body">
      <div class="card1-body-container">

        <vertical-accordion>
          <accordion-item>
            <template v-slot:header>
              Chart Editor
            </template>
            <template v-slot>
              <div>
                <div>
                  <button @click="toRender" class="btn btn-primary">Render</button>
                </div>
                <div class="pt-4 chart-container" v-if="renderChart">
                  <vue-df-e-chart-wrapper
                    :allowedResizeDirections="allowedResizeDirections"
                    :chart-config="chartConfig"
                    :dataset="chartDataset"
                    :dimensions="chartDimensions"
                    :group="group"
                    :height=300
                    :manual-update="manualUpdate"
                    :resizable=true
                    :theme="theme"
                    @onresize="onResize"
                    ref="chartWrapper"></vue-df-e-chart-wrapper>
                </div>
                <pre>
                  {{resizeData}}
                </pre>
              </div>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:header>
              Chart Definition Editor
            </template>
            <template v-slot>
              <div class="chart-config-container">
                <div class="draft-toolbar">
                  <sf-save-named-content
                    :draft-content="advancedModel.chartConfig"
                    @ondraftselected="onDraftSelected"
                    @onremovedraft="onRemovDraft"
                    component-name="pageView"
                    draft-namespace="chartDesigner"
                  ></sf-save-named-content>
                </div>
                <schema-form :form="advancedForm" :schema="advancedSchema"
                             @validationResult="onAdvancedSchema"
                             v-model="advancedModel"></schema-form>
              </div>
            </template>
          </accordion-item>
        </vertical-accordion>
      </div>
    </div>
    <div class="card1-footer">
    </div>
  </div>
</template>

<script>
  import chartingUtils from '../../../vueDfComponents/VueDfCharting/chartingUtils'

  const VueDfEChartWrapper = () => import('../../../vueDfComponents/shared/VueDfEChartWrapper')
  const SfSaveNamedContent = () => import('vue-json-schema-form-editor/src/components/schemaFormEditor/components/SfSaveNamedContent')
  import {BModal, BTabs, BTab} from 'bootstrap-vue'
  const VerticalAccordion =()=>import( "../../accordion/VerticalAccordion")
  const AccordionItem =()=>import( "../../accordion/AccordionItem")

  export default {
    name: "ChartDesignerChartPanel",
    props: {
      dataModel: Object,
      propertiesModel: Object
    },
    components: {AccordionItem, VerticalAccordion, VueDfEChartWrapper, BModal, BTabs, BTab, SfSaveNamedContent},
    computed: {
      chart: function () {
        return this.$refs.chartWrapper ? this.$refs.chartWrapper.$refs.eChart : {};
      }
    },
    data() {
      return {
        resizeData: {},
        renderChart: true,
        allowedResizeDirections: ["b"],
        manualUpdate: false,
        theme: undefined,
        group: undefined,
        chartDimensions: undefined,
        chartDataset: undefined,
        chartConfig: {},
        advancedModel: {},
        advancedSchema: {
          type: 'object',
          properties: {
            chartConfig: {
              type: 'string',
              title: 'Chart Config',
              description: 'Define chart config. Examples: <a href="https://echarts.apache.org/examples/en/index.html">ECharts examples</a>'
            }
          }
        },
        advancedForm: [
          {
            key: 'chartConfig',
            type: 'ace',
            style: 'height:400px',
            height: '100%',
            config: {
              lang: 'javascript',
              showToolbar: false
            }
          },
          {
            type: 'actions',
            items: [
              {
                type: 'submit',
                title: 'Apply'
              }
            ]
          }
        ]
      }
    },
    watch: {
      dataModel: {
        handler(newVal, oldVal) {
          console.log('Updated dataModel', newVal);
          this.chartDataset = newVal;
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      onResize: function (data) {
        this.resizeData = data;
      },
      toRender: function () {
        this.renderChart = false;
        const that = this;
        this.$nextTick(function () {
          that.renderChart = true;
        });
      },
      onAdvancedSchema: function (validationResult) {
        console.log('onAdvancedSchema', validationResult, this.advancedModel);
        if (validationResult.valid) {
          this.renderChart = false;
          const that = this;
          this.$nextTick(function () {
            that.applyContentToChart(that.advancedModel.chartConfig);
            that.renderChart = true;
          });

        }
      },
      applyContentToChart: function (chartDefString) {
        const context = {
          dataModel: this.dataModel
        }
        this.chartDataset = null;

        const res = chartingUtils.safeEval(chartDefString, context, this.chart, { jsulator: this.$jsulator});
        console.log('applyContentToChart', res);
        this.chartConfig = res || {};
      },
      onRemovDraft: function () {
        this.chartConfig = {};
        this.advancedModel = {}
      },
      onDraftSelected: function (draft) {
        console.log('On Draft Selected', draft);
        if (draft) {
          const chartConfig = draft.content;
          const obj = {
            chartConfig: chartConfig
          };
          this.advancedModel = Object.assign({}, obj);
        } else {
          this.onRemovDraft();
        }
      }
    }
  }
</script>

<style scoped>
  .draft-toolbar {
    z-index: 10;
    position: relative;
    /*right: 15px;*/
  }


  .chart-panel-container {
    min-width: 1000px;
  }

  .chart-config-container {
    min-width: 500px;
    height: 100%;
  }
  .chart-config-container .schema-for {
    margin-top: 20px;
  }

  .chart-container {
    width: 100%;
    min-width: 500px;
    height: 100%;
  }
</style>
