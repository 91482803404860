import isEmpty from "lodash/isEmpty"
import cloneDeep from "lodash/cloneDeep";

const permissionMixin = {
  computed: {
    items: function () {
      return cloneDeep(this.config.items);
    }
  },
  methods: {
    filterSubItems: function (subItem) {
      if (subItem.itemType !== 'DROP_DOWN') {
        return []
      }

      if (subItem.itemDropDownSettings && subItem.itemDropDownSettings.items) {
        let res = subItem.itemDropDownSettings.items.filter(function (item) {
          return item.title !== undefined && item.title !== null;
        });
        return res;
      }
      return [];
    },
    isAllSubItemsDisabled: function(items){
      let isDisabled = true;
      if (items && items.length>0){
        for(let i=0;i<items.length;i++){
          if(isEmpty(items[i].security)){
            return false;
          }
          if(null===items[i].security.enabled || undefined===items[i].security.enabled){
            return false;
          }
          if(items[i].security.enabled){
            return false;
          }
        }
      }
      return isDisabled;
    },
    resolveSubItems: function (itemConfig) {
      if (this.securityConfig && !this.appConfig.isComponentMode) {
        if (itemConfig.itemType !== 'DROP_DOWN') {
          return []
        }
        if (itemConfig.itemDropDownSettings && itemConfig.itemDropDownSettings.items) {
          let res = itemConfig.itemDropDownSettings.items.filter(function (item) {
            return item.title !== undefined && item.title !== null;
          });
          res = this.filterPermittedItems(res, itemConfig);
          console.log('filteredPermissionItems sub items', res);
          const isAllDisabled = this.isAllSubItemsDisabled(res);
          if(isAllDisabled){
            if (!isEmpty(itemConfig.security)){
              itemConfig.security.enabled = false;
            }else{
              itemConfig.security = {
                enabled: false
              }
            }
          }
          return res;
        }
        return [];
      } else {
        return this.filterSubItems(itemConfig);
      }
    },
    resolveItems: function (items) {
      items = items || this.items;
   //   console.log('resolveItems', items, this.securityConfig, this.appConfig, this.appConfig.isComponentMode);
      if (this.securityConfig && this.securityConfig.routePermissions.length && !this.appConfig.isComponentMode) {
        const filteredPermissionItems = this.filterPermittedItems(items);
        console.log('filteredPermissionItems items', filteredPermissionItems);
        return filteredPermissionItems;
      } else {
        return items;
      }
    },
    findPermissionByRouteItem: function (item, parent) {
      if (this.securityConfig && this.securityConfig.routePermissions && this.securityConfig.routePermissions.length > 0) {
        const routePermissions = [];
        for (let i = 0; i < this.securityConfig.routePermissions.length; i++) {
          const routePerm = this.securityConfig.routePermissions[i];
          if (routePerm && routePerm.routes && routePerm.routes.length > 0) {
            const findItem = parent ? parent.name + '=>' + item.name : item.name;
            let indexOfRouteInSecurity = routePerm.routes.indexOf(findItem);
            if (indexOfRouteInSecurity > -1) {
              routePermissions.push(routePerm);
            }
          }
        }
        return routePermissions;
      }
    },
    updateSubItemsSecurity: function(item){
      if(item && item.itemDropDownSettings && item.itemDropDownSettings.items){
        const items = this.filterPermittedItems(item.itemDropDownSettings.items, item);
        const isAllDisabled = this.isAllSubItemsDisabled(items);
        if(isAllDisabled){
          if (!isEmpty(item.security)){
            item.security.enabled = false;
          }else{
            item.security = {
              enabled: false
            }
          }
        }
      }
      return item;
    },
    filterPermittedItems: function (items, parent) {
      //  console.log('items', items);
      const that = this;
      if (items && items.length) {
        const tmp = [];
        items.forEach(function (item) {
          //  console.log('resolving permission for item', item);
          if (item.itemType === 'DROP_DOWN') { // we'll check sub items for drop down
            item = that.updateSubItemsSecurity(item);
            console.log('Resolved security for parend subitems', item);
            tmp.push(item);
          } else {
            const permissionList = that.findPermissionByRouteItem(item, parent);
            console.log('found permissionList', permissionList);
            if (permissionList && permissionList.length > 0) {
              const rolePermissions = that.getRolePermissions(permissionList);



              console.log('Resoling item permission route', item, permissionList, rolePermissions);
              item.security = rolePermissions;
              /*if (rolePermissions.enabled) {

              }*/
              tmp.push(item);
            } else {
              tmp.push(item);
            }
          }
        });
        return tmp;
      }

      return items;
    },
    matchRoles: function (userRoles, roles) {
      const matched = [];
      userRoles.forEach((userRole) => {
        roles.forEach((role) => {
          if (userRole === role) {
            matched.push(userRole);
          }
        })
      })
      return matched.length > 0 ? true : false;
    },
    getRolePermissions: function (permissionList) {
      const user = this.$userService.getCurrentApplicationUser();//$auth.getUser('etl');

      const userPermissionList = user.roles;
      console.log('user roles', userPermissionList);

      for (let i = 0; i < permissionList.length; i++) {
        const rolePermList = permissionList[i];//.rolePermissions;
        let matched = rolePermList;
        let permissionWithoutRoleApplicableToAll = null;
        if (rolePermList && rolePermList.rolePermissions && rolePermList.rolePermissions.length) {
          for (let j = 0; j < rolePermList.rolePermissions.length; j++) {
            const rolePermissions = rolePermList.rolePermissions[j];
            console.log('rolePermissions', rolePermissions);
            if (rolePermissions && rolePermissions.roles) {
              const roles = rolePermissions.roles.split(',').map(item => item.trim());
              const isMatched = this.matchRoles(userPermissionList, roles);
              if (isMatched) {
                return rolePermissions;
              }
            }else{
              permissionWithoutRoleApplicableToAll = rolePermissions;
            }
          }
          return permissionWithoutRoleApplicableToAll;
        }
      }
      //  const isAllowed = this.evalRoles(roleExpression, user.roles);
      // console.log('isAllowedRoute roleExpression ', isAllowed, roleExpression, user);
      return null;
    }
    /* evalRoles: function (expression, rolesModel) {
       const rulesEval = this.$jsulator.evaluate(expression, rolesModel);
       return rulesEval;
     },
     safeEval: function (condition, scope) {
       try {
         const scopeSafe = cloneDeep(scope);
         let res = jsnotevil.safeEval(condition, scopeSafe);
         return res;
       } catch (error) {
         return undefined;
       }
     }*/

  }
}

export default permissionMixin
