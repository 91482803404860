<template>
  <div class="expression-function-container">
    <div class="function d-inline-flex">
      <div class="function-name colored">{{ value.name }}<span class="colored">(</span></div>
      <div class="function-parameters">
        <draggable :group="{ name: 'els',  put: true }"
                   :class="['droppable-container d-flex',  allowedDropClass ]"
                   tag="div" v-model="paramPlaceholders">
          <div :key="param.id" v-for="(param, index) in paramPlaceholders" :class="allowedDropClass">
            <div class="param-placeholder d-inline-flex">
              <component :id=param.id :is="resolveComponent(param)"
                         @internalValueChanged="onInternalParamValueChanged($event, param, index)"
                         :value="param"></component>

              <b-popover :target="param.id" triggers="hover" variant="warning" placement="top">
                <element-toolbar @onAction="onToolbarAction($event, param)" :element="param"></element-toolbar>
              </b-popover>

              <span v-if="paramPlaceholders.length - 1 > index">,&nbsp;</span>
            </div>
          </div>
        </draggable>
      </div>
      <span class="colored">)</span>
    </div>
  </div>
</template>

<script>
import elementsMixin from "./elementsMixin";
import ExpressionToken from "./ExpressionToken";
import {BPopover} from "bootstrap-vue";
import ElementToolbar from "./support/ElementToolbar";

export default {
  name: "ExpressionFunction",
  mixins: [elementsMixin],
  components: {ExpressionToken, ElementToolbar, BPopover},
  data() {
    return {
      paramPlaceholders: [],//this.resolveParamPlaceholders(),
      paramValues: []
    }
  },
  computed: {
    allowedDropClass() {
      return this.paramPlaceholders.length >= this.value.maxArgumentCount ? 'context-full' : 'context-free';
    }
  },
  watch: {
    paramPlaceholders: {
      handler(newVal) {
        this.internalValue = newVal;
        this.$emit('internalValueChanged', newVal);
      }
    }
  },
  methods: {
    onToolbarAction($event, elem) {
      console.log('onToolbarAction', $event, elem);
      const index = this.paramPlaceholders.findIndex((item) => {
        return item.id === $event.element.id
      });
      if (index > -1) {
        this.paramPlaceholders.splice(index, 1);
      }
    },
    resolveComponent(elem) {
      console.log('elem', elem);
      switch (elem.type) {
        case 'FN':
          return 'ExpressionFunction';
        case 'OP':
          return 'ExpressionOperator';
        case 'TOKEN':
          return 'ExpressionToken';
      }
      return 'ExpressionToken';
    },
    onInternalParamValueChanged($event, param, index) {
      console.log('param changed ', $event, param);
      this.$set(param, 'internalValue', $event);
    },
  }
}
</script>

<style scoped>
.colored {
  color: chocolate;
}

.droppable-container {
  min-width: 50px;
  min-height: 20px;
  border: 1px dotted #ef8157;
  padding: 0px;
}

.droppable-container.context-full {
  border: 1px dotted #57a3ef;
}
</style>
