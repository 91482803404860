var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDroppable || (!_vm.isDroppable && !_vm.fieldForm.hidden)
    ? _c("div", [
        _vm.showHideEnabled
          ? _c("div", { staticClass: "fieldset-hide" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toogleShowHide()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.showHideTitle))]
              ),
            ])
          : _vm._e(),
        _vm.showHide
          ? _c(
              "fieldset",
              {
                staticClass: "schema-form-fieldset",
                class: _vm.fieldForm.htmlClass,
                attrs: { disabled: _vm.fieldForm.readonly },
              },
              [
                _c("legend", [
                  _vm._v(_vm._s(_vm.fieldForm.title || _vm.fieldSchema.title)),
                ]),
                _vm.fieldForm.description
                  ? _c("small", { staticClass: "text-muted help-block" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.fieldForm.description),
                        },
                      }),
                    ])
                  : _vm._e(),
                _c("builder-component", {
                  attrs: {
                    "field-mapper": _vm.fieldMapper,
                    form: _vm.fieldForm.items,
                    "schema-form-service": _vm.schemaFormService,
                    "full-model": _vm.fullModel,
                    "is-droppable": _vm.isDroppable,
                    "merged-form": _vm.fieldForm.items,
                    schema: _vm.fieldSchema.schema,
                  },
                  on: {
                    fullModelChanged: _vm.onFullModelChanged,
                    internalSchemaChanged: _vm.onInternalSchemaChanged,
                    schemaChanged: _vm.onChildSchemaChanged,
                  },
                  model: {
                    value: _vm.fieldSetValue,
                    callback: function ($$v) {
                      _vm.fieldSetValue = $$v
                    },
                    expression: "fieldSetValue",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }