<template>
  <div class="metric-dimension-builder-container">
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-2">
        <div class="card full">
          <div class="card-header">

          </div>
          <div class="card-body">
            <b-tabs>
              <b-tab title="DataSources">
                <data-sources @create="onCreateDataSource" @select="onSelectedDataSource"></data-sources>
              </b-tab>
              <b-tab title="Chart Design">
              </b-tab>
            </b-tabs>
          </div>
          <div class="card-footer"></div>
        </div>

      </div>
      <div class="col mb-2">
        <div class="card full">
          <div class="card-header">
            Context
          </div>
          <div class="card-body">
            <p>User will select DataSource and Load data or create new DataSource</p>
            <p>- If creating new DataSource we'll show here in Context specific configuration to create DataSource</p>
            <p>-- Datasource can be: </p>
            <ul>
              <li>HTTPs</li>
              <li>Db {Mongo, MySQL/Postgresql/...RDBMS }</li>
              <li>Integrated Source {FB, TW, GA, YT, etc }</li>
              <li>CSV {Upload .csv, .zip, .gz }</li>
            </ul>
            <hr/>
            <p>After it is created - user can load the data</p>
            <p>- When data is loaded it will be shown in Loaded Data panel (below)</p>
            <p>- From there user can "Transform" data by drag/drop fields from table</p>
            <hr />
            <p class="font-weight-bold">Table data</p>
            <p>- Data shown in Table can be Flat or Hierarchical - there will be max 100 items (or configurable)</p>
            <p>- These are "Dimensions"</p>
            <p>- User will be able to define "Metrics" such as SUM, COUNT, AVG, etc. as well as simple "concatenation", field sum and similar MATH operations</p>

            <p>- Advanced User will be able to define expression to process all fields</p>
            <br />
            <p>After expression/definition is set - user can click to see resulting data</p>
            <hr />
            <p class="font-weight-bold">Chart Design</p>
            <p>- Based on "filtered" data (filtered by expressions) we'll suggest chart type</p>
            <p>User will be able to choose Object value chart type or other EChart types</p>
            <p>- Object Value chart type - is template where user will select Main Value in Body - other values in Header or Footer - all with 3 columns - total 9 - predefined. Adjust font size - url - image, etc</p>
            <p>- There will be</p>
            <ul>
              <li>Dimensions</li>
              <li>Metrics</li>
            </ul>
            <p>- <strong>Dimensions</strong> are Category data - can be on xAxis or yAxis</p>
            <p>- <strong>Metrics</strong> will be numerical and there could be multiple "series" of metrics - showing stacked, line or area charts</p>
            <p>--  Metric can be combined with Dimension where Category data (dimension) is Time series</p>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            Loaded data
          </div>
          <div class="card-body">
            <data-table></data-table>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BTabs, BTab} from 'bootstrap-vue'
import DataTable from "@/components/MetricDimensionComponents/components/DataTable";
import DataSources from './components/DataSources'

export default {
  name: "MetricDimensionBuilder",
  components: {BTab, BTabs, DataTable, DataSources},
  data() {
    return {
      context: {}
    }
  }
}
</script>

<style scoped>

.metric-dimension-builder-container .card {
  height: 100%;
}

.metric-dimension-builder-container .card.full {
  min-height: calc(100vh - 320px);
}

</style>
