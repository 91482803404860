var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expression-element-wrapper" }, [
    _c(
      "div",
      { staticClass: "expression-element-container" },
      [
        _c("label", [_vm._v("Tokens")]),
        _c(
          "draggable",
          {
            ref: "elements",
            staticClass: "list-group",
            attrs: {
              group: { name: "els", pull: "clone", put: false },
              move: (related) =>
                _vm.onElementMove("expressionElementsDraggable", related),
              clone: _vm.clone,
              id: "expressionElementsDraggable",
              tag: "ul",
            },
            model: {
              value: _vm.tokens,
              callback: function ($$v) {
                _vm.tokens = $$v
              },
              expression: "tokens",
            },
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "flip-list", type: "transition" } },
              _vm._l(_vm.tokens, function (token) {
                return _c(
                  "li",
                  { key: token.name, staticClass: "list-group-item" },
                  [
                    _c("div", { attrs: { title: token.name } }, [
                      _c("span", [_vm._v(_vm._s(token.symbol))]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c("label", [_vm._v("Functions")]),
        _c(
          "draggable",
          {
            ref: "elements",
            staticClass: "list-group",
            attrs: {
              group: { name: "els", pull: "clone", put: false },
              move: (related) =>
                _vm.onElementMove("expressionElementsDraggable", related),
              clone: _vm.clone,
              id: "expressionElementsDraggable",
              tag: "ul",
            },
            model: {
              value: _vm.functions,
              callback: function ($$v) {
                _vm.functions = $$v
              },
              expression: "functions",
            },
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "flip-list", type: "transition" } },
              _vm._l(_vm.functions, function (func) {
                return _c(
                  "li",
                  { key: func.name, staticClass: "list-group-item" },
                  [
                    _c("div", { attrs: { title: func.name } }, [
                      _c("span", [_vm._v(_vm._s(func.name))]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c("label", [_vm._v("Operators")]),
        _c(
          "draggable",
          {
            ref: "elements",
            staticClass: "list-group",
            attrs: {
              group: { name: "expressionElements", pull: "clone", put: false },
              move: (related) =>
                _vm.onElementMove("expressionElementsDraggable", related),
              clone: _vm.clone,
              id: "expressionElementsDraggable",
              tag: "ul",
            },
            model: {
              value: _vm.operators,
              callback: function ($$v) {
                _vm.operators = $$v
              },
              expression: "operators",
            },
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "flip-list", type: "transition" } },
              _vm._l(_vm.operators, function (operator) {
                return _c(
                  "li",
                  { key: operator.name, staticClass: "list-group-item" },
                  [
                    _c("div", { attrs: { title: operator.name } }, [
                      _c("span", [_vm._v(_vm._s(operator.symbol))]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }