var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
    _vm.fieldForm.isLazy
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.lazy",
              value: _vm.localValue,
              expression: "localValue",
              modifiers: { lazy: true },
            },
          ],
          staticClass: "form-control",
          class: [_vm.isValid ? "" : "is-invalid"],
          attrs: {
            placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
            required: _vm.fieldForm.required,
            type: _vm.resolveType(),
            readonly: _vm.fieldForm.readonly,
          },
          domProps: { value: _vm.localValue },
          on: {
            change: function ($event) {
              _vm.localValue = $event.target.value
            },
          },
        })
      : _vm._e(),
    _vm.resolveType() === "checkbox" && !_vm.fieldForm.isLazy
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          staticClass: "form-control",
          class: [_vm.isValid ? "" : "is-invalid"],
          attrs: {
            placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
            required: _vm.fieldForm.required,
            readonly: _vm.fieldForm.readonly,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.localValue)
              ? _vm._i(_vm.localValue, null) > -1
              : _vm.localValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.localValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.localValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.localValue = $$c
              }
            },
          },
        })
      : _vm.resolveType() === "radio" && !_vm.fieldForm.isLazy
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          staticClass: "form-control",
          class: [_vm.isValid ? "" : "is-invalid"],
          attrs: {
            placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
            required: _vm.fieldForm.required,
            readonly: _vm.fieldForm.readonly,
            type: "radio",
          },
          domProps: { checked: _vm._q(_vm.localValue, null) },
          on: {
            change: function ($event) {
              _vm.localValue = null
            },
          },
        })
      : !_vm.fieldForm.isLazy
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          staticClass: "form-control",
          class: [_vm.isValid ? "" : "is-invalid"],
          attrs: {
            placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
            required: _vm.fieldForm.required,
            readonly: _vm.fieldForm.readonly,
            type: _vm.resolveType(),
          },
          domProps: { value: _vm.localValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.localValue = $event.target.value
            },
          },
        })
      : _vm._e(),
    !_vm.isValid
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }