
const commonIntegrationMixin = {
  props: {
    uiDefinition: {
      type: Object
    },
    dataModel: {
      type: Object
    },
    dashboardEditMode:{},
    model:{},
    id:{},
    isMaximized: Boolean,
    maximizedArea: Object
  }
}

export default commonIntegrationMixin
