var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.localValue,
              expression: "localValue",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          class: [_vm.isValid ? "" : "is-invalid"],
          attrs: {
            placeholder: _vm.resolvePlaceholder(_vm.fieldForm),
            required: _vm.fieldForm.required,
            type: "number",
            readonly: _vm.fieldForm.readonly,
          },
          domProps: { value: _vm.localValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.localValue = _vm._n($event.target.value)
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        !_vm.isValid
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }