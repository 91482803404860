<template>
  <component-base v-bind:field-form="fieldForm">
    <vue-slider :min="fieldForm.min"
                :max="fieldForm.max" :interval="fieldForm.interval"
                :minRange="fieldForm.minRange" :maxRange="fieldForm.maxRange"
                :disabled="fieldForm.disabled"
                :data="fieldForm.data"
                :dataValue="fieldForm.dataValue"
                :dataLabel="fieldForm.dataLabel"
                :direction="fieldForm.direction"
                :clickable="fieldForm.readonly"
                :railStyle="fieldForm.railStyle"
                :processStyle="fieldForm.processStyle"
                :tooltipStyle="fieldForm.tooltipStyle"
                :stepStyle="fieldForm.stepStyle"
                :stepActiveStyle="fieldForm.stepActiveStyle"
                :labelStyle = "fieldForm.labelStyle"
                :labelActiveStyle="fieldForm.labelActiveStyle"
                :dotStyle = "fieldForm.dotStyle"

                :marks="fieldForm.marks"

                v-model="localValue"/>
  </component-base>
</template>

<script>
// https://github.com/NightCatSama/vue-slider-component
import componentMixin from './componentMixins'
import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
//import VueSlideBar from "./components/VueSlideBar";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'


export default {
  name: "SfSlider",
  mixins: [componentMixin, simpleComponentMergeInMixin],
  components: {VueSlider}
}
</script>

<style scoped>

</style>
