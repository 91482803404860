<template>
  <div>
    <div v-if="!fieldForm.labelLeft" :class="['form-group','schema-form-'+fieldForm.type,{'display-condensed': fieldForm.condensed}]">
      <label v-if="resolveShouldShowTitle(fieldForm)"
             :class="['control-label', fieldForm.labelHtmlClass ]">{{ fieldForm.title }}</label>
      <slot></slot>
      <div v-if="validationError" class="invalid-feedback error-text">{{ validationError }}</div>
      <div v-if="fieldForm.description && resolveShouldShowTitle(fieldForm)" :class="fieldForm.descriptionHtmlClass"
           class="text-muted small help-block">
        <span v-html="fieldForm.description"></span>
      </div>
    </div>
    <div v-else :class="['form-group','schema-form-'+fieldForm.type, {'display-left-control':fieldForm.labelLeft}, {'display-condensed': fieldForm.condensed}]">
      <div class="row" style="align-items: center">
        <div class="col-4">
          <label v-if="resolveShouldShowTitle(fieldForm)"
                 :class="['control-label', {'label-left-control':fieldForm.labelLeft }, fieldForm.labelHtmlClass ]">{{ fieldForm.title }}</label>
        </div>
        <div class="col">
          <slot></slot>
          <div v-if="validationError" class="invalid-feedback error-text">{{ validationError }}</div>
          <div v-if="fieldForm.description && resolveShouldShowTitle(fieldForm)" :class="fieldForm.descriptionHtmlClass"
               class="text-muted small help-block">
            <span v-html="fieldForm.description"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import droppableMixins from './droppableMixins'
import componentMixin from "./componentMixins";

export default {
  name: "ComponentBase",
  props: ['fieldForm'],
  mixins: [droppableMixins, componentMixin],
  methods: {
    resolveShouldShowTitle: function (fieldForm) {
      if (fieldForm.notitle) {
        return !fieldForm.notitle;
      }
      return true;
    }
  }

}
</script>

<style scoped>
.label-left-control {
  /*margin: 0px 26px 0px 0px;*/
  margin: 0px;
}

.display-condensed {
  margin-bottom: 0;
}

.display-left-control {
 /* display: flex;
  align-items: center;*/
}
</style>
