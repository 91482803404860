import vueDfMixin from '../vueDfMixin'
import swal from 'sweetalert2'

const dashboardContainerMixin = {
  mixins: [vueDfMixin],
  data() {
    return {
      isLoadingModels: false
    }
  },
  created: function () {
    const that = this;
  //  console.log('Loading dashboards for: '+this.dashboardName, this.useProvidedModel, this.dashboardsModel, this.model);
    if (this.useProvidedModel) {
      /*this.isLoadingModels = true;
      this.$nextTick(function(){


      });*/
      this.isLoadingModels = false;
      if (!that.model) {
        console.error("There is no model in container mixin " + that.dashboardName, that.model);
      }
      that.dashboardsModel = that.model;
      that.$nextTick(function () {
        that.$dashboardBus.$emit('DASHBOARD_LOADED::' + that.dashboardName);
      });
    } else {
      this.loadModels();
    }
  },
  methods: {
    loadModels: function (next) {
      const that = this;
      this.isLoadingModels = true;
      this.$services[this.crudServiceName].loadModels(this.dashboardName, function (models) {
        const tmpModels = Object.assign([], models ? models.value : null);
        that.$set(that, 'dashboardsModel', tmpModels);
        that.$nextTick(function () {
          that.isLoadingModels = false;
       //   console.log('dashboard loaded: '+that.dashboardName);
          that.$dashboardBus.$emit('DASHBOARD_LOADED::' + that.dashboardName);
          if (next) {
            next(tmpModels);
          }
        });
      });
    },
    exportAllDashboard: function () {
      const data = JSON.stringify(this.dashboardsModel);
      const blob = new Blob([data], {type: 'application/json;charset=utf-8'})
      saveAs(blob, 'dashboards.json');
    },
    exportSelectedDashboard: function () {
      const data = JSON.stringify(this.dashboardsModel[this.selectedDashboardIndex]);
      const blob = new Blob([data], {type: 'application/json;charset=utf-8'})
      saveAs(blob, this.dashboardsModel[this.selectedDashboardIndex].title + '.json');
    },
    importDashboard: function () {
      this.$refs.dashboardContainer.$refs.uploadModal.show();
    },
    shareDashboard: function (){
      this.$refs.dashboardContainer.shareDashboard();
    },
    removeDashboard: function (dashboard, index) {
      const that = this;

      swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this Dashboard!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        heightAuto: false
      }).then((result) => {
        if (result.value) {
          that.dashboardsModel.splice(index, 1);
          that.selectedDashboardIndex = 0;
          if (that.dashboardsModel.length > 0)
            that.selectDashboard(that.selectedDashboardIndex);

          //that.$dashboardBus.$emit('DASHBOARD_SAVED', {model: this.dashboardsModel});
          that.$refs.dashboardContainer.saveDashboard({model: that.dashboardsModel});
          swal.fire(
            'Deleted!',
            'Your Dashboard has been deleted.',
            'success'
          )
        }
      });
    },
    addDashboard: function (next) {
      const dash = {
        dashid: 'dash-' + this.$widgetStore.id(),
        title: 'Dashboard',
        structure: "4-8",
        maximizable: false,
        collapsible: false,
        refreshable: false,
        editable: true,
        continuousEditMode: false,
        editMode: false,
        rows: [{
          columns: [
            {
              styleClass: "col-md-4",
              widgets: []
            },
            {
              styleClass: "col-md-8",
              widgets: []
            }
          ]
        }]
      }

      const that = this;
      this.$nextTick(function () {
        that.dashboardsModel.push(dash);
        that.selectDashboard(that.dashboardsModel.length - 1);
        that.$dashboardBus.$emit('DASHBOARD_ADDED::' + this.dashboardName, {
          model: dash,
          dashboardName: this.dashboardName
        }); // TODO use Event mechanism to save dashboard model
        // that.$refs.dashboardContainer['saveDashbaord']({model: this.model, index: this.selectedDashboardIndex})
        if(next){
          next(dash);
        }
      });
    }
  }
}

export default dashboardContainerMixin
