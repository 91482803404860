var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-container", class: _vm.containerClass },
    [
      _c(
        "form",
        {
          ref: "form",
          staticClass: "schema-form",
          class: _vm.formClass,
          attrs: { novalidate: "", role: "form" },
          on: {
            reset: function ($event) {
              $event.preventDefault()
              return _vm.onReset.apply(null, arguments)
            },
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _vm.schema && _vm.schema.title
            ? _c("legend", [_vm._v(_vm._s(_vm.schema.title))])
            : _vm._e(),
          _c("builder-component", {
            attrs: {
              "field-mapper": _vm.resolveFieldMapper(),
              "full-model": _vm.value,
              "is-droppable": _vm.isDroppable,
              "schema-form-service": _vm.schemaFormService,
              "form-bus": _vm.formBus,
              "merged-form": _vm.mergedForm,
              value: _vm.value,
            },
            on: {
              fullModelChanged: _vm.onFullModelChanged,
              internalSchemaChanged: _vm.onInternalSchemaChanged,
              schemaChanged: _vm.onSchemaChanged,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }