<template>
  <div class="dashboard-view-widget" v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <!--    <vue-df-dashboard-single
          :input-context="dataModel" v-if="render" :dashboard-edit-mode="enableEditMode" :dashboard-name="widgetDashboardName"
          :dashboard-service-instance="dashboardServiceInstance"
          :dashboard-type="dashboardType" :disable-add="disableAdd"
          :disable-edit="disableEdit"
          :is-dashboard-type-editable="isDashboardTypeEditable"
          :key="id"
          :selected-dashboard-index=0
          :model="dashboardsModel"
          :parent-dashid="computedDashid" :parent-widget-id="computedWidgetId"
          :permissions="permissions"
        ></vue-df-dashboard-single>-->
    <vue-df-dashboard-content :input-context="dataModel" v-if="render" :dashboard-edit-mode="enableEditMode"
                              :dashboard-name="widgetDashboardName"
                              :dashboard-service-instance="dashboardServiceInstance"
                              :dashboard-type="dashboardType" :disable-add="disableAdd"
                              :disable-edit="disableEdit"
                              :is-dashboard-type-editable="isDashboardTypeEditable"
                              :key="id"
                              ref="dashboardContent"
                              :model="dashboardsModel"
                              :parent-dashid="computedDashid" :parent-widget-id="computedWidgetId"
                              :permissions="permissions"
                              @dashboardchanged="onDashboardChanged"
                              v-on:dashboardtypechange="onDashboardTypeChange"></vue-df-dashboard-content>

  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'
import vueUtils from 'vue-json-schema-form/src/utils/vueUtils'
//import example from './example.json'
import VueDfDashboardContent from '../VueDfDashboard/components/VueDfDashboardContent'

export default {
  name: "VueDfWidgetGeneratorView",
  components: {VueDfDashboardContent},
  mixins: [widgetComponentViewMixins],
  props: {
    dashboardName: String,
    dashboardServiceInstance: Object
  },
  data() {
    return {
      isLoading: false,
      render: true,
      defaultMapper: {},
      personalDashboard: null
    }
  },
  created() {
    this.loadDashboards();
    this.applyDashboardContext();
  },
  watch: {
    isLoading: {
      handler(newVal) {
        this.$emit('loading', newVal);
      },
      immediate: true
    },
    isPersonal: {
      handler(newVal, oldVal) {
        console.log('updated personal', newVal, oldVal);
        if (newVal) {
          if (this.model.dashboards.length) {
            if (!this.personalDashboard) {
              this.personalDashboard = Object.assign({});
            }
            this.$set(this.personalDashboard, 'dashboards', this.model.dashboards);
            this.model.dashboards = Object.assign([])
          }
        } else {
          if (this.personalDashboard.dashboards && this.personalDashboard.dashboards.length) {
            this.model.dashboards = Object.assign([])
          }
        }
      }
    },
    /*'model.dashboards': {
      handler(newVal, oldVal) {
        this.applyCustomUiDataModelToWidgets();
      }
    }*/
  },
  computed: {
    isPersonal: function () {
      const isPersonal = this.$jsulator.evaluate('$config.data.isPersonal$', this.model);
      //  console.log('IsPersonal', isPersonal);
      return isPersonal;
    },
    dashboardsModel: function () {
      /*if (this.isPersonal) {
        return this.personalDashboard ? this.personalDashboard.dashboards : Object.assign([])
      } else {
        return this.model.dashboards;
      }*/
     // const tmp = [];
     // tmp.push(example);
      //console.log('example', tmp, this.model.dashboards);
      return this.model.dashboards; //tmp;
    },
    computedDashid: function () {
      return (this.parentDashid ? this.parentDashid + '/' : '') + this.dashid;
    },
    computedWidgetId: function () {
      return (this.parentWidgetId ? this.parentWidgetId + '/' : '') + this.id;
    },
    dashboardContext: function () {
      const context = {
        parentDashid: this.parentDashid,
        parentWidgetId: this.parentWidgetId,
        dashid: this.dashid,
        widgetId: this.id
      }
      return context;
    },
    isDashboardTypeEditable: function () {
      return this.model.config.data.isDashboardTypeEditable;
    },
    editable: function () {
      // dashboardEditMode
      console.log('Editable', this.model, this.dashboardEditMode);
      return this.model.editableSettings.editable;
    },
    dashboardType: function () {
      if (typeof this.model.dashboardType === 'string') {
        return this.model.dashboardType
      } else {
        return 'VueDfDashboardSingle'
      }
    },
    enableEditMode: function () {
      return this.isEditable && this.dashboardEditMode;
    },
    disableEdit: function () {
      return !this.isEditable || !this.dashboardEditMode;//this.isComponentMode() ? false : true;
    },
    disableAdd: function () {
      return !this.isEditable || !this.dashboardEditMode;//this.isComponentMode() ? false : true;
    },
    widgetDashboardName: function () {
      return this.id + '-' + this.dashboardName; //this.model.config.data.applicationName || 'nesto';
    }
  },
  methods: {
    applyDashboardContext: function () {
      if (this.model.config.data.dashboardContext) {
        try {
          const ctx = JSON.parse(this.model.config.data.dashboardContext);
          if (this.dataModel) {
            vueUtils.vueMerge(this.dataModel, ctx);
          }
          //  console.log('dashboardContext', ctx, this.dataModel);
        } catch (e) {
          console.warn('Dashboard context is not valid JSON', e);
        }
      }

    },

    loadDashboards: function () {
      if (this.isPersonal) {
        this.loadPersonalDashboard();
      } else {
        if (!this.model.dashboards) {
          this.model.dashboards = Object.assign([]);
        }
      }
    },
    loadPersonalDashboard: function () {
      const that = this;
      this.render = false;
      this.isLoading = true;
      this.$services[this.crudServiceName].loadPersonalDashboard(null, this.widgetDashboardName, function (response) {
        console.log('Personal dashboard response', response);
        if (!isEmpty(response)) {
          that.$set(that, 'personalDashboard', response);
        }
        that.isLoading = false;
        that.render = true;
      });
    },
    savePersonalDashboard: function (personalDashboard, next) {
      this.$services[this.crudServiceName].savePersonalDashboard(personalDashboard, next);
    },
    onDashboardChanged: function (dashboardsModel) {
      if (this.isPersonal) {
        const that = this;
        const model = {
          id: this.widgetDashboardName,
          dashboards: dashboardsModel
        }
        this.savePersonalDashboard(model, function (response) {
          console.log('Response savePersonalDashboard', response);
          if (!that.personalDashboard) {
            that.personalDashboard = {};
          }
          that.$set(that.personalDashboard, 'dashboards', dashboardsModel);
        });

      } else {
        this.$set(this.model, 'dashboards', dashboardsModel);
      }
    },
    onDashboardTypeChange: function (value) {
      //    console.log('Dashboard type changed', value, this.model);
      this.model.dashboardType = value;
    },
    isComponentMode: function () {
      return false; //this.options.isComponentMode === null || this.options.isComponentMode === undefined ? true : this.options.isComponentMode;
    }
  }
}
</script>

<style scoped>

.dashboard-view-widget ::v-deep .dashboards-content {
  padding: 0px;
  /*padding: 10px 0px 0px 0px;*/
}

/*.dashboard-view-widget ::v-deep .widget-header.view {
  display: none;
}*/


</style>
