const generator = {
  mergeTable(dashboard, generated, metaSchema){
    metaSchema.regenerateTable;

  },
  generate(widget, metaSchema) {
    const regenerateTable= metaSchema.regenerateTable;
    console.log('regenerateTable', regenerateTable);
    const configData = {}

    configData.fields = generator.fields(metaSchema);
    configData.tableEvents = generator.tableEvents(metaSchema);
    configData.tableDefinition = generator.tableDefinition(metaSchema);
    configData.pagination = generator.pagination(metaSchema);

    generator.updateEvents(configData, metaSchema);

    widget.config = {
      data: configData
    }

    return widget;
  },
  updateEvents(configData, metaSchema) {
    configData.onLoad = generator.onLoad(metaSchema);
    configData.onEvent = generator.onEvent(metaSchema);
    configData.events = generator.events(metaSchema);
  },
  onLoad(metaSchema) {
    console.log('onLoad Table generate', metaSchema);
    const onLoad = {
      "sourceType": "EVENTS",
      "eventPublish": {
        "events": [
          {
            "contextObject": "{}",
            "eventName": "onTableLoad"
          }
        ]
      }
    }
    return onLoad
  },
  onEvent(metaSchema) {
    if (!metaSchema.crudUrl) {
      return;
    }
    const events = [];

    const onTableLoad = {
      "eventType": "DASHBOARD_WIDE",
      "executeType": "REST_API",
      "eventName": "onTableLoad",
      "restApi": {
        "httpMethod": "GET",
        "responseEvents": {
          "events": [
            {
              "eventType": "OK",
              "contextObject": "$data.result$",
              "eventName": "onTableLoadedResponse"
            }
          ]
        },
        "url": metaSchema.crudUrl //"$_meta.appConfig.apiBase$+'/v1/ml/modelbuilder'"
      }
    };
    events.push(onTableLoad);

    const onTableLoadedResponse = {
      "eventType": "CURRENT_WIDGET",
      "executeType": "APPLY_DATA",
      "applyData": {
        "contextType": "MAIN",
        "contextObject": "$context$"
      },
      "eventName": "onTableLoadedResponse"
    };
    events.push(onTableLoadedResponse);

    const onRowCommandDelete = {
      "eventType": "CURRENT_WIDGET",
      "executeType": "EVENT_PUBLISH",
      "eventName": "OnRowCommandExecuted",
      "runIf": "$context.command$=='DELETE'",
      "eventPublish": {
        "events": [
          {
            "contextObject": "$data$",
            "eventName": "onDelete"
          }
        ]
      }
    }

    const onDelete = {
      "eventType": "CURRENT_WIDGET",
      "executeType": "REST_API",
      "eventName": "onDelete",
      "restApi": {
        "httpMethod": "DELETE",
        "responseEvents": {
          "events": [
            {
              "eventType": "OK",
              "contextObject": "{}",
              "eventName": "onTableLoad"
            }
          ]
        },
        "url": metaSchema.crudUrl + "+'/'+$context.item."+metaSchema.idField+"$" //"$_meta.appConfig.apiBase$+'/v1/ml/modelbuilder/'+$context.item._id$"
      }
    }

    events.push(onRowCommandDelete);
    events.push(onDelete);


    return {events: events}
  },
  events(metaSchema) {

  },
  fields(metaSchema) {
    try {
      const schemaObj = JSON.parse(metaSchema.schema);
      const fields = [];
      if (schemaObj && schemaObj.properties) {
        for (const key in schemaObj.properties) {
          const property = schemaObj.properties[key];
          if (property.type !== 'array' && property.type !== 'object') {
            fields.push({
              key: key,
              label: property.title
            })
          }
        }
        fields.push({
          "key": "commands",
          "label": "Commands",
          "cellFormat": "",
          "headFormat": ""
        });
        return fields;
      }
    } catch (e) {
      console.log('table error processing schema generation', e);
    }

  },
  tableEvents(metaSchema) {
    const tableEvents = [
      {
        "eventPublish": {
          "events": [
            {
              "contextObject": "$data$",
              "eventName": "onRowSelected"
            },
            {
              "contextObject": ""
            }
          ]
        },
        "eventType": "OnRowSelected"
      },
      {
        "eventPublish": {
          "events": [
            {
              "contextObject": "$data$",
              "eventName": "OnRowCommandExecuted"
            }
          ]
        },
        "eventType": "OnRowCommand"
      }
    ]
    return tableEvents;
  },
  tableDefinition(metaSchema) {
    const tableDefinition = {
      "rowDetails": "\n<template #cell(commands)=\"row\">\n    <div class=\"btn-group btn-small\">\n        <span @click=\"onRowCommand(row.item, 'DELETE')\" class=\"\" title=\"Delete item\">\n            <i class=\"fas fa-trash text-danger\"></i>\n        </span>\n    </div>\n</template>",
      "small": true,
      "outlined": false,
      "striped": true
    }
    return tableDefinition;
  },
  pagination(metaSchema) {
    return {
      "usePagination": true,
      "perPage": 5,
      "position": "Bottom"
    }
  }
}
export default generator
