import dashboardDefaults from './dashboardDefaults'
import tableGenerator from './tableGenerator'
import formGenerator from './formGenerator'
import cloneDeep from 'lodash/cloneDeep';
import vueUtils from "vue-json-schema-form/src/utils/vueUtils";

const generator = {
  merge(dashboard, generated, metaModel) {
    if (metaModel.regenerateTable.all && metaModel.regenerateForm.all) {
      vueUtils.vueMerge(dashboard, generated);
    } else {
      tableGenerator.mergeTable(dashboard, generated, metaModel);
      formGenerator.mergeForm(dashboard, generated, metaModel);
    }
  },
  generate(metaModel) {
    console.log('metaModel', metaModel);
    const dashboard = cloneDeep(dashboardDefaults.dashboardCommon);
    dashboard.title = metaModel.title || 'Dashboard Generated';

    const rows = generator.generateRows(metaModel);
    dashboard.rows = rows;

    return Object.assign({}, dashboard);
  },
  generateRows(metaModel) {
    const rows = []
    const columns = generator.generateColumns(metaModel);
    if (columns)
      rows.push(columns);
    return rows;
  },
  generateColumns(metaModel) {
    const columns = {
      columns: []
    }
    if (metaModel.generateTypes) {
      const widgets = generator.generateWidgetTypes(metaModel);
      if (widgets && widgets.length) {
        columns.columns.push(...widgets)
      }
      if (metaModel.generateTypes.length === 1) {
        columns.columns.forEach(function (column) {
          column.styleClass = 'col'
        });
      } else if (metaModel.generateTypes.length === 2) {
        columns.columns[0].styleClass = 'col-md-3 col-xs-12'
        columns.columns[1].styleClass = 'col-md-9 col-xs-12'
      }
    }
    return columns;
  },
  generateWidgetTypes(metaModel) {
    const widgets = [];
    metaModel.generateTypes.forEach(function (type) {
      switch (type) {
        case "TABLE":
          const tableWidget = generator.generateTable(metaModel);
          if (tableWidget) {
            console.log('tableWidget', tableWidget);
            widgets.push({widgets: [tableWidget]});
          }
          break;
        case "FORM":
          const formWidget = generator.generateForm(metaModel);
          if (formWidget) {
            widgets.push({widgets: [formWidget]});
          }
          break;
      }
    });

    return widgets;
  },
  generateTable(metaSchema) {

    let widget = cloneDeep(dashboardDefaults.tableWidgetCommon);
    widget = tableGenerator.generate(widget, metaSchema);
    return widget;
  },
  generateForm(metaSchema) {
    let widget = cloneDeep(dashboardDefaults.formWidgetCommon);
    widget = formGenerator.generate(widget, metaSchema);
    return widget;
  }

}

export default generator
