var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expression-designer-board-container" },
    [
      _c(
        "draggable",
        {
          staticClass: "droppable-container d-inline-flex context-free",
          attrs: {
            group: { name: "els", put: true },
            move: _vm.onMoveInActivities,
            tag: "div",
          },
          model: {
            value: _vm.elements,
            callback: function ($$v) {
              _vm.elements = $$v
            },
            expression: "elements",
          },
        },
        _vm._l(_vm.elements, function (elem) {
          return _c("div", { key: elem.id, staticClass: "context-free" }, [
            _c(
              "div",
              { staticClass: "element ml-2" },
              [
                _c(_vm.resolveComponent(elem), {
                  tag: "component",
                  attrs: { id: elem.id, value: elem },
                  on: {
                    internalValueChanged: function ($event) {
                      return _vm.onInternalValueChanged($event, elem)
                    },
                  },
                }),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      offset: _vm.popoverOffset(elem),
                      target: elem.id,
                      triggers: "hover",
                      placement: "top",
                    },
                  },
                  [
                    _c("element-toolbar", {
                      attrs: { element: elem },
                      on: {
                        onAction: function ($event) {
                          return _vm.onToolbarAction($event, elem)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }