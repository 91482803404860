import utils from '../../utils/utils'
import vueUtils from '../../utils/vueUtils'
import ObjectPath from "../../utils/ObjectPath";
import ConditionResolver from './conditionResolver'


const buildMixins = {
  props: ['fullModel', 'fieldMapper', 'schemaFormService'],
  data() {
    return {
      stateViewConfig: {
        show: true
      },
      shouldRender: true
    }
  },
  methods: {
    onFullModelChanged: function (changedModel) {
      console.log('Full Model changed', changedModel);
      this.$emit('fullModelChanged', changedModel);
      /*this.shouldRender = true;
      this.$nextTick(()=>{
        this.shouldRender = false;
      });*/
    },
    recalculateSchemaFromChildren: function (items) {
      if (!items || !items.length)
        return null;

      const that = this;
      let schema = {type: 'object', properties: {}};
      items.forEach(function (item) {
        if (item.schemaFieldKey) {
          schema.properties[item.schemaFieldKey] = item.schema;
        } else if (item.items) {
          schema.properties = that.recalculateSchemaFromChildren(item.items);
        } else if (item.tabs) {
          schema.properties = that.recalculateSchemaFromChildren(item.tabs);
        }
      });
      return schema;
    },
    recalculateSchemaInForm: function (formCollection) {
      if (!formCollection)
        return [];

      const that = this;
      formCollection.forEach(function (formItem) {
        // only for those items that doesn't have a key and doesn't have schema
        if (!formItem.key && !formItem.schema) {
          switch (formItem.type) {
            case 'section':
              if (formItem.items) {
                const schema = that.recalculateSchemaFromChildren(formItem.items);
                if (schema) {
                  formItem.schema = schema;
                }
              }
              break;
            case 'tabs':
              if (formItem.tabs) {
                const schema = that.recalculateSchemaFromChildren(formItem.tabs);
                if (schema) {
                  formItem.schema = schema;
                }
              }
              break;
          }
        }
      });
      return formCollection;
    },
    resolveVisible: function (fieldForm) {
      if (this.isDroppable) {
        return '';
      }
      const style = fieldForm.visible !== undefined ? fieldForm.visible ? '' : 'display:none;' : '';
      return style;
    },
    resolveStringifiedKey: function (field, index) {
     // console.log('Resolving stringify field key', field, index, this);
      if (!field)
        return;

      let strKey = null;
      if (field.guid) {
        strKey = field.guid;
      } else if (field.schemaFieldKey) {
        strKey = field.schemaFieldKey;
      } else if (field.title) {
        strKey = field.title;
      } else if (index) {
        strKey = index;
      } else {
        strKey = JSON.stringify(field);
      }
      //console.log('Field str key: ', field, strKey);
      return strKey;
    },
    stringify: function (key) {
      const res = ObjectPath.stringify(key);
      return res;
    },
    onInput: function (event, field) {
      //  console.log('OnInput', event, field);
      if (!field.key)
        return;
      //console.log('On Input value', event, field);
      const key = utils.clone(field.key);

      if (event === undefined || (Array.isArray(event) && !event.length)) {
        //console.error('Event is undefined for field', field);
        vueUtils.vueDelete(this.fullModel, key);
        // this.$emit('fullModelChanged', this.fullModel);
      } else {
        if (Array.isArray(event)) {
          vueUtils.vueSet(this.fullModel, key, event);
          // this.$emit('fullModelChanged', this.fullModel);
        } else if (typeof object === 'object') {
          vueUtils.vueSet(this.fullModel, key, event);
          // this.$emit('fullModelChanged', this.fullModel);
        } else {
          //vueUtils.vueSet(this.fullModel, key, event);

          const that = this;
          vueUtils.vueSet(this.fullModel, key, event);
          //  this.$emit('fullModelChanged', this.fullModel);

          /*this.shouldRender = true;
          this.$nextTick(function () {
            that.shouldRender = false;
          })*/
        }
      }

      //console.log('On Input value - after update', event, field, this.fullModel);
    },
    onKeylessInput: function (event, field) {
      if (typeof event === 'object') {
        for (const p in event) {
          this.$set(this.value, p, event[p]);
        }
      } else {
        const key = utils.clone(field.key);
        vueUtils.vueSet(this.fullModel, key, event);
      }
      this.$emit('fullModelChanged', this.fullModel);
    },
    resolveFieldComponent: function (field) {
      let componentType = null;
      if (field.format) {
        componentType = this.fieldMapper.formatMapper[field.format];
      }
      if (!componentType) {
        componentType = this.fieldMapper.fieldMapper[field.type];
      }

      if (componentType && componentType.name) {
        return componentType.name;
      } else {
        throw new Error('Unknown type for field: ' + JSON.stringify(field));
      }
    },
    resolveCondition: function (field, parentForm) {
      if (!field.condition) {
        return true;
      }
      //  console.log('isDroppable', this.isDroppable);
   //   console.log('Resolving condition: '+field.condition);

      if (this.isDroppable) {
        // this is Editable mode
        return true;
      }

      if (field.hidden) {
        console.log('Hidden field', field);
        return false;
      }


      if (field.condition) {
        /*const schemaFormResolver = {
          getRaw: function (key) {
            const parsedKey = ObjectPath.parse(key);
            console.log('Im in SchemaForm getRaw method', key, parsedKey, this);
            if(parsedKey){
              for(let i=0;i<parsedKey.length;i++){

              }
            }

            return {
              data: "yes"
            }
          }
        };
        const scope = {
          model: this.fullModel,
          form: field,
          parentForm: parentForm ? parentForm.parentForm : null,
          schemaFormResolver: schemaFormResolver
        };

        const boundSchemaFormResolver = schemaFormResolver.getRaw.bind(scope);
        schemaFormResolver.getRaw = boundSchemaFormResolver;
        scope.schemaFormResolver = schemaFormResolver;*/

        const conditionResolver = new ConditionResolver(this.fullModel, field, parentForm ? parentForm.parentForm : null, this.schemaFormService);
        const shouldKeep = conditionResolver.evaluate(field.condition); //utils.safeEval(field.condition, scope); // // conditionResolver.evaluate(field.condition);
          //console.log('Evaluated condition: '+shouldKeep+' - '+field.condition, conditionResolver);
        if (!shouldKeep) {

          let key = utils.clone(field.key);
          const val = utils.getValueFromModel(this.fullModel, key);
          if (val) {
            this.stateViewConfig.show = false;
            this.$nextTick(function () {
              key = utils.clone(field.key);
              vueUtils.vueDelete(this.fullModel, key);
              this.stateViewConfig.show = true;
            });

          }

        }
        return shouldKeep;
      }
      return true;
    },
    /*resolveKeylessValue: function(value, field){
      if (!field.key){
        return value;
      }else{
        return this.resolveValue(value, field);
      }
    },*/
    resolveValue: function (value, field) {
      if (!field.key){
        return value;
      }

      const key = utils.clone(field.key);
    //  console.log('Checking value for key: ' + key + ' field.schemaFieldKey: '+field.schemaFieldKey+' - in FullModel for Parent FieldForm: ', this.shouldRender, this.fullModel, field, this.fieldForm, value);
      let val = undefined;

      if(this.fullModel) {
        if (key && key.length) {
          const firstKey = field.key[0];
          if (null === this.fullModel[firstKey] || undefined === this.fullModel[firstKey]) {
            val = undefined;
          } else {
            val = utils.getValueFromModel(this.fullModel, key);
          }
        } else {
          val = this.fullModel[field.schemaFieldKey];
        }
      }

      //Note: Sometimes internal FullModel is not in Sync with ViewModel and I'll comment this: Ninel 04.04.2020
      if (null !== val && undefined !== val) {
        if (Array.isArray(val)) {
          val = Object.assign([], val);
        } else if (typeof val === 'object') {
          val = Object.assign({}, val);
        }
      }
      //console.log('val for schemaFieldKey: ' + field.schemaFieldKey + ', val: ' + val);
      return val;
    }
  }
}

export default buildMixins
