<template>
  <div class="expression-operator-container">
    {{ value.symbol }}
  </div>
</template>

<script>
import elementsMixin from "./elementsMixin";

export default {
  name: "ExpressionOperator",
  mixins:[elementsMixin]
}
</script>

<style scoped>

</style>
