<template>
  <div>
    <component-base v-bind:field-form="fieldForm">
      <select multiple v-on:change="onSelectChanged($event)" :size="fieldForm.rows || 5" class="selectpicker form-control"
              :aria-placeholder="resolvePlaceholder()"
              :readonly="fieldForm.readonly" :required="fieldForm.required"
              :class="[isValid ? '': 'is-invalid', fieldForm.fieldHtmlClass]">
        <option>{{resolvePlaceholder()}}</option>
        <option v-for="opt in resolveTitleMapOptions()" :selected="isExisting(opt)" :value="opt.value">{{ opt.name }}</option>
      </select>
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
    </component-base>
  </div>
</template>

<script>
  import componentMixin from './componentMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'

  export default {
    name: "SfMultiSelect",
    mixins: [componentMixin, simpleComponentMergeInMixin],
    data() {
      return {
        titleMap: [],
        titleMapValues: []
      }
    },
    created() {
      if (this.fieldForm.titleMap) {
        this.titleMap = this.fieldForm.titleMap;
      }
      const that = this;
      if (!this.localValue){
        if (this.fieldForm.schema.default){
          if (Array.isArray(this.fieldForm.schema.default)){
            this.fieldForm.schema.default.forEach(function(item){
              that.onInput({ value: item });
            });
          }else{
            this.onInput({ value: this.fieldForm.schema.default });
          }
        }
      }else{
        this.localValue.forEach(function(item){
          that.onInput({ value: item });
        });
      }
    },
    methods: {
      onInput: function (val) {
        const i = this.titleMapValues.indexOf(val.value);
        if (i === -1) {
          this.titleMapValues.push(val.value);
        } else {
          this.titleMapValues.splice(i, 1);
        }
      },
      isExisting: function(opt){
        if (this.titleMapValues) {
          const indexOf = this.titleMapValues.indexOf(opt.value);
          if (indexOf > -1)
            return true;
        }
      },
      resolvePlaceholder: function(){
        return this.fieldForm.placeholder || 'Please select';
      },
      /*resolveSelectOptions: function () {
        if (this.fieldForm.options) {
          if (this.fieldForm.options.callback) {
            // call sync to load titleMap
            return this.fieldForm.options.callback(this.localValue);
          }
        } else if (this.fieldForm.titleMap) {
          return this.fieldForm.titleMap;
        }
        return [];
      },*/
      resolveTitleMapOptions: function () {
        if (this.fieldForm.options) {
          if (this.fieldForm.options.callback) {
            // call sync to load titleMap
            return this.fieldForm.options.callback(this.localValue);
          }
        } else if (this.fieldForm.titleMap) {
          return this.fieldForm.titleMap;
        }
        return [];
      },
      onClick: function(event){
        if (this.localValue && this.localValue.length===1 && this.localValue[0] && this.localValue[0]===event.target.value){
          this.localValue = undefined;
        }
      },
      onSelectChanged: function (event) {

        if (event.target && event.target.length){
          let selectd = [];
          for (let i=0;i<event.target.length; i+=1){
            const sel = event.target[i];
            if (sel.selected && !(sel.text===this.resolvePlaceholder())){
              selectd.push(sel.value);
            }
          }
          if (selectd.length)
            this.titleMapValues = selectd;
          else
            this.titleMapValues = undefined;
        }else{
          this.titleMapValues = undefined;
        }
      }
    },
    watch: {
      titleMapValues: {
        handler(newVal, oldVal) {
          this.localValue = newVal;
        }
      }
    }
  }
</script>

<style scoped>

</style>
