import VueDfAppSidebar from './VueDfAppSidebar'
import VueDfAppSidebarLinkItem from './VueDfAppSidebarLinkItem'
import VueDfAppSidebarItem from './VueDfAppSidebarItem'
import VueDfAppSidebarFormItem from './VueDfAppSidebarFormItem'
import VueDfAppSidebarTemplateItem from './VueDfAppSidebarTemplateItem'

const VueDfAppSidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: true,
  displaySidebar (value) {
    this.showSidebar = value
  },
  toggleMinimize () {
    document.body.classList.toggle('app-sidebar-mini')
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'))
    }, 180)

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize)
    }, 1000)

    this.isMinimized = !this.isMinimized
  }
}

const VueDfAppSidebarStorePlugin = {

  install (Vue, options) {
    if (options && options.sidebarLinks) {
      VueDfAppSidebarStore.sidebarLinks = options.sidebarLinks
    }
    Vue.mixin({
      data () {
        return {
          vueDfAppSidebarStore: VueDfAppSidebarStore
        }
      }
    })

    Object.defineProperty(Vue.prototype, '$vueDfAppSidebar', {
      get () {
        return VueDfAppSidebarStore; //this.$root.vueDfAppSidebarStore;
      }
    });

    Vue.component(VueDfAppSidebar.name, VueDfAppSidebar)
    Vue.component(VueDfAppSidebarLinkItem.name, VueDfAppSidebarLinkItem)
    Vue.component(VueDfAppSidebarItem.name, VueDfAppSidebarItem);
    Vue.component(VueDfAppSidebarFormItem.name, VueDfAppSidebarFormItem);
    Vue.component(VueDfAppSidebarTemplateItem.name, VueDfAppSidebarTemplateItem);
  }
}

export default VueDfAppSidebarStorePlugin
