
const directives = [
  {
    name: "sfKey",
    code: {
      bind: function (el, binding, vnode) {
      // console.log('sfKey', binding);
        if (binding.value && binding.value.fieldForm){
        //  binding.value.fieldForm.arrayIndex = binding.value.index;
        }
      }
    }
  },
  {
    name: 'sfChangePosition',
    code:{
      bind: function (el, binding, vnode) {

      }
    }
  }
];


export default directives;
