<template>
  <div>
    <div class="btn-group btn-group-sm pull-right">
      <input class="form-control form-control-sm" placeholder="Enter Draft name" type="text"
             v-model="draftName">
      <button @click="saveDraft()" class="btn btn-primary" title="Save draft">
        <i class="fas fa-save"></i>
      </button>
      <select @change="onDraftSelected($event)" aria-placeholder="Select Draft"
              class="form-control form-control-sm">
        <option>Select Draft</option>
        <option :selected="draft.name===selectedDraftName" :value="draft.name"
                v-for="draft in localDraftNames">
          {{draft.name}}
        </option>
      </select>
      <button @click="loadPreviousDraft()" class="btn btn-warning" title="Load Previous">
        <i class="fas fa-upload"></i>
      </button>
      <button @click="removeDraft()" class="btn btn-danger" title="Remove draft">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
  import findIndex from "lodash/findIndex";

  export default {
    name: "SfSaveNamedContent",
    props: {
      draftNamespace: String,
      componentName: String,
      draftContent: String
    },
    created(){
      this.loadPreviousDraft();
    },
    data() {
      return {
        localDraftNames: [],
        selectedDraftName: {},
        draftName: undefined
      }
    },
    computed: {
      computedDraftNamespace: function () {
        const namespace = this.draftNamespace + ':' + this.componentName;  //'formEditorDrafts:' + this.componentName;
        return namespace;
      },
      computedFullName: function () {
        return this.computedDraftNamespace + ':' + this.draftName;
      }
    },
    methods: {
      saveDraft: function () {
        const fullName = this.computedFullName; //this.computedDraftNamespace + ':' + this.draftName;

        let draftNames = this.$ls.get(this.computedDraftNamespace);
        if (!draftNames)
          draftNames = [];

        const that = this;
        const index = findIndex(draftNames, function (item) {
          return item.name === that.draftName;
        });
        if (index === -1) {
          draftNames.push({name: this.draftName});
          this.$ls.set(this.computedDraftNamespace, draftNames);
        }

        const fullObject = {
          namespace: this.computedDraftNamespace,
          name: this.draftName,
          content: this.draftContent //this.internalSchemaForm
        }

        this.$ls.set(fullName, fullObject);

        this.$set(this, 'localDraftNames', draftNames);

        this.selectedDraftName = this.draftName;
      },
      loadPreviousDraft: function () {
        this.localDraftNames = this.$ls.get(this.computedDraftNamespace);
      },
      onDraftSelected: function (event) {
        //   console.log('Event value', event.target.value);

        let fullObject;
        if (event.target.value) {
          //  const namespace = 'formEditorDrafts:'+this.name;
          this.draftName = event.target.value;
          const fullName = this.computedFullName;//this.computedDraftNamespace + ':' + this.draftName;

          fullObject = this.$ls.get(fullName);
          this.selectedDraftName = this.draftName;

          /*if (fullObject) {
            this.recursivelyUpdateGuid(fullObject.schemaForm.form, true);
            this.onInternalSchemaChanged(fullObject.schemaForm);
          } else {
            console.warn('There is no stored schema for fullName: ' + fullName);
            this.onInternalSchemaChanged({schema: {}, form: []});
          }*/
        } else {
          //this.onInternalSchemaChanged({schema: {}, form: []});
        }
        this.$emit('ondraftselected', fullObject);
      },
      removeDraft: function () {
        let draftNames = this.$ls.get(this.computedDraftNamespace);
        if (draftNames) {
          const that = this;
          const index = findIndex(draftNames, function (item) {
            return item.name === that.draftName;
          });
          if (index > -1) {
            draftNames.splice(index, 1);

            this.$ls.set(this.computedDraftNamespace, draftNames);
            const fullName = this.computedFullName; //this.draftNamespace + ':' + this.draftName;
            this.$ls.remove(fullName);

            this.localDraftNames = draftNames;
            this.draftName = undefined;
            //this.onInternalSchemaChanged({schema: {}, form: []});
            this.$emit('onremovedraft', null);
          }
        }

      }
    }
  }
</script>

<style scoped>

</style>
