var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Elements")]),
      _c(
        "div",
        { staticClass: "expression-designer-container" },
        [_c("expression-elements")],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("label", [_vm._v("Designer")]),
        _c("expression-designer-board", {
          on: { onChange: _vm.onExpressionChanged },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "col-3" }, [
      _vm._v("\n    Expression\n    "),
      _c("pre", [_vm._v("      " + _vm._s(_vm.expressionObj) + "\n    ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }