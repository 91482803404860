<template>
  <button v-if="!fieldForm.hidden"  class="btn" :style="fieldForm.style"
          :class="[fieldForm.btnStyle || 'btn-primary']"
          type="submit">
    <span v-if="fieldForm.icon" :class="fieldForm.icon"></span>{{fieldForm.title}}
  </button>
</template>

<script>
  export default {
    name: "SfSubmitButton",
    props:['fieldForm']
  }
</script>

<style scoped>

</style>
