import VueDfIntegrationDataWidgetView from './VueDfIntegrationDataWidgetView'
import VueDfIntegrationDataWidgetEdit from './VueDfIntegrationDataWidgetEdit'

let widget = {
  type: 'VueDfIntegrationDataWidget',
  title: 'Integration Data Widget',
  description: 'Displays Integration Data',
  category: 'Integrations',
  editComponent: 'VueDfIntegrationDataWidgetEdit',
  viewComponent: 'VueDfIntegrationDataWidgetView',
  editableSettings:{
    editable: true
  },
  style:{

  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {

    Vue.component(VueDfIntegrationDataWidgetView.name, VueDfIntegrationDataWidgetView)
    Vue.component(VueDfIntegrationDataWidgetEdit.name, VueDfIntegrationDataWidgetEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
