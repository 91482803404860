var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "schema-form-section-container" }, [
    _vm.showHideEnabled
      ? _c("div", { staticClass: "fieldset-hide" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-sm btn-primary",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toogleShowHide()
                },
              },
            },
            [_vm._v(_vm._s(_vm.showHideTitle))]
          ),
        ])
      : _vm._e(),
    _vm.fieldForm.sectionType === "fieldset" && _vm.showHide
      ? _c(
          "fieldset",
          {
            class: [
              "schema-form-section-fieldset",
              _vm.fieldForm.fieldSetClass,
            ],
            style: _vm.fieldForm.style,
            attrs: { disabled: _vm.fieldForm.readonly },
          },
          [
            _c("legend", [
              _vm._v(_vm._s(_vm.fieldForm.title || _vm.fieldSchema.title)),
            ]),
            _c(
              "draggable",
              {
                staticClass: "schema-form-section-wrapper",
                class: [
                  _vm.isDroppable ? "droppable-container" : "",
                  _vm.fieldForm.sectionContainerClass,
                ],
                attrs: {
                  tag: "div",
                  handle: ".handle",
                  group: { name: "components", pull: true, put: true },
                  disabled: !_vm.isDroppable,
                  list: _vm.innerList,
                },
                on: {
                  add: _vm.onAddActions,
                  end: _vm.onEndActions,
                  change: _vm.onChangeActions,
                },
              },
              _vm._l(_vm.fieldSchemaItems, function (field, index) {
                return _c(
                  "div",
                  {
                    key: _vm.resolveStringifiedKey(field, index),
                    class: ["schema-form-section-child", _vm.childClass(field)],
                    style: field.childStyle || _vm.fieldForm.sectionChildStyle,
                  },
                  [
                    _vm.isDroppable
                      ? _c("sf-component-toolbar", {
                          attrs: {
                            guid: field.guid,
                            "cloned-dropped-components": _vm.cleanList,
                            "selected-item": _vm.selectedItem,
                            field: field,
                            "is-droppable": _vm.isDroppable,
                          },
                          on: {
                            schemaChanged: _vm.onToolbarSchemaChanged,
                            isminimized: function ($event) {
                              return _vm.onIsMinimized(field, $event)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("builder-inner-component", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.itemState.minimizedItems[field.guid],
                          expression: "!(itemState.minimizedItems[field.guid])",
                        },
                      ],
                      key: _vm.resolveStringifiedKey(field, index),
                      attrs: {
                        "component-data": { field: field },
                        "field-form": field,
                        "field-mapper": _vm.fieldMapper,
                        "field-schema": field,
                        "full-model": _vm.fullModel,
                        "is-droppable": _vm.isDroppable,
                        "parent-index": _vm.parentIndex,
                        "parent-form": _vm.resolveParent(field),
                        value: _vm.value,
                        "schema-form-service": _vm.schemaFormService,
                      },
                      on: {
                        fullModelChanged: _vm.onFullModelChanged,
                        input: function ($event) {
                          return _vm.onInput($event, field)
                        },
                        internalSchemaChanged: _vm.onInternalChildSchemaChanged,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm._e(),
    (_vm.fieldForm.sectionType === "section" || !_vm.fieldForm.sectionType) &&
    _vm.showHide
      ? _c(
          "div",
          {
            class: ["schema-form-section-section", _vm.fieldForm.sectionClass],
            style: _vm.fieldForm.style,
          },
          [
            _c(
              "draggable",
              {
                staticClass: "schema-form-section-wrapper",
                class: [
                  _vm.isDroppable ? "droppable-container" : "",
                  _vm.fieldForm.sectionContainerClass,
                ],
                attrs: {
                  tag: "div",
                  handle: ".handle",
                  group: { name: "components", pull: true, put: true },
                  disabled: !_vm.isDroppable,
                  list: _vm.innerList,
                },
                on: {
                  add: _vm.onAddActions,
                  end: _vm.onEndActions,
                  change: _vm.onChangeActions,
                },
              },
              _vm._l(_vm.fieldSchemaItems, function (field, index) {
                return _c(
                  "div",
                  {
                    key: _vm.resolveStringifiedKey(field, index),
                    class: ["schema-form-section-child", _vm.childClass(field)],
                    style: field.childStyle || _vm.fieldForm.sectionChildStyle,
                  },
                  [
                    _vm.isDroppable
                      ? _c("sf-component-toolbar", {
                          attrs: {
                            guid: field.guid,
                            "cloned-dropped-components": _vm.cleanList,
                            "selected-item": _vm.selectedItem,
                            field: field,
                            "is-droppable": _vm.isDroppable,
                          },
                          on: {
                            schemaChanged: _vm.onToolbarSchemaChanged,
                            isminimized: function ($event) {
                              return _vm.onIsMinimized(field, $event)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("builder-inner-component", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.itemState.minimizedItems[field.guid],
                          expression: "!(itemState.minimizedItems[field.guid])",
                        },
                      ],
                      key: _vm.resolveKey(field, index),
                      attrs: {
                        "component-data": { field: field },
                        "field-form": field,
                        "field-mapper": _vm.fieldMapper,
                        "field-schema": field,
                        "full-model": _vm.fullModel,
                        "is-droppable": _vm.isDroppable,
                        "parent-index": _vm.parentIndex,
                        "parent-form": _vm.resolveParent(field),
                        value: _vm.value,
                        "schema-form-service": _vm.schemaFormService,
                      },
                      on: {
                        fullModelChanged: _vm.onFullModelChanged,
                        input: function ($event) {
                          return _vm.onInput($event, field)
                        },
                        internalSchemaChanged: _vm.onInternalChildSchemaChanged,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }