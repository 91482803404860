import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import {Promised} from 'vue-promised'
import VueDfWidgetDebugView from '../utils/VueDfWidgetDebugView'
import notifyMixin from "../notifyMixin";
import swal from 'sweetalert2'
import {Portal, PortalTarget} from "portal-vue";
import vueUtils from 'vue-json-schema-form/src/utils/vueUtils'


const widgetComponentViewMixins = {
  props: ['model', 'id', 'baseConfig', 'options', 'dashboardEditMode', "dashboardName", "maximizedArea",
    "dashid", "shouldReload", "permissions", "isMaximized", "isEditable", 'parentDashid', 'parentWidgetId',
    'dashboardServiceInstance', 'crudServiceName', 'inputContext', 'widgetSettings'],
  mixins: [notifyMixin],
  components: {
    Promised, VueDfWidgetDebugView, Portal, PortalTarget
  },
  data() {
    return {
      internalWidgetSettings: this.widgetSettings,
      eventLogName: '__df_eventLog',
      isLoading: false,
      validationResult: undefined,
      dataModel: {},
      lookupModel: {},
      lookupSubscribedFunctions: {},
      debugModel: [],
      metaContextObject: {
        _meta: {}
      },
      _subscribedEvents: undefined
    }
  },
  computed: {
    dataId: function () {
      return {
        [this.id]: ''
      }
    }
  },
  created() {
    //  console.log('Created component View: ' + this.id, this);
    const that = this;

    this._subscribedEvents = [];

    this.subscribeEvents();
    this.$viewMixinServices.onCreated(this);
  },
  beforeDestroy() {
    const that = this;
    this.unsubscribeEvents();
    this.$viewMixinServices.onDestroyed(this);
  },
  mounted() {
    // console.log('Mounted id: ' + this.id, this);

  },
  beforeMount() {
    const that = this;
    // that.$nextTick(function () {
    that.loadMetaContextObject();// load from current data
    that.$viewMixinServices.loadMetaContextObject(that.metaContextObject._meta)
      .then(() => {
        //console.log('loaded widget meta', that.metaContextObject);
        that.onLoadWidget();
      }).catch((err) => {
      console.warn(err);
    });
    //  });
  },
  watch: {
    isLoading:{
      handler(newVal){
        this.$emit('loading', newVal);
      }
    },
    /*dataModel: {
      handler(newVal, oldVal) {

        //TODO - Why we need dataModel to be assigned to this.model.config.dataModel???
        if (newVal !== oldVal && newVal !== this.model.config.dataModel) {
          //  this.model.config.dataModel = newVal;
        }
      },
      deep: true
    },*/
    lookupModel: {
      handler(newVal, oldVal) {
        this.onLookupChange(newVal);
      },
      deep: true
    },
    internalWidgetSettings: {
      handler(newVal, oldVal) {
        if (newVal) {
          for (const prop in this.internalWidgetSettings) {
            this.$set(this.widgetSettings, prop, this.internalWidgetSettings[prop]);
          }
        }
      },
      deep: true
    }
  },
  methods: {
    getValidateDataModel: function () {
      return this.dataModel;
    },
    clearData: function () {
      this.dataModel = {}
    },
    onLookupChange: function (newVal) {
      if (newVal) {
        //console.log('On LookupChange', this.lookupModel, newVal, this.lookupSubscribedFunctions);
        for (let prop in newVal) {
          if (this.lookupSubscribedFunctions[prop]) {
            this.lookupSubscribedFunctions[prop](newVal[prop]);
          }
        }
      }
    },
    onLookupComponentSelected: function (context) {
      //console.log('on changed ', context);
      const event = {
        eventName: 'OnLookupSelectedKey_' + context.fieldForm.schemaFieldKey,
        context: {
          value: context.val,
          oldValue: context.oldVal
        }
      }
      this.publishEvent(event.eventName, event);
    },
    subscribeOnLookupModelChange: function (fnObj) {
      console.log('On subscribeOnLookupModelChange', fnObj);
      const schemaFieldKey = fnObj.key;
      const fn = fnObj.fn;

      // console.log('Subscribed too LookupChange', fnObj, this.lookupSubscribedFunctions, this.lookupModel);
      this.lookupSubscribedFunctions[schemaFieldKey] = fn;
      // assign if already loaded
      if (this.lookupModel && this.lookupModel[schemaFieldKey]) {
        fn(this.lookupModel[schemaFieldKey]);
      }
    },
    loadMetaContextObject: function () {
      const that = this;
      const rootDashboardId = this.dashboardServiceInstance.getRootDashboardId();

      this.metaContextObject = {
        _meta: {
          route: that.$route,
          model: that.model,
          id: that.id,
          dashid: this.dashid,
          parentDashId: this.parentDashId,
          parentWidgetId: this.parentWidgetId,
          rootDashboardId: rootDashboardId,
          baseConfig: that.baseConfig,
          appConfig: that.$appConfig.appConfig,
          inputContext: that.inputContext
        }
      }
   //   console.log('Loaded meta context object', this.metaContextObject);
    },
    reloadWidgetEvent: function () {
      //  console.log('Reloading widget with id: ' + this.id);
      this.onLoadWidget();
    },
    subscribeEvents: function () {
      if (!this.model || !this.model.config || !this.model.config.data)
        return;

      const onEvent = this.model.config.data.onEvent;
      if (onEvent) {
        if (onEvent.events) {
          const that = this;
          for (let i = 0; i < onEvent.events.length; i++) {
            const event = onEvent.events[i];
            if (event && event.eventName) {
              const index = that._subscribedEvents.indexOf(event.eventName);
              if (index === -1) {
                that.$widgetBus.$on(event.eventName, that.subscribeOnEvent);
                that._subscribedEvents.push(event.eventName);
              } else {
                //  console.log('Event name already subscribed: ' + event.eventName);
              }
            }
          }
        }
      }
    },
    unsubscribeEvents: function () {
      if (!this.model || !this.model.config || !this.model.config.data)
        return;

      const onEvent = this.model.config.data.onEvent;
      if (onEvent) {
        if (onEvent.events) {
          const that = this;
          onEvent.events.forEach(function (event) {
            if (event && event.eventName) {
              that.$widgetBus.$off(event.eventName, that.subscribeOnEvent);
            }
          });
        }
      }
    },
    executeNotifyMessage: function (eventDef, context) {
      if (eventDef.notifyMessage && eventDef.notifyMessage.message) {
        const message = this.resolveContextObject(eventDef.notifyMessage.message, context, 'string');
        this._debugLog(eventDef.eventName, 'executeNotifyMessage', eventDef, context, message);
    //    console.log('notify message', eventDef, context);

        this.notifyVueMessage(message, 'bottom', 'right', eventDef.notifyMessage.type ? eventDef.notifyMessage.type.toLowerCase() : 'info', eventDef.notifyMessage.timeout)
      }
    },
    canExecuteOnEvent: function (eventDef, eventContext) {
      //  console.log('Can we execute on Event', eventDef.eventType, eventDef, eventContext, eventContext.eventName, eventDef.eventName, eventContext.source.id, this.id);
      if (eventDef.eventName == eventContext.eventName) {

        const source = eventContext.source;

        let canExecute = false;
        switch (eventDef.eventType) {
          case 'CURRENT_WIDGET':
            if (source.id === this.id) {
              canExecute = true;
            }
            break;
          case 'WIDGET_WIDE':
            // will be handled only within single widget
            // this will include all children/parent options
            if (source.id === this.id
              ||
              (source.parentWidgetId && source.parentWidgetId.indexOf(this.id) > -1) // check if this widget is handling children events
              ||
              (this.parentWidgetId && this.parentWidgetId.indexOf(source.id) > -1) // check if this widget is handling parent events
            ) {
              canExecute = true;
            }

       //     console.log('widget wide', source.id, this.id, source.parentWidgetId, this.parentWidgetId, canExecute);

            break
          case 'DASHBOARD_WIDE':
            // will be handled in any widget at any level (possible multiple times - be cautious
            canExecute = true;
            break;
          case 'WIDGET_SPECIFIC':
            if (eventDef.widgetSpecificType) {
              //  if (source.parentWidgetId === this.parentWidgetId) {
              switch (eventDef.widgetSpecificType) {
                case 'WIDGET_INSTANCE':
                  const instanceArray = eventDef.widgetSpecificByInstance;
                  if (instanceArray && instanceArray.length) {
                    if (instanceArray.indexOf(source.model.id) > -1) {
                      canExecute = true;
                    }
                  }
                  break;
                case 'WIDGET_TYPE':
                  const typeArray = eventDef.widgetSpecificByType;
                  if (typeArray && typeArray.length) {
                    if (typeArray.indexOf(source.model.type) > -1) {
                      canExecute = true;
                    }
                  }
                  break;
              }
            }
            break;
        }

        return canExecute;
      }
      return false
    },
    _debugLog: function (name, method, definition, context, value) {
      const payload = {
        dashid: this.dashid,
        widgetId: this.id,
        name: name,
        method: method,
        definition: definition,
        context: context,
        value: value,
        dataModel: this.dataModel,
        emittedAt: new Date().getTime()
      }

      this.$widgetBus.$emit(this.eventLogName, payload);
    },
    resolveLoading: function (eventDef) {
      this.isLoading = eventDef.hideLoading === undefined || eventDef.hideLoading === 'false' ? false : true;
    },
    resolveUserRoleCanExecute: function (allowedRoles, dissalowedRoles, eventContext) {
      if ((allowedRoles === undefined || allowedRoles.length === 0) && (dissalowedRoles === undefined || dissalowedRoles.length === 0)) {
        return true;
      }
      return false;
    },
    subscribeOnEvent: function (eventContext) {
      const onEvent = this.model.config.data.onEvent;
      // console.log('Executed subscribeOnEvent', eventContext, onEvent, this.model, this.id, this.dashid, this);
      if (onEvent) {
        if (onEvent.events) {
          const that = this;
          for (let i = 0; i < onEvent.events.length; i++) {
            const eventDef = onEvent.events[i];

            //onEvent.events.forEach(function (eventDef) {
            if (eventDef && eventDef.eventName && eventDef.executeType) {

              //   console.log('BEFORE Can we execute on Event', eventDef.eventType, eventDef, eventContext, eventContext.eventName, eventDef.eventName, eventContext.source.id, this.id);
              //if (eventDef.eventName == eventContext.eventName) {
              const canExecute = that.canExecuteOnEvent(eventDef, eventContext);
              that._debugLog(eventDef.eventName, 'canExecute', eventDef, eventContext, canExecute);
              if (canExecute) {
                const runIfEvaluated = eventDef.runIf === undefined || that.resolveContextObject(eventDef.runIf, eventContext);
                const allowedForUserToExecute = that.resolveUserRoleCanExecute(eventDef.allowedRoles, eventDef.dissalowedRoles, eventContext);
                //   console.log('runIfEvaluated', runIfEvaluated, eventDef, eventContext);

                that._debugLog(eventDef.eventName, 'runIf', eventDef, eventContext, runIfEvaluated);

                if (runIfEvaluated && allowedForUserToExecute) {

                  that.resolveLoading(eventDef);

                  switch (eventDef.executeType) {
                    case 'APPLY_DATA':
                      that.executeApplyData(eventDef.applyData, eventContext, eventDef);
                      that.executeNotifyMessage(eventDef, eventContext);
                      break;
                    case 'REST_API':
                      eventContext = eventContext || {};
                      eventContext.source = that.getSource();
                      that.executeRestApi(eventDef.executeType, eventDef.restApi, eventContext, eventDef);
                      that.executeNotifyMessage(eventDef, eventContext);
                      break;
                    case 'LOCAL_STORAGE':
                      that.executeLocalStorage(eventDef, eventContext);
                      that.executeNotifyMessage(eventDef, eventContext);
                      break;
                    case 'WIDGET_SETTINGS':
                      that.executeWidgetSettings(eventDef, eventContext);
                      that.executeNotifyMessage(eventDef, eventContext);
                      break;
                    case 'NAVIGATION_SETTINGS':
                      that.executeNavigationSettings(eventDef, eventContext);
                      that.executeNotifyMessage(eventDef, eventContext);
                      break;
                    case 'EVENT_PUBLISH':
                      if (eventDef.eventPublish) {
                        that.executePublishEvents(eventDef.eventPublish, eventContext);
                        that.executeNotifyMessage(eventDef, eventContext);
                      }
                      break;
                    case 'WEB_SOCKET':
                      console.log('On Event Subscribe for ws', eventDef.executeType, eventDef.websocket, eventContext);
                      //that.executeWebSocket(eventDef.websocket, eventContext);
                      that.$viewMixinServices.executeService(eventDef.executeType, eventDef.websocket, eventContext);
                      that._debugLog(eventDef.eventName, 'executeWebSocet', eventDef, eventContext, null);
                      that.executeNotifyMessage(eventDef, eventContext);
                      break;
                    case 'NOTIFY_MESSAGE':
                      that.executeNotifyMessage(eventDef, eventContext)
                      break;
                  }
                }
              }
            }
          }
        }
      }
    },
    executeApplyData: function (applyData, data, eventDef) {
      //  console.log('Executed apply data', applyData, data);
      if (applyData && applyData.contextObject) {
        const localData = this.resolveContextObject(applyData.contextObject, this.resolveDataFromObject(data));
        this._debugLog(eventDef.eventName, 'executeApplyData', eventDef, data, localData);

        if (applyData.contextType && applyData.contextType === 'LOOKUP') {
          this.$set(this, 'lookupModel', Object.assign({}, this.lookupModel, localData));
        } else {
          this.$set(this, 'dataModel', localData);
        }
      } else {
        //this.dataModel = data.context.data;
      }
    },
    getSource: function () {
      const source = {
        id: this.id,
        dashid: this.dashid,
        model: this.model,
        parentWidgetId: this.parentWidgetId,
        parentDashid: this.parentDashid,
        dashboardServiceInstance: this.dashboardServiceInstance
      }
      return source;
    },
    publishObjectEvent: function (eventObj) {
      const name = eventObj.eventName;
      const event = eventObj.eventDefinition;
      event.eventName = name;
      this.publishEvent(name, event);
    },
    publishEvent: function (eventName, event) {
     //  console.log('Publishing event', eventName, event, this.model, this.id, this.dashid, this);
      const source = this.getSource();
      this.$set(event, 'source', source);

      if (event && event.context && event.context.context) {
        //event.context
        event._parent = {source: event.context.source, eventName: event.context.eventName};
        event.context = event.context.context;
      }

      //  console.log('Publish event: '+eventName, event);
      this.$widgetBus.$emit(eventName, event);
    },
    resolveDataFromObject: function (context) {
      //  console.log('Resolving context: ', context);
      return context ? context.data ? context.data : context : {};
    },
    executeStaticData: function (def) {
      if (def) {
        if (def.data) {
          this.dataModel = def.data;
        }
      }
    },
    resolveContextObject: function (contextObjectExpression, currentContext, outputType) {

      /* if (currentContext.context) {
         currentContext.data = currentContext.context;
         delete currentContext.context;
       }
       if (currentContext.data && currentContext.data.context){
         currentContext.data = currentContext.data.context;
       }*/

      let contextObject = currentContext; //currentContext.context || (currentContext.data && currentContext.data.context)?currentContext.data.context:currentContext.data;
      contextObject = merge({}, this.metaContextObject, contextObject);
      //console.log('contextObject', contextObject);
      /*
      Expected structure:
      {
        data: {...},
        definition: {...},
        _meta: {
          user: user,
          userToken: that.$auth.getToken(),
          route: that.$route,
          model: that.model,
          id: that.id,
          baseConfig: that.baseConfig,
          appConfig: that.$appConfig.appConfig
        }
      }
       */

      //console.log('Resolving expression - start', contextObjectExpression, contextObject, outputType);

      contextObject.dataModel = cloneDeep(this.dataModel);
      contextObject.lookupModel = cloneDeep(this.lookupModel);

      /* Here we need to have access to Widget configuration, Current User,  Current Route and Current data model*/
      if (null === contextObjectExpression || undefined === contextObjectExpression) {
        /*contextObject.data = contextObjectExpression;
        return contextObject;*/
        return contextObjectExpression;
      }

      let strObj = contextObjectExpression;
      let objType = typeof contextObjectExpression;
      switch (objType) {
        case 'object':
          try {
            strObj = JSON.stringify(contextObjectExpression);
            strObj = this.$jsulator.evaluate(strObj, contextObject);
          } catch (e) {
            console.error('Error json stringify context object expression', contextObjectExpression, contextObject);
          }
          break;
        case "string":
          strObj = this.$jsulator.evaluate(strObj, contextObject);
          break;
        default:
          strObj = strObj.toString();
          strObj = this.$jsulator.evaluate(strObj, contextObject);
      }

      //   console.log('Executed jsulator context object', strObj, contextObject, contextObjectExpression);

      let res = null;

      if (strObj) {
        outputType = outputType || 'object';
        let strObjectType = typeof (strObj);
        switch (outputType) {
          case 'object':
            if (strObjectType !== 'object') {
              try {
                strObj = JSON.parse(strObj);
              }catch (e){
                res = strObj;
                break;
              }
            }
            res = {};
            res = cloneDeep(strObj);
            break;
          default:
            res = strObj;
            break
        }
      }

      //  const message = 'Resolving expression: ' + contextObjectExpression + ', result: ' + res;
      //  const currentContextCopy = currentContext;
      //  const outputTypeCopy = outputType;

      /*this.debugModel.push({
        message: message,
        currentContext: currentContextCopy,
        outputType: outputTypeCopy
      });*/

      //  console.log('Resolved expression - end', contextObjectExpression, currentContext, outputType, res);
      return res;
    },
    executePublishEvents: function (def, context) {
      if (def) {
        // here we'll just publish the event(s)
        if (def.events) {
          //this._debugLog(def.eventName, 'executePublishEvents', def, context, null);
          const that = this;
          def.events.forEach(function (eventDef) {
            if (eventDef && eventDef.eventName) {
              const data = that.resolveDataFromObject(context);
              that._debugLog(eventDef.eventName, 'publishEvent - resolved data', eventDef, context, context);
              const event = {
                eventName: eventDef.eventName,
                context: that.resolveContextObject(eventDef.contextObject, {
                  definition: eventDef,
                  data: data
                })
              }

              that._debugLog(eventDef.eventName, 'publishEvent - resolved context', eventDef, {
                definition: eventDef,
                data: data
              }, event.context);

              that.publishEvent(event.eventName, event);
            }
          });
        }
      }
    },
    executeRestApi: function (sourceType, restApiDef, eventContext, eventDef) {
      const source = this.getSource();
    //  console.log('Executing REST_API: ', sourceType, restApiDef, eventContext, source, eventDef);

      if (eventDef)
        this._debugLog(eventDef.eventName, 'executeRestApi', eventDef, eventContext, null);

      if (eventContext) {
        this.$viewMixinServices.executeService(sourceType, restApiDef, eventContext);
      } else {
        this.$viewMixinServices.executeService(sourceType, restApiDef);
      }
    },

    findParentRef(component, ref) {
      if (component.$refs && component.$refs[ref]) {
        return component.$refs[ref];
      } else {
        return this.findParentRef(component.$parent, ref);
      }
    },
    executeNavigationSettings: function (def, eventContext) {
      console.log('executeNavigationSettings', def, eventContext);
      const that = this;
      if (def.navigationSettings && def.navigationSettings.type) {

        const contextData = that.resolveDataFromObject(eventContext);

        switch (def.navigationSettings.type) {
          case 'ROUTE':
            const routeSettings = def.navigationSettings.routeSettings;
            if (routeSettings && routeSettings.navigateTo) {
              switch (routeSettings.navigateTo) {
                case 'WIDGET':
                  if (routeSettings.navigateToWidget && routeSettings.navigateToWidget.widgetInstance) {
                    const widgetInstance = routeSettings.navigateToWidget.widgetInstance;
                    // widgetInstance should be visible from all existing dashboards on the page
                  }
                  break;
                case 'ROUTE':
                  if (routeSettings.navigateToRoute && routeSettings.navigateToRoute.routeName) {
                    const routeName = routeSettings.navigateToRoute.routeName;
                  }
                  break;
                case 'LINK':
                  if (routeSettings.navigateToLink && routeSettings.navigateToLink.url) {
                    const url = that.resolveContextObject(routeSettings.navigateToLink.url, contextData, 'string');
                    const target = routeSettings.navigateToLink.urlTarget || '_self';

                    console.log('url to navigate: '+url, target, routeSettings);
                    window.open(url, routeSettings.navigateToLink.urlTarget);

                  }
                  break;
              }
            }
            break;
          case 'MODAL':
            const modalSettings = def.navigationSettings.modalSettings;
            if (modalSettings && modalSettings.type) {
              console.log('modalSettings', modalSettings, contextData);
              switch (modalSettings.type) {
                case 'CONFIRM_MODAL':
                  if (modalSettings.confirmModal) {
                    swal.fire({
                      title: that.resolveContextObject(modalSettings.confirmModal.title, contextData, 'string') || 'Modal',
                      html: that.resolveContextObject(modalSettings.confirmModal.bodyText, contextData, 'string'),
                      confirmButtonText: that.resolveContextObject(modalSettings.confirmModal.confirmButtonText, contextData, 'string') || 'OK',
                      heightAuto: false
                    }).then((result) => {
                      that.executeNavigationSettingsAfterEvents(modalSettings, result, contextData);
                    });
                  }
                  break;
                case 'YES_NO_MODAL':
                  if (modalSettings.yesNoModal) {
                    swal.fire({
                      title: that.resolveContextObject(modalSettings.yesNoModal.title, contextData, 'string') || 'Modal',
                      html: that.resolveContextObject(modalSettings.yesNoModal.bodyText, contextData, 'string'),
                      type: modalSettings.yesNoModal.iconType,//that.resolveContextObject(modalSettings.yesNoModal.iconType, contextData, 'string'),
                      showCancelButton: true,
                      confirmButtonText: that.resolveContextObject(modalSettings.yesNoModal.confirmButtonText, contextData, 'string') || 'Yes', //modalSettings.yesNoModal.confirmButtonText || 'Yes',
                      cancelButtonText: that.resolveContextObject(modalSettings.yesNoModal.cancelButtonText, contextData, 'string') || 'No',
                      footer: that.resolveContextObject(modalSettings.yesNoModal.footerHtml, contextData, 'string'),
                      heightAuto: false
                    }).then((result) => {
                      that.executeNavigationSettingsAfterEvents(modalSettings, result, contextData);
                    });
                  }
                  break;
                case 'WIDGET_MODAL':
                  if (modalSettings.widgetModal && modalSettings.widgetModal.existingWidgetByType) {
                    /*
                        Should be able to create new Widget instance based on Type (should have dropdown of all types
                        When widget is selected at runtime, we'll provide 'dataModel' and model.config of the caller (+ widetId, dashboardId, some meta data, etc)
                        When Type selected - user can select Widget By Type and Widget by Instance
                        WidgetByInstance: user can select from drop-down to show widget that exists on the current Dashboard or inside child dashboard of the current parent dashboar

                        WidgetByType:
                          - There will be option to:
                            - Create new
                            - Reference existing
                     */
                //    console.log('modalSettings', modalSettings);
                    const refModal = that.findParentRef(that, 'widgetInstanceModal');
                    if (refModal) {
                      // eventContext
                      const obj = {
                        widgetId: modalSettings.widgetModal.existingWidgetByType,
                        eventContext: eventContext
                      }
                      refModal.setWidgetModalOptions(obj, 'view', function () {
                        console.log('Showing modal');
                        refModal.showViewModal()
                          .then(function (viewDataModel) {
                            console.log('Closed modal', viewDataModel);
                            that.executeNavigationSettingsAfterEvents(modalSettings, viewDataModel, contextData);
                          });
                      });
                    }

                  }
                  break;
                case 'WINDOW_URL_MODAL':
                  if (modalSettings.windowUrlModal && modalSettings.windowUrlModal.url) {
                    // TODO -

                    console.log('Ru window url', modalSettings.windowUrlModal, eventContext);

                    const windowUrl = that.resolveContextObject(modalSettings.windowUrlModal.url, contextData, 'string');
                    const windowTarget = that.resolveContextObject(modalSettings.windowUrlModal.target, contextData, 'string');
                    const windowFeatures = that.resolveContextObject(modalSettings.windowUrlModal.features,contextData, 'string');

                    let windowHandler = window.open(windowUrl, windowTarget, windowFeatures);
                    let intervalHandler = setInterval(function () {
                      if (windowHandler && windowHandler.closed) {
                        window.clearInterval(intervalHandler);
                        intervalHandler = undefined;
                        windowHandler = undefined;
                        // that.loadAuthAccounts();
                        that.executeNavigationSettingsAfterEvents(modalSettings, eventContext, contextData);
                      }
                    }, 1000);

                  }
                  break;
              }
            }
            break
          case 'REPLACE_WIDGET':
            const settings = def.navigationSettings.replaceWidgetSettings;
            const widgetReplace = settings.widgetReplace;
            if (widgetReplace && widgetReplace.existingWidgetByType) {
              console.log('widgetReplace', widgetReplace, this);
            }
            break;
        }
      }
    },
    executeNavigationSettingsAfterEvents: function (modalSettings, resultObj, prevContextData) {
      console.log('executeNavigationSettingsAfterEvents', modalSettings, resultObj, prevContextData);
      if (modalSettings.responseEvents && modalSettings.responseEvents.events) {
        const events = modalSettings.responseEvents.events;
        const that = this;
        events.forEach(function (eventObj) {
          const event = {
            eventName: eventObj.eventName,
            context: that.resolveContextObject(eventObj.contextObject, {
              data: resultObj,
              definition: eventObj,
              prevContextData: prevContextData
            })
          }
          that.publishEvent(event.eventName, event);
        });
      }
    },
    executeWidgetSettings: function (def, eventContext) {
      console.log('executeWidgetSettings', def, eventContext);

      this._debugLog(def.eventName, 'executeWidgetSettings', def, eventContext, null);
      this.internalWidgetSettings = def.widgetSettings;
    },
    executeLocalStorage: function (def, eventContext) {
    //  console.log('executeLocalStorage', def, eventContext);
      this._debugLog(def.eventName, 'executeLocalStorage', def, eventContext, null);

      const localStorage = def.localStorage;
      if (localStorage && localStorage.contextType) {
        let keyName, storeObject, ttl;
        let resultObj;
        switch (localStorage.contextType) {
          case 'LOAD':
            // console.log('Loading from local storage', def, eventContext, this.$storage)
            keyName = this.resolveContextObject(localStorage.keyName, this.resolveDataFromObject(eventContext), 'string');
            if (keyName) {
              resultObj = this.$storage.get(keyName);
            }
            break;
          case 'STORE':
            console.log('Storing from local storage', def, eventContext, this.$storage)
            storeObject = this.resolveContextObject(localStorage.contextObject, this.resolveDataFromObject(eventContext)); //this.$jsulator.evaluate(localStorage.contextObject, )
            keyName = this.resolveContextObject(localStorage.keyName, this.resolveDataFromObject(eventContext), 'string');
            if (localStorage.ttl)
              ttl = this.resolveContextObject(localStorage.ttl, this.resolveDataFromObject(eventContext), 'string');
            if (null !== keyName || undefined !== keyName) {
              resultObj = this.$storage.set(keyName, storeObject, ttl);
            }
            break;
          case 'REMOVE':
            console.log('Removing from local storage', def, eventContext, this.$storage)
            keyName = this.resolveContextObject(localStorage.keyName, this.resolveDataFromObject(eventContext), 'string');
            if (keyName) {
              resultObj = this.$storage.remove(keyName);
            }
            break;
        }
        if (localStorage.responseEvents && localStorage.responseEvents.events) {
          const events = localStorage.responseEvents.events;
          const that = this;
          events.forEach(function (eventObj) {
            const event = {
              eventName: eventObj.eventName,
              context: that.resolveContextObject(eventObj.contextObject, {
                data: resultObj,
                definition: eventObj
              })
            }
            that.publishEvent(event.eventName, event);
          });
        }
      }
    },
    onLoadWidget: function () {
      const onLoad = this.model.config.data.onLoad;

      if (onLoad) {
        this.isLoading = onLoad.showLoader === undefined || onLoad.showLoader === false ? false : true;

        if (onLoad.sourceType) {
      //    console.log('OnLoadWidget', onLoad, this.inputContext, this.id);
          const that = this;
          switch (onLoad.sourceType) {
            case 'REST_API':
              const eventContext = {};
              eventContext.source = that.getSource();
              that.executeRestApi(onLoad.sourceType, onLoad.restApi, eventContext);
              break;
            case 'EVENTS':
              that.executePublishEvents(onLoad.eventPublish, this.inputContext);
              break;
            case 'STATIC_DATA':
              that.executeStaticData(onLoad.staticData);
              break;
          }
        }
      }
    },
    onSubmit: function (dataValue) {
      const onSubmit = this.model.config.data.onSubmit;
      //  console.log('OnSubmit dataValue', dataValue);
      if (onSubmit && onSubmit.destinationType) {
        const that = this;
        const eventContext = {
          data: dataValue
        }
        switch (onSubmit.destinationType) {
          case 'REST_API':
            //that.executeRestApi(onSubmit.restApi, dataValue);
            eventContext.source = that.getSource();
            that.executeRestApi(onSubmit.destinationType, onSubmit.restApi, eventContext);
            //that.$viewMixinServices.executeService(onSubmit.destinationType, onSubmit.restApi, eventContext);
            break;
          case 'EVENTS':
            that.executePublishEvents(onSubmit.eventPublish, eventContext);
            break;
        }
        //this.notifyMessage('Successfully Submitted');
      }
    },
    mergeInputContext(ctxStr){
      if (ctxStr){
        let ctxObj = ctxStr;
        if (typeof ctxStr === 'string'){
          try{
            ctxObj = JSON.parse(ctxStr);
          }catch (e){
            console.log('Input context not valid JSON', e);
          }
        }
        ctxObj = ctxObj || {};
        if (!this.dataModel){
          this.dataModel = {};
        }
        vueUtils.vueMerge(this.dataModel, ctxObj);
      }
    }
  }
};

export default widgetComponentViewMixins
