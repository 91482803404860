<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>

<script>
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
  import kanbanEditSchemaProp from './kanbanEditSchemaProp'
  export default {
    name: "VueDfKanbanEdit",
    mixins: [widgetComponentEditMixins],
    data(){
      return {
        schemaFormCustom: {
          schemaProperties:{
            events:{
              type: 'array',
              title: "{{ value.name || 'Tab '+$index }}",
              description: "Description for: tabarray",
              items:{
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                    description: 'Define event name that will be matched in Events tab'
                  },
                  kanbanEvent: {
                    type: 'string',
                    title: 'Kanban Event',
                    description: 'Kanban event that will be emitted and match named event',
                    enum: ['KANBAN_ON_LOAD', 'KANBAN_ON_SAVE', 'KANBAN_ON_CREATE_LIST',
                      'KANBAN_ITEM_ON_MOVE', 'KANBAN_ITEM_ON_DROP', 'KANBAN_ITEM_ON_DELETE', 'KANBAN_ITEM_ON_BEFORE_OPEN']
                  }
                }
              }
            }
          },
          formItem:{
            title: 'Kanban Definition',
            items:[
              {
                type: 'tabs',
                tab:{
                  title: 'Events',
                  items:[
                    {
                      type: 'section',
                      sectionType: 'fieldset',
                      title: 'Kanban Events',
                      items:[
                        {
                          type: 'help',
                          description: 'Use Kanban events to match define events and actions'
                        },
                        {
                          key: 'events',
                          type: 'tabarray',
                          items:[
                            {
                              key: 'events[].name'
                            },
                            {
                              key: 'events[].kanbanEvent',
                              type:'select'
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              }

            ]
          }
        }
      }
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    methods:{
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.form[0].tabs.splice(1, 1);
        schemaFormSourceDestination.form[0].tabs.splice(0, 1);

        this.schemaFormCustom.schemaProperties = kanbanEditSchemaProp.schema.properties;
        this.schemaFormCustom.formItem.items = kanbanEditSchemaProp.form;

        for (const prop in this.schemaFormCustom.schemaProperties) {
          schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
        }
        schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);

        this.schemaForm = schemaFormSourceDestination;

      }
    }
  }
</script>

<style scoped>

</style>
