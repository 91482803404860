import jsulator from 'jsulator'

const jsulatorPlugin = {
  install(Vue, options) {

    const jsulatorObj = jsulator.simpleJsulator();
   // console.log('Installing jsulator');
    Object.defineProperty(Vue.prototype, '$jsulator', {
      get() {
        return jsulatorObj;
      }
    })
  }
}

export default jsulatorPlugin
