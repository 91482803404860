<template>
  <chart-builder-editor></chart-builder-editor>
</template>

<script>
import ChartBuilderEditor from 'vue-dashboard-framework-components/src/components/chartBuilder/ChartBuilderEditor';

export default {
  name: "ChartBuilder",
  components: {ChartBuilderEditor}
}
</script>

<style scoped>

</style>
