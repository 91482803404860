<template>
  <div>
  <pre>
      {{ value }}
    </pre>

  </div>
</template>

<script>
export default {
  name: "ChartConfigEditor",
  props:{
    value: Object
  }
}
</script>

<style scoped>

</style>

