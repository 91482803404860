import { render, staticRenderFns } from "./VueDfDashboardWidgetSaveAs.vue?vue&type=template&id=0414a6f0&scoped=true&"
import script from "./VueDfDashboardWidgetSaveAs.vue?vue&type=script&lang=js&"
export * from "./VueDfDashboardWidgetSaveAs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0414a6f0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0414a6f0')) {
      api.createRecord('0414a6f0', component.options)
    } else {
      api.reload('0414a6f0', component.options)
    }
    module.hot.accept("./VueDfDashboardWidgetSaveAs.vue?vue&type=template&id=0414a6f0&scoped=true&", function () {
      api.rerender('0414a6f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-dashboard-framework/src/components/vuedf/VueDfDashboardWidgetSaveAs.vue"
export default component.exports