var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("ul", { staticClass: "list-unstyled" }, [
          _c("li", [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.authenticate("facebook")
                  },
                },
              },
              [_vm._v("auth Facebook")]
            ),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.link("google")
                  },
                },
              },
              [_vm._v("auth Google")]
            ),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.authenticate("twitter")
                  },
                },
              },
              [_vm._v("auth Twitter")]
            ),
          ]),
        ]),
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.modal-1",
                    modifiers: { "modal-1": true },
                  },
                ],
              },
              [_vm._v("Launch demo modal")]
            ),
            _c(
              "b-modal",
              {
                attrs: { "ok-only": "", id: "modal-1", title: "BootstrapVue" },
              },
              [_c("p", { staticClass: "my-4" }, [_vm._v("Hello from modal!")])]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "header row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "select",
          {
            on: {
              change: function ($event) {
                return _vm.onSelectChanged($event)
              },
            },
          },
          _vm._l(_vm.tests, function (test) {
            return _c(
              "option",
              {
                domProps: {
                  selected: test.value === _vm.selectedValue,
                  value: test.value,
                },
              },
              [_vm._v(_vm._s(test.label) + "\n        ")]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("schema-form", {
            attrs: { form: _vm.form, schema: _vm.schema },
            on: { validationResult: _vm.onValidationResult },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _c("div", { staticClass: "body-content" }, [
            _c("h4", [_vm._v("Model")]),
            _c("pre", [_vm._v(_vm._s(_vm.value))]),
            _c("hr"),
            _c("h4", [_vm._v("Validation result")]),
            _c("pre", [_vm._v(" " + _vm._s(_vm.validationResult.errors))]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "col-md-6" }, [
        _c("h4", [_vm._v("Form")]),
        _c("pre", [_vm._v(_vm._s(_vm.form))]),
        _c("hr"),
        _c("h4", [_vm._v("Schema")]),
        _c("pre", [_vm._v(_vm._s(_vm.schema))]),
      ]),
    ]),
    _c("div", { staticClass: "row" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }