var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c(
        "div",
        { staticClass: "widget-app-view" },
        [
          _vm.dashboardName
            ? _c("vue-df-app-dashboard", {
                attrs: {
                  "security-config": _vm.model.config.data.securitySettings,
                  "app-config": _vm.appConfig,
                  config: _vm.model.config.data,
                  "dashboard-edit-mode": _vm.dashboardEditMode,
                  "input-context": _vm.dataModel,
                  "parent-dash-id": _vm.dashid,
                  "parent-dashboard-name": _vm.dashboardName,
                },
              })
            : _vm._e(),
          !_vm.appDashboardName
            ? _c("p", { staticClass: "text-center" }, [
                _vm._v("Define Application name in settings before continue."),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }