<template>
  <div class="position-up-down" :class="[placement]">
    <a v-if="isFirst" @click.prevent="onUp" title="UP" href="" class="small">
      <i class="fas fa-arrow-up"></i>
    </a>
    <a v-if="isLast" @click.prevent="onDown" title="DOWN" href="" class="small">
      <i class="fas fa-arrow-down"></i>
    </a>
  </div>
</template>

<script>
import utils from '../../../utils/utils'

export default {
  name: "SfChangePosition",
  props: {
    arrayValues: Array,
    currentIndex: Number,
    currentItem: [Number, String, Boolean, Object],
    placement: {
      type: String,
      default: function () {
        return 'left'
      }
    }
  },
  computed: {
    shouldShow() {
      return this.arrayValues !== null && this.arrayValues.length > 2;
    },
    isFirst() {
      return this.currentIndex > 0;
    },
    isLast() {
      return this.currentIndex < this.arrayValues.length - 1;
    }
  },
  methods: {
    onUp() {
      /*const tmp = this.arrayValues.splice(this.currentIndex, 1);
      console.log('Spliced current index', tmp, this.arrayValues);
      this.arrayValues.splice(this.currentIndex-1, 0, tmp[0]);
      console.log('new Array', this.arrayValues);*/
      const that = this;
      this.rearrangeArray(this.arrayValues, this.currentIndex, this.currentIndex - 1, function (values) {
        that.$emit('arrayupdated', {values: that.arrayValues});
      });

    },
    onDown() {
      /*const tmp = this.arrayValues.splice(this.currentIndex, 1);
      this.arrayValues.splice(this.currentIndex+1, 0, tmp[0]);*/
      const that = this;
      this.rearrangeArray(this.arrayValues, this.currentIndex, this.currentIndex + 1, function (values) {
        that.$emit('arrayupdated', {values: that.arrayValues});
      });

    },
    rearrangeArray: function (arr, currentIndex, newIndex, next) {
      //console.log('rearrangeArray values', arr, currentIndex, newIndex);

      const newArray = utils.rearrangeArray(arr, currentIndex, newIndex);
      next(newArray);
    }
  }
}
</script>

<style scoped>
.position-up-down {
  top: 10px;
  position: relative;
}

.position-up-down.left {
  left: 5px;
  float: left;
}

.position-up-down.right {
  right: 5px;
  float: right;
}

</style>
