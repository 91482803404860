<template>
  <component-base v-bind:field-form="fieldForm">
    <div class="picker-group" :class="[isValid ? '': 'is-invalid']">
      <date-range-picker :autoApply="fieldForm.autoApply"
                         :control-container-class="'reportrange-text'"
                         :dateFormat="fieldForm.dateFormat"

                         :linkedCalendars="fieldForm.linkedCalendars || true"
                         :disabled="fieldForm.disabled || fieldForm.readOnly"
                         :maxDate="fieldForm.maxDate" :minDate="fieldForm.minDate"
                         :opens="fieldForm.opens || 'center'"
                         :showDropdowns="fieldForm.showDropdowns || false"
                         :showWeekNumbers="fieldForm.showWeekNumbers || false"
                         :singleDatePicker="fieldForm.singleDatePicker || false"
                         :timePicker="fieldForm.timePicker || false"
                         :timePicker24Hour="fieldForm.timePicker24Hour || false"

                         class="picker-input"
                         ref="picker"
                         v-model="dateRange"
      >
      </date-range-picker>
      <div class="picker-button">
        <button @click="clearInput()" class="btn btn-sm btn-outline-secondary" title="Clear date" type="button">Clear</button>
      </div>
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage }}</small>
    </div>
  </component-base>
</template>
<!--  :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD HH:mm:ss' }"-->
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import componentMixin from "./componentMixins";
import simpleComponentMergeInMixin from "./simpleComponentMerginMixin";
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import {DateTime} from "luxon";

export default {
  name: "SfDateTimeRange",
  components: {DateRangePicker},
  mixins: [componentMixin, simpleComponentMergeInMixin],
  data() {
    return {
      dateRange: {}
    }
  },
  watch: {
    localValue: {
      handler(newVal, oldVal){
        if (!isEqual(newVal, this.dateRange)){
          if (typeof newVal === 'string'){
            let data = this.initValue();
            try{
              data = JSON.parse(newVal);
            }catch (e){

            }
            this.dateRange = data;
          }else {
            this.dateRange = newVal ? newVal : this.initValue();
          }
        }
      },
      deep: true
    },
    dateRange: {
      handler(newVal, oldVal) {
        console.log('daterange newVal', newVal);
       // if (newVal !== this.localValue) {
          if (newVal && newVal.startDate === null && newVal.endDate === null) {
            this.localValue = null;
          } else {
            this.localValue = newVal;
          }
       // }
      },
      deep: true
    }
  },
  created() {
    console.log('created');
    if (!isEmpty(this.localValue)) {
      this.dateRange = this.localValue;
    } else if (this.fieldForm.schema && this.fieldForm.schema.default) {

    }
  },
  filters: {
    date(val) {
      return val ? DateTime.fromJSDate(val).toISODate() : ''
    }
  },
  methods: {
    convertFromString: function(str){

    },
    initValue: function (){
      return {
        startDate: null,
        endDate: null
      }
    },
    clearInput: function () {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.dateRange = null;
    }
  }
}
</script>

<style scoped>
.picker-input {
  display: flex;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.picker-group {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
}

.picker-button {
  display: flex;
}

.vue-daterange-picker ::v-deep .daterangepicker {
  top: 36px;
}
</style>
