const dashboardDefaults = {
  dashboardCommon: {
    "dashboardThemeModel": {
      "dashboardTheme": "simple",
      "widgetTheme": "simple"
    },
    "widgetsConfigModel": {
      "allowAllWidgets": true,
      "editableAllWidgets": true
    },
    "hideTitle": true,
    "structure": "3-9",
    "maximizable": false,
    "collapsible": false,
    "refreshable": true,
    "editable": true,
    "continuousEditMode": false,
    "editMode": false,
    "rows": []
  },
  tableWidgetCommon: {
    "type": "VueDfTable",
    "title":"Table",
    "description": "Displays a table of data",
    "category": "Data",
    "editComponent": "VueDfTableEdit",
    "viewComponent": "VueDfTableView",
    "editableSettings": {
      "editable": true
    },
    "data": {},
    "config": {
      "data": {}
    }
  },
  formWidgetCommon: {
    "type": "VueDfSchemaForm",
    "title": "Form",
    "description": "Configure SchemaForm widget",
    "editComponent": "VueDfSchemaFormEdit",
    "viewComponent": "VueDfSchemaFormView",
    "category": "Data",
    "editableSettings": {
      "editable": true
    },
    "config": {
      "data": {}
    },
    "data": {}
  }
}
export default dashboardDefaults
