import VueDfDashboard from './components/vuedf'
import EventBus from './components/vuedf/eventBus'
import WidgetBus from './components/vuedf/utils/widgetEventBus'
import EventBusPlugin from './EventBusPlugin'
import VueNotify from 'vue-notifyjs'
import VueElementLoading from 'vue-element-loading'

const vueDashboardFrameworkPlugin = {
  registerComponents(Vue, options) {
    Vue.component("VueElementLoading", VueElementLoading);
  },
  registerPlugins(Vue, options) {
    Vue.use(EventBus);
    Vue.use(WidgetBus);
    Vue.use(VueNotify, {type: 'primary', timeout: 2000});
    Vue.use(EventBusPlugin, options.eventBus);
    Vue.use(VueDfDashboard)

  },
  install(Vue, options) {

    if (!Vue.__vueDfRegistered) {
      this.registerComponents(Vue, options);
      this.registerPlugins(Vue, options);
      this.__vueDfRegistered = true;
    }
  }
};

export default vueDashboardFrameworkPlugin;
