/*
Every live widget (instantiated) will have access to this.$widgetBus and:
 - it will "subscribe" on Created to predefined Events and will "subscribe" after defined
 - it will "unsubscribe" on BeforeDestroy and when subscription is removed (TODO - this should be checked???)
Communication is valid within single Dashboard on page - other Dashboards are not instantiated nor their widgets
There are communication types:
- Dashboard wide
  All widgets will send and receive events by name from other widgets on same Dashboard. This includes sub-dashboards.
- Widget wide
  This is applicable to Composite widgets (Dashboard widget, Application widget) and within single widget. Events propagated only within that particular widget
- Widget Specific
  Send to widgets by Type and/or widgets by InstanceId - Receive from widgets by Type and/or widgets by InstanceId

 */
const vueEventBus = {

  install: function (Vue, options) {
    const widgetBus = new Vue();
    Vue.widgetBus = widgetBus;


    Object.defineProperty(Vue.prototype, '$widgetBus', {
      get: function () {
        return widgetBus
      }
    })
  }
}


export default vueEventBus
