import { render, staticRenderFns } from "./SfDate.vue?vue&type=template&id=b55b3b34&scoped=true&"
import script from "./SfDate.vue?vue&type=script&lang=js&"
export * from "./SfDate.vue?vue&type=script&lang=js&"
import style0 from "./SfDate.vue?vue&type=style&index=0&id=b55b3b34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b55b3b34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b55b3b34')) {
      api.createRecord('b55b3b34', component.options)
    } else {
      api.reload('b55b3b34', component.options)
    }
    module.hot.accept("./SfDate.vue?vue&type=template&id=b55b3b34&scoped=true&", function () {
      api.rerender('b55b3b34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/vue-schema-form/src/components/schemaForm/SfDate.vue"
export default component.exports