var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "component-base",
        { attrs: { "field-form": _vm.fieldForm } },
        [
          _vm.toShow
            ? _c("treeselect", {
                class: [
                  _vm.isValid ? "" : "is-invalid",
                  _vm.fieldForm.fieldHtmlClass,
                ],
                attrs: {
                  required: _vm.fieldForm.required,
                  "always-open": _vm.fieldForm.alwaysOpen,
                  disabled: _vm.fieldForm.readonly || _vm.fieldForm.disabled,
                  "default-expand-level": _vm.fieldForm.defaultExpandLevel || 1,
                  "disable-branch-nodes":
                    _vm.fieldForm.branchNodesDisabled === undefined
                      ? true
                      : _vm.fieldForm.branchNodesDisabled,
                  multiple: _vm.fieldForm.multiSelect || false,
                  flat: _vm.fieldForm.flat || false,
                  normalizer: _vm.normalizer,
                  "sort-value-by": _vm.fieldForm.sortSelectedBy || "INDEX",
                  options: _vm.internalTitleMap,
                  "show-count": _vm.fieldForm.showCount || true,
                  placeholder: _vm.fieldForm.placeholder,
                },
                on: { open: _vm.onOpen },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option-label",
                      fn: function ({
                        node,
                        shouldShowCount,
                        count,
                        labelClassName,
                        countClassName,
                      }) {
                        return _c("label", { class: labelClassName }, [
                          node.isBranch
                            ? _c("span", [
                                node.raw[_vm.branchImage]
                                  ? _c("img", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        src: node.raw[_vm.branchImage],
                                        height: "15",
                                        width: "15",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(_vm._s(node.label)),
                              ])
                            : _vm._e(),
                          !node.isBranch
                            ? _c("span", [
                                node.raw[_vm.leafImage]
                                  ? _c("img", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        src: node.raw[_vm.leafImage],
                                        height: "15",
                                        width: "15",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(_vm._s(node.label)),
                              ])
                            : _vm._e(),
                          shouldShowCount
                            ? _c("span", { class: countClassName }, [
                                _vm._v("(" + _vm._s(count) + ")"),
                              ])
                            : _vm._e(),
                        ])
                      },
                    },
                    {
                      key: "value-label",
                      fn: function ({ node }) {
                        return _c("label", {}, [
                          node.isBranch
                            ? _c("span", [
                                node.raw[_vm.branchImage]
                                  ? _c("img", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        src: node.raw[_vm.branchImage],
                                        height: "15",
                                        width: "15",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(_vm._s(_vm.resolveNodeLabel(node))),
                              ])
                            : _vm._e(),
                          !node.isBranch
                            ? _c("span", [
                                node.raw[_vm.leafImage]
                                  ? _c("img", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        src: node.raw[_vm.leafImage],
                                        height: "15",
                                        width: "15",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(_vm._s(_vm.resolveNodeLabel(node))),
                              ])
                            : _vm._e(),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  1985122119
                ),
                model: {
                  value: _vm.internalValue,
                  callback: function ($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue",
                },
              })
            : _vm._e(),
          !_vm.isValid
            ? _c("small", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }