var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "metric-dimension-builder-container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-3 col-sm-12 mb-2" }, [
        _c("div", { staticClass: "card full" }, [
          _c("div", { staticClass: "card-header" }),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "DataSources" } },
                    [
                      _c("data-sources", {
                        on: {
                          create: _vm.onCreateDataSource,
                          select: _vm.onSelectedDataSource,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-tab", { attrs: { title: "Chart Design" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "card-footer" }),
        ]),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("\n          Loaded data\n        "),
          ]),
          _c("div", { staticClass: "card-body" }, [_c("data-table")], 1),
          _c("div", { staticClass: "card-footer" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col mb-2" }, [
      _c("div", { staticClass: "card full" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("\n          Context\n        "),
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("p", [
            _vm._v(
              "User will select DataSource and Load data or create new DataSource"
            ),
          ]),
          _c("p", [
            _vm._v(
              "- If creating new DataSource we'll show here in Context specific configuration to create DataSource"
            ),
          ]),
          _c("p", [_vm._v("-- Datasource can be: ")]),
          _c("ul", [
            _c("li", [_vm._v("HTTPs")]),
            _c("li", [_vm._v("Db {Mongo, MySQL/Postgresql/...RDBMS }")]),
            _c("li", [_vm._v("Integrated Source {FB, TW, GA, YT, etc }")]),
            _c("li", [_vm._v("CSV {Upload .csv, .zip, .gz }")]),
          ]),
          _c("hr"),
          _c("p", [_vm._v("After it is created - user can load the data")]),
          _c("p", [
            _vm._v(
              "- When data is loaded it will be shown in Loaded Data panel (below)"
            ),
          ]),
          _c("p", [
            _vm._v(
              '- From there user can "Transform" data by drag/drop fields from table'
            ),
          ]),
          _c("hr"),
          _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Table data")]),
          _c("p", [
            _vm._v(
              "- Data shown in Table can be Flat or Hierarchical - there will be max 100 items (or configurable)"
            ),
          ]),
          _c("p", [_vm._v('- These are "Dimensions"')]),
          _c("p", [
            _vm._v(
              '- User will be able to define "Metrics" such as SUM, COUNT, AVG, etc. as well as simple "concatenation", field sum and similar MATH operations'
            ),
          ]),
          _c("p", [
            _vm._v(
              "- Advanced User will be able to define expression to process all fields"
            ),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              "After expression/definition is set - user can click to see resulting data"
            ),
          ]),
          _c("hr"),
          _c("p", { staticClass: "font-weight-bold" }, [
            _vm._v("Chart Design"),
          ]),
          _c("p", [
            _vm._v(
              '- Based on "filtered" data (filtered by expressions) we\'ll suggest chart type'
            ),
          ]),
          _c("p", [
            _vm._v(
              "User will be able to choose Object value chart type or other EChart types"
            ),
          ]),
          _c("p", [
            _vm._v(
              "- Object Value chart type - is template where user will select Main Value in Body - other values in Header or Footer - all with 3 columns - total 9 - predefined. Adjust font size - url - image, etc"
            ),
          ]),
          _c("p", [_vm._v("- There will be")]),
          _c("ul", [
            _c("li", [_vm._v("Dimensions")]),
            _c("li", [_vm._v("Metrics")]),
          ]),
          _c("p", [
            _vm._v("- "),
            _c("strong", [_vm._v("Dimensions")]),
            _vm._v(" are Category data - can be on xAxis or yAxis"),
          ]),
          _c("p", [
            _vm._v("- "),
            _c("strong", [_vm._v("Metrics")]),
            _vm._v(
              ' will be numerical and there could be multiple "series" of metrics - showing stacked, line or area charts'
            ),
          ]),
          _c("p", [
            _vm._v(
              "--  Metric can be combined with Dimension where Category data (dimension) is Time series"
            ),
          ]),
        ]),
        _c("div", { staticClass: "card-footer" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }