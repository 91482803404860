var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "dashboard-simple" } },
    [
      _c("vue-df-dashboard-simple", {
        attrs: {
          categories: _vm.selectedDashboard.categories,
          "continuous-edit-mode": false,
          "crud-service-name": _vm.crudServiceName,
          "dashboard-name": _vm.dashboardName,
          dashid: _vm.selectedDashboard.dashid,
          "default-mapper": _vm.defaultMapper,
          "edit-mode": false,
          collapsible: false,
          editable: false,
          maximizable: false,
          options: _vm.options,
          model: _vm.selectedDashboard,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }