<template>
  <div :class="'schema-form-tabarray-'+(fieldForm.tabType || 'left')+' '+(fieldForm.htmlClass || '')"
       class="clearfix schema-form-tabarray"
       v-if="fieldFormArray">
    <div class="row tabarray-container">
      <div :class="resolveTabClass()" class="tabarray-menu"
           v-if="!fieldForm.tabType || fieldForm.tabType !== 'right'">
        <div class="nav-tabs-navigation verical-navs">
          <div class="nav-tabs-wrapper">
            <ul aria-orientation="vertical" class="nav flex-column nav-stacked nav-tabs nav-tabs-left flex-nowrap"
                role="tablist">
              <li @click="onSelectItem(index)" class="nav-item"
                  v-for="(item, index) in arrayValues">
                <sf-change-position :array-values="arrayValues" :current-index="index"
                                    :current-item="item" @arrayupdated="onArrayValuesChanged"></sf-change-position>
                <a :class="{'active':selectedTab===index}" @click.prevent="" class="tabbed nav-link"><span
                  class="nav-text">{{ tabTitles[index] }}</span> </a>
              </li>
              <li :disabled="resolveMaxItems(fieldForm) <= (arrayValues.length || 0 ) || isDroppable"
                  :hidden="fieldForm.readonly || fieldForm.add === null"
                  @click.prevent="appendToArray(resolveMaxItems(fieldForm))"
                  class="nav-item">
                <a :class="fieldForm.btnStyle && fieldForm.btnStyle.add?fieldForm.btnStyle.add:'btn-primary'"
                   :disabled="isDroppable"
                   class="nav-link btn btn-sm"
                   href="#">
                  <i class="fas fa-plus"></i>
                  {{ fieldForm.add || 'Add' }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div :class="[fieldForm.contentClass || 'col', 'tabarray-content']">
        <div :class="fieldForm.fieldHtmlClass" class="tab-content" role="tabpanel">
          <template v-if="arrayValues && arrayValues.length>0">
            <div :class="['tab-'+selectedTab+' index-'+selectedTab, {'active':true}]"
                 :key="resolveArrayStringifiedKey(selectedTab, selectedTab)"
                 class="tab-pane clearfix">
              <builder-component :field-mapper="fieldMapper"
                                 :form="resolveMergedForm(selectedTab)" :full-model="fullModel" :is-droppable="isDroppable"
                                 :merged-form="resolveMergedForm(selectedTab)"
                                 :parent-form="resolveParentForm(selectedTab)"
                                 :parent-index="selectedTab"
                                 :schema-form-service="schemaFormService"
                                 :schema="resolveFieldSchema(selectedTab)"
                                 :value="resolveSelectedValue(selectedTab)"
                                 @fullModelChanged="onFullModelChanged" @internalSchemaChanged="onInternalSchemaChanged"
                                 @schemaChanged="onSchemaArrayChanged">
              </builder-component>

              <div
                v-sf-key="{ 'fieldForm': fieldForm, 'parentIndex': parentIndex, 'index': selectedTab, 'fieldValue':arrayValues[selectedTab] }"></div>

              <button :class="(fieldForm.btnStyle && fieldForm.btnStyle.remove) || 'btn-default'"
                      :disabled="isDroppable"
                      @click="deleteFromArray(arrayValues[selectedTab], selectedTab)"
                      class="btn btn-sm pull-right"
                      type="button"
                      v-if="!fieldForm.readonly || !fieldForm.remove === null">
                <i class="glyphicon glyphicon-trash"></i>
                {{ fieldForm.remove || 'Remove' }}
              </button>

            </div>
          </template>
          <template v-else>
            <builder-component :field-mapper="fieldMapper"
                               :form="[]" :full-model="fullModel" :is-droppable="isDroppable"
                               :merged-form="[]"
                               :schema="{}"
                               :value="{}"
                               @fullModelChanged="onFullModelChanged" @internalSchemaChanged="onInternalSchemaChanged"
                               @schemaChanged="onSchemaArrayChanged">
            </builder-component>
          </template>
        </div>

      </div>
    </div>
    <div v-if="validationError" class="invalid-feedback error-text">{{validationError}}</div>
    <div :class="fieldForm.descriptionHtmlClass" class="text-muted small help-block">
      <span  v-html="fieldForm.description" ></span>
    </div>
  </div>
</template>

<script>
import componentMixin from './componentMixins'
import droppableMixins from './droppableMixins'
import containerMixin from './containerMixin'
import BuilderComponent from './BuilderComponent'
import utils from '../../utils/utils'
import vueUtils from '../../utils/vueUtils'
import ObjectPath from "../../utils/ObjectPath";
import schemaFormElements from './schemaFormElements'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import SfChangePosition from "./utilComponents/SfChangePosition";
import isEmpty from 'lodash/isEmpty'

export default {
  name: "SfTabArray",
  mixins: [componentMixin, droppableMixins, containerMixin],
  components: {
    SfChangePosition,
    BuilderComponent
  },
  data() {
    return {
      arrayItem: null,
      arrayValues: [],
      selectedTab: 0,
      fieldFormArray: undefined,
      tabTitles: []
    }
  },
  beforeDestroy() {
    if (this.isDroppable) {
      const that = this;
      this.$bus.$off('selectedItemModelApplied', that.onSelectedItemModelAppliedInArray);
    }
  },
  created() {
    this.assignArrayValues();
    //  console.log('TabArray created');
    if (this.isDroppable) {
      const that = this;
      this.$bus.$on('selectedItemModelApplied', that.onSelectedItemModelAppliedInArray);
    }
  },
  mounted() {
    //this.assignArrayValues();
    this.updateFormItems();
  },
  watch: {
    arrayValues: {
      handler(newVal, oldVal) {
        //     console.log('Array Values changed in TabArray', newVal, this.fieldForm);
        this.updateTitles();
        if (newVal && (!this.fieldFormArray || this.fieldFormArray.length===0)) {//(this.fieldFormArray===undefined || this.fieldFormArray===null || this.fieldFormArray.length===0)) {
          this.updateFormItems();
        }
        //this.localValue = newVal;
      },
      deep: true
    },
    localValue: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
         // console.log('localValue changed', newVal);
          this.arrayValues = newVal || [];
        }
        /*if (newVal !== oldVal) {
          if (isEqual(newVal, this.arrayValues)){
            this.arrayValues = newVal
          }else{
            this.arrayValues = [];
          }
        }*/
      }
    }
  },
  methods: {
    resolveTabClass(){
      //{'col-2': !fieldForm.tabType || fieldForm.tabType === 'left'}
      let tabClass = this.fieldForm.tabClass;
      if (!tabClass){
        tabClass =  !this.fieldForm.tabType || this.fieldForm.tabType === 'left'?'col-2':''
      }
      return tabClass;
    },
    resolveSelectedValue: function(selectedTab){
      const value = this.arrayValues[selectedTab];
   //   console.log('resolveSelectedValue', selectedTab, this.arrayValues, value);
      return value;
    },
    resolveParentForm: function (index) {
      return {
        parentForm: this.parentForm,
        arrayIndex: this.parentForm ? this.parentForm.arrayIndex : null
      }
    },
    onArrayValuesChanged: function (value) {
      //this.updateFormItems();
     // console.log('Rearanged values', value);
      this.$set(this, 'localValue', value.values);
      this.updateFormItems();
    //  vueUtils.vueSet(this.fullModel, this.fieldForm, value.values);

    },
    resolveArrayStringifiedKey: function (field, index) {
      if (!field)
        return;

      let key = index;

      if (field.guid) {
        key = field.guid;
      } else if (field.schemaFieldKey) {
        key = field.schemaFieldKey;
      } else {
        key = index;//JSON.stringify(field);
      }
      //  console.log('Stringified key in tabarray', key, field);
      return key;
    },
    resolveFieldSchema: function (index) {
      const schemaItems = this.fieldSchema.schema.items;
      return schemaItems;
    },
    resolveMergedForm: function (index) {
     // console.log('resolveMergedForm', index, this.fieldFormArray);
      const component = this.fieldFormArray[index];
      return component;
    },
    resolveMaxItems: function (fieldForm) {
      let maxItems = 1000000;
      if (fieldForm.schema && fieldForm.schema.maxItems) {
        maxItems = fieldForm.schema.maxItems;
      } else if (fieldForm.maxItems) {
        maxItems = fieldForm.maxItems
      }
      return maxItems;
    },
    resolveMinItems: function (fieldForm) {
      let minItems = 0;
      if (fieldForm.schema && fieldForm.schema.minItems) {
        minItems = fieldForm.schema.minItems;
      } else if (fieldForm.minItems) {
        minItems = fieldForm.minItems;
      }
      return minItems;
    },
    onSelectedItemModelAppliedInArray: function (item) {
      /*console.log('Received array onSelectedItemModelApplied', this.fieldForm, item);
      const that = this;
      this.$nextTick(function () {
        that.mergeInContainerLeafItem(item, '[].');
      })*/
    },
    onSchemaArrayChanged: function (schemaArray) {

      console.log('onSchemaArrayChanged TabArray', schemaArray, this.fieldForm);

      let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);
      let builderFormArray = schemaAndForm.form;
      let builderSchema = schemaAndForm.schema;

      this.fieldForm.schema.items = builderSchema.properties;

      // update child items keys
      const that = this;
      forEach(builderFormArray, function (formItem) {
        formItem.key = that.fieldForm.key + '[].' + formItem.key;
      });

      this.fieldForm.items = builderFormArray;

      this.$emit('schemaChanged', {schemaFormComponent: this.fieldForm});
    },
    onInternalSchemaChanged: function (schemaArray) {

      console.log('onInternalSchemaChanged - start', schemaArray, utils.clone(this.fieldForm.items));
      const that = this;
      if (!schemaArray || schemaArray.length === 0) {
        this.fieldForm.schema.items = {};
        this.fieldForm.items = [];
      } else {
        const schemaAndForm = this.updateFirstItemsKeyRecursively(this.fieldForm, schemaArray, '[].');

        this.fieldForm.schema.items = schemaAndForm.schema;
        this.fieldForm.items = schemaAndForm.form;
      }
      console.log('onInternalSchemaChanged - end', schemaArray, utils.clone(this.fieldForm));

      this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm, componentType: 'array '});
      this.$nextTick(function () {
        that.updateFormItems();
      });
    },
    assignArrayValues: function () {
      let key = utils.clone(this.fieldForm.key);
      let vals = utils.getValueFromModel(this.fullModel, key);
      if (!vals) {
        vals = Object.assign([]);
        if (this.isDroppable) {
          vals.push(this.resolveArrayItem());
        }
        vueUtils.vueSet(this.fullModel, key, vals);
      }
      this.arrayValues = vals;

    },
    updateTitles: function () {
      if (!this.arrayValues)
        return;
      // console.log('TabArray updateTitles');

      // const fieldArray = [];
      const tabTitles = [];
      for (let i = 0; i < this.arrayValues.length; i += 1) {
        const item = this.arrayValues[i];


        const val = (item === 0) ? item : utils.safeEval(this.fieldForm.title, {value: item, form: this.fieldForm, index: i});
        const tabTitle = (val === undefined || val === null) ? ('Tab ' + i) : val; //  ('Tab ' + i)
        //  console.log('Evaluating title in UpdateTitles: '+tabTitle, this.fieldForm.title, item, this.fieldForm);
        tabTitles.push(tabTitle);
      }
      this.tabTitles = tabTitles;
    },
    updateFormItems: function () {
      if (!this.arrayValues)
        return;

      const fieldArray = [];
      // const tabTitles = [];
      const that = this;
      if (this.arrayValues.length > 0) {
        for (let i = 0; i < this.arrayValues.length; i += 1) {
          //const item = this.arrayValues[i];
          const clonedItems = merge([], utils.clone(this.fieldForm.items));
          const newFieldFormItems = clonedItems.map((eachForm, index) => {
            // console.log('TabArray Processing clonedItems', eachForm, that.fieldForm);
            if (eachForm.key && that.fieldForm.key) {
              if (eachForm.type === 'fieldset') {
                const fieldsetForm = that.resolveSingleComponentCopyWithIndex(i, eachForm, that.fieldForm);
                if (fieldsetForm.items) {
                  const fieldsetItems = that.updateArrayIndexRecursively(i, fieldsetForm, that.fieldForm);
                  that.$set(fieldsetForm, 'items', fieldsetItems);
                  that.$set(fieldsetForm, 'arrayIndex', i)
                }
                return fieldsetForm;
              } else {
                const tmpEachForm = that.resolveSingleComponentCopyWithIndex(i, eachForm, that.fieldForm);
                return tmpEachForm;
              }
            } else if (eachForm.type === 'section') {
              const sectionItems = that.updateArrayIndexRecursively(i, eachForm, that.fieldForm);
              that.$set(eachForm, 'items', sectionItems);
              that.$set(eachForm, 'arrayIndex', i);
              return eachForm;
            } else if (eachForm.type === 'tabs') {
              const tabsItems = that.updateArrayIndexRecursively(i, eachForm, that.fieldForm);
              forEach(tabsItems, function (item) {
                delete item.key;
              });
              that.$set(eachForm, 'tabs', tabsItems);
              that.$set(eachForm, 'arrayIndex', i);
              return eachForm;
            } else {
              that.$set(eachForm, 'arrayIndex', i);
              this.appendArrayIndex(eachForm, i);
              return utils.clone(eachForm);
            }

          });
          // console.log('Push to FieldArray New Field Form Items for index: '+i, newFieldFormItems, clonedItems, this.fieldForm.items);
          if (newFieldFormItems && newFieldFormItems.length > 0)
            fieldArray.push(newFieldFormItems);
        }
      }
      if (this.fieldFormArray !== fieldArray) {
        this.fieldFormArray = fieldArray;
      }
      // this.tabTitles = tabTitles;
      //   console.log('Updated tab array items', this.arrayValues, this.tabTitles, this.fieldFormArray);
    },
    appendArrayIndex: function(eachForm, index){
      //TODO - how to handle button click in child components - this should be solved !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!\

      /*if (eachForm.indexArray===undefined || eachForm.indexArray===null){
        this.$set(eachForm, 'indexArray', []);
      }
      eachForm.indexArray.push(index);*/
    },
    updateArrayIndexRecursively: function (index, eachForm, fieldForm) {
      const that = this;
      let items = eachForm.items || [];
      if (eachForm.type === 'tabs') {
        items = eachForm.tabs;
      }
      const clonedItems = utils.clone(items);
      let tmpItems = [];
      forEach(clonedItems, function (item) {
        if (item.key) {
          item = that.resolveSingleComponentCopyWithIndex(index, item, fieldForm);
        }
        if (item.type !== 'tabarray' || item.type !== 'array') {

          if (item.type === 'section' || item.type === 'tabs') {
            item.arrayIndex = index;
          }
          if (item.items && item.items.length > 0) {
            const updatedItems = that.updateArrayIndexRecursively(index, item, fieldForm);
            item.items = utils.clone(updatedItems);
          } else if (item.tabs && item.tabs.length > 0) {
            const updatedTabs = that.updateArrayIndexRecursively(index, item, fieldForm);
            item.tabs = utils.clone(updatedTabs);
          }
        }
        tmpItems.push(item);
      });
      return tmpItems;
    },
    resolveSingleComponentCopyWithIndex: function (index, eachForm, fieldForm) {
      let slice = Array.isArray(eachForm.key) ? eachForm.key.slice(fieldForm.key.length) : eachForm.key;
      let eachFormCopy = utils.clone(eachForm);
      eachFormCopy.key = slice;
      const copy = this.copyWithIndex(eachFormCopy, index);
      copy.key = fieldForm.key.concat(copy.key);
      return copy;
    },
    copyWithIndex: function (field, index) {
      const that = this;
      const copy = utils.clone(field);
      copy.arrayIndex = index;
      utils.traverseForm(copy, function (form) {
        //console.log('traversing form: ' + form.type + ' = ' + form.schemaFieldKey + ', index: ' + index, form);
        that.setIndex(form, index);
      });
      return copy;
    },
    setIndex: function (field, index) {
      field.arrayIndex = index;
      this.appendArrayIndex(field, index);

      if (field.key) {
        // todo fix mutable object
        // eslint-disable-next-line no-param-reassign
        let tmpKey = field.key;
        if (!Array.isArray(field.key)) {
          tmpKey = ObjectPath.parse(tmpKey);
        }
        let i = tmpKey.indexOf("");
        if (i > -1) {
          tmpKey[i] = index;
        }
        //   console.log('Old => New Key: ' + utils.clone(field.key) + ' => ' + utils.clone(tmpKey));
        field.key = tmpKey;
      }

    },

    deleteFromArray: function (fieldValue, index) {

      console.log('Delete field', fieldValue, index, this.fieldForm.key, this.arrayValues, this.fullModel);
     // this.arrayValues.splice(index,1);

      vueUtils.vueSplice(this.fullModel, utils.clone(this.fieldForm.key), index, 1);
      /*this.arrayValues.splice(index, 1);
      this.updateFormItems();
      this.$nextTick(function(){
        this.selectLast();
      }); */
      this.$nextTick(function () {
        this.selectLast();
      });
    },
    resolveArrayItem: function () {
      this.arrayItem = {};
      return this.arrayItem;
    },
    appendToArray: function (maxItems) {
      if (!this.arrayValues) {
        this.arrayValues = [];
      }
      if (maxItems && maxItems <= this.arrayValues.length || this.isDroppable) {
        return;
      }
      this.arrayValues.push(this.resolveArrayItem());
      this.updateFormItems();
      this.updateTitles();

      this.selectLast();
    },
    selectLast: function () {
      this.onSelectItem(this.arrayValues.length - 1);
    },
    onSelectItem: function (index) {
      this.selectedTab = index;
    }
  }
}
</script>

<style scoped>

.tabbed.nav-link {
  text-align: right;
}

.nav-tabarray-navigation {
  padding-top: 30px;
}

.nav-tabs-wrapper {
  word-break: break-all;
}


</style>
