var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "component-base",
    { attrs: { "field-form": _vm.fieldForm } },
    [
      _c("vue-tags-input", {
        class: [
          _vm.isValid ? "" : "is-invalid",
          "form-control",
          "schema-form-tags-input",
        ],
        attrs: {
          required: _vm.fieldForm.required,
          placeholder: _vm.resolvePlaceholder(_vm.fieldForm) || "Add Tag",
          disabled: _vm.fieldForm.readonly,
          tags: _vm.tags,
        },
        on: { "tags-changed": _vm.changedTags },
        model: {
          value: _vm.tag,
          callback: function ($$v) {
            _vm.tag = $$v
          },
          expression: "tag",
        },
      }),
      !_vm.isValid
        ? _c("small", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }