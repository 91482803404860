<template>
  <div>
    <div class="text-danger text-center p-5" v-if="!internalWidgets && internalWidgets.length>0">
      There is no widgets to select
    </div>
    <div v-if="internalWidgets">
      <div class="widget-group-container">
        <div class="row mb-4" v-for="groupArray in groupWidgets">
          <div class="col" v-for="(value, type) in groupArray">
            <strong>{{type}}</strong>
            <hr/>
            <template v-for="(widget, key) in value">
              <dt>
                <a @click.prevent="addWidget(widget)" href="">{{ widget.title }}</a>
              </dt>
              <dd v-if="widget.description">{{ widget.description}}</dd>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import defaultMixins from './defaultMixin'
  import cloneDeep from 'lodash/cloneDeep'
  import isEmpty from 'lodash/isEmpty'
  import find from 'lodash/find'
  import permissionMixin from "./permissionsMixin";

  export default {
    name: "VueDfDashboardAddWidget",
    mixins: [defaultMixins, permissionMixin],
    props: ['widgets'],
    data() {
      return {
        internalWidgets: {},
        colNumbers: 4
      }
    },
    beforeMount() {
      this.filterWidgets();
    },
    computed: {
      groupWidgets: function () {
        console.log('this.internalWidgets', this.internalWidgets);
        const grouped = {};
        for (const pr in this.internalWidgets) {
          const widget = this.internalWidgets[pr];
          const category = this.internalWidgets[pr].category;
          if (!grouped[category]) {
            grouped[category] = [];
          }
          grouped[category].push(this.internalWidgets[pr]);
        }
        if (grouped) {
          const res = [], col = this.colNumbers;
          let c = 0;
          let tmpRes = {};
          const totalNum = Object.keys(grouped).length;
          for (const p in grouped) {
            const widget = grouped[p];
            let modulo = c % col;
            // console.log('p', p, modulo);
            tmpRes[p] = widget;

            if (modulo === col - 1) {
              res.push(cloneDeep(tmpRes));
              tmpRes = {};
            }

            if (c === totalNum - 1 && !isEmpty(tmpRes)) {
              res.push(cloneDeep(tmpRes));
            }
            ++c;
          }
          console.log('Res in groped', res);
          return res;
        }

        return null;
      },
      createCategories: function () {
        return false;
      }
    },
    methods: {
      filterWidgets: function () {
        console.log('Before mount widgegts', this.internalWidgets, this.widgets, this.model, this.editMode);
        if (this.model.widgetsConfigModel && !this.model.widgetsConfigModel.allowAllWidgets) {
          const filteredWidgets = {};
          const that = this;
          if (this.model.widgetsConfigModel.allowedWidgets && this.model.widgetsConfigModel.allowedWidgets.length > 0) {
            this.model.widgetsConfigModel.allowedWidgets.forEach(function (item) {
              const widget = find(that.widgets, {type: item});
              if (widget) {
                filteredWidgets[item] = widget;
              }
            });
            this.$widgetStore.vueJsonMerge(this.internalWidgets, filteredWidgets);
          }
        } else {
          this.$widgetStore.vueJsonMerge(this.internalWidgets, this.widgets);
        }
      },
      addWidget: function (widget) {
        this.$emit('addwidget', widget);
      }
    }
  }
</script>

<style scoped>

</style>
