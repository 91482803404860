<template>
  <div class="home">
    <div class="row">
      <div class="col">
        <ul class="list-unstyled">
          <li>
            <button @click="authenticate('facebook')">auth Facebook</button>
            <button @click="link('google')">auth Google</button>
            <button @click="authenticate('twitter')">auth Twitter</button>
          </li>
        </ul>
        <div>
          <b-button v-b-modal.modal-1>Launch demo modal</b-button>

          <b-modal ok-only  id="modal-1" title="BootstrapVue">
            <p class="my-4">Hello from modal!</p>
          </b-modal>
        </div>
      </div>
    </div>
    <div class="header row">
      <div class="col-md-12">
        <select v-on:change="onSelectChanged($event)">
          <option :selected="test.value===selectedValue" v-bind:value="test.value" v-for="test in tests">{{
              test.label
            }}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <schema-form :form="form" :schema="schema" @validationResult="onValidationResult" v-model="value"></schema-form>
        <div class="body-content">
          <h4>Model</h4>
          <pre>{{ value }}</pre>
          <hr/>
          <h4>Validation result</h4>
          <pre> {{ validationResult.errors }}</pre>
        </div>
      </div>

      <div class="col-md-6">
        <h4>Form</h4>
        <pre>{{ form }}</pre>
        <hr/>
        <h4>Schema</h4>
        <pre>{{ schema }}</pre>
      </div>
    </div>

    <div class="row">


    </div>
  </div>
</template>

<script>
import {BModal, BButton, VBModal} from 'bootstrap-vue'
import Vue from 'vue'
import {schemaFormPlugin} from "vue-json-schema-form/src/plugins";

export default {
  name: 'home',
  components: {BModal, BButton},
  directives: {'b-modal': VBModal},
  data() {
    return {
      tests: [
        {label: "File Upload", value: "data/fileUpload.json"},
        {label: "Simple", value: "data/simple.json"}, {
          label: "Array",
          value: "data/array.json"
        }, {label: 'simpleMarkdown', value: 'data/simplemarkdown.json'}, {label: 'ace', value: 'data/simpleace.json'},
        {label: 'simpleLinks', value: 'data/simplelinks.json'}, {
          label: 'section',
          value: 'data/section.json'
        }, {label: 'tabarray', value: 'data/tabarray.json'}, {
          label: 'tabkitchensink',
          value: 'data/tabskitchensink.json'
        }, {label: 'arrayselect', value: 'data/arrayselect.json'}, {label: 'condition', value: 'data/condition.json'},
        {label: 'conditionalarray - ne radi', value: 'data/conditionalarray.json'},
        {label: 'date', value: 'data/date.json'}, {
          label: 'datetime',
          value: 'data/datetime.json'
        }, {label: 'geopoint', value: 'data/geopoint.json'},
        {label: 'help', value: 'data/help.json'}, {
          label: 'kitchenSink',
          value: 'data/kitchenSink.json'
        }, {label: 'login', value: 'data/login.json'},
        {label: 'object', value: 'data/object.json'}, {label: 'radio', value: 'data/radio.json'},
        {label: 'readonly', value: 'data/readonly.json'}, {label: 'simplearray', value: 'data/simplearray.json'},
        {label: 'tabs', value: 'data/tabs.json'},
        {label: 'types', value: 'data/types.json'},
        {label: 'select', value: 'data/select.json'},
        {label: 'selectFilter', value: 'data/selectfilter.json'},
        {label: 'selectGroup', value: 'data/selectGroup.json'},
        {label: 'template', value: 'data/template.json'},
        {label: 'templateList', value: 'data/templateList.json'},
        {label: 'queryBuilder', value: 'data/queryBuilder.json'},
        {label: 'tableEditor', value: 'data/tableEditor.json'},
        {label: 'propertyObject', value: 'data/propertyObject.json'},
        {label: 'checkboxes', value: 'data/checkboxes.json'},
        {label: 'diagramEditor', value: 'data/diagramEditor.json'},
        {label: 'schemaFormEditor', value: 'data/schemaFormEditor.json'}
      ],
      schema: {},
      form: [],
      value: {},
      selectedValue: 'data/checkboxes.json',
      validationResult: {}
    }
  },
  beforeCreate() {
    Vue.use(schemaFormPlugin);
  },
  mounted() {
    this.loadSelectData();
  },
  methods: {
    link: function (provider) {
      this.$auth.link(provider).then(function (arg1, arg2) {
        // Execute application logic after successful social authentication
        console.log('Link provider: ' + provider, arg1, arg2);
      })
    },
    authenticate: function (provider) {
      this.$auth.authenticate(provider).then(function (arg1, arg2) {
        // Execute application logic after successful social authentication
        console.log('Authenticated provider: ' + provider, arg1, arg2);
      })
    },
    onValidationResult: function (value) {
      this.validationResult = value;
    },
    resolveMapper: function () {
      return this.$defaultMapper;
    },
    onSelectChanged: function (event) {
      this.selectedValue = event.target.value;
      this.loadSelectData();
    },
    loadSelectData: function () {
      if (this.selectedValue) {
        Vue.axios.get(this.selectedValue).then((response) => {
          //console.log(response.data)
          this.schema = response.data.schema;
          this.form = response.data.form;
          this.value = response.data.data || {};
          this.validationResult = {};
        })
      } else {
        this.schema = {};
        this.form = [];
        this.value = {};
        this.validationResult = {};
      }
    }
  }
}
</script>

<style scoped>
.header {
  text-align: center;
}

</style>
