var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "btn-group btn-group-sm pull-right" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.draftName,
            expression: "draftName",
          },
        ],
        staticClass: "form-control form-control-sm",
        attrs: { placeholder: "Enter Draft name", type: "text" },
        domProps: { value: _vm.draftName },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.draftName = $event.target.value
          },
        },
      }),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { title: "Save draft" },
          on: {
            click: function ($event) {
              return _vm.saveDraft()
            },
          },
        },
        [_c("i", { staticClass: "fas fa-save" })]
      ),
      _c(
        "select",
        {
          staticClass: "form-control form-control-sm",
          attrs: { "aria-placeholder": "Select Draft" },
          on: {
            change: function ($event) {
              return _vm.onDraftSelected($event)
            },
          },
        },
        [
          _c("option", [_vm._v("Select Draft")]),
          _vm._l(_vm.localDraftNames, function (draft) {
            return _c(
              "option",
              {
                domProps: {
                  selected: draft.name === _vm.selectedDraftName,
                  value: draft.name,
                },
              },
              [_vm._v("\n        " + _vm._s(draft.name) + "\n      ")]
            )
          }),
        ],
        2
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-warning",
          attrs: { title: "Load Previous" },
          on: {
            click: function ($event) {
              return _vm.loadPreviousDraft()
            },
          },
        },
        [_c("i", { staticClass: "fas fa-upload" })]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-danger",
          attrs: { title: "Remove draft" },
          on: {
            click: function ($event) {
              return _vm.removeDraft()
            },
          },
        },
        [_c("i", { staticClass: "fas fa-times" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }