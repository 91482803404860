import SfChangePosition from './utilComponents/SfChangePosition'
import SfComponentToolbar from "./utilComponents/SfComponentToolbar";

const schemaFormHelperPlugins = {

  install(Vue, options) {
    Vue.component(SfComponentToolbar.name, SfComponentToolbar);
    Vue.use(SfChangePosition)
  }

}

export default schemaFormHelperPlugins
