var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget-debug card p-4" }, [
    _c("div", { staticClass: "widget-debug-header" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm",
          on: {
            click: function ($event) {
              return _vm.toggleShow()
            },
          },
        },
        [_vm._v("Debug")]
      ),
    ]),
    _vm.show
      ? _c("div", { staticClass: "widget-debug-body" }, [
          _c(
            "ul",
            { staticClass: "list-unstyled" },
            _vm._l(_vm.model, function (item) {
              return _c("li", [
                _vm._v("\n        " + _vm._s(item) + "\n      "),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }