<template>
  <div class="schema-form-section-container">
    <div v-if="showHideEnabled" class="fieldset-hide">
      <a class="btn btn-sm btn-primary" href @click.prevent="toogleShowHide()">{{ showHideTitle }}</a>
    </div>
    <fieldset v-if="fieldForm.sectionType==='fieldset' && showHide" :disabled="fieldForm.readonly"
              :class="['schema-form-section-fieldset', fieldForm.fieldSetClass]" :style="fieldForm.style">
      <legend>{{ fieldForm.title || fieldSchema.title }}</legend>
      <draggable tag="div" class="schema-form-section-wrapper"
                 :class="[isDroppable?'droppable-container':'', fieldForm.sectionContainerClass]"
                 :handle="'.handle'"
                 :group="{ name: 'components', pull: true, put: true }"
                 :disabled="!isDroppable" @add="onAddActions" @end="onEndActions" @change="onChangeActions"
                 :list="innerList">
        <div :class="['schema-form-section-child', childClass(field) ]" v-for="(field, index) in fieldSchemaItems"
             :key="resolveStringifiedKey(field, index)" :style="field.childStyle || fieldForm.sectionChildStyle">
          <sf-component-toolbar v-if="isDroppable" :guid="field.guid" :cloned-dropped-components="cleanList"
                                @schemaChanged="onToolbarSchemaChanged" @isminimized="onIsMinimized(field, $event)"
                                :selected-item="selectedItem" :field="field" :is-droppable="isDroppable">
          </sf-component-toolbar>

          <builder-inner-component
            :component-data="{ field: field }"
            :field-form="field" :key="resolveStringifiedKey(field, index)"
            :field-mapper="fieldMapper"
            :field-schema="field" :full-model="fullModel" :is-droppable="isDroppable"
            :parent-index="parentIndex"
            :parent-form="resolveParent(field)"
            :value="value"
            :schema-form-service="schemaFormService"
            @fullModelChanged="onFullModelChanged" @input="onInput($event, field)"
            @internalSchemaChanged="onInternalChildSchemaChanged"
            v-show="!(itemState.minimizedItems[field.guid])"
          />

        </div>
      </draggable>
    </fieldset>
    <div v-if="(fieldForm.sectionType==='section' || !fieldForm.sectionType) && showHide"
         :class="['schema-form-section-section', fieldForm.sectionClass]" :style="fieldForm.style">
      <draggable tag="div" class="schema-form-section-wrapper"
                 :class="[isDroppable?'droppable-container':'', fieldForm.sectionContainerClass]"
                 :handle="'.handle'"
                 :group="{ name: 'components', pull: true, put: true }"
                 :disabled="!isDroppable" @add="onAddActions" @end="onEndActions" @change="onChangeActions"
                 :list="innerList">
        <div :class="['schema-form-section-child', childClass(field)]" v-for="(field, index) in fieldSchemaItems"
             :key="resolveStringifiedKey(field, index)" :style="field.childStyle || fieldForm.sectionChildStyle">
          <sf-component-toolbar v-if="isDroppable" :guid="field.guid" :cloned-dropped-components="cleanList"
                                @schemaChanged="onToolbarSchemaChanged" @isminimized="onIsMinimized(field, $event)"
                                :selected-item="selectedItem" :field="field" :is-droppable="isDroppable">
          </sf-component-toolbar>

          <builder-inner-component
            :component-data="{ field: field }"
            :field-form="field" :key="resolveKey(field, index)"
            :field-mapper="fieldMapper"
            :field-schema="field" :full-model="fullModel" :is-droppable="isDroppable"
            :parent-index="parentIndex"
            :parent-form="resolveParent(field)"
            :value="value"
            :schema-form-service="schemaFormService"
            @fullModelChanged="onFullModelChanged" @input="onInput($event, field)"
            @internalSchemaChanged="onInternalChildSchemaChanged"
            v-show="!(itemState.minimizedItems[field.guid])"
          />

        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import utils from '../../utils/utils'
import componentMixin from './componentMixins'
import buildMixins from './buildMixins'
import droppableMixins from './droppableMixins'
import findIndex from 'lodash/findIndex'
import merge from 'lodash/merge'
import schemaFormElements from './schemaFormElements'
import {Portal, PortalTarget} from 'portal-vue'
import BuilderInnerComponent from "./BuilderInnerComponent";
import showHideMixin from './showHideMixin'
import minimizeMixin from './minimizeMixin'


export default {
  name: "SfSection",
  mixins: [buildMixins, componentMixin, droppableMixins, showHideMixin, minimizeMixin],
  components: {
    Portal, PortalTarget, BuilderInnerComponent
  },
  data() {
    return {
      sectionValue: {},
      selectedItem: {},
      clonedDroppedComponents: [],
      innerList: [],
      cleanList: [],
      shouldUpdate: true,
      count: 0
    }
  },
  computed: {
    fieldSchemaItems: function () {
      const that = this;
      // console.log('FieldSchema items in section', this.fieldForm, this.fieldSchema);
      return this.fieldSchema.items.filter(function (item) {
        return that.resolveCondition(item, that.parentForm);
      });
    },

  },
  methods: {
    resolveParent: function (field) {
      //field.parentForm = this.parentForm;
      return this.parentForm;
    },
    childClass: function (field) {
      return field.childClass || this.fieldForm.sectionChildClass;
    },
    resolveKey: function (field) {
      return field.key ? field.key.toString() : '';
    },
    selectedItemModelApplied: function (item) {
      /*if (this.fieldForm.guid===item.guid) {
        console.log('Received in Section leaf item', item, this.fieldForm);
        for(const prop in this.fieldForm){
          delete this.fieldForm[prop];
        }
        vueUtils.vueMerge(this.fieldForm, item);
        //this.$set(this, 'fieldForm', item);

        console.log('Received in Section item - After Merge', item, this.fieldForm);

        this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm});
      }*/
    },
    onPortalTargetChange: function (hasContent) {
      if (hasContent) {
        this.count += 1;
      }
    },
    resolvePortalTo: function () {
      if (this.fieldForm.sectionType === 'fieldset') {
        return 'fieldsetTarget-' + this.fieldForm.guid;
      }
      return 'sectionTarget-' + this.fieldForm.guid;
    },
    onInternalChildSchemaChanged: function (schemaFormEvt) {
      const schemaFormComponent = schemaFormEvt.schemaFormComponent;

      console.log('Received child schema changed - first ' + this.fieldForm.guid, utils.clone(schemaFormEvt), utils.clone(this.fieldForm), utils.clone(this.cleanList));

      let computedMergedForm = this.fieldForm.items;
      const idx = findIndex(computedMergedForm, function (item) {
        const found = item.guid === schemaFormComponent.guid
        return found;
      });
      if (idx > -1) {

        this.fieldForm.items[idx] = schemaFormComponent;
        this.cleanList[idx] = schemaFormComponent;
        this.innerList[idx] = schemaFormComponent;

        console.log('Received child schema changed - start ' + this.fieldForm.guid, utils.clone(schemaFormEvt), utils.clone(this.fieldForm), this.cleanList);

        this.fieldForm.items = this.recalculateSchemaInForm(this.fieldForm.items);
        this.cleanList = utils.clone(this.fieldForm.items);
        this.innerList = utils.clone(this.fieldForm.items);

//          this.updateActionItems();

        let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(this.cleanList);////fieldForm.items);
        let builderFormArray = schemaAndForm.form;
        let builderSchema = schemaAndForm.schema;

        this.fieldForm.schema = this.fieldForm.schema || {
          type: 'object'
        };

        this.fieldForm.schema.properties = builderSchema.properties;

        console.log('Received child schema changed - end ' + this.fieldForm.guid, utils.clone(schemaFormEvt), utils.clone(this.fieldForm), this.cleanList, schemaAndForm);

        this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm});
      } else {
        console.warn("There is no item in Section list: ", schemaFormEvt);
      }
    },
    onToolbarSchemaChanged: function (schema) {
      console.log('On Toolbar change', schema, this.innerList, this.cleanList);
      //this.innerList = this.cleanList;
      const that = this;
      const removedIndexes = [];
      for (let i = 0; i < this.innerList.length; i++) {
        let item = this.innerList[i];
        if (findIndex(this.cleanList, function (cItem) {
          return cItem.guid === item.guid;
        }) === -1) {
          removedIndexes.push(i);
        }
      }
      removedIndexes.forEach(function (index) {
        that.innerList.splice(index, 1);
      });

      this.updateActionItems();
    },
    updateSchemaPropertiesRecursively: function (items) {
      let schemaProperties = {};
      let tmpSchemaProperties = null;
      let hasKey = false;
      const that = this;
      items.forEach(function (item) {
        if (item.schema && item.schemaFieldKey) {
          schemaProperties[item.schemaFieldKey] = item.schema;
        } else if (item.items && item.items.length > 0) {
          tmpSchemaProperties = that.updateSchemaPropertiesRecursively(item.items);
          for (const prop in tmpSchemaProperties) {
            hasKey = true;
          }
        } else if (item.tabs && item.tabs.length > 0) {
          tmpSchemaProperties = that.updateSchemaPropertiesRecursively(item.tabs);
          for (const prop in tmpSchemaProperties) {
            hasKey = true;
          }
        }
      });

      if (hasKey) {
        schemaProperties = merge(schemaProperties, tmpSchemaProperties);
      }

      //   console.log('schemaProperties', utils.clone(schemaProperties), utils.clone(items));
      return schemaProperties;
    },
    updateActionItems: function () {
      this.shouldUpdate = false;
      //  console.log('Update action items', utils.clone(this.cleanList), utils.clone(this.innerList));
      const that = this;
      // const preparedList = [];
      that.fieldForm.items = Object.assign([]);
      this.cleanList.forEach(function (item) {
        const element = utils.clone(schemaFormElements.elements[item.name]);
        //  console.log('updateActionItem from cleanList - before', utils.clone(item));
        item = merge({}, element.form, item);
        //item.htmlClass = item.htmlClass || 'col';
        //   console.log('updateActionItem from cleanList - after', utils.clone(item));
        that.fieldForm.items.push(item);
      });
      this.shouldUpdate = true;

      this.fieldForm.schema = this.fieldForm.schema || {
        type: 'object'
      };

      const schemaProperties = that.updateSchemaPropertiesRecursively(this.fieldForm.items);
      //  console.log('schemaProperties', schemaProperties);
      this.fieldForm.schema.properties = schemaProperties;

      delete this.fieldForm.key;
      delete this.fieldForm.schemaKey;
      delete this.fieldForm.schemaFieldKey;

      this.$emit('internalSchemaChanged', {schemaFormComponent: that.fieldForm});
    },
    onAddActions: function (event) {
      console.log('Actions onAdd', event);

      const addedObjectIndex = findIndex(this.innerList, function (item) {
        return item.guid === event.item._underlying_vm_.guid; //TODO - is there better option???
      });

      const component = this.getMergedSchemaObject(addedObjectIndex, this.innerList); //this.innerList[addedObjectIndex];

      this.cleanList.splice(addedObjectIndex, 0, component);
      this.updateActionItems();
    },
    onEndActions: function (event) {
      console.log('OnEndActions in Section', event, this.fieldForm);
    },
    onChangeActions: function (event) {

      if (event.removed) {
        //this.innerList.splice(event.removed.oldIndex, 1);
        const elem = event.removed.element;
        const index = findIndex(this.innerList, function (item) {
          return item.guid === elem.guid;
        });
        if (index > -1) {
          this.innerList.splice(index, 1);
        }
      }
      if (event.removed || event.moved) {
        this.cleanList = [];
        const that = this;
        for (let i = 0; i < this.innerList.length; i++) {
          const item = this.getMergedSchemaObject(i, this.innerList);
          this.cleanList.push(item);
        }
        console.log('After change or remove', event, this.cleanList, this.innerList);
        this.updateActionItems();
      }
    },
    onCreated: function () {
      if (this.fieldForm && this.fieldForm.items && this.fieldForm.items.length > 0) {
        const that = this;
        this.fieldForm.items.forEach(function (item, index) {
          that.cleanList[index] = item;
          that.innerList[index] = item;
        });
      }
    }
  },
  created() {
    const that = this;
    if (this.isDroppable) {// only if we are in Edit mode
      this.$bus.$on('selectedItem', that.onSelectedItem);
      //console.log('Section created', this.cleanList, this.innerList, this.fieldForm);
      this.onCreated();

      this.$bus.$on('selectedItemModelApplied', that.selectedItemModelApplied);

      delete this.fieldForm.key;
      delete this.fieldForm.schemaKey;
      delete this.fieldForm.schemaFieldKey;
    }
  },
  beforeDestroy() {
    if (this.isDroppable) {
      const that = this;
      this.$bus.$off('selectedItem', that.onSelectedItem);
      this.$bus.$off('selectedItemModelApplied', that.selectedItemModelApplied);
    }
  }
}
</script>

<style scoped>

.schema-form-section-section, .schema-form-section-fieldset {
  width: 100%;
  height: 100%;
}

.schema-form-section-container {
  height: 100%;
}

.schema-form-section-wrapper {
  /*display: flex;*/
  flex-direction: inherit;
  /*margin-bottom: 20px;*/
}

fieldset .schema-form-section-wrapper {
  flex-direction: row;
}

.droppable-container .row {
  margin-right: 0px;
  margin-left: 0px;
}

</style>
