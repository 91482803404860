var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _c(
          "div",
          { class: [_vm.isValid ? "" : "is-invalid"] },
          _vm._l(_vm.internalTitleMap, function (opt) {
            return _c("div", { staticClass: "radio" }, [
              _c("label", [
                _c("input", {
                  key: _vm.internalValue,
                  class: _vm.fieldForm.fieldHtmlClass,
                  attrs: {
                    type: "radio",
                    required: _vm.fieldForm.required,
                    disabled: _vm.fieldForm.readonly || _vm.fieldForm.disabled,
                  },
                  domProps: {
                    value: opt[_vm.valueKey],
                    checked: opt[_vm.valueKey] === _vm.internalValue,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChangeEvent($event)
                    },
                    click: function ($event) {
                      return _vm.onClick($event)
                    },
                  },
                }),
                _c("span", {
                  domProps: { innerHTML: _vm._s(opt[_vm.labelKey]) },
                }),
              ]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }