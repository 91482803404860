
import VueDfAppDropdown from './components/UIComponents/VueDfAppDropdown'
import VueDfAppView from './VueDfAppView'
import VueDfAppEdit from './VueDfAppEdit'

import DashboardSingleLayout from './layouts/DashboardSingleLayout'
import VueDynamicRoute from './layouts/VueDynamicRoute'

import VueDfSidebarPlugin from './components/UIComponents/SidebarPlugin'

let widget = {
  type: 'VueDfApp',
  title: 'Application',
  description: 'Configure Application widget',
  category: 'Application',
  editComponent: 'VueDfAppEdit',
  viewComponent: 'VueDfAppView',
  hideTitle: true,
  editableSettings:{
    editable: true
  },
  frameless: true,
  data: {},
  config: {
    data: {}
  }
}

const vueDfPlugin = {
  initRouter: function (router) {
    return router.beforeEach((to, from, next) => {
    //  console.log('Before each in App', to, from, next);
      if (to.matched.some(record => record.meta.requiresAuth)) {
        const app = router.app;
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!app.$auth.isAuthenticated()) {
          next({
            path: '/login',
            query: {redirect: to.fullPath}
          })
        } else {
          next()
        }
      } else {
        next() // make sure to always call next()!
      }
    })
  },
  install(Vue, options) {

   // console.log('Installing VueDfApp')

    Vue.component(VueDfAppView.name, VueDfAppView)
    Vue.component(VueDfAppEdit.name, VueDfAppEdit)
    Vue.component(VueDfAppDropdown.name, VueDfAppDropdown)

    Vue.component(VueDynamicRoute.name, VueDynamicRoute);
    Vue.component(DashboardSingleLayout.name, DashboardSingleLayout);

    Vue.widgetStore.widget(widget);

    Vue.use(VueDfSidebarPlugin);
  }
}

export default vueDfPlugin
