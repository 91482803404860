<template>
  <div class="chart-type-editor">
    <schema-form v-if="schema" :schema="schema" :form="form" v-model="data"></schema-form>
<!--
    <pre>
      {{ value }}
    </pre>-->

  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  name: "ChartTypeEditor",
  props: {
    value: Object
  },
  data() {
    return {
      schema: {},
      form: [],
      data: {}
    }
  },
  mounted() {

  },
  computed: {
    config() {
      return this.value && this.value.context ? this.value.context.config : null;
    },
    configData(){
      return this.value && this.value.data?this.value.data : null;
    }
  },
  watch:{
    data:{
      handler(newVal, oldVal){
        if (!isEqual(newVal, this.value.context.data)){
          this.value.context.data = Object.assign({}, newVal);
        }
      },
      deep: true,
      immediate: true
    },
    'value.context.config': {
      handler(newVal, oldVal){
        this.calculateSchemaForm();
      },
      deep: true,
      immediate: true
    },
    'value.context.data':{
      handler(newVal, oldVal){
        if (newVal && !isEqual(newVal, this.data)){
          this.data = newVal
        }
        console.log('Value changed', newVal);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    resolveForm(schemaConfig){
      if (schemaConfig){
        const form = [];
        for(let prop in schemaConfig){
          const formObj = {
            key: prop,
            condensed: true,
            labelLeft: true
          }
          form.push(formObj);
        }
        return form;
      }
      return [];
    },
    resolveSchema(schemaConfig){
      const schemaObj = {
        type: 'object',
        properties: schemaConfig
      }
      return schemaObj;
    },
    calculateSchemaForm() {
      console.log('calculating schemaform', this.value, this.config);
      if (this.config) {
          this.form = Object.assign([], this.resolveForm(this.config));
          this.schema = Object.assign({}, this.resolveSchema(this.config));
      }
    }
  }
}
</script>

<style scoped>

</style>
