<template>
  <vue-df-dashboard-container :crud-service-name="crudServiceName"
                              :dashboard-name="dashboardName"
                              :dashboard-service-instance="dashboardServiceInstance"
                              :dashboardsContainerClass="'dashboard-tabs'" :dashboardsContentClass="'tab-content'"
                              :default-mapper="defaultMapper" :disable-add="disableAdd"
                              :disable-edit="disableEdit"
                              :guid="guid"
                              :include-widgets="includeWidgets"
                              :key="dashboardName"
                              :input-context="inputContext"
                              :model="dashboardsModel"
                              :navigationPosition="navigationSettings.position"
                              :parent-dashid="parentDashid"
                              :parent-widget-id="parentWidgetId"
                              :permissions="permissions"
                              :selected-dashboard-index="selectedDashboardIndex"
                              :structures="structures" :use-provided-model="useProvidedModel"
                              ref="dashboardContainer">
    <div class="dashboard-tabs-navigation nav-tabs-navigation" slot="navigation"
         v-if="dashboardEditMode">
      <div class="dashboard-tabs-wrapper nav-tabs-wrapper">
        <ul class="list-unstyled">
          <li>
            <vue-df-dropdown class="nav-item btn-rotate dropdown last1" direction="none"
                             icon="fas fa-cog"
                             position="left"
                             tag="li">
              <div class="nav-link">
                <a :aria-expanded="isOpen"
                   aria-haspopup="true"
                   class="dropdown-toggle"
                   data-toggle="dropdown"
                   slot="title"
                   slot-scope="{isOpen}">
                  <i class="nc-icon nc-bell-55"></i>
                  <p>
                    <span class="d-lg-none d-md-block">Export Dashboard</span>
                  </p>
                </a>
                <a @click.prevent="addDashboard()" class="dropdown-item" href="">Add Dashboard</a>
                <a class="" href="#">
                  <hr/>
                </a>
                <a @click.prevent="importDashboard()" class="dropdown-item" href="">Import Dashboard</a>
                <a class="" href="#">
                  <hr/>
                </a>
                <a @click.prevent="shareDashboard()" class="dropdown-item" href="">Share Dashboard</a>

              </div>
            </vue-df-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </vue-df-dashboard-container>
</template>

<script>

import VueDfDashboardContainer from '../VueDfDashboardContainer'
import dashboardContainerMixin from '../dashboardContainerMixin'
import dashboardSharedMixin from '../dashboardSharedMixin'
import VueDfDropdown from "../../components/VueDfDropdown";

export default {
  name: "VueDfDashboardSingle",
  props: {
    navigationSettings: {
      type: Object,
      default: function () {
        return {
          position: 'top',
          navClass: '',
          areaOrientation: '',
          navTypeClass: 'nav-tabs'
        }
      }
    },
    dashboardEditMode: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  mixins: [dashboardContainerMixin, dashboardSharedMixin],
  components: {
    VueDfDashboardContainer, VueDfDropdown
  }
}
</script>

<style scoped>

</style>
