const minimizeMixin = {
  data(){
    return {
      itemState: {
        minimizedItems: {}
      }
    }
  },
  methods:{
    onIsMinimized: function (field, isMinimized) {
      this.$set(this.itemState.minimizedItems, isMinimized.guid, isMinimized.status);
      console.log('onIsMinimized IsMinimized', field, isMinimized, this.itemState);
    },
  }
}

export default  minimizeMixin;
