<template>
  <div class="widget-app-view"  v-if="dashboardEditMode || widgetSettings.widgetVisible">
    <vue-df-app-dashboard :security-config="model.config.data.securitySettings" :app-config="appConfig" :config="model.config.data"
                          :dashboard-edit-mode="dashboardEditMode" :input-context="dataModel" :parent-dash-id="dashid"
                          :parent-dashboard-name="dashboardName" v-if="dashboardName">

    </vue-df-app-dashboard>
    <p class="text-center" v-if="!appDashboardName">Define Application name in settings before continue.</p>
  </div>
</template>

<script>
  import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'
  const VueDfAppDashboard = ()=>import('./components/VueDfAppDashboard')

  export default {
    name: "VueDfAppView",
    props: {
      model: {
        type: Object
      },
      dashboardName: {
        type: String
      },
      dashid: {
        type: String
      }
    },
    mixins: [widgetComponentViewMixins],
    components: {
      VueDfAppDashboard
    },
    created() {
      if (this.model.config.data.applicationContext){
        /*try{
          const ctx = JSON.parse(this.model.config.data.applicationContext);
          if (!this.dataModel){
            this.dataModel = {}
          }
        }
        console.log('VueDfApp', this.model.config.data);*/
        this.mergeInputContext(this.model.config.data.applicationContext);
        //console.log('VueDfApp dataModel', this.dataModel);
      }
    },
    data() {
      return {}
    },
    computed: {
      appConfig: function () {
        const appConfig = {
          disableEdit: this.disableEdit,
          disableAdd: this.disableAdd,
          appDashboardName: this.model.config.data.applicationName || null,
          isComponentMode: this.isComponentMode()
        };
        return appConfig;
      },

      disableEdit: function () {
        return this.isComponentMode() ? false : true;
      },
      disableAdd: function () {
        return this.isComponentMode() ? false : true;
      },
      appDashboardName: function () {
        return this.model.config.data.applicationName || null;
      }
    },
    methods: {
      isComponentMode: function () {
        return this.options.isComponentMode === null || this.options.isComponentMode === undefined ? true : this.options.isComponentMode;
      },
      createCss: function () {

      }
    }
  }
</script>

<style scoped>

  .widget-app-view ::v-deep .widget-header.view {
    display: none;
  }

</style>
