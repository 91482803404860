<template>
<div>
  <pre>
      {{ value }}
    </pre>

</div>
</template>

<script>
export default {
  name: "BuilderHeaderEditor",
  props:{
    value: Object
  }
}
</script>

<style scoped>

</style>
