<template>
  <div :class="resolveTheme" class="dashboard-container" v-bind="dataId">

    <div class="toolbar-container" v-bind="dataId">
      <div v-if="!dashboardCopy.hideTitle || isEditMode" class="dashboard-title" v-bind="dataId">
        <h3>
          <span v-if="isEditMode">{{dashid}}  </span>
          <span :key="count">{{dashboardCopy.title}}</span>
        </h3>
      </div>
      <div :class="['editable', {'editing':isEditMode}]" class="dashboard-toolbar" v-if="options.editable">
        <span class="icon-container">
            <a :title="translate('ADF_DASHBOARD_TITLE_TOOLTIP_ADD') || 'Add new widget'"
               @click.prevent="addWidgetDialog()" href=""
               v-if="isEditMode">
              <i class="fas fa-plus"></i>
            </a>
            <a :title="translate('ADF_COMMON_EDIT_DASHBOARD') || 'Edit Dashboard'"
               @click.prevent="editDashboardDialog()" href=""
               v-if="isEditMode">
              <i class="fas fa-cog"></i>
            </a>
          <a :title="translate('ADF_COMMON_EDIT_CUSTOM_WIDGETS') || 'Edit Custom Widgets'"
             @click.prevent="editCustomWidgetsDialog()" href=""
             v-if="isEditMode">
              <i class="fas fa-wrench"></i>
            </a>
            <a
              :title="isEditMode ? translate('ADF_DASHBOARD_TITLE_TOOLTIP_SAVE') || 'Save Changes' : translate('ADF_DASHBOARD_TITLE_TOOLTIP_EDIT_MODE') || 'Edit'"
              @click.prevent="toggleEditMode()"
              href=""
              v-if="options.editable">
              <i :class="{'far fa-edit' : !isEditMode, 'far fa-save' : isEditMode}"></i>
            </a>
            <a :title="translate('ADF_DASHBOARD_TITLE_TOOLTIP_EXPORT') || 'Export Dashboard'" @click.prevent="exportDashboard()"
               href=""
               v-if="isEditMode">
              <i class="fas fa-file-export"></i>
            </a>
            <a :title="translate('ADF_DASHBOARD_TITLE_TOOLTIP_IMPORT') || 'Import Dashboard'" @click.prevent="importDashboard()"
               href=""
               v-if="isEditMode">
              <i class="fas fa-file-import"></i>
            </a>
          <a :title="translate('ADF_DASHBOARD_TITLE_TOOLTIP_IMPORT_WIDGET') || 'Import Widget'" @click.prevent="importWidget()"
             href=""
             v-if="isEditMode">
              <i class="fas fa-upload"></i>
            </a>
            <a :title="translate('ADF_DASHBOARD_TITLE_TOOLTIP_UNDO') || 'Undo Changes'"
               @click.prevent="cancelEditMode()" href=""
               v-if="isEditMode">
              <i class="adf-flip fas fa-redo"></i>
            </a>
          </span>

      </div>
    </div>
    <b-modal :ref="'InsertWidgets'" :scrollable="true"
             :title="translate('ADF_DASHBOARD_TITLE_ADD_WIDGETS') || 'Add Widgets'" @show="loadWidgets()"
             hide-header-close lazy no-close-on-backdrop no-close-on-esc
             ok-only size="xl">
      <vue-df-dashboard-add-widget :dashboard-name="dashboardName"
                                   :dashboard-service-instance="dashboardService"
                                   :dashid="dashid"
                                   :default-mapper="defaultMapper"
                                   :edit-mode="isEditMode"
                                   :model="dashboardCopy"
                                   :permissions="permissions" :widgets="widgets"
                                   v-on:addwidget="addWidget($event)"></vue-df-dashboard-add-widget>
    </b-modal>

    <b-modal :ref="'EditDashboardModal'" :scrollable="true"
             :title="translate('ADF_DASHBOARD_TITLE_EDIT_DASHBOARD') || 'Edit Dashboard'"
             hide-header-close lazy no-close-on-backdrop no-close-on-esc
             ok-only size="xl">
      <vue-df-dashboard-edit :model="dashboardCopy" :structures="structuresCopy"
                             @dashboardcontextupdate="onDashboardContextUpdated($event)"
                             @dashboardcssupdated="onDashboardCssUpdated($event)"
                             v-on:dashboardupdated="onDashboardUpdated($event)"
                             v-on:titleupdate="onDashboardTitleUpdate($event)"
                             v-on:hidetitleupdate="onDashboardHideTitleUpdate($event)"
      ></vue-df-dashboard-edit>
    </b-modal>

    <b-modal :ref="'EditCustomWidgetsModal'" :scrollable="true"
             :title="translate('ADF_DASHBOARD_TITLE_EDIT_CUSTOM_WIDGETS') || 'Edit Custom Widgets'"
             hide-header-close lazy no-close-on-backdrop no-close-on-esc
             ok-only size="xl">
      <vue-df-dashboard-edit-custom-widget :crud-service-name="crudServiceName" :dashboard-name="dashboardName" :widgets="widgets"></vue-df-dashboard-edit-custom-widget>
    </b-modal>

    <b-modal :ref="'uploadModal'" :title="'Upload Dashboard Definition'" @ok="onUploadInput" lazy>
      <b-form-file class="mb-2" ref="file-upload" v-model="uploadedFile"></b-form-file>
      <b-form-checkbox v-model="shouldMergeNewUpload">Should merge in</b-form-checkbox>
    </b-modal>

    <b-modal :ref="'uploadWidgetModal'" :title="'Upload Widget Definition'" @ok="onWidgetUploadInput" lazy>
      <b-form-file class="mb-2" ref="file-upload" v-model="uploadedFile"></b-form-file>
    </b-modal>

    <div :class="{'edit' : isEditMode}" :key="count" class="dashboard" v-bind="dataId">
      <vue-df-dashboard-row
        :continuous-edit-mode="continuousEditMode"
        :crud-service-name="crudServiceName"
        :dashboard-name="dashboardName"
        :dashboard-service-instance="dashboardService"
        :dashid="dashid"
        :default-mapper="defaultMapper"
        :edit-mode="isEditMode"
        :key="index"
        :input-context="inputContext"
        :model="dashboardCopy"
        :options="options"
        :permissions="permissions"
        :row="row"
        :parent-widget-id="parentWidgetId"
        :parent-dashid="parentDashid"
        v-for="(row, index) in dashboardCopy.rows">
      </vue-df-dashboard-row>
    </div>
  </div>
</template>

<script>

  import defaultMixins from './defaultMixin'
  import dashboardMixin from './dashboardMixin'
  import widgetMixin from "./widgetMixin";
  import permissionMixin from "./permissionsMixin";
  import {BModal, BFormFile, BFormCheckbox, BFormInput} from 'bootstrap-vue'

  export default {
    name: "vue-df-dashboard",
    mixins: [defaultMixins, dashboardMixin, widgetMixin, permissionMixin],
    props: ['useProvidedModel'],
    components: {
      BModal, BFormFile, BFormCheckbox, BFormInput
    },

    computed: {
      resolveTheme: function () {
        return this.model && this.model.dashboardThemeModel && this.model.dashboardThemeModel.dashboardTheme ? this.model.dashboardThemeModel.dashboardTheme : '';
      }
    }
  }
</script>

<style scoped>

  .dashboard-title a {
    padding: 5px;
  }

  .dashboard-toolbar {
    padding: 2px;
    position: absolute;
    top: 5px;
    right: 35px;
    background-color: white;
    border-radius: 6px;
    z-index: 1;
  }

  .dashboard-toolbar:after {
    content: '';
    position: absolute;
    z-index: -1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    width: 100%;
    left: 0;
    height: 100%;
    bottom: 0;
  }

  .dashboard-toolbar .icon-container {
    font-size: 16px;
  }

  .dashboard-toolbar a {
    padding: 5px;
  }


</style>
