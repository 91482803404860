import ObjectPath from "../../utils/ObjectPath";
import jsnotevil from "jsnotevil";

class SchemaFormService  {
  constructor(schemaForm) {
    this.schemaForm = schemaForm;
    this.internalEventName = "__internalEvent";
  }
  getSchemaForm(){
    return this.schemaForm;
  }

  publishEvent(key, eventName, payload){
    if (this.schemaForm.formBus){
      const context = {
        targetKey: key, //ObjectPath.parse(key),
        eventName: eventName,
        payload: payload
      }
      console.log('Publishing schemaForm event', context);
      this.schemaForm.formBus.$emit(this.internalEventName, context)
    }
  }

  subscribe(key, handler){
    if (this.schemaForm.formBus){
      this.schemaForm.formBus.$on(this.internalEventName, handler);
    }
  }

  unsubscribe(key, handler){
    if (this.schemaForm.formBus){
      this.schemaForm.formBus.$off(this.internalEventName, handler);
    }
  }

}

export default SchemaFormService;
