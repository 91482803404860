<template>
  <div class="dashboard-tabs">
    <div class="dashboard-tabs-navigation nav-tabs-navigation ">
      <div class="dashboard-tabs-wrapper nav-tabs-wrapper">
        <ul class="nav nav-tabs" role="tablist">
          <li v-for="(dashboard, index) in dashboardsModel"
              class="nav-item " :class="{'active':selectedDashboardIndex===index}">
            <div class="nav-link-container">
              <a href="" @click.prevent="selectDashboard(index)" :class="{'active':selectedDashboardIndex===index}"
                 class="nav-link">{{dashboard.title}}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="tab-content">
      <template v-if="selectedDashboard">
        <vue-df-dashboard :dashid="selectedDashboard.dashid"
                          :maximizable="false"
                          :categories="selectedDashboard.categories"
                          :model="selectedDashboard"
                          :default-mapper="defaultMapper"
                          :collapsible="false"
                          :editable="false"
                          :continuous-edit-mode="false"
                          :edit-mode="false">
        </vue-df-dashboard>
      </template>
    </div>
  </div>
</template>

<script>

  import vueDfMixin from '../vueDfMixin'
  import dashboardSharedMixin from './dashboardSharedMixin'

  export default {
    name: "VueDfDashboardViewer",
    mixins:[vueDfMixin, dashboardSharedMixin],
    data(){
      return {
        false: false
      }
    },
    mounted(){
      const that = this;

      const dashboardKey = this.$route.params.id || 'vueDfViewDashboard';
      this.$services[this.crudServiceName].loadModels(dashboardKey, function (models) {
        that.$set(that, 'dashboardsModel', models?models.value:null);
        that.$nextTick(function () {
          that.$dashboardBus.$emit('DASHBOARD_LOADED::'+dashboardKey);
        });
      });
    }
  }
</script>

<style scoped>

</style>
