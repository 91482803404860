import cloneDeep from 'lodash/cloneDeep'
import jsnotevil from "jsnotevil";

import * as echarts from 'echarts/lib/echarts';
import isEmpty from 'lodash/isEmpty'
import 'echarts/extension/dataTool'
import chartBuilder from "./data/chartBuilder";
import chartDataUtils from './data/chartDataUtils'
import geoMapLoaderUtils from './geoMapLoaderUtils'

const chartingUtils = {
  isObject(val) {
    if (val === null) {
      return false;
    }
    return ((typeof val === 'function') || (typeof val === 'object'));
  },
  isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  },

  safeEval: function (content, context, chart, api, chartId) {
    if (isEmpty(content))
      return;
    try {
      const contentSafe = cloneDeep(content);
      context = !isEmpty(context)?context:null;

      geoMapLoaderUtils.addHttp(api.http);

      api = api || {};
      api.dataHelper = chartDataUtils;
      api.chartBuilder = chartBuilder;
      api.Math = Math;
      api.Date = Date;
      api.cloneDeep = cloneDeep;
      api.mapLoader = geoMapLoaderUtils;

//      console.log('Chart', chart);


      const contextSafe = {console: console, api: api,  data: context, chart: chart, Math: Math, Date: Date, echarts:echarts, chartId:chartId}; //context?cloneDeep(context):{ type: 'bar'};

      let fn = jsnotevil.Function('context', contentSafe);
      let res = fn(contextSafe);
      if (!res) {
        return {};
      }
      if (this.isFunction(res)) {
        const resChart = res(chart);
       // console.log('ResChart', resChart);
        return resChart;
      } else if (res instanceof Object) {
       // console.log('res in charting', res);
        return res;
      } else {
        throw Error('Unknown result');
      }
    } catch (error) {
      console.warn('Error in evaluation for content && context', content, context, error);
      return undefined;
    }
  }
}

export default chartingUtils
