<template>
  <div class="wrapper">
    <div class="main-panel-full">
      <dashboard-content></dashboard-content>
    </div>
  </div>
</template>

<script>
  import DashboardContent from './Content.vue'

  export default {
    name: 'DashboardSingleLayout',
    components: {
      DashboardContent
    },
    created() {

    }
  }
</script>
