import defaultStructures from './defaultStructures'

const dashboardSharedMixin = {
  props:{
    inputContext:{
      type:[Object, Array, String, Number, Boolean]
    },
    structures: {
      type: Object, default: function () {
        return defaultStructures;
      }
    },
    includeWidgets: {
      type: Array
    },
    dashboardName: {
      type: String,
      default: function () {
        return 'vueDfViewDashboard'
      }
    },
    disableAdd: {
      type: Boolean, default: function () {
        return false;
      }
    },
    disableEdit: {
      type: Boolean, default: function () {
        return false;
      }
    },
    crudServiceName: {
      type: String, default: function () {
        return 'storage'
      }
    },
    permissions: {
      type: Object
    },
    defaultMapper: {
      type: Object,
      default: function(){
        return {
          'linklist': 'VueDfLinkList',
          'weather': 'VueDfLinkList',
          'markdown': 'VueDfLinkList',
          'randommsg': 'VueDfLinkList'
        };
      }
    },
    model: {
      type: Array
    },
    useProvidedModel: {
      type: Boolean
    },
    parentWidgetId:{
      type: String
    },
    parentDashid: {
      type: String
    },
    dashboardServiceInstance:{
      type: Object
    }
  },
  methods:{
  }
}

export default dashboardSharedMixin
