import EventBusWrapper from './EventBusWrapper'


export default {
  install(Vue, config) {

    if (!Vue.__vueDfEventBusRegistered) {
      const eb = new EventBusWrapper(Vue, config);
      Vue.eventBus = eb;
      //Vue.prototype.$eventBus = eb;
      Object.defineProperty(Vue.prototype, '$eventBus', {
        get: function () {
          return eb;
        }
      })
      Vue.__vueDfEventBusRegistered = true;
    }
  }

}
