<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>

<script>
import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
import defaultStructures from "vue-dashboard-framework/src/components/vuedf/containers/defaultStructures";
import dashboardGenerator from './dashboardGenerator'
import find from 'lodash/find'

export default {
  name: "VueDfWidgetGeneratorEdit",
  mixins: [widgetComponentEditMixins],
  props: {
    dashboardName: String
  },
  data() {
    return {
      defaultStructure: '3-9',
      schemaFormCustom: {
        schemaProperties: {
          shouldGenerate: {
            type: 'boolean',
            title: 'Should generate?',
            description: 'Select true to run generate based on provided schema and settings',
            default: true
          },
          models: {
            type: 'array',
            title: 'Models',
            description: 'List of models to generate and show as Tabs or Single dashboard',
            items: {
              type: 'object',
              properties: {
                title: {
                  type: 'string',
                  title: 'Title',
                  description: 'Model title identification'
                },
                generateTypes: {
                  type: 'array',
                  items: {
                    type: 'string'
                  },
                  default: ['TABLE', 'FORM'],
                  title: 'Generate Widget types',
                  description: 'New Dashboard Widget will be generated with new generated widget types based on schema. Suitable to generate Table and Form'
                },
                regenerateAll: {
                  type: 'boolean',
                  title: 'Regenerate All',
                  description: 'Check this to regenerate everything',
                  default: false
                },
                regenerateForm: {
                  type: 'object',
                  default: {},
                  properties: {
                    all: {
                      type: 'boolean',
                      title: 'All',
                      description: 'Regenerate all',
                      default: false
                    },
                    modelSchema: {
                      type: 'boolean',
                      title: 'Regenerate model schema',
                      description: 'Regenerate model schema but not custom added'
                    },
                    modelForm: {
                      type: 'boolean',
                      title: 'Regenerate model form',
                      description: 'Regenerate model form but not custom added'
                    },
                    events: {
                      type: 'boolean',
                      title: 'Regenerate events',
                      description: 'Regenerate model events but not custom added'
                    }
                  }
                },
                regenerateTable: {
                  type: 'object',
                  default: {},
                  properties: {
                    all: {
                      type: 'boolean',
                      title: 'All',
                      description: 'Regenerate all',
                      default: false
                    },
                    modelFields: {
                      type: 'boolean',
                      title: 'Regenerate model fields',
                      description: 'Regenerate model fields but not custom added'
                    },
                    events: {
                      type: 'boolean',
                      title: 'Regenerate events',
                      description: 'Regenerate model events but not custom added'
                    }
                  }
                },
                schema: {
                  type: 'string',
                  title: 'Data Schema',
                  description: 'Data model schema to be used to generate common widgets and settings',
                  default: "{\n" +
                    "  \"type\": \"object\",\n" +
                    "  \"properties\": {}\n" +
                    "}"
                },
                idField: {
                  type: 'string',
                  title: 'Id Field Name',
                  description: 'Name of the key field. Will be used to send delete, update or copy to new'
                },
                crudUrl: {
                  type: 'string',
                  title: 'Base URL',
                  description: 'Main CRUD URL to be used to send HTTP GET, POST, DELETE, UPDATE'
                },
                useAdvancedCrud: {
                  type: 'boolean',
                  title: 'Use advanced urls?',
                  description: 'Use advanced URLs to define fine granulation for each HTTPs request',
                  default: false
                },
                advancedCrudUrls: {
                  type: 'array',
                  title: 'URLs',
                  description: 'List of CRUD Urls mapped',
                  items: {
                    type: 'object',
                    properties: {
                      name: {
                        type: 'string',
                        title: 'Name',
                        description: 'Identification name'
                      },
                      httpMethod: {
                        type: 'string',
                        title: 'HTTP Method',
                        enum: ['POST', 'GET', 'DELETE', 'UPDATE'],
                        default: 'POST'
                      },
                      url: {
                        type: 'string',
                        title: 'Url',
                        description: 'Url to be used'
                      }
                    }
                  }
                }
              }
            }
          }
        },
        formItem: {
          title: 'Dashboard Definition',
          items: [
            {
              key: 'shouldGenerate'
            },
            {
              type: 'tabarray',
              key: 'models',
              title: "{{ value.title ||  'Tab '+$index }}",
              items: [
                {
                  type: 'section',
                  sectionContainerClass: "row",
                  items: [
                    {
                      childClass: 'col-4',
                      type: 'section',
                      sectionType: 'fieldset',
                      title: 'Common',
                      items: [
                        {
                          key: 'models[].title'
                        },
                        {
                          key: 'models[].generateTypes',
                          type: 'select',
                          multiSelect: true,
                          titleMap: [
                            {
                              name: 'TABLE',
                              value: 'TABLE'
                            },
                            {
                              name: 'FORM',
                              value: 'FORM'
                            }
                          ]
                        }
                      ]
                    },
                    {
                      type: 'section',
                      childClass: 'col-8',
                      sectionType: 'fieldset',
                      title: 'Regeneration',
                      items: [
                        {
                          type: 'section',
                          sectionContainerClass: "row",
                          items: [
                            {
                              type: 'fieldset',
                              childClass: 'col',
                              sectionType: 'fieldset',
                              sectionContainerClass: "row1",
                              title: 'Table',
                              key: 'models[].regenerateTable',
                              condition: 'model.models[form.key[1]].generateTypes[0]==="TABLE"',
                              items: [
                                {
                                  key: 'models[].regenerateTable.all'
                                },
                                {
                                  key: 'models[].regenerateTable.modelFields',
                                  condition: '!model.models[form.key[1]].regenerateTable.all',
                                },
                                {
                                  key: 'models[].regenerateTable.events',
                                  condition: '!model.models[form.key[1]].regenerateTable.all'
                                }

                              ]

                            },
                            {
                              type: 'fieldset',
                              childClass: 'col',
                              sectionType: 'fieldset',
                              sectionContainerClass: "row1",
                              key: 'models[].regenerateForm',
                              condition: 'model.models[form.key[1]].generateTypes[1]==="FORM"',
                              title: 'Form',
                              items: [
                                {key: 'models[].regenerateForm.all'},
                                {
                                  key: 'models[].regenerateForm.modelSchema',
                                  condition: 'model.models[form.key[1]].regenerateForm && !model.models[form.key[1]].regenerateForm.all'
                                },
                                {
                                  key: 'models[].regenerateForm.modelForm',
                                  condition: '!model.models[form.key[1]].regenerateForm.all && model.models[form.key[1]].regenerateForm'
                                },
                                {
                                  key: 'models[].regenerateForm.events',
                                  condition: '!model.models[form.key[1]].regenerateForm.all && model.models[form.key[1]].regenerateForm'
                                }
                              ]

                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'section',
                  sectionContainerClass: "row",
                  items: [
                    {
                      type: 'section',
                      sectionType: 'fieldset',
                      childClass: 'col-4',
                      title: 'Data',
                      items: [
                        {
                          key: 'models[].schema',
                          type: 'ace',
                          style: 'min-height:400px;',
                          config: {
                            lang: 'json'
                          }
                        }
                      ]
                    },
                    {
                      type: 'section',
                      sectionType: 'fieldset',
                      childClass: 'col-8',
                      title: 'CRUD',
                      items: [
                        {
                          type: 'section',
                          sectionContainerClass: "row",
                          items: [
                            {
                              key: 'models[].crudUrl',
                              type: 'text',
                              childClass: 'col-8',
                            },
                            {
                              key: 'models[].idField',
                              type: 'text',
                              childClass: 'col-4',
                            }
                          ]
                        },
                        {
                          key: 'models[].useAdvancedCrud'
                        },
                        {
                          key: 'models[].advancedCrudUrls',
                          condition: 'model.models[form.key[1]].useAdvancedCrud',
                          type: 'tabarray',
                          title: "{{ value.name ||  'Tab '+$index }}",
                          items: [
                            {
                              type: 'section',
                              sectionContainerClass: "row",
                              items: [
                                {
                                  key: 'models[].advancedCrudUrls[].name',
                                  type: 'text',
                                  childClass: 'col-8',
                                },
                                {
                                  key: 'models[].advancedCrudUrls[].httpMethod',
                                  type: 'select',
                                  childClass: 'col-4'
                                }
                              ]
                            },
                            {
                              key: 'models[].advancedCrudUrls[].url',
                              type: 'text'
                            }
                          ]
                        }
                      ]
                    }

                  ]
                }
              ]
            }
          ]
        }
      }
    }
  },
  beforeMount() {
    this.mergeSchemaFormWithCustom();
  },
  methods: {
    onBeforeEditOk(editDefinition) {

      const modelsData = editDefinition.config.data;
      console.log('on before edit definition', modelsData);

      if (modelsData) {
        // this.model.dashboards = Object.assign([]);
        const that = this;
        if (modelsData.shouldGenerate) {
          modelsData.models.forEach(function (dashboardDataModel) {
            const dashboard = find(that.model.dashboards, {title: dashboardDataModel.title});

            console.log('Running dashboard model: ' + dashboardDataModel);
            const dashboardModel = dashboardGenerator.generate(dashboardDataModel);
            // dashboardModel.title = dashboardDataModel.name || dashboardModel.title;

            if (!dashboard) {
              const dashid = 'dash-' + that.$widgetStore.id();
              dashboardModel.dashid = dashid;
              that.model.dashboards.push(dashboardModel);
            } else {
              dashboardGenerator.merge(dashboard, dashboardModel, dashboardDataModel);
              //vueUtils.vueMerge(dashboard, dashboardModel);
            }
            console.log('In Generator on close editDefinition & model', dashboardDataModel, dashboardModel);
          });
        }
      } else {
        this.model.dashboards = Object.assign([]);
      }
    },
    updateStructures(dashboardModel) {
      dashboardModel.structure = this.defaultStructure;
      const structure = defaultStructures[dashboardModel.structure];
      if (structure) {
        dashboardModel.rows.forEach(function (dashboardRow) {
          if (dashboardRow.columns && dashboardRow.columns.length) {
            let rI = 0;
            while (rI < structure.rows.length) {
              const row = structure.rows[rI];
              let i = 0;
              while (i < dashboardRow.columns.length) {
                const column = dashboardRow.columns[i];

                ++i;
              }
              ++rI;
            }

          }
        });
      }
    },
    mergeSchemaFormWithCustom: function () {
      const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();

      for (const prop in this.schemaFormCustom.schemaProperties) {
        schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
      }
      schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);
      this.schemaForm = schemaFormSourceDestination;
    }
  }
}
</script>

<style scoped>

</style>
