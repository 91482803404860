import cloneDeep from 'lodash/cloneDeep'
import chartDataUtils from './chartDataUtils'

const chartBuilder = {

  _buildBaseChart(def) {
    if (!def.category) {
      def.category = 'vertical'
    }
    let base = {
      legend: {},
      tooltip: {}
    }
    if (def.category === 'vertical') {
      base.xAxis = {
        type: 'category',
        boundaryGap: false
      }
      base.yAxis = {
        type: 'value'
      }
    } else {
      base.yAxis = {
        type: 'category',
        boundaryGap: false
      }
      base.xAxis = {
        type: 'value'
      }
    }
    return base;
  },
  build(def, data) {
    const chartDef = this._buildBaseChart(def); //cloneDeep(this.baseChart);
    const chartType = def.chartType;
    let chart = chartDef;
    switch (chartType) {
      case 'line':
      case 'bar':
      case 'area':
        chart = this._buildCommonChart(chartDef, def, data);
        break;
      case 'scatter':
        chart = this._buildScatterChart(chartDef, def, data);
        break;
      case 'pie':
        chart = this._buildPieChart(chartDef, def, data);
        break;
      case 'radar':
        chart = this._buildRadarChart(chartDef, def, data);
        break;
    }
    return chart;
  },
  _buildRadarChart(chartDef, def, data) {
    const legendData = chartDataUtils.uniqueBy(data, (i) => i[def.seriesGroup]);
    console.log('legendData', legendData);

    chartDef.legend.data = legendData;
    const radar = {
      shape: def.shape || 'diamond',
      indicator: []
    }

    const indicator = chartDataUtils.uniqueBy(data, (i) => i[def.xAxis]);
    indicator.forEach((item) => {
      radar.indicator.push({name: item});
    });

    chartDef.radar = radar;
    delete chartDef.xAxis;
    delete chartDef.yAxis;

    const groupedBy = chartDataUtils.groupBy(data, def.seriesGroup);
    console.log('radar groupedBy', groupedBy);

    const series = [];
    for (const group in groupedBy) {
      const val = groupedBy[group];

      const tmpSeries = {
        type: 'radar',
        areaStyle: {normal: {}},
        data: []
      }
      const seriesData = [];

      indicator.forEach((item) => {
        let found = false;
        const tmpVal = val.filter((i) => {
          return i[def.xAxis] === item;
        });
        const firstItem = tmpVal[0];
        if (firstItem !== undefined && firstItem[def.yAxis] !== undefined) {
          seriesData.push(firstItem[def.yAxis]);
        } else {
          seriesData.push(0);
        }
      });
      const radarItem = {
        name: group,
        value: seriesData
      }
      tmpSeries.data.push(radarItem);

      series.push(tmpSeries);
    }

    console.log('radar series', series);
    chartDef.series = series;

    console.log('radar chartdef', chartDef);

    return chartDef;
  },
  _buildPieChart(chartDef, def, data) {

    delete chartDef.xAxis;
    delete chartDef.yAxis;

    const pieSeries = [];
    data.forEach((item) => {
      const obj = {
        name: item[def.xAxis],
        value: item[def.yAxis]
      }
      pieSeries.push(obj);
    });

    chartDef.legend = {
      orient: 'vertical',
      left: 'left'
    }

    chartDef.series = [
      {
        type: 'pie',
        data: pieSeries,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]

    console.log('Chart pie def', chartDef);

    return chartDef;
  },
  _buildScatterChart(chartDef, def, data) {
    return this._buildCommonChart(chartDef, def, data);
  },
  _buildResolveCategoryAxis(def){
    return def.category==='vertical'?'xAxis':'yAxis';
  },
  _buildCommonChart(chartDef, def, data) {
    const categoryAxis = this._buildResolveCategoryAxis(def);
    if (Array.isArray(data)) {
      chartDef[categoryAxis].data = chartDataUtils.uniqueBy(data, (i) => i[def[categoryAxis]]);
    } else {
      chartDef[categoryAxis].data = Object.keys(data);
    }

    if (Array.isArray(data)) {
      const series = this._resolveSeries(def, data);
      chartDef.series = series;
    } else {
      chartDef.series = [
        {
          type: this._resolveSeriesType(def),
          smooth: 0.6,
          data: Object.values(data)
        }
      ]
    }
    console.log('chartDef', chartDef);

    return chartDef;
  },
  _resolveSeries(def, data) {
    const series = [];
    const groupedByDomain = chartDataUtils.groupBy(data, def.seriesGroup);
    const seriesType = this._resolveSeriesType(def);

    for (const group in groupedByDomain) {
      const s1 = {
        name: group,
        type: seriesType,
        smooth: 0.6
      };
      if (this._isAreaStyle(def)) {
        s1.areaStyle = {}
      }
      const projectedArray = chartDataUtils.projectArray(groupedByDomain[group], [def.xAxis, def.yAxis]);

      s1.data = projectedArray;
      series.push(s1);
    }
    return series;
  },
  _isAreaStyle(def) {
    return def.chartType === 'area';
  },
  _resolveSeriesType(def) {
    const seriesType = def.chartType;
    switch (seriesType) {
      case 'area':
        return 'line'
      default:
        return seriesType;
    }
  }
}


export default chartBuilder
