<template>
  <div>
    <schema-form-editor name="formEditor" :is-debug="false"></schema-form-editor>
    <!--@onSchemaChanged="onSchemaChanged"-->
  </div>
</template>

<script>

import schemaFormEditorPlugin from "vue-json-schema-form-editor/src/components/schemaFormEditor";
import Vue from 'vue'

  export default {
    name: "formeditor",
    beforeCreate() {
      Vue.use(schemaFormEditorPlugin)
    },
    methods:{
      /*onSchemaChanged: function(schema){
        console.log('FormEditor schema changed', schema);
      }*/
    }
  }
</script>

<style scoped>

</style>
