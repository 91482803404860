<template>
  <component-base v-bind:field-form="fieldForm">
    <div class="radio-inline-container" :class="[isValid ? '': 'is-invalid']">
      <label :class="fieldForm.labelClass" :title="opt[labelKey]" class="radio-inline mr-2"
             v-for="(opt, index) in internalTitleMap">
        <input :checked="opt[valueKey]===internalValue" :class="fieldForm.fieldHtmlClass"
               :disabled="fieldForm.readonly || fieldForm.disabled"
               :key="internalValue"
               :value="opt[valueKey]"
               :required="fieldForm.required"
               type="radio"
               v-on:change="onChangeEvent($event)"
               v-on:click="onClickReset($event)">
        <span class="ml-1" v-html="opt[labelKey]"></span>
      </label>
    </div>
  </component-base>
</template>

<script>
  import componentMixin from './componentMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
  import titleMapMixins from "./titleMapMixins"

  export default {
    name: "SfRadiosInline",
    mixins: [componentMixin, simpleComponentMergeInMixin, titleMapMixins],
    watch: {
      internalValue: {
        handler(newVal, oldVal) {
          if (newVal !== oldVal && newVal !== this.localValue) {
            this.localValue = newVal;
          }
        }
      }
    },
    methods: {
      onClickReset: function (event) {
        if (this.internalValue === event.target.value) {
          this.internalValue = undefined;
        }
      },
      onChangeEvent: function (event) {
        this.internalValue = event.target.value;
      }
    }
  }
</script>

<style scoped>

  .radio-inline-container {
    display: flex;
    align-items: center;
    padding: 7px;
  }
  .radio-inline {
    margin-bottom: 0px;
  }

</style>
