var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
    _c(
      "div",
      {
        staticClass: "radio-inline-container",
        class: [_vm.isValid ? "" : "is-invalid"],
      },
      _vm._l(_vm.internalTitleMap, function (opt, index) {
        return _c(
          "label",
          {
            staticClass: "radio-inline mr-2",
            class: _vm.fieldForm.labelClass,
            attrs: { title: opt[_vm.labelKey] },
          },
          [
            _c("input", {
              key: _vm.internalValue,
              class: _vm.fieldForm.fieldHtmlClass,
              attrs: {
                disabled: _vm.fieldForm.readonly || _vm.fieldForm.disabled,
                required: _vm.fieldForm.required,
                type: "radio",
              },
              domProps: {
                checked: opt[_vm.valueKey] === _vm.internalValue,
                value: opt[_vm.valueKey],
              },
              on: {
                change: function ($event) {
                  return _vm.onChangeEvent($event)
                },
                click: function ($event) {
                  return _vm.onClickReset($event)
                },
              },
            }),
            _c("span", {
              staticClass: "ml-1",
              domProps: { innerHTML: _vm._s(opt[_vm.labelKey]) },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }