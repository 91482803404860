import schemaFormGenerator from 'vue-json-schema-form-editor/src/components/schemaFormEditor/schemaFormGenerator'

const generator = {
  mergeForm(dashboard, generated, metaSchema){
    metaSchema.regenerateForm
  },
  generate(widget, metaSchema) {

    const regenerateForm= metaSchema.regenerateForm;
    console.log('regenerateForm', regenerateForm);

    const configData = {}
    configData.schemaForm = generator.schemaForm(metaSchema);
    generator.updateEvents(configData, metaSchema);

    widget.config = {
      data: configData
    };

    return widget;
  },
  updateEvents(configData, metaSchema) {
    configData.onLoad = generator.onLoad(metaSchema);
    configData.onEvent = generator.onEvent(metaSchema);
    configData.onSubmit = generator.onSubmit(metaSchema);
    configData.events = generator.events(metaSchema);
  },
  onSubmit(metaSchema) {
    return {
      "destinationType": "EVENTS",
      "eventPublish": {
        "events": [
          {
            "contextObject": "{}",
            "eventName": "onFormSubmit"
          }
        ]
      }
    }
  },
  onLoad(metaSchema) {

  },
  onEvent(metaSchema) {
    const events = [];

    const onRowSelected = {
      "eventType": "DASHBOARD_WIDE",
      "executeType": "APPLY_DATA",
      "applyData": {
        "contextType": "MAIN",
        "contextObject": "$context$"
      },
      "eventName": "onRowSelected"
    };
    events.push(onRowSelected);

    const onSubmitEvent = {
      "eventType": "CURRENT_WIDGET",
      "executeType": "REST_API",
      "eventName": "onFormSubmit",
      "restApi": {
        "httpMethod": "POST",
        "responseEvents": {
          "events": [
            {
              "eventType": "OK",
              "contextObject": "{}",
              "eventName": "onTableLoad"
            }
          ]
        },
        //"url": "$_meta.appConfig.apiBase$+'/v1/ml/modelbuilder'",
        "url": metaSchema.crudUrl,
        "body": "$dataModel$",
        "contentType": "Json"
      }
    }
    events.push(onSubmitEvent);

    const onBtnClickDelete = {
        "eventType": "CURRENT_WIDGET",
        "executeType": "REST_API",
        "eventName": "onBtnClickDelete",
        "restApi": {
          "httpMethod": "DELETE",
          "responseEvents": {
            "events": [
              {
                "eventType": "OK",
                "contextObject": "{}",
                "eventName": "onTableLoad"
              },
              {
                "eventType": "OK",
                "contextObject": "{}",
                "eventName": "clearTheForm"
              }
            ]
          },
          "contentType": "Json",
          //"url": "$_meta.appConfig.apiBase$+'/v1/ml/model/delete'"
        //  "url": metaSchema.crudUrl + '/$dataModel.'+metaSchema.idField+'$'
          "url": metaSchema.crudUrl + "+'/'+$dataModel."+metaSchema.idField+"$"
        }
      };

    events.push(onBtnClickDelete);

    const clearTheForm = {
      "eventType": "CURRENT_WIDGET",
      "executeType": "APPLY_DATA",
      "applyData": {
        "contextType": "MAIN",
        "contextObject": "$someNullValue$"
      },
      "eventName": "clearTheForm"
    };

    events.push(clearTheForm);

    return {events: events}
  },
  events(metaSchema) {

  },
  schemaForm(metaSchema) {
    const schemaForm = {
      schema: {},
      form: []
    }
    try {
      const schemaObj = JSON.parse(metaSchema.schema);

      if (schemaObj && schemaObj.properties) {
        schemaForm.schema = schemaObj;
        const form = schemaFormGenerator.generateFromSchema(schemaObj);
        generator.appendFormActions(form, metaSchema);
        schemaForm.form = form;
      }
    } catch (e) {
      console.log('form error processing schema generation', e);
    }
    return schemaForm;
  },
  appendFormActions(form, metaSchema) {
    const actions = {
      "items": [
        {
          "items": [
            {
              "type": "submit",
              "title": "Save",
              "guid": schemaFormGenerator.generateUUID(),
              "name": "submit"
            },
            {
              "type": "reset",
              "title": "Reset",
              "guid": schemaFormGenerator.generateUUID(),
              "name": "reset"
            },
            {
              "type": "button",
              "btnStyle": "btn-warning ml-3",
              "onClick": {
                "options": "{}",
                "fn": "function(options, event, fieldForm, fullModel){ fullModel."+metaSchema.idField+"=undefined; }"
              },
              "title": "Copy",
              "guid": schemaFormGenerator.generateUUID(),
              "name": "button"
            },
            {
              "type": "button",
              "title": "Delete",
              "guid": schemaFormGenerator.generateUUID(),
              "name": "button",
              "onClick": {
                "options": {
                  "eventName": "onBtnClickDelete",
                  "eventDefinition": {
                    "context": {}
                  }
                },
                "fn": "function(options, event, fieldForm, fullModel,context){ const fn = context.this.lookupMethodOnParents(context.this, 'publishObjectEvent'); if (fn){ options.eventDefinition.context.fullModel = fullModel; fn(options);}  }"
              },
              "btnStyle": "btn-danger"
            }
          ],
          "type": "actions",
          "guid": schemaFormGenerator.generateUUID(),
          "name": "actions",
          "htmlClass": "mb-2"
        }
      ],
      "type": "section",
      "guid": schemaFormGenerator.generateUUID(),
      "name": "section",
      "sectionType": "section",
      "sectionContainerClass": "row"
    }
    form.push(actions);
  }
}
export default generator
