var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalEdit",
      attrs: {
        scrollable: true,
        title: _vm.widget.title,
        lazy: "",
        size: "full",
      },
      on: { ok: _vm.handleEditConfirmed },
    },
    [
      _c("schema-form", {
        ref: "schemaForm",
        attrs: { form: _vm.schemaForm.form, schema: _vm.schemaForm.schema },
        model: {
          value: _vm.widgetDefinitionModel,
          callback: function ($$v) {
            _vm.widgetDefinitionModel = $$v
          },
          expression: "widgetDefinitionModel",
        },
      }),
      _vm._v("\n  " + _vm._s(_vm.widgetDefinitionModel) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }