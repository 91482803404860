import schemaFormElements from './schemaFormElements'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import lastIndexOf from 'lodash/lastIndexOf'
import utils from '../../utils/utils'
import ObjectPath from "../../utils/ObjectPath";
import isEqual from 'lodash/isEqual'
import vueUtils from '../../utils/vueUtils'
import cloneDeep from "lodash/cloneDeep";

const droppableMixins = {
  props: {
    isDroppable: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
      guid: null,
      currentDraggableState: {},
      currentDraggableItem: {}
    }
  },
  created() {
  //  if (this.isDroppable) {// only if we are in Edit mode
      this.guid = this.fieldForm && this.fieldForm.guid ? this.fieldForm.guid : schemaFormElements.generateUUID();
      //console.log('Generating GUID', this.fieldForm, this.guid);
  //  }
  },
  methods: {
    mergeInLeafItem: function (item) {
      if (this.fieldForm.guid === item.guid) {
        console.log('Received in leaf item', item, this.fieldForm);
        for (const prop in this.fieldForm) {
          delete this.fieldForm[prop];
        }
        vueUtils.vueMerge(this.fieldForm, item);
        //this.$set(this, 'fieldForm', item);

        console.log('Received in leaf item - After Merge', item, this.fieldForm);

        this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm});
      }
    },
    updateKeyRecursively: function (objectItem, configItem, parentKey, previousParentKey) {
      const existingKey = objectItem.schemaFieldKey;

      let newKeyStringified = configItem.key;
      if (Array.isArray(newKeyStringified)) {
        newKeyStringified = configItem.key[0];
      }

      if (previousParentKey) {
        newKeyStringified = newKeyStringified.substring(previousParentKey.length);
        newKeyStringified = newKeyStringified.substring(lastIndexOf(newKeyStringified, '.') + 1);
      }

      parentKey = parentKey || '';
      newKeyStringified = parentKey ? parentKey + '.' + newKeyStringified : newKeyStringified;

      objectItem.schemaFieldKey = newKeyStringified;
      objectItem.copyKey = newKeyStringified;

      const newKeyArray = ObjectPath.parse(newKeyStringified);
      objectItem.key = newKeyArray;
      objectItem.schemaKey = newKeyArray;

      const currentKeyByType = newKeyStringified + (objectItem.type === 'array' ? '[]' : '');

      const that = this;
      if (objectItem.items) {
        forEach(objectItem.items, function (item) {
          that.updateKeyRecursively(item, item, currentKeyByType, existingKey);
        });
      }
      if (objectItem.schema && objectItem.schema.properties) {
        forEach(objectItem.schema.properties, function (value, key) {
        });
      }

    },
    mergeInConfigItem: function (configItem) {
      //let configItemClone = utils.clone(configItem);
      console.log('mergeInConfigItem received', configItem);

      const configItemIndex = findIndex(this.clonedDroppedComponents, function (item) {
        return item.guid === configItem.guid;
      });

      if (configItemIndex > -1) {
        let objectItem = this.clonedDroppedComponents[configItemIndex];

        // if Key is changed we have to go into all children
        if (!isEqual(objectItem.key, configItem.key)) {
          this.updateKeyRecursively(objectItem, configItem);
        }

        this.clonedDroppedComponents.splice(configItemIndex, 1, objectItem);
        const that = this;
        that.$emit('schemaChanged', that.clonedDroppedComponents);
      }
    },
    onSelectedItem: function (item) {
      this.selectedItem = item;
    },

    onSchemaChanged: function (schemaArray) {
      console.log('onSchemaChanged - before', schemaArray, this.fieldForm);
      let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);
      let builderFormArray = schemaAndForm.form;
      let builderSchema = schemaAndForm.schema;


      this.fieldForm.schema.properties = builderSchema.properties;

      // update child items keys
      const that = this;
      forEach(builderFormArray, function (formItem) {
        formItem.key = that.fieldForm.key + '.' + formItem.key;
      });

      this.fieldForm.items = builderFormArray;

      console.log('onSchemaChanged - after', schemaArray, this.fieldForm, schemaAndForm);

      this.$emit('schemaChanged', {schemaFormComponent: this.fieldForm});
    },
    selectItem: function (formField) {
      const cloned = utils.clone(formField);
      delete cloned.isMinimized;

      console.log('Set Selected', cloned);
      this.$schemaFormEditorStore.setSelected(cloned);
      this.$bus.$emit("selectedItem", cloned);
      this.selectedItem = cloned;
    },
    resolveSchemaFormList: function (list) {
      let schema = schemaFormElements.resolveSchema(list);
      let form = schemaFormElements.resolveForm(list);

      console.log('resolveSchemaFormList - before', this, cloneDeep(list));
      let mergedSchema = this.resolveDft(schema, form);

      console.log('resolveSchemaFormList - after', this, cloneDeep(list), cloneDeep(mergedSchema));

      return mergedSchema;
    },
    getMergedSchemaObject: function (addedObjectIndex, list) {
      let mergedSchema = this.resolveSchemaFormList(list);
      const mergedObject = mergedSchema[addedObjectIndex];
      mergedObject.name = list[addedObjectIndex].name;

      return mergedObject;
    },
    onMove: function (evt) {
      this.currentDraggableState = {
        event: 'move',
        draggedContext: evt.draggedContext,
        fromComponent: evt.from.__vue__,
        toComponent: evt.relatedContext
      }

      /*console.log('onMove', {
        evt: evt,
        currentDraggableState: this.currentDraggableState,
        clonedDropped: this.clonedDroppedComponents,
        internal: this.internalCalculatedMergedSchemaForm
      });*/


    },
    onEnd: function (evt) {
      console.log('onEnd', {
        evt: evt,
        clonedDropped: utils.clone(this.clonedDroppedComponents),
        internal: utils.clone(this.internalCalculatedMergedSchemaForm)
      });

      this.$emit('internalSchemaChanged', this.clonedDroppedComponents);
    },
    onChange: function (evt) {
      console.log('onChange', {
        evt: evt,
        clonedDropped: utils.clone(this.clonedDroppedComponents),
        internal: utils.clone(this.internalCalculatedMergedSchemaForm)
      });
      /*
      0 - When dragged from left list it will first call OnChange with "added" property containing dropped context object - element: { name, guid, component }
       */

      this.currentDraggableItem = evt;

      if (evt.removed) {

        // const missingIndex = this.syncIndexes();
        const internalItemIndex = findIndex(this.internalCalculatedMergedSchemaForm, function (item) {
          return item.guid === evt.removed.element.guid;
        });

        console.log('Removed indexes', {

          oldIndex: evt.removed.oldIndex,
          internalItemIndex: internalItemIndex
        })

        console.log('onChange-end', {
          evt: evt,
          clonedDropped: utils.clone(this.clonedDroppedComponents),
          internal: utils.clone(this.internalCalculatedMergedSchemaForm)
        });
        //  this.$emit('internalSchemaChanged', this.clonedDroppedComponents);
      } else if (evt.moved) {
        // this.$emit('internalSchemaChanged', this.clonedDroppedComponents);
      }
    },
    onAdd: function (evt) {
      console.log('onAdd', {
        evt: evt,
        clonedDropped: utils.clone(this.clonedDroppedComponents),
        internal: utils.clone(this.internalCalculatedMergedSchemaForm)
      });
      /*
    0 -  When dragged from left list it will first call OnChange with "added" property containing dropped context object - element: { name, guid, component }
    1 -  It will call onAdd and item will be already stored in this.clonedDroppedComponents - evt.item is what we need - to access guid: evt.item._underlying_vm_.guid
    2 -  this.droppedCalculatedMergedForm - list that will be rendered

    TODO:

    Main goal is to prepare final schema & form and provide to root Form component to render itself

    3 -  There are three types of components:
          - Builder (Array)
          - Container (Object or just form, such as Fieldset or Section) containing Builder
          - Simple (atomic component) - such as Text, Password, Select, etc

    4 -  onAdd will happen in Builder - e.g. we are adding element into array
    5 -  ON ADD can happen when:
            - CLONING element from left list (repository)
            - REARANGING element from some other list (parent, sibling or child)
              - In this case it will also happen:
                  - onChange: removed
                  - onChange: added
                  - onEnd (???) to check this


        5.1 - CLONING:
              - Find element by guid in this.clonedDroppedComponents
              - Resolve defaults for this type (fieldForm)
              - Add to final list
        5.2 - REARANGING
              -


       */

      // const missingIndex = this.syncIndexes();

      let addedObjectIndex = findIndex(this.clonedDroppedComponents, function (item) {
        return item.guid === evt.item._underlying_vm_.guid; //TODO - is there better option???
      });


      console.log('onAdd indexes', {
        missingIndex: -1,
        oldIndex: evt.oldIndex,
        newIndex: evt.newIndex,
        internalItemIndex: addedObjectIndex
      })

      if (addedObjectIndex === -1) {
        // it is not yet added (why not ???)
        //this.clonedDroppedComponents.splice(evt.newIndex, 0, )
        console.log('Object not added')

        if (this.currentDraggableItem.added) {
          const element = this.currentDraggableItem.added.element;
          addedObjectIndex = this.currentDraggableItem.added.newIndex;

          this.clonedDroppedComponents.splice(addedObjectIndex, 0, utils.clone(element));
          this.currentDraggableItem = {};
        }
      }

      if (evt.newIndex !== addedObjectIndex) {
        this.clonedDroppedComponents = schemaFormElements.rearrangeArray(this.clonedDroppedComponents, addedObjectIndex, evt.newIndex);
      }

      const addedObject = this.getMergedSchemaObject(evt.newIndex, this.clonedDroppedComponents);

      let clonedItem = utils.clone(addedObject);
      this.selectItem(clonedItem);

      // this.internalCalculatedMergedSchemaForm.splice(evt.newIndex, 0, addedObject);
      this.clonedDroppedComponents.splice(evt.newIndex, 1, addedObject);

      console.log('onAdd-ended', {
        evt: evt,
        clonedDropped: utils.clone(this.clonedDroppedComponents),
        internal: utils.clone(this.internalCalculatedMergedSchemaForm)
      });


      this.$emit('internalSchemaChanged', this.clonedDroppedComponents);
    },
    syncIndexes: function () {

      let missingIndex = null;
      let i = 0;
      const that = this;
      forEach(this.clonedDroppedComponents, function (clonedItem) {
        const addedObjectIndex = findIndex(that.internalCalculatedMergedSchemaForm, function (item) {
          return item.guid === clonedItem.guid; //TODO - is there better option???
        });
        if (addedObjectIndex > -1) {
          if (i !== addedObjectIndex) {
            schemaFormElements.rearrangeArray(that.internalCalculatedMergedSchemaForm, addedObjectIndex, i);
          }
        } else {
          missingIndex = addedObjectIndex;
        }
        ++i;
      });
      return missingIndex;
    },

    onAdd_old: function (evt) {

      const idx = evt.newIndex; // - Stil doesn' work TODO check what is wrong with newIndex - Draggable doesn't put on correct index // solutions: https://visdup.blogspot.com/2019/01/sortablejs-with-vue-20-sorts-incorrectly.html

      const item = evt.item;
      if (item.tagName.toLowerCase() === 'li' && item.className === 'list-group-item') {

        const addedObject = this.clonedDroppedComponents[evt.newIndex];//.splice(idx, 1)[0];//[idx];

        const addedObjectIndex = findIndex(this.clonedDroppedComponents, function (item) {
          return item.guid === evt.item._underlying_vm_.guid; //TODO - is there better option???
        });

        if (idx !== addedObjectIndex) {
          this.clonedDroppedComponents = schemaFormElements.rearrangeArray(this.clonedDroppedComponents, addedObjectIndex, idx);
        }
        const that = this;
        this.$nextTick(function () {
          let schema = schemaFormElements.resolveSchema(that.clonedDroppedComponents);
          let form = schemaFormElements.resolveForm(that.clonedDroppedComponents);

          let mergedSchema = that.resolveDft(schema, form);

          const mergedObject = mergedSchema[idx];
          mergedObject.name = that.clonedDroppedComponents[idx].name;

          let clonedItem = utils.clone(mergedObject);


          that.selectItem(clonedItem);

          that.droppedCalculatedMergedForm.splice(idx, 0, mergedObject);

          that.$emit('schemaChanged', that.droppedCalculatedMergedForm);
        });

      } else {
        //
        console.log('Added evt', evt);
        console.log('clonedDroppedComponents', this.clonedDroppedComponents);
        console.log('droppedCalculatedMergedForm', this.droppedCalculatedMergedForm);
      }

    },
    onEnd_old: function (evt) {
      console.log('On end in builder component', evt);

      if (evt.from.__vue__._uid === evt.to.__vue__._uid) {
        if (evt.newIndex === evt.oldIndex) {
          return false;
        } else {
          this.clonedDroppedComponents = schemaFormElements.rearrangeArray(this.clonedDroppedComponents, evt.oldIndex, evt.newIndex);
          this.droppedCalculatedMergedForm = schemaFormElements.rearrangeArray(this.droppedCalculatedMergedForm, evt.oldIndex, evt.newIndex);

          this.selectItem(this.droppedCalculatedMergedForm[evt.newIndex]);
          this.$emit('schemaChanged', this.droppedCalculatedMergedForm);
        }
      } else {
        //   console.log('Executed on end on different lists');
        // we should add in new List but remove from first list
        //this.onAdd(evt);

        // we should remove from this list as it is called Add on another list
        //this.clonedDroppedComponents.splice(evt.oldIndex, 1);4
        const that = this;
        const itemGuid = evt.item._underlying_vm_.guid;
        this.$nextTick(function () {
          const itemIndex = findIndex(that.droppedCalculatedMergedForm, function (item) {
            return item.guid === itemGuid;
          });
          //that.droppedCalculatedMergedForm.splice(itemIndex, 1);
          that.$emit('schemaChanged', that.droppedCalculatedMergedForm);
        })
      }
    },
    onChange_old: function (evt) {
      console.log('Changed', evt);

      //that.droppedCalculatedMergedForm.splice(itemIndex, 1);
      //that.$emit('schemaChanged', that.droppedCalculatedMergedForm);

      if (evt.removed) {
        const guid = evt.removed.element.guid;
        const that = this;
        const itemIndex = findIndex(that.droppedCalculatedMergedForm, function (item) {
          return item.guid === guid;
        });
        that.droppedCalculatedMergedForm.splice(itemIndex, 1);
        that.$emit('schemaChanged', that.droppedCalculatedMergedForm);

      } else if (evt.moved && evt.moved.newIndex === evt.moved.oldIndex) {
        return false;
      }
    },
    resolveDft: function (schema, form) {
      let schemaCloned = utils.clone(schema);
      let formCloned = utils.clone(form);

      if (schemaCloned && Object.keys(schemaCloned).length) {
        const merged = utils.mergeForm(schemaCloned, formCloned, {}, {});
        return merged;
      }
    }
  }
}


export default droppableMixins
