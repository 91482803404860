var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _vm.dashboardEditMode && _vm.isDashboardTypeEditable
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("schema-form", {
                attrs: { form: _vm.typeForm, schema: _vm.typeSchema },
                model: {
                  value: _vm.dashboardTypeModel,
                  callback: function ($$v) {
                    _vm.dashboardTypeModel = $$v
                  },
                  expression: "dashboardTypeModel",
                },
              }),
              _c("hr"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(_vm.resolveDashboardType, {
            ref: "dashboardContainer",
            tag: "component",
            attrs: {
              "dashboard-edit-mode": _vm.dashboardEditMode,
              "dashboard-name": _vm.dashboardName,
              "default-mapper": _vm.defaultMapper,
              "disable-add": _vm.disableAdd,
              "disable-edit": _vm.disableEdit,
              permissions: _vm.permissions,
              "input-context": _vm.inputContext,
              "dashboard-service-instance": _vm.dashboardServiceInstance,
              model: _vm.dashboardModel,
              "parent-widget-id": _vm.parentWidgetId,
              "parent-dashid": _vm.parentDashid,
              "use-provided-model": true,
              "crud-service-name": "http",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }