var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "dashboard-simple" } },
    [
      _c("h4", [_vm._v("DA LI SE OVO KORISTI???")]),
      _c(
        "div",
        { staticClass: "pull-right", staticStyle: { width: "400px" } },
        [
          _c("router-link", { attrs: { to: { name: "neka proba" } } }, [
            _vm._v("Neka proba"),
          ]),
        ],
        1
      ),
      _c("router-view"),
      _c("vue-df-dashboard-simple", {
        attrs: {
          categories: _vm.selectedDashboard.categories,
          collapsible: false,
          "continuous-edit-mode": false,
          dashid: _vm.selectedDashboard.dashid,
          "default-mapper": _vm.defaultMapper,
          "edit-mode": false,
          editable: false,
          maximizable: false,
          model: _vm.selectedDashboard,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }