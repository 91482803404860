import Vue from 'vue'
const vueEventBus = {
  install: function (Vue, options) {

    if (!Vue.dashboardBus) {
      const dashboardBus = new Vue();
      Vue.dashboardBus = dashboardBus;
      Object.defineProperty(Vue.prototype, '$dashboardBus', {
        get: function () {
          return Vue.dashboardBus
        }
      })
    }
  }
}


export default vueEventBus
