var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c(
        "div",
        { staticClass: "kanban-view" },
        [
          _c("vue-kanban", {
            model: {
              value: _vm.kanbanModel,
              callback: function ($$v) {
                _vm.kanbanModel = $$v
              },
              expression: "kanbanModel",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }