import droppableMixins from './droppableMixins'

const simpleComponentMergeInMixin = {
  mixins: [droppableMixins],
  methods: {
    selectedItemModelApplied: function(item){
    }
  },
  created(){
    if (this.isDroppable){
      const that = this;
      this.$bus.$on('selectedItemModelApplied', that.selectedItemModelApplied);
    }
  },
  beforeDestroy() {
    if (this.isDroppable) {
      const that = this;
      this.$bus.$off('selectedItemModelApplied', that.selectedItemModelApplied);
    }
  }
}




export default simpleComponentMergeInMixin
