var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c(
        "div",
        { staticClass: "dashboard-view-widget" },
        [
          _vm.render
            ? _c("vue-df-dashboard-content", {
                key: _vm.id,
                ref: "dashboardContent",
                attrs: {
                  "input-context": _vm.dataModel,
                  "dashboard-edit-mode": _vm.enableEditMode,
                  "dashboard-name": _vm.widgetDashboardName,
                  "dashboard-service-instance": _vm.dashboardServiceInstance,
                  "dashboard-type": _vm.dashboardType,
                  "disable-add": _vm.disableAdd,
                  "disable-edit": _vm.disableEdit,
                  "is-dashboard-type-editable": _vm.isDashboardTypeEditable,
                  model: _vm.dashboardsModel,
                  "parent-dashid": _vm.computedDashid,
                  "parent-widget-id": _vm.computedWidgetId,
                  permissions: _vm.permissions,
                },
                on: {
                  dashboardchanged: _vm.onDashboardChanged,
                  dashboardtypechange: _vm.onDashboardTypeChange,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }