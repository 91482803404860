const componentUtils = {
  sourceDestinationSchemaForm: {
    "schema": {
      "properties": {
        "onLoad": {
          "type": "object",
          "properties": {
            "sourceType": {
              "key": "sourceType",
              "type": "string",
              "readonly": false,
              "hidden": false,
              "title": "Source Type",
              "description": "Select type of the data source",
              "default": "REST_API"
            },
            "showLoader": {
              "key": "showLoader",
              "type": "boolean",
              "title": "Show Loader",
              "description": "Show loader on load of the widget",
              "default": false
            },
            "restApi": {
              "type": "object",
              "properties": {
                "url": {
                  "key": "url",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Url",
                  "description": "Expression allowed to get context data"
                },
                "httpMethod": {
                  "key": "httpMethod",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "HTTP Method",
                  "description": "Description for: radiosinline",
                  "default": "GET"
                },
                "headers": {
                  "key": "headers",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "HTTP Headers",
                  "description": "Define Headers to be included in the request. Example: Authorization=bearer ${user.token}$;"
                },
                "body": {
                  "key": "body",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Body",
                  "description": "Define body for POST and PUT methods using expressions",
                  "default": "{}"
                },
                "responseEvents": {
                  "type": "object",
                  "properties": {
                    "events": {
                      "type": "array",
                      "items": {
                        "type": "object",
                        "properties": {
                          "eventName": {
                            "key": "eventName",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "Publish Event Name",
                            "description": "Define Event name to publish"
                          },
                          "eventType": {
                            "key": "eventType",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "On Response Type",
                            "description": "Select response type",
                            "default": "OK"
                          },
                          "contextObject": {
                            "key": "contextObject",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "Context Object",
                            "description": "Use expression to define Context Object in json form",
                            "default": "{}"
                          }
                        },
                        "required": []
                      }
                    }
                  },
                  "required": []
                }
              },
              "required": []
            },
            "staticData": {
              "type": "object",
              "properties": {
                "data": {
                  "key": "data",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Static Data",
                  "description": "Enter Static data to be used in Widget"
                }
              },
              "required": []
            },
            "eventPublish": {
              "type": "object",
              "properties": {
                "events": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "eventName": {
                        "key": "eventName",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Publish Event Name",
                        "description": "Define name for the event to publish"
                      },
                      "contextObject": {
                        "key": "contextObject",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Context Object",
                        "description": "Use expression to define Context Object in json form",
                        "default": "{}"
                      }
                    },
                    "required": []
                  }
                }
              },
              "required": []
            }
          },
          "required": []
        },
        "onSubmit": {
          "type": "object",
          "properties": {
            "destinationType": {
              "key": "destinationType",
              "type": "string",
              "readonly": false,
              "hidden": false,
              "title": "Destination Type",
              "description": "Select type of the data destination",
              "default": "REST_API"
            },
            "restApi": {
              "type": "object",
              "properties": {
                "url": {
                  "key": "url",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Url",
                  "description": "Expression allowed to get context data"
                },
                "httpMethod": {
                  "key": "httpMethod",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "HTTP Method",
                  "description": "Define HTTP Method to be used",
                  "default": "GET"
                },
                "contentType": {
                  "type": "string",
                  "title": "Content Type",
                  "description": "Select content type",
                  "default": "Json"
                },
                "headers": {
                  "key": "headers",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "HTTP Headers",
                  "description": "Define Headers to be included in the request. Example: Authorization=bearer ${user.token}$;"
                },
                "body": {
                  "key": "body",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Body",
                  "description": "Define body for POST and PUT methods using expressions"
                },
                "responseEvents": {
                  "type": "object",
                  "properties": {
                    "events": {
                      "type": "array",
                      "items": {
                        "type": "object",
                        "properties": {
                          "eventName": {
                            "key": "eventName",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "Publish Event Name",
                            "description": "Define Event Name"
                          },
                          "eventType": {
                            "key": "eventType",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "On Response Type",
                            "description": "Select Response Type",
                            "default": "OK"
                          },
                          "contextObject": {
                            "key": "contextObject",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "Context Object",
                            "description": "Use expression to define Context Object in json form",
                            "default": "{}"
                          }
                        },
                        "required": []
                      }
                    }
                  },
                  "required": []
                }
              },
              "required": []
            },
            "eventPublish": {
              "type": "object",
              "properties": {
                "events": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "eventName": {
                        "key": "eventName",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Event Name",
                        "description": "Define name for the publish event"
                      },
                      "contextObject": {
                        "key": "contextObject",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Context Object",
                        "description": "Use expression to define Context Object in json form",
                        "default": "{}"
                      }
                    },
                    "required": []
                  }
                }
              },
              "required": []
            }
          },
          "required": []
        },
        "onEvent": {
          "type": "object",
          "properties": {
            "events": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "eventName": {
                    "key": "eventName",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Event Name",
                    "description": "Define event name to subscribe"
                  },
                  "eventType": {
                    "key": "eventType",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Event Type",
                    "description": "Select event type to subscribe",
                    "default": "DASHBOARD_WIDE"
                  },
                  "widgetSpecificType": {
                    "key": "widgetSpecificType",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Widget Specific Type",
                    "default": "WIDGET_TYPE"
                  },
                  "widgetSpecificByType": {
                    "key": "widgetSpecificByType",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Widget Specific by Type",
                    "description": "Select to subscribe by one or more Types"
                  },
                  "widgetSpecificByInstance": {
                    "key": "widgetSpecificByInstance",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Widget Specific by Instance",
                    "description": "Select to subscribe by one or more Instances"
                  },
                  "executeType": {
                    "key": "executeType",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Execute type on event",
                    "description": "Select type of event execution",
                    "default": "APPLY_DATA"
                  },
                  "hideLoading": {
                    "key": "hideLoading",
                    "type": "string",
                    "title": "Hide Loading",
                    "description": "Hide loader on the widget"
                  },
                  "runIf": {
                    "key": "runIf",
                    "type": "string",
                    "title": "Run If",
                    "description": "Event will be executed only if RunIf expression is satisfied or empty. Example: !IS_NULL($data._id$)"
                  },
                  "notifyMessage": {
                    "type": "object",
                    "properties": {
                      "message": {
                        "key": "message",
                        "type": "string",
                        "title": "Message",
                        "description": "Define message with expression"
                      },
                      "type": {
                        "key": "type",
                        "type": "string",
                        "title": "Type",
                        "description": "Select type of the message",
                        "default": "Primary"
                      },
                      "timeout": {
                        "key": "timeout",
                        "type": "number",
                        "title": "Timeout",
                        "description": "Define timeout to be closed in milliseconds",
                        "default": 3000
                      }
                    },
                    "required": []
                  },
                  "websocket": {
                    "type": "object",
                    "properties": {
                      "connections": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "properties": {
                            "connectionType": {
                              "key": "connectionType",
                              "type": "string",
                              "readonly": false,
                              "hidden": false,
                              "title": "Connection Type",
                              "description": "Select connection type",
                              "default": "INTERNAL"
                            },
                            "url": {
                              "key": "url",
                              "type": "string",
                              "readonly": false,
                              "hidden": false,
                              "title": "Url",
                              "description": "Description for: text"
                            },
                            "userName": {
                              "key": "userName",
                              "type": "string",
                              "readonly": false,
                              "hidden": false,
                              "title": "User Name",
                              "description": "Define User name to connect to Web Socket"
                            },
                            "password": {
                              "key": "password",
                              "type": "string",
                              "readonly": false,
                              "hidden": false,
                              "title": "Password",
                              "description": "Define Password to connect to Web Socket"
                            },
                            "actions": {
                              "type": "array",
                              "items": {
                                "type": "object",
                                "properties": {
                                  "actionType": {
                                    "key": "actionType",
                                    "type": "string",
                                    "readonly": false,
                                    "hidden": false,
                                    "title": "Action Type",
                                    "description": "Select Action type",
                                    "default": "PUBLISH"
                                  },
                                  "topicName": {
                                    "key": "topicName",
                                    "type": "string",
                                    "readonly": false,
                                    "hidden": false,
                                    "title": "Topic Name",
                                    "description": "Define Topic Name"
                                  },
                                  "contextObject": {
                                    "key": "contextObject",
                                    "type": "string",
                                    "readonly": false,
                                    "hidden": false,
                                    "title": "Context Object",
                                    "description": "Use expression to define Context Object in json form"
                                  },
                                  "publishEvents": {
                                    "type": "object",
                                    "properties": {
                                      "events": {
                                        "type": "array",
                                        "items": {
                                          "type": "object",
                                          "properties": {
                                            "eventName": {
                                              "key": "eventName",
                                              "type": "string",
                                              "readonly": false,
                                              "hidden": false,
                                              "title": "Event Name",
                                              "description": "Define Event Name"
                                            },
                                            "contextObject": {
                                              "key": "contextObject",
                                              "type": "string",
                                              "readonly": false,
                                              "hidden": false,
                                              "title": "Context Object",
                                              "description": "Use expression to define Context Object in json form"
                                            }
                                          },
                                          "required": []
                                        }
                                      }
                                    },
                                    "required": []
                                  }
                                },
                                "required": []
                              }
                            }
                          },
                          "required": []
                        }
                      }
                    },
                    "required": []
                  },
                  "applyData": {
                    "type": "object",
                    "properties": {
                      "contextType": {
                        "key": "contextType",
                        "type": "string",
                        "title": "Context Type",
                        "description": "Where to apply the data. Should it be main data or lookup data used by lookup controls",
                        "default": "MAIN"
                      },
                      "contextObject": {
                        "key": "contextObject",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Context Object",
                        "description": "Define context object to be applied on model"
                      }
                    },
                    "required": []
                  },
                  "restApi": {
                    "type": "object",
                    "properties": {
                      "url": {
                        "key": "url",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Url",
                        "description": "Expression allowed to get context data"
                      },
                      "httpMethod": {
                        "key": "httpMethod",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "HTTP Method",
                        "description": "Define HTTP Method to be used",
                        "default": "GET"
                      },
                      "contentType": {
                        "type": "string",
                        "title": "Content Type",
                        "description": "Select content type",
                        "default": "Json"
                      },
                      "download":{
                        "type": "string",
                        "title": "Is Download?",
                        "description": "Select is Downloadable content"
                      },
                      "headers": {
                        "key": "headers",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "HTTP Headers",
                        "description": "Define Headers to be included in the request. Example: Authorization=bearer ${user.token}$;"
                      },
                      "body": {
                        "key": "body",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Body",
                        "description": "Define body for POST and PUT methods using expressions",
                        "default": "{}"
                      },
                      "responseEvents": {
                        "type": "object",
                        "properties": {
                          "events": {
                            "type": "array",
                            "items": {
                              "type": "object",
                              "properties": {
                                "eventName": {
                                  "key": "eventName",
                                  "type": "string",
                                  "readonly": false,
                                  "hidden": false,
                                  "title": "Publish Event Name",
                                  "description": "Define Event Name"
                                },
                                "eventType": {
                                  "key": "eventType",
                                  "type": "string",
                                  "readonly": false,
                                  "hidden": false,
                                  "title": "On Response Type",
                                  "description": "Select Response Type",
                                  "default": "OK"
                                },
                                "contextObject": {
                                  "key": "contextObject",
                                  "type": "string",
                                  "readonly": false,
                                  "hidden": false,
                                  "title": "Context Object",
                                  "description": "Use expression to define Context Object in json form",
                                  "default": "{}"
                                }
                              },
                              "required": []
                            }
                          }
                        },
                        "required": []
                      }
                    },
                    "required": []
                  },
                  "localStorage": {
                    "type": "object",
                    "properties": {
                      "contextType": {
                        "key": "contextType",
                        "type": "string",
                        "title": "Context Type",
                        "description": "Where to apply the data. Should it be main data or lookup data used by lookup controls",
                        "default": "MAIN"
                      },
                      "keyName": {
                        "key": "keyName",
                        "type": "string",
                        "title": "Key name",
                        "description": "Enter key name used as key in local storage"
                      },
                      "ttl": {
                        "key": "ttl",
                        "type": "string",
                        "title": "TTL",
                        "description": "Enter Time to live in milliseconds."
                      },
                      "contextObject": {
                        "key": "contextObject",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Context Object",
                        "description": "Define context object to be applied on model"
                      },
                      "responseEvents": {
                        "type": "object",
                        "properties": {
                          "events": {
                            "type": "array",
                            "items": {
                              "type": "object",
                              "properties": {
                                "eventName": {
                                  "key": "eventName",
                                  "type": "string",
                                  "readonly": false,
                                  "hidden": false,
                                  "title": "Publish Event Name",
                                  "description": "Define Event name to publish"
                                },
                                "contextObject": {
                                  "key": "contextObject",
                                  "type": "string",
                                  "readonly": false,
                                  "hidden": false,
                                  "title": "Context Object",
                                  "description": "Use expression to define Context Object in json form",
                                  "default": "{}"
                                }
                              },
                              "required": []
                            }
                          }
                        },
                        "required": []
                      }
                    },
                    "required": []
                  },
                  "eventPublish": {
                    "type": "object",
                    "properties": {
                      "events": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "properties": {
                            "eventName": {
                              "key": "eventName",
                              "type": "string",
                              "readonly": false,
                              "hidden": false,
                              "title": "Event Name",
                              "description": "Define name for the Event"
                            },
                            "contextObject": {
                              "key": "contextObject",
                              "type": "string",
                              "readonly": false,
                              "hidden": false,
                              "title": "Context Object",
                              "description": "Use expression to define Context Object in json form",
                              "default": "{}"
                            }
                          },
                          "required": []
                        }
                      }
                    },
                    "required": []
                  },
                  "widgetSettings": {
                    "type": "object",
                    "properties": {
                      "widgetVisible": {
                        "key": "widgetVisible",
                        "type": "boolean",
                        "readonly": false,
                        "hidden": false,
                        "title": "Widget Visible",
                        "description": "Select the option to show or hide Widget body",
                        "default": true
                      }
                    },
                    "required": []
                  },
                  "navigationSettings": {
                    "type": "object",
                    "properties": {
                      "type": {
                        "key": "type",
                        "type": "string",
                        "readonly": false,
                        "hidden": false,
                        "title": "Navigation Type",
                        "description": "Select navigation type"
                      },
                      "routeSettings": {
                        "type": "object",
                        "properties": {
                          "navigateTo": {
                            "key": "navigateTo",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "Navigate To",
                            "description": "Select where to navigate"
                          },
                          "navigateToWidget": {
                            "type": "object",
                            "properties": {
                              "widgetInstance": {
                                "key": "widgetInstance",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Widget Instance",
                                "description": "Select widget instance to navigate to"
                              }
                            },
                            "required": []
                          },
                          "navigateToRoute": {
                            "type": "object",
                            "properties": {
                              "routeName": {
                                "key": "routeName",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Route name",
                                "description": "Define route name to navigate"
                              }
                            },
                            "required": []
                          },
                          "navigateToLink": {
                            "type": "object",
                            "properties": {
                              "url": {
                                "key": "url",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Url location",
                                "description": "Define url location to navigate on event"
                              },
                              "urlTarget": {
                                "key": "urlTarget",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Target",
                                "description": "Select target to open the URL location"
                              }
                            },
                            "required": []
                          }
                        },
                        "required": []
                      },
                      "modalSettings": {
                        "type": "object",
                        "properties": {
                          "type": {
                            "key": "type",
                            "type": "string",
                            "readonly": false,
                            "hidden": false,
                            "title": "Modal Type",
                            "description": "Select modal type"
                          },
                          "yesNoModal": {
                            "type": "object",
                            "properties": {
                              "title": {
                                "key": "title",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Title",
                                "description": "Define title text"
                              },
                              "iconType": {
                                "key": "iconType",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Type",
                                "description": "Select modal type",
                                "default": "info"
                              },
                              "bodyText": {
                                "key": "bodyText",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Body Text",
                                "description": "Enter body text - can be HTML."
                              },
                              "confirmButtonText": {
                                "key": "confirmButtonText",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Confirm button text",
                                "description": "Enter confirm button text",
                                "default": "Yes"
                              },
                              "cancelButtonText": {
                                "key": "cancelButtonText",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Cancel button text",
                                "description": "Enter cancel button text",
                                "default": "No"
                              },
                              "footerHtml": {
                                "key": "footerHtml",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Footer",
                                "description": "Enter footer text. Html can be used"
                              }
                            },
                            "required": []
                          },
                          "widgetModal": {
                            "type": "object",
                            "properties": {
                              "type": {
                                "key": "type",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Widget Type",
                                "description": "Select widget type to connect or create new widget by type"
                              },
                              "widgetInstance": {
                                "key": "widgetInstance",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Widget Instance",
                                "description": "Select widget instance to open in modal window"
                              },
                              "createOrReference": {
                                "key": "createOrReference",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Create or Reference",
                                "description": "Select to create new widget by type or reference already created"
                              },
                              "widgetByType": {
                                "key": "widgetByType",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Widget by Type",
                                "description": "Select widget by type to define widget to be opened in modal on event execute"
                              },
                              "existingWidgetByType": {
                                "key": "existingWidgetByType",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Reference",
                                "description": "Select existing modal widget as reference"
                              }
                            },
                            "required": []
                          },
                          "confirmModal": {
                            "type": "object",
                            "properties": {
                              "title": {
                                "key": "title",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Title",
                                "description": "Define title text"
                              },
                              "iconType": {
                                "key": "iconType",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Type",
                                "description": "Select type of the confirmation",
                                "default": "info"
                              },
                              "bodyText": {
                                "key": "bodyText",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Body Text",
                                "description": "Enter body text - can be HTML."
                              }
                            },
                            "required": []
                          },
                          "windowUrlModal": {
                            "type": "object",
                            "title": "Window Url Modal",
                            "description": "Enter URL to be open in modal window",
                            "properties": {
                              "url": {
                                "type": "string",
                                "title": "Url",
                                "description": "Enter url to be opened in window modal"
                              },
                              "target": {
                                "type": "string",
                                "title": "Target",
                                "description": "Enter target to window open",
                                "default": "_blank"
                              },
                              "features": {
                                "type": "string",
                                "title": "Features",
                                "description": "Enter window open features.",
                                "default": "toolbar=yes,scrollbars=yes,resizable=yes,height=560,width=770"
                              }
                            }
                          },
                          "responseEvents": {
                            "type": "object",
                            "properties": {
                              "events": {
                                "type": "array",
                                "items": {
                                  "type": "object",
                                  "properties": {
                                    "eventName": {
                                      "key": "eventName",
                                      "type": "string",
                                      "readonly": false,
                                      "hidden": false,
                                      "title": "Publish Event Name",
                                      "description": "Define Event name to publish"
                                    },
                                    "contextObject": {
                                      "key": "contextObject",
                                      "type": "string",
                                      "readonly": false,
                                      "hidden": false,
                                      "title": "Context Object",
                                      "description": "Use expression to define Context Object in json form"
                                    }
                                  },
                                  "required": []
                                }
                              }
                            },
                            "required": []
                          }
                        },
                        "required": []
                      },
                      "replaceWidgetSettings": {
                        "type": "object",
                        "title": "Replace Widget",
                        "description": "Define how to replace existing widget content",
                        "properties": {
                          "widgetReplace": {
                            "type": "object",
                            "properties": {
                              "type": {
                                "key": "type",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Widget Type",
                                "description": "Select widget type to connect or create new widget by type"
                              },
                              "widgetInstance": {
                                "key": "widgetInstance",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Widget Instance",
                                "description": "Select widget instance to open in modal window"
                              },
                              "createOrReference": {
                                "key": "createOrReference",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Create or Reference",
                                "description": "Select to create new widget by type or reference already created"
                              },
                              "widgetByType": {
                                "key": "widgetByType",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Widget by Type",
                                "description": "Select widget by type to define widget to be opened in modal on event execute"
                              },
                              "existingWidgetByType": {
                                "key": "existingWidgetByType",
                                "type": "string",
                                "readonly": false,
                                "hidden": false,
                                "title": "Reference",
                                "description": "Select existing modal widget as reference"
                              }
                            }
                          },
                          "responseEvents": {
                            "type": "object",
                            "properties": {
                              "events": {
                                "type": "array",
                                "items": {
                                  "type": "object",
                                  "properties": {
                                    "eventName": {
                                      "key": "eventName",
                                      "type": "string",
                                      "readonly": false,
                                      "hidden": false,
                                      "title": "Publish Event Name",
                                      "description": "Define Event name to publish"
                                    },
                                    "contextObject": {
                                      "key": "contextObject",
                                      "type": "string",
                                      "readonly": false,
                                      "hidden": false,
                                      "title": "Context Object",
                                      "description": "Use expression to define Context Object in json form"
                                    }
                                  },
                                  "required": []
                                }
                              }
                            },
                            "required": []
                          }
                        }
                      }
                    },
                    "required": []
                  }
                },
                "required": []
              }
            }
          },
          "required": []
        },
        "onWebSocket": {
          "type": "object",
          "properties": {
            "connections": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "connectionType": {
                    "key": "connectionType",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Connection Type",
                    "description": "Internal type is to connect to Pipeline WS, while external is for outside world",
                    "default": "INTERNAL"
                  },
                  "url": {
                    "key": "url",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Url",
                    "description": "Define Web Socket server url"
                  },
                  "userName": {
                    "key": "userName",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "User Name",
                    "description": "Define username for connecting to Web Socket"
                  },
                  "password": {
                    "key": "password",
                    "type": "string",
                    "readonly": false,
                    "hidden": false,
                    "title": "Password",
                    "description": "Define password for connecting to Web Socket"
                  },
                  "actions": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "actionType": {
                          "key": "actionType",
                          "type": "string",
                          "readonly": false,
                          "hidden": false,
                          "title": "Action Type",
                          "description": "Select Web Socket action type",
                          "default": "SUBSCRIBE"
                        },
                        "topicName": {
                          "key": "topicName",
                          "type": "string",
                          "readonly": false,
                          "hidden": false,
                          "title": "Topic Name",
                          "description": "Define Topic name"
                        },
                        "headers": {
                          "key": "headers",
                          "type": "string",
                          "readonly": false,
                          "hidden": false,
                          "title": "Headers",
                          "description": "Use expression to define Headers as object (key/value) to be sent to EventBus"
                        },
                        "publishEvents": {
                          "type": "object",
                          "properties": {
                            "events": {
                              "type": "array",
                              "items": {
                                "type": "object",
                                "properties": {
                                  "eventName": {
                                    "key": "eventName",
                                    "type": "string",
                                    "readonly": false,
                                    "hidden": false,
                                    "title": "Event Name",
                                    "description": "Define name for the Event"
                                  },
                                  "contextObject": {
                                    "key": "contextObject",
                                    "type": "string",
                                    "readonly": false,
                                    "hidden": false,
                                    "title": "Context Object",
                                    "description": "Use expression to define Context Object in json form"
                                  }
                                },
                                "required": []
                              }
                            }
                          },
                          "required": []
                        }
                      },
                      "required": []
                    }
                  }
                },
                "required": []
              }
            }
          },
          "required": []
        },
        "styling": {
          "type": "object",
          "properties": {
            "card": {
              "type": "object",
              "properties": {
                "widgetCard": {
                  "key": "widgetCard",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Widget Card Class",
                  "description": "Enter class name for the widget card",
                  "default": "card"
                },
                "widgetCardHeader": {
                  "key": "widgetCardHeader",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Widget Card Header Class",
                  "description": "Enter class name for the widget card header",
                  "default": "card-header"
                },
                "widgetCardHeaderTitle": {
                  "key": "widgetCardHeaderTitle",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Widget Card Header Title",
                  "description": "Enter class name for the widget card header title",
                  "default": "card-title"
                },
                "widgetCardBody": {
                  "key": "widgetCardBody",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Widget Card Body Class",
                  "description": "Enter class name for the widget card body",
                  "default": "card-body"
                }
              },
              "required": []
            },
            "css": {
              "key": "css",
              "type": "string",
              "readonly": false,
              "hidden": false,
              "title": "Css",
              "description": "Define CSS styles for the widget"
            }
          },
          "required": []
        }
      },
      "type": "object",
      "required": []
    },
    "form": [
      {
        "tabs": [
          {
            "title": "On Load",
            "items": [
              {
                "readonly": false,
                "hidden": false,
                "placeholder": "Placeholder for: {}",
                "items": [
                  {
                    "type": "section",
                    "sectionContainerClass": "row",
                    "items": [
                      {
                        "readonly": false,
                        "hidden": false,
                        "childClass": "col-10",
                        "placeholder": "Placeholder for: {}",
                        "titleMap": [
                          {
                            "value": "REST_API",
                            "name": "Rest API"
                          },
                          {
                            "value": "EVENTS",
                            "name": "Events"
                          },
                          {
                            "name": "Static data",
                            "value": "STATIC_DATA"
                          }
                        ],
                        "type": "radiosinline",
                        "key": "onLoad.sourceType",
                        "guid": "7ea5a007-83f1-48f7-bf6a-dfe624deb93f",
                        "name": "radiosinline",
                        "title": "Source Type",
                        "description": "Select type of the data source",
                        "default": "REST_API"
                      },
                      {
                        "childClass": "col-2 my-auto",
                        "key": "onLoad.showLoader",
                        "title": "Show Loader",
                        "description": "Show loader on load of the widget",
                        "type": "checkbox",
                        "name": "checkbox"
                      }
                    ],
                    "name": "section"
                  },
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "items": [
                      {
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter Url",
                            "type": "text",
                            "guid": "0bbba9d6-bd7a-4155-a98b-da1c3f930727",
                            "name": "text",
                            "key": "onLoad.restApi.url",
                            "title": "Url",
                            "description": "Expression allowed to get context data"
                          },
                          {
                            "titleMap": [
                              {
                                "value": "GET",
                                "name": "GET"
                              },
                              {
                                "value": "POST",
                                "name": "POST"
                              },
                              {
                                "name": "PUT",
                                "value": "PUT"
                              },
                              {
                                "name": "DELETE",
                                "value": "DELETE"
                              }
                            ],
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "radiosinline",
                            "key": "onLoad.restApi.httpMethod",
                            "guid": "f6d62ece-88c1-4add-ba37-9fd277fbb153",
                            "name": "radiosinline",
                            "title": "HTTP Method",
                            "description": "Description for: radiosinline",
                            "default": "GET"
                          }
                        ],
                        "type": "section",
                        "guid": "7bbb2155-4623-4960-942d-305e8836e2bb",
                        "name": "section",
                        "sectionContainerClass": "row",
                        "sectionChildClass": "col"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Enter semicolon separated list of header Authorization=bearer ${user.token}$;",
                        "type": "textarea",
                        "key": "onLoad.restApi.headers",
                        "guid": "b6cb76f8-0c0a-4100-b183-c987d2a8fc5c",
                        "name": "textarea",
                        "title": "HTTP Headers",
                        "description": "Define Headers to be included in the request. Example: Authorization=bearer ${user.token}$;"
                      },
                      {
                        "style": "min-height:200px",
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "type": "ace",
                        "key": "onLoad.restApi.body",
                        "guid": "7c6496b3-8152-4f94-a59a-a9e1840f19ee",
                        "name": "ace",
                        "condition": "model.onLoad.restApi.httpMethod==='POST' || model.onLoad.restApi.httpMethod==='PUT'",
                        "title": "Body",
                        "description": "Define body for POST and PUT methods using expressions",
                        "default": "{}"
                      },
                      {
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "tabarray",
                            "add": "New",
                            "remove": "Delete",
                            "btnStyle": {
                              "remove": "btn-danger"
                            },
                            "title": "{{ value.eventName }}",
                            "items": [
                              {
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Enter event name",
                                    "type": "text",
                                    "key": "onLoad.restApi.responseEvents.events[].eventName",
                                    "guid": "f5eba383-d3d8-4705-9b5b-0f4fad849ffa",
                                    "name": "text",
                                    "title": "Publish Event Name",
                                    "description": "Define Event name to publish"
                                  },
                                  {
                                    "titleMap": [
                                      {
                                        "value": "OK",
                                        "name": "OK"
                                      },
                                      {
                                        "value": "ERROR",
                                        "name": "ERROR"
                                      },
                                      {
                                        "name": "SECURITY ISSUE",
                                        "value": "SECURITY_ISSUE"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "radiosinline",
                                    "key": "onLoad.restApi.responseEvents.events[].eventType",
                                    "guid": "e87d5663-a585-4459-8eb7-fad160dc105f",
                                    "name": "radiosinline",
                                    "title": "On Response Type",
                                    "description": "Select response type",
                                    "default": "OK"
                                  }
                                ],
                                "type": "section",
                                "guid": "b4092f49-c23a-4cc9-b37a-70dba93ec05d",
                                "name": "section",
                                "sectionContainerClass": "row",
                                "sectionChildClass": "col"
                              },
                              {
                                "style": "min-height:200px",
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Enter context object expression",
                                "type": "ace",
                                "key": "onLoad.restApi.responseEvents.events[].contextObject",
                                "guid": "d0d1ff17-366c-4455-b235-1f46bbf4a9c4",
                                "name": "ace",
                                "title": "Context Object",
                                "description": "Use expression to define Context Object in json form",
                                "default": "{}"
                              }
                            ],
                            "key": "onLoad.restApi.responseEvents.events",
                            "guid": "96dfbce8-20bc-453b-b80b-1182f996c23f",
                            "description": "Define actions on response event",
                            "name": "tabarray"
                          }
                        ],
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "type": "fieldset",
                        "key": "onLoad.restApi.responseEvents",
                        "guid": "f0aaf6dd-96ee-4a0c-985a-979ddc43e959",
                        "name": "fieldset",
                        "title": "Execute Events after response received",
                        "description": "Define on REST API response events",
                        "htmlClass": "card"
                      }
                    ],
                    "type": "fieldset",
                    "key": "onLoad.restApi",
                    "guid": "94923c72-0630-475c-9422-5bb95b272690",
                    "name": "fieldset",
                    "condition": "model.onLoad.sourceType==='REST_API'",
                    "title": "Rest API",
                    "htmlClass": "card"
                  },
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "items": [
                      {
                        "style": "min-height:300px",
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Enter static data",
                        "type": "ace",
                        "key": "onLoad.staticData.data",
                        "guid": "bcd5a41d-0c32-4f44-9406-d9d345e502b3",
                        "name": "ace",
                        "items": [],
                        "title": "Static Data",
                        "description": "Enter Static data to be used in Widget"
                      }
                    ],
                    "type": "fieldset",
                    "key": "onLoad.staticData",
                    "guid": "eeeb2312-4602-4e11-b5d8-0dbba34fa1c0",
                    "title": "Static Data",
                    "description": "Define Static data used in Widget",
                    "name": "fieldset",
                    "condition": "model.onLoad.sourceType==='STATIC_DATA'",
                    "htmlClass": "card"
                  },
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "items": [
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "type": "tabarray",
                        "add": "New",
                        "remove": "Delete",
                        "btnStyle": {
                          "remove": "btn-danger"
                        },
                        "title": "{{ value.eventName }}",
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter Event Name",
                            "type": "text",
                            "key": "onLoad.eventPublish.events[].eventName",
                            "guid": "c3bc1b8b-71e3-4a0d-973e-a3196b30a001",
                            "name": "text",
                            "title": "Publish Event Name",
                            "description": "Define name for the event to publish"
                          },
                          {
                            "style": "min-height:200px",
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "ace",
                            "key": "onLoad.eventPublish.events[].contextObject",
                            "guid": "72f79e77-eea2-48b5-8641-ef0c6214ec3e",
                            "name": "ace",
                            "title": "Context Object",
                            "description": "Use expression to define Context Object in json form",
                            "default": "{}"
                          }
                        ],
                        "key": "onLoad.eventPublish.events",
                        "guid": "23ed0e73-9ebe-448f-828a-0a19fc4df141",
                        "description": "Define events to publish",
                        "name": "tabarray"
                      }
                    ],
                    "type": "fieldset",
                    "key": "onLoad.eventPublish",
                    "guid": "09412c8a-fc9c-45ba-b352-46ba2b498046",
                    "title": "Publish Events",
                    "description": "Define events to be published",
                    "name": "fieldset",
                    "condition": "model.onLoad.sourceType==='EVENTS'",
                    "htmlClass": "card"
                  }
                ],
                "type": "fieldset",
                "key": "onLoad",
                "guid": "0bba7992-7831-48a8-b9b6-3cfc10e901c7",
                "name": "fieldset",
                "title": "On Load Definition",
                "description": "Define OnLoad event",
                "htmlClass": "card"
              }
            ],
            "_id": "ON_LOAD"
          },
          {
            "items": [
              {
                "readonly": false,
                "hidden": false,
                "placeholder": "Placeholder for: {}",
                "items": [
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "titleMap": [
                      {
                        "value": "REST_API",
                        "name": "Rest API"
                      },
                      {
                        "name": "Events",
                        "value": "EVENTS"
                      }
                    ],
                    "type": "radiosinline",
                    "key": "onSubmit.destinationType",
                    "guid": "3b8301c5-f2cd-4555-9028-f6c7b20685fa",
                    "name": "radiosinline",
                    "title": "Destination Type",
                    "description": "Select type of the data destination",
                    "default": "REST_API"
                  },
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "items": [
                      {
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter Url",
                            "type": "textarea",
                            "key": "onSubmit.restApi.url",
                            "guid": "e6fc9bf9-d81f-4cf6-bdbc-f9654e9fa3c3",
                            "name": "textarea",
                            "title": "Url",
                            "description": "Expression allowed to get context data"
                          },
                          {
                            "titleMap": [
                              {
                                "value": "GET",
                                "name": "GET"
                              },
                              {
                                "value": "POST",
                                "name": "POST"
                              },
                              {
                                "name": "PUT",
                                "value": "PUT"
                              },
                              {
                                "name": "DELETE",
                                "value": "DELETE"
                              }
                            ],
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "radiosinline",
                            "key": "onSubmit.restApi.httpMethod",
                            "guid": "686af75b-5ed2-4fa7-83b0-317301727ad3",
                            "name": "radiosinline",
                            "title": "HTTP Method",
                            "description": "Define HTTP Method to be used",
                            "default": "GET"
                          },
                          {
                            "titleMap": [
                              {
                                "value": "Json",
                                "name": "Json"
                              },
                              {
                                "value": "formData",
                                "name": "Form Data"
                              }
                            ],
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Select content type",
                            "type": "radiosinline",
                            "key": "onSubmit.restApi.contentType",
                            "guid": "4d9528f1-e958-4610-8756-92d44d8c4374",
                            "name": "radiosinline",
                            "condition": "model.onSubmit.restApi.httpMethod==='POST'"
                          }
                        ],
                        "type": "section",
                        "guid": "6b0e4519-6089-409a-80e5-43dcec7b3386",
                        "name": "section",
                        "sectionType": "section",
                        "sectionContainerClass": "row",
                        "sectionChildClass": "col"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "type": "textarea",
                        "key": "onSubmit.restApi.headers",
                        "guid": "3b564f21-24cd-4596-aec1-7414e8576435",
                        "name": "textarea",
                        "placeholder": "Enter semicolon separated list of header Authorization=bearer ${user.token}$;",
                        "title": "HTTP Headers",
                        "description": "Define Headers to be included in the request. Example: Authorization=bearer ${user.token}$;"
                      },
                      {
                        "style": "min-height:200px",
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "type": "ace",
                        "key": "onSubmit.restApi.body",
                        "guid": "8fa791f3-c020-45e1-8f3b-751f6cdbe846",
                        "name": "ace",
                        "condition": "model.onSubmit.restApi.httpMethod==='POST' || model.onSubmit.restApi.httpMethod==='PUT'",
                        "title": "Body",
                        "description": "Define body for POST and PUT methods using expressions"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "tabarray",
                            "add": "New",
                            "remove": "Delete",
                            "btnStyle": {
                              "remove": "btn-danger"
                            },
                            "title": "{{ value.eventName }}",
                            "items": [
                              {
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Enter Event Name",
                                    "type": "text",
                                    "key": "onSubmit.restApi.responseEvents.events[].eventName",
                                    "guid": "01b79ecd-f75e-4667-b775-5900d144e688",
                                    "name": "text",
                                    "title": "Publish Event Name",
                                    "description": "Define Event Name"
                                  },
                                  {
                                    "titleMap": [
                                      {
                                        "value": "OK",
                                        "name": "OK"
                                      },
                                      {
                                        "value": "ERROR",
                                        "name": "ERROR"
                                      },
                                      {
                                        "name": "SECURITY ISSUE",
                                        "value": "SECURITY_ISSUE"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "radiosinline",
                                    "key": "onSubmit.restApi.responseEvents.events[].eventType",
                                    "guid": "30c4bd7e-2ea2-4d4b-817b-a7b499885fd2",
                                    "name": "radiosinline",
                                    "title": "On Response Type",
                                    "description": "Select Response Type",
                                    "default": "OK"
                                  }
                                ],
                                "type": "section",
                                "guid": "f84321dd-2184-42ad-8ebe-8ad2a39aef93",
                                "name": "section",
                                "sectionContainerClass": "row",
                                "sectionChildClass": "col"
                              },
                              {
                                "style": "min-height:200px",
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "ace",
                                "key": "onSubmit.restApi.responseEvents.events[].contextObject",
                                "guid": "1fde9bc7-4171-48ca-a617-7caa44ec81b4",
                                "name": "ace",
                                "title": "Context Object",
                                "description": "Use expression to define Context Object in json form",
                                "default": "{}"
                              }
                            ],
                            "key": "onSubmit.restApi.responseEvents.events",
                            "guid": "3f293d76-3e40-4891-b502-6db8d421f822",
                            "description": "Define actions on response event",
                            "name": "tabarray"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onSubmit.restApi.responseEvents",
                        "guid": "d3ca96ac-d74e-4c5c-8450-08d42e6f0205",
                        "title": "Execute Events after response received",
                        "description": "Define on REST API response events",
                        "name": "fieldset",
                        "htmlClass": "card"
                      }
                    ],
                    "type": "fieldset",
                    "key": "onSubmit.restApi",
                    "guid": "fe717af3-228e-474d-b203-3792e601264d",
                    "title": "Rest API",
                    "description": "Define Rest API configuration",
                    "name": "fieldset",
                    "condition": "model.onSubmit.destinationType==='REST_API'",
                    "htmlClass": "card"
                  },
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "items": [
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "type": "tabarray",
                        "add": "New",
                        "remove": "Delete",
                        "btnStyle": {
                          "remove": "btn-danger"
                        },
                        "title": "{{ value.eventName }}",
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter Event Name",
                            "type": "text",
                            "key": "onSubmit.eventPublish.events[].eventName",
                            "guid": "bdedb780-de22-48a8-9af8-b6d70348d1de",
                            "name": "text",
                            "title": "Event Name",
                            "description": "Define name for the publish event"
                          },
                          {
                            "style": "min-height:200px",
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "ace",
                            "key": "onSubmit.eventPublish.events[].contextObject",
                            "guid": "77a2907d-6d6f-4645-8a74-b31bb2d366bf",
                            "name": "ace",
                            "title": "Context Object",
                            "description": "Use expression to define Context Object in json form",
                            "default": "{}"
                          }
                        ],
                        "key": "onSubmit.eventPublish.events",
                        "guid": "2e837947-1556-4d2c-a443-97b59b46defa",
                        "description": "Define events to be published",
                        "name": "tabarray"
                      }
                    ],
                    "type": "fieldset",
                    "key": "onSubmit.eventPublish",
                    "guid": "c60d6669-ecd7-475c-b47c-78db3cc9c0f3",
                    "title": "Events",
                    "description": "Define Events configuration",
                    "name": "fieldset",
                    "condition": "model.onSubmit.destinationType==='EVENTS'",
                    "htmlClass": "card"
                  }
                ],
                "type": "fieldset",
                "key": "onSubmit",
                "guid": "6205fb5d-fc77-4f7e-83ad-61436b8f7398",
                "title": "On Submit Definition",
                "description": "Define actions on Submit",
                "name": "fieldset",
                "htmlClass": "card"
              }
            ],
            "title": "On Submit",
            "_id": "ON_SUBMIT"
          },
          {
            "items": [
              {
                "readonly": false,
                "hidden": false,
                "placeholder": "Placeholder for: {}",
                "items": [
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "type": "tabarray",
                    "add": "New",
                    "remove": "Delete",
                    "btnStyle": {
                      "remove": "btn-danger"
                    },
                    "title": "{{ value.eventName }}",
                    "items": [
                      {
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter event name",
                            "type": "text",
                            "key": "onEvent.events[].eventName",
                            "guid": "84cae54e-f903-4521-a53d-b6ed39983fdd",
                            "name": "text",
                            "title": "Event Name",
                            "description": "Define event name to subscribe"
                          },
                          {
                            "items": [
                              {
                                "titleMap": [
                                  {
                                    "value": "DASHBOARD_WIDE",
                                    "name": "Dashboard wide"
                                  },
                                  {
                                    "value": "WIDGET_WIDE",
                                    "name": "Widget wide"
                                  },
                                  {
                                    "value": "CURRENT_WIDGET",
                                    "name": "Current Widget"
                                  },
                                  {
                                    "name": "Widget specific",
                                    "value": "WIDGET_SPECIFIC"
                                  }
                                ],
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "radiosinline",
                                "key": "onEvent.events[].eventType",
                                "guid": "ef4479d9-d735-4acd-8d10-f537c4f03e3c",
                                "name": "radiosinline",
                                "title": "Event Type",
                                "description": "Select event type to subscribe",
                                "default": "DASHBOARD_WIDE"
                              }
                            ],
                            "type": "section",
                            "guid": "7771e13d-155d-4d83-ab23-5838f0167445",
                            "name": "section"
                          }
                        ],
                        "type": "section",
                        "guid": "82dad40d-902c-4388-afd5-40d0a8b74753",
                        "name": "section",
                        "sectionContainerClass": "row",
                        "sectionChildClass": "col"
                      },
                      {
                        "items": [
                          {
                            "items": [
                              {
                                "titleMap": [
                                  {
                                    "value": "WIDGET_TYPE",
                                    "name": "Widget Type"
                                  },
                                  {
                                    "value": "WIDGET_INSTANCE",
                                    "name": "Widget Instance"
                                  }
                                ],
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "radiosinline",
                                "key": "onEvent.events[].widgetSpecificType",
                                "guid": "c36e28ab-058c-4a5d-9251-1fb389f5a05d",
                                "name": "radiosinline",
                                "title": "Widget Specific Type",
                                "default": "WIDGET_TYPE"
                              },
                              {
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Select widget by type",
                                    "type": "select",
                                    "multiSelect": true,
                                    "key": "onEvent.events[].widgetSpecificByType",
                                    "guid": "7dc14569-0fe4-4ff8-a1d4-b1e6e25c897f",
                                    "options": {
                                      "callback": "getWidgetsByType"
                                    },
                                    "name": "select",
                                    "condition": "model.onEvent.events[form.arrayIndex].widgetSpecificType==='WIDGET_TYPE'",
                                    "title": "Widget Specific by Type",
                                    "description": "Select to subscribe by one or more Types"
                                  },
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "select",
                                    "multiSelect": true,
                                    "key": "onEvent.events[].widgetSpecificByInstance",
                                    "guid": "7b2c1660-1651-4731-b13a-97eb8e62a76d",
                                    "options": {
                                      "callback": "getWidgetsByInstance"
                                    },
                                    "name": "select",
                                    "condition": "model.onEvent.events[form.arrayIndex].widgetSpecificType==='WIDGET_INSTANCE'",
                                    "title": "Widget Specific by Instance",
                                    "description": "Select to subscribe by one or more Instances"
                                  }
                                ],
                                "type": "section",
                                "guid": "14b20161-d0be-436d-ac50-65496727f720",
                                "name": "section"
                              }
                            ],
                            "type": "section",
                            "guid": "877f7be3-fb1f-48cd-94c0-5f89253a636f",
                            "name": "section",
                            "sectionType": "fieldset",
                            "sectionContainerClass": "row",
                            "sectionChildClass": "col",
                            "condition": "model.onEvent.events[form.arrayIndex].eventType==='WIDGET_SPECIFIC'",
                            "title": "Widget Specific"
                          }
                        ],
                        "type": "section",
                        "guid": "e0dcb406-5d56-48d0-9d83-6963003ef9c0",
                        "name": "section"
                      },
                      {
                        "items": [
                          {
                            "titleMap": [
                              {
                                "value": "APPLY_DATA",
                                "name": "Apply data"
                              },
                              {
                                "value": "REST_API",
                                "name": "Rest API"
                              },
                              {
                                "value": "LOCAL_STORAGE",
                                "name": "Local storage"
                              },
                              {
                                "name": "Widget Settings",
                                "value": "WIDGET_SETTINGS"
                              },
                              {
                                "name": "Navigation Settings",
                                "value": "NAVIGATION_SETTINGS"
                              },
                              {
                                "name": "Event publish",
                                "value": "EVENT_PUBLISH"
                              },
                              {
                                "name": "Web Socket",
                                "value": "WEB_SOCKET"
                              },
                              {
                                "name": "Notify Message",
                                "value": "NOTIFY_MESSAGE"
                              }
                            ],
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "radiosinline",
                            "key": "onEvent.events[].executeType",
                            "guid": "c538e0ea-5bbd-4d06-b097-36c3da4bcd6d",
                            "name": "radiosinline",
                            "childClass": "col-10",
                            "title": "Execute type on event",
                            "description": "Select type of event execution",
                            "default": "APPLY_DATA"
                          },
                          {
                            "key": "onEvent.events[].hideLoading",
                            "type": "radiosinline",
                            "titleMap": [
                              {
                                "value": "true",
                                "name": "Show"
                              },
                              {
                                "value": "false",
                                "name": "Hide"
                              }
                            ],
                            "childClass": "col-2",
                            "title": "Hide Loading",
                            "description": "Hide loader on the widget",
                            "name": "radiosinline"
                          }
                        ],
                        "type": "section",
                        "guid": "6eaa3e8a-22f6-4f90-8b36-af1c55c7f3c5",
                        "sectionContainerClass": "row",
                        "name": "section"
                      },
                      {
                        "key": "onEvent.events[].runIf",
                        "title": "Run If",
                        "description": "Event will be executed only if RunIf expression is satisfied or empty. Example: !IS_NULL($data._id$)",
                        "type": "text",
                        "name": "text"
                      },
                      {
                        "type": "section",
                        "name": "section",
                        "sectionContainerClass": "row",
                        "sectionChildClass": "col",
                        "items": [
                          {
                            "key": "onEvent.events[].notifyMessage",
                            "type": "fieldset",
                            "htmlClass": "card",
                            "showHide": false,
                            "items": [
                              {
                                "type": "section",
                                "sectionContainerClass": "row",
                                "sectionChildClass": "col",
                                "items": [
                                  {
                                    "key": "onEvent.events[].notifyMessage.message",
                                    "childClass": "col-8",
                                    "title": "Message",
                                    "description": "Define message with expression",
                                    "type": "text",
                                    "name": "text"
                                  },
                                  {
                                    "key": "onEvent.events[].notifyMessage.type",
                                    "title": "Type",
                                    "description": "Select type of the message",
                                    "default": "Primary",
                                    "type": "select",
                                    "titleMap": [
                                      {
                                        "name": "Primary",
                                        "value": "Primary"
                                      },
                                      {
                                        "name": "Success",
                                        "value": "Success"
                                      },
                                      {
                                        "name": "Warning",
                                        "value": "Warning"
                                      },
                                      {
                                        "name": "Danger",
                                        "value": "Danger"
                                      }
                                    ],
                                    "name": "select"
                                  },
                                  {
                                    "key": "onEvent.events[].notifyMessage.timeout",
                                    "title": "Timeout",
                                    "description": "Define timeout to be closed in milliseconds",
                                    "default": 3000,
                                    "type": "number",
                                    "name": "number"
                                  }
                                ],
                                "name": "section"
                              }
                            ],
                            "title": "Notify Message",
                            "name": "fieldset"
                          }
                        ]
                      },
                      {
                        "htmlClass": "card",
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "tabarray",
                            "add": "New",
                            "remove": "Delete",
                            "btnStyle": {
                              "remove": "btn-danger"
                            },
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "titleMap": [
                                  {
                                    "value": "INTERNAL",
                                    "name": "Internal"
                                  },
                                  {
                                    "value": "EXTERNAL",
                                    "name": "External"
                                  }
                                ],
                                "type": "radiosinline",
                                "key": "onEvent.events[].websocket.connections[].connectionType",
                                "guid": "d81cc0bc-48f8-4388-ba7e-a0c5e8e586be",
                                "name": "radiosinline",
                                "title": "Connection Type",
                                "description": "Select connection type",
                                "default": "INTERNAL"
                              },
                              {
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "text",
                                    "key": "onEvent.events[].websocket.connections[].url",
                                    "guid": "19ec3b3c-782a-43da-8d56-1468a65c5a0f",
                                    "name": "text",
                                    "title": "Url",
                                    "description": "Description for: text"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter Username",
                                        "type": "text",
                                        "key": "onEvent.events[].websocket.connections[].userName",
                                        "guid": "d102e0f2-67ae-47f4-871d-1f26407f1191",
                                        "name": "text",
                                        "title": "User Name",
                                        "description": "Define User name to connect to Web Socket"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter Password",
                                        "type": "password",
                                        "key": "onEvent.events[].websocket.connections[].password",
                                        "guid": "cb3ee4e4-bcf7-4f6c-9c7f-1543634aea54",
                                        "name": "password",
                                        "title": "Password",
                                        "description": "Define Password to connect to Web Socket"
                                      }
                                    ],
                                    "type": "section",
                                    "guid": "98007449-47d1-471f-a3af-80256bc69603",
                                    "name": "section",
                                    "sectionType": "section",
                                    "sectionContainerClass": "row",
                                    "sectionChildClass": "col"
                                  }
                                ],
                                "type": "section",
                                "guid": "4f3f4ba2-7629-4258-a19a-3c85a7d6820f",
                                "name": "section",
                                "sectionType": "fieldset",
                                "title": "Connection Settings",
                                "condition": "model.onEvent.events[form.arrayIndex].websocket.connections[form.arrayIndex].connectionType==='EXTERNAL'"
                              },
                              {
                                "items": [
                                  {
                                    "type": "tabarray",
                                    "add": "New",
                                    "remove": "Delete",
                                    "btnStyle": {
                                      "remove": "btn-danger"
                                    },
                                    "title": "{{ 'Tab '+$index }}",
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "titleMap": [
                                          {
                                            "value": "SEND",
                                            "name": "Send"
                                          },
                                          {
                                            "name": "Publish",
                                            "value": "PUBLISH"
                                          }
                                        ],
                                        "type": "radiosinline",
                                        "key": "onEvent.events[].websocket.connections[].actions[].actionType",
                                        "guid": "dd5c8519-6858-4842-b640-ceeb5251659e",
                                        "name": "radiosinline",
                                        "title": "Action Type",
                                        "description": "Select Action type",
                                        "default": "PUBLISH"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter Topic Name",
                                        "type": "text",
                                        "key": "onEvent.events[].websocket.connections[].actions[].topicName",
                                        "guid": "6fe4bab4-2dc3-4e80-a065-dbbcd6267554",
                                        "name": "text",
                                        "title": "Topic Name",
                                        "description": "Define Topic Name"
                                      },
                                      {
                                        "style": "min-height:200px",
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "ace",
                                        "key": "onEvent.events[].websocket.connections[].actions[].contextObject",
                                        "guid": "a898cf34-e8d8-4b91-b4e0-6f85f3f864c4",
                                        "name": "ace",
                                        "title": "Context Object",
                                        "description": "Use expression to define Context Object in json form"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "items": [
                                          {
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Placeholder for: {}",
                                            "type": "tabarray",
                                            "add": "New",
                                            "remove": "Delete",
                                            "btnStyle": {
                                              "remove": "btn-danger"
                                            },
                                            "items": [
                                              {
                                                "readonly": false,
                                                "hidden": false,
                                                "placeholder": "Enter Event name",
                                                "type": "text",
                                                "key": "onEvent.events[].websocket.connections[].actions[].publishEvents.events[].eventName",
                                                "guid": "3895511b-6ec5-44f9-b7d9-07f5c0558b99",
                                                "name": "text",
                                                "title": "Event Name",
                                                "description": "Define Event Name"
                                              },
                                              {
                                                "style": "min-height:200px",
                                                "readonly": false,
                                                "hidden": false,
                                                "placeholder": "Placeholder for: {}",
                                                "type": "ace",
                                                "key": "onEvent.events[].websocket.connections[].actions[].publishEvents.events[].contextObject",
                                                "guid": "2f9d4a21-fca4-47b5-afec-e7cd378c444a",
                                                "name": "ace",
                                                "title": "Context Object",
                                                "description": "Use expression to define Context Object in json form"
                                              }
                                            ],
                                            "key": "onEvent.events[].websocket.connections[].actions[].publishEvents.events",
                                            "guid": "5cebb3b6-abf4-427f-ad6d-2fc25a64130c",
                                            "name": "tabarray",
                                            "title": "{{ 'Tab '+$index }}",
                                            "description": "Define Events to publish on receiving from Web Socket topic"
                                          }
                                        ],
                                        "type": "fieldset",
                                        "key": "onEvent.events[].websocket.connections[].actions[].publishEvents",
                                        "guid": "93a396ba-a29e-46cf-9d2a-1448e23adfcc",
                                        "name": "fieldset",
                                        "condition": "model.onEvent.events[form.arrayIndex].websocket.connections[form.arrayIndex].actions[form.arrayIndex].actionType==='SEND'",
                                        "title": "Publish Local Events",
                                        "description": "Publish Events on message reply from Send to topic"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "key": "onEvent.events[].websocket.connections[].actions",
                                    "guid": "911259e2-f288-4939-8674-cec3afd11d2b",
                                    "name": "tabarray",
                                    "description": "Description for: tabarray"
                                  }
                                ],
                                "type": "section",
                                "guid": "0f1886a5-64bf-4017-9d76-0438fbc89016",
                                "name": "section",
                                "sectionType": "fieldset",
                                "title": "Action Settings"
                              }
                            ],
                            "key": "onEvent.events[].websocket.connections",
                            "guid": "07a6f23f-1f81-409a-89b6-195159132b57",
                            "name": "tabarray",
                            "title": "{{ 'Tab '+$index }}",
                            "description": "Description for: tabarray"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onEvent.events[].websocket",
                        "guid": "10d50515-a53e-47e2-bf2e-76c68de166b4",
                        "name": "fieldset",
                        "condition": "model.onEvent.events[form.arrayIndex].executeType==='WEB_SOCKET'",
                        "title": "Web Socket",
                        "description": "Define Web Socket activities"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "key": "onEvent.events[].applyData.contextType",
                            "guid": "c08064ab-5a99-41f7-9b84-9faf2b1e3238",
                            "type": "radiosinline",
                            "title": "Context Type",
                            "description": "Where to apply the data. Should it be main data or lookup data used by lookup controls",
                            "default": "MAIN",
                            "titleMap": [
                              {
                                "name": "MAIN",
                                "value": "MAIN"
                              },
                              {
                                "name": "LOOKUP",
                                "value": "LOOKUP"
                              }
                            ],
                            "name": "radiosinline"
                          },
                          {
                            "style": "min-height:200px",
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "ace",
                            "key": "onEvent.events[].applyData.contextObject",
                            "guid": "c08064ab-5a99-41f7-9b84-9faf2b1e3278",
                            "name": "ace",
                            "title": "Context Object",
                            "description": "Define context object to be applied on model"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onEvent.events[].applyData",
                        "guid": "b236b0c1-5e94-4678-a733-56645cc1a30d",
                        "title": "Apply Data",
                        "description": "Define how to apply incoming data",
                        "name": "fieldset",
                        "condition": "model.onEvent.events[form.arrayIndex].executeType==='APPLY_DATA'",
                        "htmlClass": "card"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Enter Url",
                                "type": "textarea",
                                "key": "onEvent.events[].restApi.url",
                                "guid": "e0cdec7a-5919-4602-9cf8-88b68a8c6614",
                                "name": "textarea",
                                "title": "Url",
                                "description": "Expression allowed to get context data",
                                "childClass":"col-6"
                              },
                              {
                                "titleMap": [
                                  {
                                    "value": "GET",
                                    "name": "GET"
                                  },
                                  {
                                    "value": "POST",
                                    "name": "POST"
                                  },
                                  {
                                    "name": "PUT",
                                    "value": "PUT"
                                  },
                                  {
                                    "name": "DELETE",
                                    "value": "DELETE"
                                  }
                                ],
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "radiosinline",
                                "key": "onEvent.events[].restApi.httpMethod",
                                "guid": "0a0b53a8-5444-44f6-a3ca-dad63442733f",
                                "name": "radiosinline",
                                "title": "HTTP Method",
                                "description": "Define HTTP Method to be used",
                                "default": "GET"
                              },
                              {
                                "titleMap":[
                                  {
                                    "value":"download",
                                    "name":"Download?"
                                  }
                                ],
                                "readonly": false,
                                "hidden": false,
                                "type": "radiosinline",
                                "key": "onEvent.events[].restApi.download",
                                "guid": "4563fede-615d-23d1-1812-09f8574a344b",
                                "name": "radiosinline",
                                "condition": "model.onEvent.events[form.arrayIndex].executeType==='REST_API' && (model.onEvent.events[form.arrayIndex].restApi.httpMethod==='GET')"

                              },
                              {
                                "titleMap": [
                                  {
                                    "value": "Json",
                                    "name": "Json"
                                  },
                                  {
                                    "value": "formData",
                                    "name": "Form Data"
                                  }
                                ],
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Select content type",
                                "type": "radiosinline",
                                "key": "onEvent.events[].restApi.contentType",
                                "guid": "8f6335de-6a5d-47d1-8812-09c857da684b",
                                "name": "radiosinline",
                                "condition": "model.onEvent.events[form.arrayIndex].executeType==='REST_API' && (model.onEvent.events[form.arrayIndex].restApi.httpMethod==='POST')"
                              }
                            ],
                            "type": "section",
                            "guid": "60b5c768-7ff9-42b2-a8df-da6262c8470c",
                            "name": "section",
                            "sectionContainerClass": "row",
                            "sectionChildClass": "col"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "type": "textarea",
                            "key": "onEvent.events[].restApi.headers",
                            "guid": "04857bec-4ae0-4507-9450-8b602bc3f2ab",
                            "name": "textarea",
                            "placeholder": "Enter semicolon separated list of header Authorization=bearer ${user.token}$;",
                            "title": "HTTP Headers",
                            "description": "Define Headers to be included in the request. Example: Authorization=bearer ${user.token}$;"
                          },
                          {
                            "style": "min-height:200px",
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "ace",
                            "key": "onEvent.events[].restApi.body",
                            "guid": "98de7a81-12e5-44cd-bba2-7146a81d970a",
                            "name": "ace",
                            "condition": "model.onEvent.events[form.arrayIndex].executeType==='REST_API' && (model.onEvent.events[form.arrayIndex].restApi.httpMethod==='POST' || model.onEvent.events[form.arrayIndex].restApi.httpMethod==='PUT')",
                            "title": "Body",
                            "description": "Define body for POST and PUT methods using expressions",
                            "default": "{}"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "tabarray",
                                "add": "New",
                                "remove": "Delete",
                                "btnStyle": {
                                  "remove": "btn-danger"
                                },
                                "title": "{{ value.eventName }}",
                                "items": [
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter Event Name",
                                        "type": "text",
                                        "key": "onEvent.events[].restApi.responseEvents.events[].eventName",
                                        "guid": "5c6fe25a-02e8-4895-bcaa-c6419dab038e",
                                        "name": "text",
                                        "title": "Publish Event Name",
                                        "description": "Define Event Name"
                                      },
                                      {
                                        "titleMap": [
                                          {
                                            "value": "OK",
                                            "name": "OK"
                                          },
                                          {
                                            "value": "ERROR",
                                            "name": "ERROR"
                                          },
                                          {
                                            "name": "SECURITY ISSUE",
                                            "value": "SECURITY_ISSUE"
                                          }
                                        ],
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "radiosinline",
                                        "key": "onEvent.events[].restApi.responseEvents.events[].eventType",
                                        "guid": "c0b34d0b-c000-4937-be66-ea9f6fd0ec82",
                                        "name": "radiosinline",
                                        "title": "On Response Type",
                                        "description": "Select Response Type",
                                        "default": "OK"
                                      }
                                    ],
                                    "type": "section",
                                    "guid": "e1de63e6-71f4-489f-8b2a-5c421c26779a",
                                    "name": "section",
                                    "sectionContainerClass": "row",
                                    "sectionChildClass": "col"
                                  },
                                  {
                                    "style": "min-height:200px",
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "ace",
                                    "key": "onEvent.events[].restApi.responseEvents.events[].contextObject",
                                    "guid": "9debe7e9-4bcd-4cbb-bb10-0543aaaef4bb",
                                    "name": "ace",
                                    "title": "Context Object",
                                    "description": "Use expression to define Context Object in json form",
                                    "default": "{}"
                                  }
                                ],
                                "key": "onEvent.events[].restApi.responseEvents.events",
                                "guid": "aa907a0b-6376-46f2-90f6-4cd2a1154643",
                                "description": "Define actions on response event",
                                "name": "tabarray"
                              }
                            ],
                            "type": "fieldset",
                            "key": "onEvent.events[].restApi.responseEvents",
                            "guid": "60f2b04b-0ee3-4f98-884e-a42675fcd851",
                            "title": "Execute Events after response received",
                            "description": "Define on REST API response events",
                            "name": "fieldset"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onEvent.events[].restApi",
                        "guid": "3adbd5c6-49bc-4103-8f9b-5cce9e770b0c",
                        "title": "Rest API",
                        "description": "Define Rest API configuration",
                        "name": "fieldset",
                        "condition": "model.onEvent.events[form.arrayIndex].executeType==='REST_API'",
                        "htmlClass": "card"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "key": "onEvent.events[].localStorage.contextType",
                            "guid": "1238064ab-5a99-41f7-9b84-9faf2b1e3238",
                            "type": "radiosinline",
                            "title": "Context Type",
                            "description": "Where to apply the data. Should it be main data or lookup data used by lookup controls",
                            "default": "MAIN",
                            "titleMap": [
                              {
                                "name": "LOAD",
                                "value": "LOAD"
                              },
                              {
                                "name": "STORE",
                                "value": "STORE"
                              },
                              {
                                "name": "REMOVE",
                                "value": "REMOVE"
                              }
                            ],
                            "name": "radiosinline"
                          },
                          {
                            "type": "section",
                            "sectionContainerClass": "row",
                            "items": [
                              {
                                "key": "onEvent.events[].localStorage.keyName",
                                "type": "text",
                                "guid": "fasfasfw-5a99-41f7-9b84-9faf2b1e3278",
                                "childClass": "col",
                                "title": "Key name",
                                "description": "Enter key name used as key in local storage",
                                "name": "text"
                              },
                              {
                                "key": "onEvent.events[].localStorage.ttl",
                                "type": "text",
                                "childClass": "col-2",
                                "guid": "gawwew-fqwe-41f7-2323-9faf2b1e3278",
                                "condition": "model.onEvent.events[form.arrayIndex].localStorage.contextType==='STORE'",
                                "title": "TTL",
                                "description": "Enter Time to live in milliseconds.",
                                "name": "text"
                              }
                            ],
                            "name": "section"
                          },
                          {
                            "style": "min-height:200px",
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "ace",
                            "key": "onEvent.events[].localStorage.contextObject",
                            "guid": "ct1064ab-5a99-41f7-9b84-9faf2b1e3278",
                            "name": "ace",
                            "title": "Context Object",
                            "description": "Define context object to be applied on model",
                            "condition": "model.onEvent.events[form.arrayIndex].localStorage.contextType==='STORE'"
                          },
                          {
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "tabarray",
                                "add": "New",
                                "remove": "Delete",
                                "btnStyle": {
                                  "remove": "btn-danger"
                                },
                                "title": "{{ value.eventName }}",
                                "items": [
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter event name",
                                        "type": "text",
                                        "key": "onEvent.events[].localStorage.responseEvents.events[].eventName",
                                        "guid": "treba383-were-5t5t-gwew-0f4fad849ffa",
                                        "name": "text",
                                        "title": "Publish Event Name",
                                        "description": "Define Event name to publish"
                                      }
                                    ],
                                    "type": "section",
                                    "guid": "rty92f49-tr53-gsdg-ht4r-70dba93ec05d",
                                    "name": "section",
                                    "sectionContainerClass": "row",
                                    "sectionChildClass": "col"
                                  },
                                  {
                                    "style": "min-height:200px",
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Enter context object expression",
                                    "type": "ace",
                                    "key": "onEvent.events[].localStorage.responseEvents.events[].contextObject",
                                    "guid": "rrd1ff17-2344-egwe-4545-1f46bbf4a9c4",
                                    "name": "ace",
                                    "title": "Context Object",
                                    "description": "Use expression to define Context Object in json form",
                                    "default": "{}"
                                  }
                                ],
                                "key": "onEvent.events[].localStorage.responseEvents.events",
                                "guid": "96dtrce8-re32-453b-6534-1182f996c23f",
                                "description": "Define actions on response event",
                                "name": "tabarray"
                              }
                            ],
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "fieldset",
                            "key": "onEvent.events[].localStorage.responseEvents",
                            "guid": "k3kksk3-96ee-ioro-plpl-979ddc43e959",
                            "name": "fieldset",
                            "title": "Execute Events after response received",
                            "description": "Define response events",
                            "htmlClass": "card"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onEvent.events[].localStorage",
                        "guid": "1236b0c1-5e94-4678-reee-56645cc1a30d",
                        "title": "Local storage",
                        "description": "Define how to apply incoming data",
                        "name": "fieldset",
                        "condition": "model.onEvent.events[form.arrayIndex].executeType==='LOCAL_STORAGE'",
                        "htmlClass": "card"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "type": "tabarray",
                            "add": "New",
                            "remove": "Delete",
                            "btnStyle": {
                              "remove": "btn-danger"
                            },
                            "title": "{{ value.eventName }}",
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Enter Event Name",
                                "type": "text",
                                "key": "onEvent.events[].eventPublish.events[].eventName",
                                "guid": "e7ad5fb9-3f9f-4220-a6b2-82049008f72d",
                                "name": "text",
                                "title": "Event Name",
                                "description": "Define name for the Event"
                              },
                              {
                                "style": "min-height:200px",
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "ace",
                                "key": "onEvent.events[].eventPublish.events[].contextObject",
                                "guid": "535bc15f-fbb5-41e4-b1f0-9edb072d7b06",
                                "name": "ace",
                                "title": "Context Object",
                                "description": "Use expression to define Context Object in json form",
                                "default": "{}"
                              }
                            ],
                            "key": "onEvent.events[].eventPublish.events",
                            "guid": "36d6bd06-446b-4c9a-94ce-1a72a5cd8ef3",
                            "description": "Define events to be published",
                            "name": "tabarray"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onEvent.events[].eventPublish",
                        "guid": "ad3fc8d5-2c1d-404a-8ff2-9d935afdfe61",
                        "title": "Event Publish",
                        "description": "Define Event Publish configuration",
                        "name": "fieldset",
                        "condition": "model.onEvent.events[form.arrayIndex].executeType==='EVENT_PUBLISH'",
                        "htmlClass": "card"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "checkbox",
                                "key": "onEvent.events[].widgetSettings.widgetVisible",
                                "guid": "e2f01aa1-a3e2-4ac6-9f84-77a9e08ff92f",
                                "name": "checkbox",
                                "indexArray": [
                                  0,
                                  0
                                ],
                                "title": "Widget Visible",
                                "description": "Select the option to show or hide Widget body",
                                "default": true
                              }
                            ],
                            "type": "section",
                            "guid": "54151bad-4893-4e42-8ab8-74ad1953591f",
                            "name": "section"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onEvent.events[].widgetSettings",
                        "guid": "480cbf23-6741-42eb-b70a-c899c27a353b",
                        "name": "fieldset",
                        "condition": "model.onEvent.events[form.arrayIndex].executeType==='WIDGET_SETTINGS'",
                        "title": "Widget Settings",
                        "description": "Define widget settings changes that will be executed on event after Run If is true"
                      },
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "titleMap": [
                              {
                                "value": "MODAL",
                                "name": "Modal"
                              },
                              {
                                "value": "ROUTE",
                                "name": "Route"
                              },
                              {
                                "name": "Replace Widget",
                                "value": "REPLACE_WIDGET"
                              }
                            ],
                            "type": "radiosinline",
                            "key": "onEvent.events[].navigationSettings.type",
                            "guid": "e743ebdd-0892-4c2c-924c-aab613c9f6e4",
                            "name": "radiosinline",
                            "title": "Navigation Type",
                            "description": "Select navigation type"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "items": [
                              {
                                "items": [
                                  {
                                    "titleMap": [
                                      {
                                        "value": "WIDGET",
                                        "name": "Widget"
                                      },
                                      {
                                        "value": "ROUTE",
                                        "name": "Route"
                                      },
                                      {
                                        "name": "Link",
                                        "value": "LINK"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "radiosinline",
                                    "key": "onEvent.events[].navigationSettings.routeSettings.navigateTo",
                                    "guid": "d962cd40-e2de-4dab-b9af-adac73b86dcd",
                                    "name": "radiosinline",
                                    "title": "Navigate To",
                                    "description": "Select where to navigate"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Select widget instance",
                                        "type": "select",
                                        "key": "onEvent.events[].navigationSettings.routeSettings.navigateToWidget.widgetInstance",
                                        "guid": "3b5a4a2b-e7e1-4e0f-ad40-886750d79319",
                                        "name": "select",
                                        "groupBy": "dashboardTitle",
                                        "valueKey": "id",
                                        "labelKey": "fullTitle",
                                        "options": {
                                          "callback": "getWidgetInstancesIncludingParent"
                                        },
                                        "callbackType": "callback",
                                        "title": "Widget Instance",
                                        "description": "Select widget instance to navigate to"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.routeSettings.navigateToWidget",
                                    "guid": "b5f3362f-40cb-47a3-b9ba-320c6c7c7e45",
                                    "name": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.routeSettings.navigateTo==='WIDGET'",
                                    "title": "Navigate to Widget",
                                    "description": "Define widget to navigate"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter route name",
                                        "type": "text",
                                        "key": "onEvent.events[].navigationSettings.routeSettings.navigateToRoute.routeName",
                                        "guid": "ab910717-7b08-4ad8-9862-d5055e6d80ca",
                                        "name": "text",
                                        "title": "Route name",
                                        "description": "Define route name to navigate"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.routeSettings.navigateToRoute",
                                    "guid": "f0006ac8-aab2-42c2-9200-f5fb30aafa11",
                                    "name": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.routeSettings.navigateTo==='ROUTE'",
                                    "title": "Navigate to Route",
                                    "description": "Define the route to navigate"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter URL",
                                        "type": "textarea",
                                        "key": "onEvent.events[].navigationSettings.routeSettings.navigateToLink.url",
                                        "guid": "508e0e18-7221-4cb0-b187-8c4f590a8100",
                                        "name": "textarea",
                                        "title": "Url location",
                                        "description": "Define url location to navigate on event"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "titleMap": [
                                          {
                                            "value": "_self",
                                            "name": "Self"
                                          },
                                          {
                                            "value": "_blank",
                                            "name": "New window"
                                          }
                                        ],
                                        "type": "radiosinline",
                                        "key": "onEvent.events[].navigationSettings.routeSettings.navigateToLink.urlTarget",
                                        "guid": "33c57b47-ad45-4dfa-825a-fc9d5e023fe8",
                                        "name": "radiosinline",
                                        "title": "Target",
                                        "description": "Select target to open the URL location"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.routeSettings.navigateToLink",
                                    "guid": "12a81d43-6068-42b2-a3be-31924acc059a",
                                    "name": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.routeSettings.navigateTo==='LINK'",
                                    "title": "Navigate to Link",
                                    "description": "Define the link to navigate"
                                  }
                                ],
                                "type": "section",
                                "guid": "3dceac25-7160-465e-86a7-7c92cd2d8249",
                                "name": "section"
                              }
                            ],
                            "type": "fieldset",
                            "key": "onEvent.events[].navigationSettings.routeSettings",
                            "guid": "9d13651a-7112-422c-9613-398939f8c636",
                            "name": "fieldset",
                            "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.type==='ROUTE'",
                            "title": "Route Settings",
                            "description": "Define route settings"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "items": [
                              {
                                "items": [
                                  {
                                    "titleMap": [
                                      {
                                        "value": "CONFIRM_MODAL",
                                        "name": "Confirm"
                                      },
                                      {
                                        "value": "YES_NO_MODAL",
                                        "name": "Yes/No"
                                      },
                                      {
                                        "name": "Widget Modal",
                                        "value": "WIDGET_MODAL"
                                      },
                                      {
                                        "name": "Window Url Modal",
                                        "value": "WINDOW_URL_MODAL"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "radiosinline",
                                    "key": "onEvent.events[].navigationSettings.modalSettings.type",
                                    "guid": "bc493781-f52c-4f10-86b0-6216b89db004",
                                    "name": "radiosinline",
                                    "title": "Modal Type",
                                    "description": "Select modal type"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter title text",
                                        "type": "text",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.yesNoModal.title",
                                        "guid": "3dc2d884-9534-40a8-884f-c5bc973c86ec",
                                        "name": "text",
                                        "title": "Title",
                                        "description": "Define title text"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "titleMap": [
                                          {
                                            "value": "info",
                                            "name": "Info"
                                          },
                                          {
                                            "value": "success",
                                            "name": "Success"
                                          },
                                          {
                                            "name": "Question",
                                            "value": "question"
                                          },
                                          {
                                            "name": "Warning",
                                            "value": "warning"
                                          },
                                          {
                                            "name": "Error",
                                            "value": "error"
                                          }
                                        ],
                                        "type": "radiosinline",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.yesNoModal.iconType",
                                        "guid": "2824d002-adfc-4965-8019-0bc44da910c5",
                                        "name": "radiosinline",
                                        "title": "Type",
                                        "description": "Select modal type",
                                        "default": "info"
                                      },
                                      {
                                        "style": "min-height:100px",
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "ace",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.yesNoModal.bodyText",
                                        "guid": "ef57733a-7b1c-45e9-a148-9ded2d1c1afe",
                                        "name": "ace",
                                        "config": {
                                          "lang": "html"
                                        },
                                        "title": "Body Text",
                                        "description": "Enter body text - can be HTML."
                                      },
                                      {
                                        "items": [
                                          {
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Enter text",
                                            "type": "text",
                                            "key": "onEvent.events[].navigationSettings.modalSettings.yesNoModal.confirmButtonText",
                                            "guid": "b75a503a-ed5c-4f97-a8bc-be77b7789a59",
                                            "name": "text",
                                            "title": "Confirm button text",
                                            "description": "Enter confirm button text",
                                            "default": "Yes"
                                          },
                                          {
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Enter text",
                                            "type": "text",
                                            "key": "onEvent.events[].navigationSettings.modalSettings.yesNoModal.cancelButtonText",
                                            "guid": "7d8170ff-91ac-44e2-b601-88693885b71e",
                                            "name": "text",
                                            "title": "Cancel button text",
                                            "description": "Enter cancel button text",
                                            "default": "No"
                                          }
                                        ],
                                        "type": "section",
                                        "guid": "1e984628-12f9-413e-83c2-5819001aa865",
                                        "name": "section",
                                        "sectionContainerClass": "row",
                                        "sectionChildClass": "col"
                                      },
                                      {
                                        "style": "min-height:100px",
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "ace",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.yesNoModal.footerHtml",
                                        "guid": "1042412b-8b24-4a13-b4cc-0f9e0f8a12fe",
                                        "name": "ace",
                                        "config": {
                                          "lang": "html"
                                        },
                                        "title": "Footer",
                                        "description": "Enter footer text. Html can be used"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.modalSettings.yesNoModal",
                                    "guid": "6656f562-8c84-4776-87c3-f01a97a339d4",
                                    "name": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.modalSettings.type==='YES_NO_MODAL'",
                                    "title": "Yes/No Modal",
                                    "description": "Define Yes/No modal"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "titleMap": [
                                          {
                                            "value": "BY_TYPE",
                                            "name": "Widget by Type"
                                          },
                                          {
                                            "value": "INSTANCE",
                                            "name": "Widget Instance"
                                          }
                                        ],
                                        "type": "radiosinline",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.widgetModal.type",
                                        "guid": "9b34b53d-f8f6-4d44-b99e-ef69383ae268",
                                        "name": "radiosinline",
                                        "title": "Widget Type",
                                        "description": "Select widget type to connect or create new widget by type"
                                      },
                                      {
                                        "items": [
                                          {
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Select widget instance",
                                            "type": "select",
                                            "key": "onEvent.events[].navigationSettings.modalSettings.widgetModal.widgetInstance",
                                            "guid": "7b54845e-f97e-4da0-979d-d08a87c398b7",
                                            "groupBy": "dashboardTitle",
                                            "valueKey": "id",
                                            "labelKey": "fullTitle",
                                            "name": "select",
                                            "options": {
                                              "callback": "getWidgetInstancesIncludingParent"
                                            },
                                            "callbackType": "callback",
                                            "title": "Widget Instance",
                                            "description": "Select widget instance to open in modal window"
                                          }
                                        ],
                                        "type": "section",
                                        "guid": "1b7e5710-62d8-4047-9ac7-0787594235ef",
                                        "name": "section",
                                        "sectionType": "fieldset",
                                        "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.modalSettings.widgetModal.type==='INSTANCE'",
                                        "title": "By Instance"
                                      },
                                      {
                                        "items": [
                                          {
                                            "items": [
                                              {
                                                "titleMap": [
                                                  {
                                                    "value": "CREATE",
                                                    "name": "Create"
                                                  },
                                                  {
                                                    "value": "REFERENCE",
                                                    "name": "Reference"
                                                  }
                                                ],
                                                "readonly": false,
                                                "hidden": false,
                                                "placeholder": "Placeholder for: {}",
                                                "type": "radiosinline",
                                                "key": "onEvent.events[].navigationSettings.modalSettings.widgetModal.createOrReference",
                                                "guid": "eedba5bb-0686-49ab-8281-697e378a34a9",
                                                "name": "radiosinline",
                                                "title": "Create or Reference",
                                                "description": "Select to create new widget by type or reference already created"
                                              }
                                            ],
                                            "type": "section",
                                            "guid": "254a8db6-1051-4206-87e7-66a1c25ef1dd",
                                            "name": "section"
                                          },
                                          {
                                            "items": [
                                              {
                                                "readonly": false,
                                                "hidden": false,
                                                "placeholder": "Select widget type",
                                                "type": "select",
                                                "key": "onEvent.events[].navigationSettings.modalSettings.widgetModal.widgetByType",
                                                "guid": "8c52e822-885e-42e9-835b-07594c7c8e13",
                                                "name": "select",
                                                "callbackType": "callback",
                                                "options": {
                                                  "callback": "getWidgetDefinitions"
                                                },
                                                "title": "Widget by Type",
                                                "description": "Select widget by type to define widget to be opened in modal on event execute"
                                              },
                                              {
                                                "type": "button",
                                                "title": "Create",
                                                "guid": "8c474f45-743c-4609-b8f1-0f176bedaa55",
                                                "name": "button",
                                                "onClick": {
                                                  "options": {
                                                    "targetKey": "onEvent.events[form.key[2]].navigationSettings.modalSettings.widgetModal.existingWidgetByType"
                                                  },
                                                  "callback": "createModalWidgetByType"
                                                }
                                              }
                                            ],
                                            "type": "section",
                                            "guid": "a1d08d76-6f32-4b86-b17e-9d14e8a866b8",
                                            "name": "section",
                                            "sectionType": "section"
                                          },
                                          {
                                            "items": [
                                              {
                                                "readonly": false,
                                                "hidden": false,
                                                "placeholder": "Select modal widget",
                                                "type": "select",
                                                "key": "onEvent.events[].navigationSettings.modalSettings.widgetModal.existingWidgetByType",
                                                "guid": "20f7ac40-1baa-46bc-bbe5-4741e092d943",
                                                "name": "select",
                                                "callbackType": "callback",
                                                "options": {
                                                  "callback": "getAllCreatedModalWidgetInstances"
                                                },
                                                "title": "Reference",
                                                "description": "Select existing modal widget as reference"
                                              },
                                              {
                                                "items": [
                                                  {
                                                    "type": "button",
                                                    "title": "Edit",
                                                    "guid": "0b6afe66-391f-47bf-a0f0-6e8d7554dd65",
                                                    "name": "button",
                                                    "btnStyle": "btn-warning",
                                                    "onClick": {
                                                      "options": {
                                                        "fieldName": "existingWidgetByType",
                                                        "targetKey": "onEvent.events[form.key[2]].navigationSettings.modalSettings.widgetModal.existingWidgetByType"
                                                      },
                                                      "callback": "editModalWidgetInstanceByType"
                                                    }
                                                  },
                                                  {
                                                    "type": "button",
                                                    "title": "Delete",
                                                    "guid": "04f7bc40-391f-47bf-b0f0-a08d657554dd",
                                                    "name": "button",
                                                    "btnStyle": "btn-danger",
                                                    "onClick": {
                                                      "options": {
                                                        "fieldName": "existingWidgetByType",
                                                        "targetKey": "onEvent.events[form.key[2]].navigationSettings.modalSettings.widgetModal.existingWidgetByType"
                                                      },
                                                      "callback": "deleteModalWidgetInstanceByType"
                                                    }
                                                  }
                                                ],
                                                "type": "actions",
                                                "guid": "d01f9eb8-a9d6-4fac-b53d-312fc580a623",
                                                "name": "actions"
                                              }
                                            ],
                                            "type": "section",
                                            "guid": "5e5d3478-7d4c-4a98-b142-69491964cd4e",
                                            "name": "section",
                                            "sectionType": "section"
                                          }
                                        ],
                                        "type": "section",
                                        "guid": "01aa1c20-f646-44c1-a339-879feda59496",
                                        "name": "section",
                                        "sectionType": "fieldset",
                                        "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.modalSettings.widgetModal.type==='BY_TYPE'",
                                        "title": "By Type"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.modalSettings.widgetModal",
                                    "guid": "4e476218-0022-4a88-b8c2-a829c4cfbbcc",
                                    "name": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.modalSettings.type==='WIDGET_MODAL'",
                                    "title": "Widget Modal",
                                    "description": "Define Widget modal"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter title text",
                                        "type": "text",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.confirmModal.title",
                                        "guid": "6b00dc6b-c803-483b-8baa-d14e773f793e",
                                        "name": "text",
                                        "title": "Title",
                                        "description": "Define title text"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "titleMap": [
                                          {
                                            "value": "info",
                                            "name": "Info"
                                          },
                                          {
                                            "value": "success",
                                            "name": "Success"
                                          },
                                          {
                                            "name": "Question",
                                            "value": "question"
                                          },
                                          {
                                            "name": "Warning",
                                            "value": "warning"
                                          },
                                          {
                                            "name": "Error",
                                            "value": "error"
                                          }
                                        ],
                                        "type": "radiosinline",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.confirmModal.iconType",
                                        "guid": "dc223803-1a35-46cd-8503-36999584d7e9",
                                        "name": "radiosinline",
                                        "title": "Type",
                                        "description": "Select type of the confirmation",
                                        "default": "info"
                                      },
                                      {
                                        "style": "min-height:100px",
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "ace",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.confirmModal.bodyText",
                                        "guid": "e41220be-4d11-441e-a726-f846c6bec174",
                                        "name": "ace",
                                        "title": "Body Text",
                                        "config": {
                                          "lang": "html"
                                        },
                                        "description": "Enter body text - can be HTML."
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.modalSettings.confirmModal",
                                    "guid": "e1a189c5-be41-414b-ab9b-3994495f87a9",
                                    "name": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.modalSettings.type==='CONFIRM_MODAL'",
                                    "title": "Confim Modal",
                                    "description": "Define Confirm modal"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter URL",
                                        "type": "textarea",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.windowUrlModal.url",
                                        "guid": "2c98352b-b415-4c02-837e-e4c9c86d4117",
                                        "name": "textarea",
                                        "title": "Url",
                                        "description": "Enter url to be opened in window modal"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter target",
                                        "type": "text",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.windowUrlModal.target",
                                        "guid": "c9c60805-8b22-491f-a040-892b42a77955",
                                        "name": "text",
                                        "title": "Target",
                                        "description": "Enter target to window open",
                                        "default": "_blank"
                                      },
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter window open features",
                                        "type": "textarea",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.windowUrlModal.features",
                                        "guid": "bff8096e-737e-4095-a544-0823f86bbf87",
                                        "name": "textarea",
                                        "title": "Features",
                                        "description": "Enter window open features.",
                                        "default": "toolbar=yes,scrollbars=yes,resizable=yes,height=560,width=770"
                                      }
                                    ],
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.modalSettings.windowUrlModal",
                                    "guid": "ede12772-6133-4a03-b529-1535d114c666",
                                    "name": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.modalSettings.type==='WINDOW_URL_MODAL'",
                                    "title": "Window Url Modal",
                                    "description": "Enter URL to be open in modal window"
                                  }
                                ],
                                "type": "section",
                                "guid": "29e4fa7b-279e-4bec-8e6e-3bd0ee04cc8b",
                                "name": "section"
                              },
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "tabarray",
                                    "add": "New",
                                    "remove": "Delete",
                                    "btnStyle": {
                                      "remove": "btn-danger"
                                    },
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter event name",
                                        "type": "text",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.responseEvents.events[].eventName",
                                        "guid": "f5439129-6d28-45e6-942a-3bb5d90789d9",
                                        "name": "text",
                                        "title": "Publish Event Name",
                                        "description": "Define Event name to publish"
                                      },
                                      {
                                        "style": "min-height:150px",
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "ace",
                                        "key": "onEvent.events[].navigationSettings.modalSettings.responseEvents.events[].contextObject",
                                        "guid": "d758bea7-d434-4b8f-b00b-e4c739d7e550",
                                        "name": "ace",
                                        "title": "Context Object",
                                        "description": "Use expression to define Context Object in json form"
                                      }
                                    ],
                                    "key": "onEvent.events[].navigationSettings.modalSettings.responseEvents.events",
                                    "guid": "bf6ed1c6-9bf5-4f65-be73-b8fce9b787fc",
                                    "name": "tabarray",
                                    "title": "{{ value.eventName }}",
                                    "description": "Define actions on response event"
                                  }
                                ],
                                "type": "fieldset",
                                "key": "onEvent.events[].navigationSettings.modalSettings.responseEvents",
                                "guid": "79b4246d-f584-40c3-b3c9-41906263bcad",
                                "name": "fieldset",
                                "title": "Execute Events after response received",
                                "description": "Define events to be executed after modal is closed"
                              }
                            ],
                            "type": "fieldset",
                            "key": "onEvent.events[].navigationSettings.modalSettings",
                            "guid": "a572f3ee-7c54-4fb0-a770-6f471c3974d6",
                            "name": "fieldset",
                            "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.type==='MODAL'",
                            "title": "Modal Settings",
                            "description": "Define modal settings"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "items": [
                              {
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "titleMap": [
                                      {
                                        "value": "BY_TYPE",
                                        "name": "Widget by Type"
                                      },
                                      {
                                        "value": "INSTANCE",
                                        "name": "Widget Instance"
                                      }
                                    ],
                                    "type": "radiosinline",
                                    "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.widgetReplace.type",
                                    "guid": "9b67b53d-f8f6-4d22-b99e-ef69383ae268",
                                    "name": "radiosinline",
                                    "title": "Widget Type",
                                    "description": "Select widget type to connect or create new widget by type"
                                  },
                                  {
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Select widget instance",
                                        "type": "select",
                                        "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.widgetReplace.widgetInstance",
                                        "guid": "7b54845e-f97e-4da0-234d-d08a87c398b7",
                                        "groupBy": "dashboardTitle",
                                        "valueKey": "id",
                                        "labelKey": "fullTitle",
                                        "name": "select",
                                        "options": {
                                          "callback": "getWidgetInstancesIncludingParent"
                                        },
                                        "callbackType": "callback",
                                        "title": "Widget Instance",
                                        "description": "Select widget instance to replace with"
                                      }
                                    ],
                                    "type": "section",
                                    "guid": "1b7e5710-62d8-3047-9ac7-1237594235ef",
                                    "name": "section",
                                    "sectionType": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.replaceWidgetSettings.widgetReplace.type==='INSTANCE'",
                                    "title": "By Instance"
                                  },
                                  {
                                    "items": [
                                      {
                                        "items": [
                                          {
                                            "titleMap": [
                                              {
                                                "value": "CREATE",
                                                "name": "Create"
                                              },
                                              {
                                                "value": "REFERENCE",
                                                "name": "Reference"
                                              }
                                            ],
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Placeholder for: {}",
                                            "type": "radiosinline",
                                            "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.widgetReplace.createOrReference",
                                            "guid": "eedba5bb-0686-49ab-8281-123e378a34a9",
                                            "name": "radiosinline",
                                            "title": "Create or Reference",
                                            "description": "Select to create new widget by type or reference already created"
                                          }
                                        ],
                                        "type": "section",
                                        "guid": "123a8db6-1051-4206-87e7-66a1c25ef1dd",
                                        "name": "section"
                                      },
                                      {
                                        "items": [
                                          {
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Select widget type",
                                            "type": "select",
                                            "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.widgetReplace.widgetByType",
                                            "guid": "3c52e822-885e-42e9-835b-07594c7c8e13",
                                            "name": "select",
                                            "callbackType": "callback",
                                            "options": {
                                              "callback": "getWidgetDefinitions"
                                            },
                                            "title": "Widget by Type",
                                            "description": "Select widget by type to define widget used as replacement on event execute"
                                          },
                                          {
                                            "type": "button",
                                            "title": "Create",
                                            "guid": "9c474f45-743c-4609-b8f1-2f176bedaa55",
                                            "name": "button",
                                            "onClick": {
                                              "options": {
                                                "targetKey": "onEvent.events[form.key[2]].navigationSettings.replaceWidgetSettings.widgetReplace.existingWidgetByType"
                                              },
                                              "callback": "createModalWidgetByType"
                                            }
                                          }
                                        ],
                                        "type": "section",
                                        "guid": "a2d08d76-6f32-4b86-b17e-9d14e8a866b8",
                                        "name": "section",
                                        "sectionType": "section"
                                      },
                                      {
                                        "items": [
                                          {
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Select modal widget",
                                            "type": "select",
                                            "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.widgetReplace.existingWidgetByType",
                                            "guid": "25f7ac40-1baa-34bc-bbe5-4741e092d943",
                                            "name": "select",
                                            "callbackType": "callback",
                                            "options": {
                                              "callback": "getAllCreatedModalWidgetInstances"
                                            },
                                            "title": "Reference",
                                            "description": "Select existing widget as reference"
                                          },
                                          {
                                            "items": [
                                              {
                                                "type": "button",
                                                "title": "Edit",
                                                "guid": "0b4afe66-291f-47bf-a0f0-6e8d7554dd65",
                                                "name": "button",
                                                "btnStyle": "btn-warning",
                                                "onClick": {
                                                  "options": {
                                                    "fieldName": "existingWidgetByType",
                                                    "targetKey": "onEvent.events[form.key[2]].navigationSettings.replaceWidgetSettings.widgetReplace.existingWidgetByType"
                                                  },
                                                  "callback": "editModalWidgetInstanceByType"
                                                }
                                              },
                                              {
                                                "type": "button",
                                                "title": "Delete",
                                                "guid": "05f7bc40-291f-58bf-b0f0-a08d657554dd",
                                                "name": "button",
                                                "btnStyle": "btn-danger",
                                                "onClick": {
                                                  "options": {
                                                    "fieldName": "existingWidgetByType",
                                                    "targetKey": "onEvent.events[form.key[2]].navigationSettings.replaceWidgetSettings.widgetReplace.existingWidgetByType"
                                                  },
                                                  "callback": "deleteModalWidgetInstanceByType"
                                                }
                                              }
                                            ],
                                            "type": "actions",
                                            "guid": "d01f7eb8-a9d6-4fac-b21d-312fc580a623",
                                            "name": "actions"
                                          }
                                        ],
                                        "type": "section",
                                        "guid": "3e5d3478-4d4c-4a98-b142-69491964cd4e",
                                        "name": "section",
                                        "sectionType": "section"
                                      }
                                    ],
                                    "type": "section",
                                    "guid": "11aa1c20-f646-34c1-a339-879feda59496",
                                    "name": "section",
                                    "sectionType": "fieldset",
                                    "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.replaceWidgetSettings.widgetReplace.type==='BY_TYPE'",
                                    "title": "By Type"
                                  }
                                ],
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "fieldset",
                                "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.widgetReplace",
                                "guid": "4e476218-0022-3a88-b8c2-a829c4cfbbcc",
                                "name": "fieldset",
                                "title": "Replace Widget",
                                "description": "Define replacement Widget"
                              },
                              {
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "tabarray",
                                        "add": "New",
                                        "remove": "Delete",
                                        "btnStyle": {
                                          "remove": "btn-danger"
                                        },
                                        "items": [
                                          {
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Enter event name",
                                            "type": "text",
                                            "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.responseEvents.events[].eventName",
                                            "guid": "f4479129-6d28-44e6-942a-3bb5d90789d9",
                                            "name": "text",
                                            "title": "Publish Event Name",
                                            "description": "Define Event name to publish"
                                          },
                                          {
                                            "style": "min-height:150px",
                                            "readonly": false,
                                            "hidden": false,
                                            "placeholder": "Placeholder for: {}",
                                            "type": "ace",
                                            "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.responseEvents.events[].contextObject",
                                            "guid": "d756bea7-d434-4b8f-b00b-e4c739d7e550",
                                            "name": "ace",
                                            "title": "Context Object",
                                            "description": "Use expression to define Context Object in json form"
                                          }
                                        ],
                                        "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.responseEvents.events",
                                        "guid": "bc4ed1c6-9bf6-4f65-ce73-b8fce9b787fc",
                                        "name": "tabarray",
                                        "title": "{{ value.eventName }}",
                                        "description": "Define actions on response event"
                                      }
                                    ],
                                    "type": "fieldset",
                                    "key": "onEvent.events[].navigationSettings.replaceWidgetSettings.responseEvents",
                                    "guid": "79b4246d-f584-50c3-c2c9-41906263bcbd",
                                    "name": "fieldset",
                                    "title": "Execute Events after response received",
                                    "description": "Define events to be executed after modal is closed"
                                  }
                                ],
                                "type": "section",
                                "guid": "1c2ac695-f3c0-51d1-b058-81bb6ec49b9b",
                                "name": "section"
                              }
                            ],
                            "type": "fieldset",
                            "key": "onEvent.events[].navigationSettings.replaceWidgetSettings",
                            "guid": "a8bc79ba-9b2f-409b-a1ab-b84979207389",
                            "name": "fieldset",
                            "condition": "model.onEvent.events[form.arrayIndex].navigationSettings.type==='REPLACE_WIDGET'",
                            "title": "Replace Widget Settings",
                            "description": "Define how to replace existing widget content"
                          }
                        ],
                        "type": "fieldset",
                        "key": "onEvent.events[].navigationSettings",
                        "guid": "1bb845ca-79e5-43a8-996e-2c6309e63536",
                        "name": "fieldset",
                        "condition": "model.onEvent.events[form.arrayIndex].executeType==='NAVIGATION_SETTINGS'",
                        "title": "Navigation Settings",
                        "description": "Define navigation settings occurred on event execute"
                      }
                    ],
                    "key": "onEvent.events",
                    "guid": "4180c899-4f8c-438c-b0ab-8b9944d44e03",
                    "description": "Define Events and actions",
                    "name": "tabarray"
                  }
                ],
                "type": "fieldset",
                "key": "onEvent",
                "guid": "e51729a6-2979-4663-88e0-fbb958b31da5",
                "title": "On Event Definition",
                "description": "Define On Event configuration",
                "name": "fieldset",
                "htmlClass": "card"
              }
            ],
            "title": "On Event",
            "_id": "ON_EVENT"
          },
          {
            "items": [
              {
                "readonly": false,
                "hidden": false,
                "placeholder": "Placeholder for: {}",
                "items": [
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "type": "tabarray",
                    "add": "New",
                    "remove": "Delete",
                    "btnStyle": {
                      "remove": "btn-danger"
                    },
                    "items": [
                      {
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "titleMap": [
                          {
                            "value": "INTERNAL",
                            "name": "Internal"
                          },
                          {
                            "value": "EXTERNAL",
                            "name": "External"
                          }
                        ],
                        "type": "radiosinline",
                        "key": "onWebSocket.connections[].connectionType",
                        "guid": "3c5c932a-6060-47dc-8401-ae3a85dc7a50",
                        "name": "radiosinline",
                        "title": "Connection Type",
                        "description": "Internal type is to connect to Pipeline WS, while external is for outside world",
                        "default": "INTERNAL"
                      },
                      {
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter Url",
                            "type": "text",
                            "key": "onWebSocket.connections[].url",
                            "guid": "a9090cf2-c7b2-4d07-ad2e-7a0d26a482d8",
                            "name": "text",
                            "title": "Url",
                            "description": "Define Web Socket server url"
                          },
                          {
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Enter Username",
                                "type": "text",
                                "key": "onWebSocket.connections[].userName",
                                "guid": "f788d014-c67f-4851-b124-be64457669fb",
                                "name": "text",
                                "title": "User Name",
                                "description": "Define username for connecting to Web Socket"
                              },
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Enter Password",
                                "type": "password",
                                "key": "onWebSocket.connections[].password",
                                "guid": "2371db75-0358-4ff0-a4c0-92095c1eb3e1",
                                "name": "password",
                                "title": "Password",
                                "description": "Define password for connecting to Web Socket"
                              }
                            ],
                            "type": "section",
                            "guid": "860966f2-ea73-4de4-a5c9-0a4b7784f8e7",
                            "name": "section",
                            "sectionContainerClass": "row",
                            "sectionChildClass": "col"
                          }
                        ],
                        "type": "section",
                        "guid": "22061ed2-c1ac-46ae-8e2a-0c250da01529",
                        "name": "section",
                        "sectionType": "fieldset",
                        "title": "Connection Settings",
                        "condition": "model.onWebSocket.connections[form.arrayIndex].connectionType==='EXTERNAL'"
                      },
                      {
                        "items": [
                          {
                            "type": "tabarray",
                            "add": "New",
                            "remove": "Delete",
                            "btnStyle": {
                              "remove": "btn-danger"
                            },
                            "title": "{{ value.topicName || 'Tab '+$index }}",
                            "items": [
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "titleMap": [
                                  {
                                    "value": "SUBSCRIBE",
                                    "name": "Subscribe"
                                  }
                                ],
                                "type": "radiosinline",
                                "key": "onWebSocket.connections[].actions[].actionType",
                                "guid": "653dc8bb-6707-48ca-b9ad-a88f14766ac6",
                                "name": "radiosinline",
                                "title": "Action Type",
                                "description": "Select Web Socket action type",
                                "default": "SUBSCRIBE"
                              },
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Enter Topic Name",
                                "type": "text",
                                "key": "onWebSocket.connections[].actions[].topicName",
                                "guid": "1e809a1b-d0b9-45d4-b70a-ec009b67c194",
                                "name": "text",
                                "title": "Topic Name",
                                "description": "Define Topic name"
                              },
                              {
                                "style": "min-height:300px",
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "type": "ace",
                                "key": "onWebSocket.connections[].actions[].headers",
                                "guid": "ede064e6-d35c-450f-a052-08da612ac055",
                                "name": "ace",
                                "title": "Headers",
                                "description": "Use expression to define Headers as object (key/value) to be sent to EventBus"
                              },
                              {
                                "readonly": false,
                                "hidden": false,
                                "placeholder": "Placeholder for: {}",
                                "items": [
                                  {
                                    "readonly": false,
                                    "hidden": false,
                                    "placeholder": "Placeholder for: {}",
                                    "type": "tabarray",
                                    "add": "New",
                                    "remove": "Delete",
                                    "btnStyle": {
                                      "remove": "btn-danger"
                                    },
                                    "items": [
                                      {
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Enter Event name",
                                        "type": "text",
                                        "key": "onWebSocket.connections[].actions[].publishEvents.events[].eventName",
                                        "guid": "101d680d-9460-411b-920d-1c86bf764188",
                                        "name": "text",
                                        "title": "Event Name",
                                        "description": "Define name for the Event"
                                      },
                                      {
                                        "style": "min-height:200px",
                                        "readonly": false,
                                        "hidden": false,
                                        "placeholder": "Placeholder for: {}",
                                        "type": "ace",
                                        "key": "onWebSocket.connections[].actions[].publishEvents.events[].contextObject",
                                        "guid": "f41fbbbc-5107-44b5-b27d-914ecfea1d30",
                                        "name": "ace",
                                        "title": "Context Object",
                                        "description": "Use expression to define Context Object in json form"
                                      }
                                    ],
                                    "key": "onWebSocket.connections[].actions[].publishEvents.events",
                                    "guid": "fa8c6b4f-4676-406f-91de-c8453e4032ac",
                                    "name": "tabarray",
                                    "title": "{{ value.eventName || 'Tab '+$index }}",
                                    "description": "Define Events to publish on receiving from Web Socket topic"
                                  }
                                ],
                                "type": "fieldset",
                                "key": "onWebSocket.connections[].actions[].publishEvents",
                                "guid": "443b0491-7951-4237-9f96-63541c6491b5",
                                "name": "fieldset",
                                "condition": "model.onWebSocket.connections[form.arrayIndex].actions[form.arrayIndex].actionType==='SUBSCRIBE'",
                                "title": "Publish Local Events",
                                "description": "Publish Events on message from subscribed topic"
                              }
                            ],
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Placeholder for: {}",
                            "key": "onWebSocket.connections[].actions",
                            "guid": "7f9df34b-6f50-475f-acc1-4a7e2b5bd51b",
                            "name": "tabarray",
                            "description": "Define Web Socket actions"
                          }
                        ],
                        "type": "section",
                        "guid": "df98f552-9ac9-49ce-be37-891490d24049",
                        "name": "section",
                        "sectionType": "fieldset",
                        "title": "Action Settings"
                      }
                    ],
                    "key": "onWebSocket.connections",
                    "guid": "8b753878-755a-43df-8ee6-30a61cfa9c71",
                    "name": "tabarray",
                    "title": "{{ value.url || 'Tab '+$index }}",
                    "description": "Define Web Socket connections"
                  }
                ],
                "type": "fieldset",
                "key": "onWebSocket",
                "guid": "4e4b8fff-e95d-44a1-9a9b-3089e96f20c2",
                "name": "fieldset",
                "htmlClass": "card",
                "title": "On Web Socket Definition",
                "description": "Define how to manage Web Socket data"
              }
            ],
            "title": "On Web Socket",
            "_id": "ON_WEBSOCKET"
          },
          {
            "title": "Styling",
            "items": [
              {
                "htmlClass": "card",
                "readonly": false,
                "hidden": false,
                "placeholder": "Placeholder for: {}",
                "items": [
                  {
                    "readonly": false,
                    "hidden": false,
                    "placeholder": "Placeholder for: {}",
                    "items": [
                      {
                        "items": [
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter class name",
                            "type": "text",
                            "key": "styling.card.widgetCard",
                            "guid": "379f963c-6cf4-4da5-85f3-979e4d416040",
                            "name": "text",
                            "title": "Widget Card Class",
                            "description": "Enter class name for the widget card",
                            "default": "card"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter class name",
                            "type": "text",
                            "key": "styling.card.widgetCardHeader",
                            "guid": "d79a478d-3bf4-42d3-a293-329e8e77c385",
                            "name": "text",
                            "title": "Widget Card Header Class",
                            "description": "Enter class name for the widget card header",
                            "default": "card-header"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter class name",
                            "type": "text",
                            "key": "styling.card.widgetCardHeaderTitle",
                            "guid": "198e8ac4-3c83-4e50-91ad-60c58eaa364a",
                            "name": "text",
                            "title": "Widget Card Header Title",
                            "description": "Enter class name for the widget card header title",
                            "default": "card-title"
                          },
                          {
                            "readonly": false,
                            "hidden": false,
                            "placeholder": "Enter class name",
                            "type": "text",
                            "key": "styling.card.widgetCardBody",
                            "guid": "56486d5f-048f-4cbc-953b-abd96e249a63",
                            "name": "text",
                            "title": "Widget Card Body Class",
                            "description": "Enter class name for the widget card body",
                            "default": "card-body"
                          }
                        ],
                        "type": "section",
                        "guid": "6203608b-6773-410a-8f54-483c47f4fd2d",
                        "name": "section",
                        "sectionType": "section",
                        "sectionContainerClass": "row",
                        "sectionChildClass": "col"
                      }
                    ],
                    "type": "fieldset",
                    "key": "styling.card",
                    "guid": "52427a75-e826-4d97-817b-32944395d4a5",
                    "name": "fieldset",
                    "title": "Card",
                    "description": "Define widget card style classes"
                  },
                  {
                    "items": [
                      {
                        "style": "min-height:300px",
                        "readonly": false,
                        "hidden": false,
                        "placeholder": "Placeholder for: {}",
                        "type": "ace",
                        "key": "styling.css",
                        "guid": "6231b068-5d9a-4c88-8eac-58e789d9ecd7",
                        "name": "ace",
                        "config": {
                          "lang": "css"
                        },
                        "title": "Css",
                        "description": "Define CSS styles for the widget"
                      }
                    ],
                    "type": "section",
                    "guid": "e60e90cb-55d4-4292-9872-34e1db50dff5",
                    "name": "section",
                    "sectionType": "fieldset",
                    "title": "CSS"
                  }
                ],
                "type": "fieldset",
                "key": "styling",
                "guid": "19794a47-f2ec-4895-b731-d4e0c5874f21",
                "name": "fieldset",
                "title": "Styling",
                "description": "Define styling for the widget"
              }
            ],
            "_id": "STYLING"
          }
        ],
        "type": "tabs",
        "guid": "3b8100a9-81af-4c72-9f78-a0579c7f664c",
        "name": "tabs",
        "fieldHtmlClass": "p-2",
        "selectedIndex": 0
      }
    ]
  }
}

export default componentUtils
