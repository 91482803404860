var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-form-tabs", class: _vm.fieldForm.htmlClass },
    [
      _c(
        "div",
        {
          staticClass: "tabs-container",
          class: { "tabs-scroll": _vm.scrollTabs },
        },
        [
          _vm.scrollTabs
            ? _c(
                "span",
                {
                  staticClass: "scroll-left tab-scroll",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.scroll_left.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-chevron-circle-left" })]
              )
            : _vm._e(),
          _c(
            "ul",
            {
              staticClass: "nav nav-tabs sf-tabs",
              class: [
                _vm.fieldForm.tabListHtmlClass,
                { "main-tabs": _vm.scrollTabs },
              ],
              attrs: { id: _vm.guid },
            },
            _vm._l(_vm.fieldForm.tabs, function (tab, index) {
              return _vm.isVisible(tab, index)
                ? _c(
                    "li",
                    {
                      key: tab.title,
                      staticClass: "nav-item nav-link",
                      class: [
                        { active: _vm.selectedTab === index },
                        _vm.fieldForm.tabHtmlClass,
                        _vm.fieldForm.selectedTabHtmlClass,
                      ],
                      attrs: { disabled: _vm.fieldForm.readonly },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.selectTab(tab, index, "true")
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#" } }, [
                        _vm._v(_vm._s(tab.title)),
                      ]),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm.scrollTabs
            ? _c(
                "span",
                {
                  staticClass: "scroll-right tab-scroll",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.scroll_right.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-chevron-circle-right" })]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "tab-content", class: _vm.fieldForm.fieldHtmlClass },
        [
          _vm.tabForm
            ? _c("builder-component", {
                key: _vm.count,
                tag: "builder-component",
                attrs: {
                  "field-mapper": _vm.fieldMapper,
                  form: _vm.tabForm,
                  "full-model": _vm.fullModel,
                  "is-droppable": _vm.isDroppable,
                  "merged-form": _vm.tabForm,
                  "parent-index": _vm.selectedTab,
                  schema: _vm.tabSchema,
                  "schema-form-service": _vm.schemaFormService,
                  value: _vm.resolveValue(_vm.value, _vm.tabForm),
                },
                on: {
                  fullModelChanged: _vm.onFullModelChanged,
                  internalSchemaChanged: _vm.onInternalSchemaChanged,
                  schemaChanged: _vm.onChildSchemaChanged,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }