var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _c(
          "select",
          {
            staticClass: "selectpicker form-control",
            class: [
              _vm.isValid ? "" : "is-invalid",
              _vm.fieldForm.fieldHtmlClass,
            ],
            attrs: {
              multiple: "",
              size: _vm.fieldForm.rows || 5,
              "aria-placeholder": _vm.resolvePlaceholder(),
              readonly: _vm.fieldForm.readonly,
              required: _vm.fieldForm.required,
            },
            on: {
              change: function ($event) {
                return _vm.onSelectChanged($event)
              },
            },
          },
          [
            _c("option", [_vm._v(_vm._s(_vm.resolvePlaceholder()))]),
            _vm._l(_vm.resolveTitleMapOptions(), function (opt) {
              return _c(
                "option",
                {
                  domProps: { selected: _vm.isExisting(opt), value: opt.value },
                },
                [_vm._v(_vm._s(opt.name))]
              )
            }),
          ],
          2
        ),
        !_vm.isValid
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }