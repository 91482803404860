import VertxWebSocketHandler from "./websocket/vertxWebSocketHandler";

const webSocketMixinService = {
  name: 'WEB_SOCKET',
  internalHandler: {},
  async _init(componentThis) {
    this.internalHandler[componentThis.id] = new VertxWebSocketHandler({ref: componentThis});
  },
  async onCreated(componentThis) {
    this._init(componentThis);

    this._subscribe(componentThis);
  },
  async onDestroyed(componentThis) {
   // console.log('on Destroyed', componentThis);
    this._unsubscribe(componentThis);
  },
  async onComponentEvent(eventName, eventData) {

  },
  async loadMetaContextObject(context) {
    if (context && context.id && this.internalHandler[context.id]) {
      if (this.internalHandler[context.id].loadMetaContextObject) {
        await this.internalHandler[context.id].loadMetaContextObject(context);
      }
    }
  },
  async executeService(def, eventContext) {
    const that = this;
    console.log('WS executeService', def, eventContext);
    const source = eventContext.source;
    const widgetId = source.id;
    if (widgetId && that.internalHandler[widgetId] && def && def.connections && def.connections.length > 0) {
      console.log('WS Executing for def.connections', def.connections);
      def.connections.forEach(function (connection) {
        switch (connection.connectionType) {
          case 'INTERNAL':
            that.internalHandler[widgetId].executeService(connection, eventContext);
            break;
          case 'EXTERNAL':
            // not implemented
            break;
        }
      });
    }
  },
  async _subscribe(componentThis) {
    const that = this;
    const onWebSocket = componentThis.model.config.data?componentThis.model.config.data.onWebSocket:null;
    if (that.internalHandler[componentThis.id] && onWebSocket && onWebSocket.connections && onWebSocket.connections.length > 0) {
      onWebSocket.connections.forEach(function (connection) {
        if (connection.connectionType === 'INTERNAL') {
          if (connection.actions && connection.actions.length > 0) {
            connection.actions.forEach(function (action) {
              if (action.actionType && action.actionType === 'SUBSCRIBE') {
                console.log('Connecting the EventBus', action, componentThis);
                that.internalHandler[componentThis.id].subUnsubEventBus(action, true);
              }
            });
          }
        }
      });
    }
  },
  async _unsubscribe(componentThis) {
    const that = this;
    const onWebSocket = componentThis.model.config.data?componentThis.model.config.data.onWebSocket:null;
    //console.log('Unsubscribing widget: '+componentThis.id);


    if (that.internalHandler[componentThis.id] && onWebSocket && onWebSocket.connections && onWebSocket.connections.length > 0) {
      onWebSocket.connections.forEach(function (connection) {
        if (connection.connectionType === 'INTERNAL') {
          if (connection.actions && connection.actions.length > 0) {
            connection.actions.forEach(function (action) {
              if (action.actionType && action.actionType === 'SUBSCRIBE') {
                console.log('on Destroyed - subUnsubEventBus', action, componentThis);
                that.internalHandler[componentThis.id].subUnsubEventBus(action, false);

              }
            });
          }
        }
      });
    }
    delete that.internalHandler[componentThis.id];
  }
}

const webSocketMixinServicePlugin = {
  install(Vue, options) {
    //VertxWebSocketHandler.vue = Vue;
    webSocketMixinService.vue = Vue;

    Vue.viewMixinServices.add(webSocketMixinService.name, webSocketMixinService);
  }
}

export default webSocketMixinServicePlugin;
