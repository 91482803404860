var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.fieldForm.labelLeft
      ? _c(
          "div",
          {
            class: [
              "form-group",
              "schema-form-" + _vm.fieldForm.type,
              { "display-condensed": _vm.fieldForm.condensed },
            ],
          },
          [
            _vm.resolveShouldShowTitle(_vm.fieldForm)
              ? _c(
                  "label",
                  { class: ["control-label", _vm.fieldForm.labelHtmlClass] },
                  [_vm._v(_vm._s(_vm.fieldForm.title))]
                )
              : _vm._e(),
            _vm._t("default"),
            _vm.validationError
              ? _c("div", { staticClass: "invalid-feedback error-text" }, [
                  _vm._v(_vm._s(_vm.validationError)),
                ])
              : _vm._e(),
            _vm.fieldForm.description &&
            _vm.resolveShouldShowTitle(_vm.fieldForm)
              ? _c(
                  "div",
                  {
                    staticClass: "text-muted small help-block",
                    class: _vm.fieldForm.descriptionHtmlClass,
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.fieldForm.description),
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ],
          2
        )
      : _c(
          "div",
          {
            class: [
              "form-group",
              "schema-form-" + _vm.fieldForm.type,
              { "display-left-control": _vm.fieldForm.labelLeft },
              { "display-condensed": _vm.fieldForm.condensed },
            ],
          },
          [
            _c(
              "div",
              { staticClass: "row", staticStyle: { "align-items": "center" } },
              [
                _c("div", { staticClass: "col-4" }, [
                  _vm.resolveShouldShowTitle(_vm.fieldForm)
                    ? _c(
                        "label",
                        {
                          class: [
                            "control-label",
                            { "label-left-control": _vm.fieldForm.labelLeft },
                            _vm.fieldForm.labelHtmlClass,
                          ],
                        },
                        [_vm._v(_vm._s(_vm.fieldForm.title))]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm._t("default"),
                    _vm.validationError
                      ? _c(
                          "div",
                          { staticClass: "invalid-feedback error-text" },
                          [_vm._v(_vm._s(_vm.validationError))]
                        )
                      : _vm._e(),
                    _vm.fieldForm.description &&
                    _vm.resolveShouldShowTitle(_vm.fieldForm)
                      ? _c(
                          "div",
                          {
                            staticClass: "text-muted small help-block",
                            class: _vm.fieldForm.descriptionHtmlClass,
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.fieldForm.description),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }