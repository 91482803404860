<template>
  <div id="dashboard-simple">
    <vue-df-dashboard-simple :categories="selectedDashboard.categories"
                             :continuous-edit-mode="false"
                             :crud-service-name="crudServiceName"
                             :dashboard-name="dashboardName"
                             :dashid="selectedDashboard.dashid"
                             :default-mapper="defaultMapper"
                             :edit-mode="false"

                             :collapsible="false"
                             :editable="false"
                             :maximizable="false"

                             :options="options"

                             :model="selectedDashboard">
    </vue-df-dashboard-simple>
  </div>
</template>

<script>

  const VueDfDashboardSimple = ()=>import("vue-dashboard-framework/src/components/vuedf/containers/VueDfDashboardSimple")

  export default {
    name: "VueDynamicRoute",
    components: {VueDfDashboardSimple},
    data() {
      return {
        defaultMapper: {},
        selectedDashboard: {},
        crudServiceName: 'http',
        vueDfApplicationService: 'vueDfApplicationService',
        dashboardName: null
      }
    },
    created() {
      this.$auth.isAuthenticated();
      this.loadDashboard();
    },
    watch: {
      '$route': {
        handler(newVal, oldVal) {
         // console.log('Route changed', newVal, oldVal);
          this.loadDashboard();
        }
      }
    },
    computed:{
      options: function(){
        return {
          editable: false,
          maximizable: false,
          collapsible: false,
          refreshable: false,
          isComponentMode: this.$services[this.vueDfApplicationService].isComponentMode
        }
      }
    },
    methods: {
      loadDashboard: function () {
        const metaDef = this.$route.meta.def;
     //   console.log('Loading based on metaDef', metaDef);

        const application = this.$services[this.vueDfApplicationService].getApplicationByDashid(metaDef.parentDashid);

     //   console.log('Loading based on application', application);

        if (application) {
          this.dashboardName = application.parentDashboardName;
          this.selectedDashboard = application.dashboard;
        } else {
          console.warn('There is no application by DashboardId: ' + metaDef.parentDashid, this.$route.meta);
        }
      }
    }
  }
</script>

<style scoped>

</style>
