var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
    _c("input", {
      staticClass: "form-control",
      class: [_vm.isValid ? "" : "is-invalid", _vm.fieldForm.fieldHtmlClass],
      attrs: {
        required: _vm.fieldForm.required,
        type: "file",
        readonly: _vm.fieldForm.readonly,
      },
      on: { change: _vm.onLocalFileChanged },
    }),
    _c("div", { staticClass: "form-control file-control" }, [
      _c("span", [_vm._v(_vm._s(_vm.placeholder))]),
      _c("i", { staticClass: "fas fa-upload float-right" }),
    ]),
    !_vm.isValid
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.validationMessage)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }