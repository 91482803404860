<template>
  <button v-if="!fieldForm.hidden" :class="[fieldForm.btnStyle || 'btn-primary']" :style="fieldForm.style"
          @click.prevent="buttonClick($event,fieldForm)"
          class="btn">
    <span :class="fieldForm.icon" v-if="fieldForm.icon"></span>{{ fieldForm.title }}
  </button>
</template>

<script>
import functionDiscoveryMixin from './functionDiscoveryMixin'
import formBusMixin from './formBusMixin'
import utils from "../../utils/utils";

export default {
  name: "SfButton",
  props: ['fieldForm', 'fullModel', 'schemaFormService', 'parentFieldForm', 'parentForm', 'value'],
  mixins: [functionDiscoveryMixin, formBusMixin],
  methods: {
    findParentFieldWithKey: function (component) {
      if (component) {
        if (component.fieldForm && component.fieldForm.key) {
          return component.fieldForm;
        } else {
          return this.findParentFieldWithKey(component.$parent);
        }
      }
    },
    resolveValue: function (value, field) {
      if (!field.key) {
        return value;
      }

      const key = utils.clone(field.key);
      // console.log('Checking value for key: ' + key + ' field.schemaFieldKey: '+field.schemaFieldKey+' - in FullModel for Parent FieldForm: ', this.shouldRender, this.fullModel, field, this.fieldForm, value);
      let val = undefined;

      if (this.fullModel) {
        if (key && key.length) {
          const firstKey = field.key[0];
          if (null === this.fullModel[firstKey] || undefined === this.fullModel[firstKey]) {
            val = undefined;
          } else {
            val = utils.getValueFromModel(this.fullModel, key);
          }
        } else {
          val = this.fullModel[field.schemaFieldKey];
        }
      }

      //Note: Sometimes internal FullModel is not in Sync with ViewModel and I'll comment this: Ninel 04.04.2020
      if (null !== val && undefined !== val) {
        if (Array.isArray(val)) {
          val = Object.assign([], val);
        } else if (typeof val === 'object') {
          val = Object.assign({}, val);
        }
      }
      //console.log('val for schemaFieldKey: ' + field.schemaFieldKey + ', val: ' + val);
      return val;
    },
    buttonClick: function (event, fieldForm) {

      console.log('btnClick', this.fieldForm, this.fullModel, this.schemaFormService, this.parentFieldForm, this.parentForm, this.value);

      if (fieldForm.onClick) {
        if (fieldForm.onClick.callback) {
          this.runCallback(event, fieldForm);
        } else if (fieldForm.onClick.fn) {
          this.runFn(event, fieldForm);
        }
      }
    },
    runFn: function (event, fieldForm) {
      let options = this.resolveOptions(fieldForm.onClick.options);
      let fn = new Function("return " + fieldForm.onClick.fn)();
      if (fn) {
        this.runFunction(fn, options, event, fieldForm);
      }
    },
    runCallback: function (event, fieldForm) {
      let onClickCallback = fieldForm.onClick.callback;
      let options = this.resolveOptions(fieldForm.onClick.options);

      if (typeof (onClickCallback) === 'function') {
        onClickCallback(event, fieldForm);
      } else if (typeof onClickCallback === 'string') {
        const fn = this.lookupMethodOnParents(this, onClickCallback);
        if (fn) {
          this.runFunction(fn, options, event, fieldForm);
        }
      }
    },
    resolveOptions: function (options) {
      if (options) {
        if (typeof options === 'string') {
          try {
            options = JSON.parse(options);
          } catch (e) {
            console.warn('Button onClick options is not object type: ' + options, e);
          }
        }
        return options;
      }
      return undefined;
    },
    runFunction: function (fn, options, event, fieldForm) {
      const parentFieldWithKey = this.findParentFieldWithKey(this);
      console.log('onButtonClick parentFieldWithKey', parentFieldWithKey);
      let data = this.fullModel;
      if (parentFieldWithKey) {
        data = this.resolveValue(this.fullModel, parentFieldWithKey);
      }

      if (fn instanceof Function) {
        const _meta = {
          route: this.$route ? this.$route : null,
          appConfig: this.$appConfig ? this.$appConfig.appConfig : null,
          user: this.$userService ? this.$userService.getCurrentUser() : null
        };
        const context = {
          _meta: _meta,
          console: console,
          parentFieldForm: this.parentFieldForm,
          rest: this.$http,
          set: this.$set,
          formBus: this.formBus,
          schemaFormService: this.schemaFormService,
          parentFieldWithKey: parentFieldWithKey,
          this: this,
          data: data
        }

        fn(options, event, fieldForm, this.fullModel, context);
      } else if (fn instanceof Promise) {
        console.log('FN is promise???', fn);
      }
    }
  }
}
</script>

<style scoped>

</style>
