<template>
  <schema-form :form="form" :schema="schema" v-model="dataModel" @validationResult="onValidationResult"></schema-form>
</template>

<script>
  import findIndex from 'lodash/findIndex'
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: "PropertyEditor",
    props:['value'],
    data() {
      return {
        dataModel: {},
        schema: {
          type: 'object',
          properties: {
            props: {
              title: 'Properties',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name'
                  },
                  title: {
                    type: 'string',
                    title: 'Title'
                  },
                  dataType: {
                    type: 'string',
                    title: 'Data Type',
                    enum: ['string', 'number', 'integer', 'boolean', 'datetime']
                  },
                  type: {
                    type: 'string',
                    title: 'Type',
                    enum: ['dimension', 'metric']
                  },
                  metricType:{
                    type: 'string',
                    title: 'Metric Type',
                    enum: ['SUM', 'AVG', 'COUNT','MAX', 'MIN', 'STD_DEV'],
                    default: 'SUM',
                    condition: "model.props[form.arrayIndex].type==='metric'"
                  },
                  isCalculated:{
                    type: 'string',
                    title: 'Is Calculated',
                    default: false
                  },
                  calculatedField:{
                    type: 'string',
                    title: 'Calculated Field'
                  }
                }
              }
            }
          }
        },
        form: [
          {
            key: 'props',
            type: 'array',
            items: [
              {
                type: 'section',
                sectionContainerClass: 'row',
                sectionChildClass: 'col',
                items: [
                  {
                    key: 'props[].name',
                    type: 'text'
                  },
                  {
                    key: 'props[].title',
                    type: 'text'
                  },
                  {
                    key: 'props[].dataType',
                    type: 'select'
                  },
                  {
                    key: 'props[].type',
                    type: 'select'
                  },
                  {
                    key: 'props[].metricType',
                    type: 'select'
                  },
                  {
                    key: 'props[].isCalculated',
                    type: 'radiosinline',
                    titleMap:[{name:'True', value: 'true'}, {name: 'False', value: 'false'}]
                  }
                ]
              },
              {
                key: 'props[].calculatedField',
                condition: "model.props[form.key[1]].isCalculated==='true'",
                type: 'textarea'
              }
            ]
          },
          {
            type: 'actions',
            items:[
              {
                type: 'submit',
                title: 'Apply'
              }
            ]
          }
        ],
        content: undefined
      }
    },
    watch:{
      value: function(newVal, oldVal){
        console.log('Value in proEdtor changed', newVal);
        if (newVal!==oldVal){
          this.content = newVal;
          this.prepareData();
        }
      }
    },
    created(){
      if (!isEmpty(this.value)) {
        this.content = this.value;
        this.prepareData();
      }
    },
    methods:{
      onValidationResult: function(event){
        console.log('sending valid', event, this.dataModel.props);
        if (event.valid){
          if (this.dataModel && this.dataModel.props)
          this.$emit('input', this.dataModel.props);
        }
      },
      prepareData: function () {
        if (!isEmpty(this.content)) {
          const props = Object.assign([], this.prepareProperties(this.content));
          this.dataModel = {
            props: props
          }
          console.log('prepared props', this.dataModel);
        }else{
          this.dataModel = {};
        }
      },
      prepareProperties: function (data) {
        console.log('Received data', data);
        if (!data) return data;

        let props = [];
        const that = this;
        data.forEach(function (item) {
          for (const p in item) {
            const property = {
              title: p[0].toUpperCase()+p.substring(1),
              name: p,
              dataType: typeof item[p]
            }
            if (property.dataType === 'number' || property.dataType === 'integer') {
              property.type = 'metric';
              property.metricType='SUM';
            } else {
              property.type = 'dimension'
            }
            that.insertIfNotExists(props, property);
          }
        });
        return props;
      },
      insertIfNotExists: function (arr, item) {
        if (findIndex(arr, function (p) {
          return p.name === item.name;
        }) === -1) {
          arr.push(item);
        }
      }
    }
  }
</script>

<style scoped>

</style>
