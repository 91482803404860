<template>
  <div>
    <component-base :field-form="fieldForm">
      <div class="form-checkboxes-container" :class="[isValid ? '': 'is-invalid']">
        <div class="form-check form-check-inline" v-for="checkbox in titleMap">
          <input :readonly="fieldForm.readonly"
                 :value="checkbox[valueKey]"
                 v-model="titleMapValues"
                 :class="[fieldForm.fieldHtmlClass]"
                 :required="fieldForm.required"
                 class="form-check-input"
                 type="checkbox">
          <label class="form-check-label my-auto">{{ checkbox[labelKey] }}</label>
        </div>
      </div>
    </component-base>
  </div>
</template>

<script>
import componentMixin from './componentMixins'
import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
import titleMapBaseMixin from "./titleMapBaseMixin";
import isEqual from "lodash/isEqual";

export default {
  name: "SfCheckBoxes",
  mixins: [componentMixin, simpleComponentMergeInMixin, titleMapBaseMixin],
  data() {
    return {
      titleMapValues: []
    }
  },
  beforeMount() {
    /*if (!this.localValue && this.fieldForm.schema.default) {
      this.titleMapValues = this.fieldForm.schema.default;
    }*/
  //  console.log('checkboxes', this.titleMap, this.titleMapValues, this.internalValue, this.valueKey, this.labelKey);
  },
  methods: {

  },
  watch: {
    localValue: {
      handler(newVal){
        if (!isEqual(newVal, this.titleMapValues)){
          if (!newVal){
            this.titleMapValues = [];
          }else {
            this.titleMapValues = newVal;
          }
        }
      },
      deep: true,
      immediate: true
    },
    titleMapValues:{
      handler(newVal, oldVal){
      //  console.log('titleMapValues', newVal)
        this.internalValue = newVal;
      },
      deep: true
    },
    internalValue: {
      handler(newVal, oldVal) {
      //  console.log('Internavalue changed', newVal);
        if (!isEqual(newVal, this.localValue)) {
          if (!newVal || newVal.length === 0) {
            this.localValue = null;
          } else {
            this.localValue = newVal;
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
