<template>
  <component-base v-bind:field-form="fieldForm">
    <vue-tags-input :class="[isValid ? '': 'is-invalid', 'form-control', 'schema-form-tags-input']"
                    :required="fieldForm.required"
                    v-model="tag" :placeholder="resolvePlaceholder(fieldForm) || 'Add Tag'"
                    :disabled="fieldForm.readonly"
                    :tags="tags"
                    @tags-changed="changedTags"
    />

    <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
  </component-base>
</template>

<script>
  import VueTagsInput from '@johmun/vue-tags-input';
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
  import componentMixin from './componentMixins'

  export default {
    name: "SfTagsInput",
    mixins: [componentMixin,simpleComponentMergeInMixin],
    components: {
      VueTagsInput,
    },
    data() {
      return {
        tag: '',
        tags: []
      }
    },
    beforeMount(){

    },
    mounted() {
      const tiComponent = this.$el.querySelector('.ti-input');
      tiComponent.classList = [];
      tiComponent.classList.add('schema-ti-input');

      this.$nextTick(function () {
        if (this.localValue) {
          this.tags = this.localValue.map(text => ({text}));
        }
      })

    },
    methods: {
      changedTags: function (newTags) {
        this.tags = newTags;
        //this.localValue = newTags;
        const tagsVals = newTags.map(r => r['text']);

        this.localValue = tagsVals.length?tagsVals:undefined;
      }
    },
    watch:{
      localValue:{
        handler(newVal, oldVal){
          if (newVal!==oldVal){
            if (newVal) {
              this.tags = newVal.map(text => ({text}));
            }else{
              this.tags = [];
              this.tag = '';
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
  .schema-form-tags-input.vue-tags-input {
    max-width: 100%;
    height: 100%;
  }

  .form-group .form-control {
    padding: 5px;
  }

</style>
