<template>
  <div id="leaflet-container" :style="computedStyle"  v-if="dashboardEditMode || widgetSettings.widgetVisible">
<!--    <vue-element-loading :active.sync="isLoading"
                         :can-cancel="false" delay="0.7"
                         is-full-page="false"></vue-element-loading>-->
    <l-map ref="leafletMap"
           :zoom="geoModel.commons.zoom"
           :center="geoModel.commons.center">
      <l-tile-layer :url="url"></l-tile-layer>
      <l-polyline v-if="linesReady" v-for="polyline in geoModel.polyLines"
                  v-bind:key="polyline.name" :lat-lngs="polyline.lines" :color="polyline.color"></l-polyline>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {LMap, LTileLayer, LPolyline} from 'vue2-leaflet';
import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'

export default {
  name: "VueDfLeafletView",
  props: {
    model: {
      type: Object
    },
    dashboardName: {
      type: String
    },
    dashid: {
      type: String
    }
  },
  mixins: [widgetComponentViewMixins],
  components: {
    LMap,
    LTileLayer,
    LPolyline
  },
  data() {
    return {
      linesReady: false,
      geoModel: {},
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoomDefault: 3,
      centerDefault: [47.413220, -1.219482],
      bounds: null
    }
  },
  watch: {
    dataModel: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initData();
        }
      },
      deep: true
    }
  },
  created() {
    //this.geoModel = this.model.config.data;
    this.initData();
  },
  computed: {
    computedStyle() {
      return {
        height: '600px'
      }
    },
    __configData() {
      return this.model.config.data || {};
    },
    __commons() {
      return this.__configData.commons || {}
    },
    __polyLines() {
      return this.__configData.polyLines;
    }
  },
  methods: {
    initData() {
      console.log('initializing data');
      const data =  {
        commons: this.resolveCommons(),
        polyLines: this.resolveLinesList()
      }
      this.$set(this, 'geoModel', data);
    },
    resolveLinesList() {
      const lines = [];

      if (this.__polyLines) {
        this.__polyLines.forEach((item) => {
          const obj = {
            name: this.resolveContextObject(item.name, {}, 'string'),
            color: this.resolveContextObject(item.color, {}, 'string'),
            lines: this.resolveContextObject(item.lines, {}) || []
          }
          lines.push(obj);
        });
        this.linesReady = true;
      }
      console.log('resolved lines', lines);
      return lines;
    },
    resolveCommons() {
      const lat = this.resolveContextObject(this.__commons.lat, {});
      const lon = this.resolveContextObject(this.__commons.lon, {});
      const zoom = this.resolveContextObject(this.__commons.zoom, {});
      console.log('resolved lat, lon, zoom', lat, lon, zoom);
      let center = this.centerDefault;
      if (lat && lon){
        center = [lat, lon];
      }
      return {
        center: center,
        zoom: zoom || this.zoomDefault
      }
    },
    resolvePolylineLines(polyline) {
      let arr1 = this.resolveContextObject(polyline.lines, {});

      console.log('array lines', polyline, arr1);
      return arr1;
    },
    resolvePolylineColor(polyline) {
      const color = polyline.color;
      console.log('array color', polyline, color);
      return color;
    }
  }
}
</script>

<style scoped>

</style>
