<template>
  <schema-form :schema="resolvedSchema" :form="resolvedForm" v-model="model.config.data"></schema-form>
</template>

<script>
import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
import widgetMixin from 'vue-dashboard-framework/src/components/vuedf/widgetMixin'

export default {
  name: "VueDfLeafletEdit",
  mixins: [widgetComponentEditMixins, widgetMixin],
  props: ['crudServiceName'],
  data() {
    return {
      schemaFormCustom: {
        schemaProperties: {
          commons: {
            type: 'object',
            properties: {
              lat: {
                type: 'string',
                title: 'Latitude',
                description: 'Latitude definition'
              },
              lon: {
                type: 'string',
                title: 'Longitude',
                description: 'Longitude definition'
              },
              zoom: {
                type: 'string',
                title: 'Zoom',
                description: 'Zoom definition'
              }
            }
          },
          "polyLines": {
            "type": "array",
            "items": {
              "type": "object",
              "properties": {
                "name": {
                  "key": "name",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Name",
                  "description": "Enter line name"
                },
                "color": {
                  "key": "color",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Color",
                  "description": "Enter line color such as red, blue, green, etc."
                },
                "lines": {
                  "key": "lines",
                  "type": "string",
                  "readonly": false,
                  "hidden": false,
                  "title": "Lines",
                  "description": "Enter lines in format array lat lon: [ [lat,lon], [lat, lon], ... ] or use expression to get from data source"
                }
              },
              "required": []
            },
            "title": "{{ value.name || 'Tab '+$index }}",
            "description": "Enter lines to bi shown in different coloring"
          }
        },
        formItem: {
          title: 'Geo Map data definition',
          items: [

            {
              type: 'fieldset',
              title: 'Defaults',
              htmlClass: 'card1',
              description: 'Define default values for Geo Map or use Expressions to extract from data sources',
              key: 'commons',
              items: [
                {
                  type: 'section',
                  sectionContainerClass: "row",
                  sectionChildClass: "col",
                  items: [
                    {key: 'commons.lat', placeholder: 'Enter Latitude'},
                    {key: 'commons.lon', placeholder: 'Enter Longitude'},
                    {
                      key: 'commons.zoom',
                      placeholder: 'Enter Zoom'
                    }
                  ]
                }
              ]
            },
            {
              "type": "tabs",
              "tabs": [
                {
                  "title": "Lines",
                  items: [
                    {
                      "readonly": false,
                      "hidden": false,
                      "placeholder": "Placeholder for: {}",
                      "type": "tabarray",
                      "add": "New",
                      "remove": "Delete",
                      "btnStyle": {
                        "remove": "btn-danger"
                      },
                      "items": [
                        {
                          "items": [
                            {
                              "readonly": false,
                              "hidden": false,
                              "placeholder": "Enter name",
                              "type": "text",
                              "key": "polyLines[].name",
                              "guid": "e418245d-1c54-4204-adf1-1f3307c58cb9",
                              "name": "text",
                              "title": "Name",
                              "description": "Enter line name"
                            },
                            {
                              "readonly": false,
                              "hidden": false,
                              "placeholder": "Enter color",
                              "type": "text",
                              "key": "polyLines[].color",
                              "guid": "16adef7f-da93-472e-96e4-0722ecff9d39",
                              "name": "text",
                              "title": "Color",
                              "description": "Enter line color such as red, blue, green, etc."
                            }
                          ],
                          "type": "section",
                          sectionContainerClass: "row",
                          sectionChildClass: "col",
                          "guid": "e321bfba-3503-4a36-a19d-cee3309dbc07",
                          "name": "section"
                        },
                        {
                          "style": "min-height:150px",
                          "readonly": false,
                          "hidden": false,
                          "placeholder": "Placeholder for: {}",
                          "type": "ace",
                          "key": "polyLines[].lines",
                          "guid": "b7f3f104-6d6a-44f2-be5b-34ae17fe231f",
                          "name": "ace",
                          "title": "Lines",
                          "description": "Enter lines in format array lat lon: [ [lat,lon], [lat, lon], ... ] or use expression to get from data source"
                        }
                      ],
                      "guid": "102f4683-ccb2-498a-92b4-075b73d6bd0f",
                      "name": "tabarray",
                      "key": "polyLines"
                    }
                  ]
                },
                {
                  title: 'Polygons',
                  items: []
                },
                {
                  title: 'Markers',
                  items: []
                }
              ]
            }

          ]
        }
      }
    }
  },
  beforeMount() {
    this.mergeSchemaFormWithCustom();
  }
  ,
  methods: {
    mergeSchemaFormWithCustom: function () {

      const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
      //schemaFormSourceDestination.schema.properties.commons = this.schemaFormCustom.schemaProperties.commons;
      for (const prop in this.schemaFormCustom.schemaProperties) {
        schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
      }
      schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);

      this.filterCustomEditable(schemaFormSourceDestination.form[0].tabs);

      this.schemaForm = schemaFormSourceDestination;

      console.log('Merged with custom schema');
    }
  }
}
</script>

<style scoped>

</style>
