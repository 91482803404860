<template>
  <form onsubmit="return false;" role="form">
    <div class="row">
      <div class="col-11">
        <div class="form-group">
          <label for="dashboardTitle" v-bind:text="translate('ADF_COMMON_TITLE')">Title</label>
          <input :value="copy.title" class="form-control" id="dashboardTitle" required="" type="text"
                 v-on:input="onTitleUpdate($event)">
        </div>
      </div>
      <div class="col-1">
        <div class="form-group float-left">
          <label>Hide Title?</label>
          <input v-model="copy.hideTitle" class="form-control" @change="onHideTitleUpdate($event)" type="checkbox">
        </div>
      </div>
    </div>


    <b-tabs>
      <b-tab title="Common">
        <div class="form-group p-2">
          <label>
            <input @change="onCheckChanged" type="checkbox" v-model="copy.maximizable">
            <span> Maximizable</span>
          </label>
          <label>
            <input @change="onCheckChanged" class="ml-3" type="checkbox" v-model="copy.collapsible">
            <span> Collapsible</span>
          </label>
          <label>
            <input @change="onCheckChanged" class="ml-3" type="checkbox" v-model="copy.refreshable">
            <span> Refreshable</span>
          </label>
        </div>
        <div class="form-group p-2">
          <label v-bind:text="translate('ADF_EDIT_DASHBOARD_STRUCTURE_LABEL')">Structure</label>
          <div class="row">
            <div class="col-lg-4 col-xs-12" v-for="structureColumn in splitedStructures">
              <div class="mb-2 mt-2" v-for="(structure, key) in structureColumn">
                <div class="row">
                  <div class="col-sm-2">
                    <label>
                      <input :checked="copy.structure===key" :model="copy.structure" :value="key" name="structure"
                             type="radio"
                             v-on:change="changeStructure(key, structure)">
                    </label>
                  </div>
                  <div @click.prevent="changeStructure(key, structure)" class="col-sm-9">
                    <vue-df-dashboard-structure-preview :selected="copy.structure===key" :structure="structure"
                                                        :title="key">
                    </vue-df-dashboard-structure-preview>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Context">
        <div class="form-group ace-container row mt-1">
          <div class="col">
            <vue-ace-editor :config="aceConfig" :content="contextContent" :height="'100%'"
                            :position="'absolute'"
                            :width="'97%'"
                            @change-content="onAceValueChanged($event)"
                            v-on:input="onAceValueChanged($event)"></vue-ace-editor>
          </div>
        </div>
      </b-tab>
      <b-tab title="Widgets Settings">
        <div class="ace-container">
        <schema-form :schema="widgetsConfigSchema" :form="widgetsConfigForm"
                     :value="widgetsConfigModel" @validationResult="onValidationSchemaConfigResult"></schema-form>
        </div>
      </b-tab>
      <b-tab title="Theme">
        <div class="form-group ace-container row mt-1">
          <div class="col">
            <vue-df-dashboard-theme v-model="dashboardThemeModel" @input="onDashboardThemeModelChange"></vue-df-dashboard-theme>
            <vue-ace-editor :config="aceCssConfig" :content="cssContent" :height="'100%'"
                            :position="'absolute'"
                            :width="'97%'"
                            @change-content="onCssAceValueChanged($event)"
                            v-on:input="onCssAceValueChanged($event)"></vue-ace-editor>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </form>
</template>

<script>
  import Vue from 'vue'

  const VueDfDashboardStructurePreview = () => import("./VueDfDashboardStructurePreview");
  const VueAceEditor = () => import('vue-json-schema-form-editor/src/aceEditor/VueAceEditor')
  import {BTabs, BTab} from 'bootstrap-vue'
  import defaultMixins from './defaultMixin'
  import cloneDeep from 'lodash/cloneDeep'
  import widgetMixin from "./widgetMixin";
  import isEqual from 'lodash/isEqual'
  import VueDfDashboardTheme from "./VueDfDashboardTheme";
  export default {
    name: "VueDfDashboardEdit",
    components: {VueDfDashboardTheme, VueDfDashboardStructurePreview, BTabs, BTab, VueAceEditor},
    mixins: [defaultMixins, widgetMixin],
    props: ['structures'],
    data() {
      return {
        dashboardThemeModel: {},
        widgetsConfigModel: {},
        widgetsConfigSchema: {
          type: 'object',
          properties: {
            allowAllWidgets: {
              type: 'boolean',
              default: true,
              title: 'Allow all widgets?',
              description: 'Select if all widgets allowed to be added to Dashboards. Default: true'
            },
            editableAllWidgets: {
              type: 'boolean',
              default: true,
              title: 'Editable all widgets?',
              description: 'Select if all widgets editable. Default: true'
            },
            allowedWidgets: {
              type: 'array',
              title: 'Allowed Widgets',
              description: 'Select allowed widgets to be added to Dashboards',
              items: {
                type: 'string'
              }
            },
            editableWidgets: {
              type: 'array',
              title: 'Editable Widgets',
              description: 'Select editable widgets. These will be applied to allowed widgets.',
              items: {
                type: 'string'
              }
            }
          }
        },
        widgetsConfigForm: [
          {
            type: 'section',
            sectionContainerClass: "row",
            sectionChildClass: "col-6",
            items: [
              {
                type: 'section',
                sectionContainerClass: "row",
                sectionChildClass: "col",
                items: ['allowAllWidgets', 'editableAllWidgets']
              }
            ]
          },
          {
            type: 'section',
            sectionContainerClass: "row",
            sectionChildClass: "col",
            items:[
              {
                type: 'section',
                sectionType: 'fieldset',
                title: 'Allowed widgets',
                condition: '!model.allowAllWidgets',
                items: [
                  {
                    notitle: true,
                    key: 'allowedWidgets',
                    groupBy: 'category',
                    type: 'select',
                    rows: 10,
                    multiSelect: true,
                    options: {
                      callback: ()=>this.getWidgets()
                    }
                  }
                ]
              },
              {
                type: 'section',
                sectionType: 'fieldset',
                title: 'Editable widgets',
                condition: '!model.editableAllWidgets',
                items: [
                  {
                    notitle: true,
                    key: 'editableWidgets',
                    groupBy: 'category',
                    type: 'select',
                    rows: 10,
                    multiSelect: true,
                    options: {
                      callback: ()=>this.getWidgets()
                    }
                  }
                ]
              }
            ]
          }
        ],
        splitedStructures: [],
        copy: {},
        contextContent: '',
        aceConfig: {
          lang: 'json'
        },
        cssContent: '',
        aceCssConfig: {
          lang: 'css'
        }
      }
    },
    beforeMount() {
      this.splitedStructures = this.splitStructures(this.structures, 3);
      //this.copy = cloneDeep(this.model);
      this.$widgetStore.vueJsonMerge(this.copy, this.model);
    },
    mounted() {
      if(this.copy.context){
        this.contextContent = JSON.stringify(this.copy.context, null, 2);
      }
      if(this.copy.cssContent){
        this.cssContent = this.copy.cssContent;
      }
      if (this.copy.widgetsConfigModel){
        this.widgetsConfigModel = this.copy.widgetsConfigModel;
      }
      if (this.copy.dashboardThemeModel){
        this.dashboardThemeModel = this.copy.dashboardThemeModel;
      }
    },
    watch:{
      dashboardThemeModel: {
        handler(newVal, oldVal){
          console.log('Watch changed dashboard theme model', newVal, oldVal, this.dashboardThemeModel);
          if (newVal!==oldVal)
            this.onDashboardThemeModelChange(newVal);
        },
        deep: true,
        immediate: true
      },
      widgetsConfigModel: {
        handler(newVal, oldVal){
          console.log('Changed widgetsConfigModel', newVal, oldVal);
          //if(!isEqual(newVal, this.copy.widgetsConfigModel)){
            this.copy.widgetsConfigModel = newVal;
            this.$emit('dashboardupdated', this.copy);
          //}
        },
        deep: true
      }
    },
    methods: {
      onAceValueChanged: function (event) {
        let context = {};
        try{
          context = JSON.parse(event);
        }catch (e) {

        }
        this.copy.context = context;
        this.$emit('dashboardcontextupdated', this.copy);
      },
      onDashboardThemeModelChange: function(changedModel){
        console.log('Changed model', changedModel, this.dashboardThemeModel);
        this.copy.dashboardThemeModel = changedModel;
      },
      onCssAceValueChanged: function (event) {
        this.copy.cssContent = event;
        this.$emit('dashboardcssupdated', this.copy);
      },
      onCheckChanged: function (event) {
        this.$emit('dashboardupdated', this.copy);
      },
      isSelected: function (key) {
        const isSelected = this.copy.structure === key;
        return isSelected;
      },
      onHideTitleUpdate: function(event){
        console.log('hide title update', event, event.target.checked);
        this.$set(this.copy, 'hideTitle', event.target.checked);
       // this.copy.hideTitle = event.target.checked;
        this.$emit('hidetitleupdate', event.target.checked);

      },
      onTitleUpdate: function (event) {
        //this.copy.title = event.target.value;
        this.$set(this.copy,'title', event.target.value);
        this.$emit('titleupdate', event.target.value);
      },
      splitStructures: function (object, size) {

        const arr = [];//Object.assign([]);
        let i = 0;
        for (const prop in object) {
          const value = object[prop];
          const key = prop;

          const index = i++ % size;
          if (!arr[index]) {
            arr[index] = {};
          }
          arr[index][key] = value;
        }

        return arr;
      },
      changeStructure: function (key, structure) {

        let columns = this._readColumns(this.copy);
        let counter = 0;

        let rows = [];

        this.$set(this.copy, 'rows', cloneDeep(structure.rows));

        while (counter < columns.length) {
          counter = this._fillStructure(this.copy, columns, counter);
        }

        if (this.copy.structure !== key) {
          //this.copy.structure = key;
          this.$set(this.copy, 'structure', key);
        }

        this.splitedStructures = this.splitStructures(this.structures, 3);

        //console.log('structure changed', this.copy);
        this.$emit('dashboardupdated', this.copy);
      },
      _readColumns: function (root, columns) {

        columns = columns || [];

        if (root.rows) {
          for (let i = 0; i < root.rows.length; i += 1) {
            const row = root.rows[i];
            if (row.columns) {
              for (let j = 0; j < row.columns.length; j += 1) {
                const col = row.columns[j];
                if (!col.hasOwnProperty('rows')) {
                  columns.push(col);
                }
                // keep reading columns until we can't any more
                this._readColumns(col, columns);
              }
            }
          }
        }

        return columns;

      },
      _fillStructure: function (root, columns, counter) {
        counter = counter || 0;

        if (root.rows) {

          for (let i = 0; i < root.rows.length; i += 1) {
            const row = root.rows[i];
            for (let j = 0; j < row.columns.length; j += 1) {
              const column = row.columns[j];
              // if the widgets prop doesn't exist, create a new array for it.
              // this allows ui.sortable to do it's thing without error
              if (!column.widgets) {
                this.$set(column, 'widgets', Vue.observable([]));
              }
              // if a column exist at the counter index, copy over the column
              if (columns[counter]) {
                // do not add widgets to a column, which uses nested rows
                if (!column.rows) {
                  this._copyWidgets(columns[counter], column);
                  counter++;
                }
              }
              // run fillStructure again for any sub rows/columns
              counter = this._fillStructure(column, columns, counter);
            }
          }
        } else {
          //counter = columns.length;
        }
        return counter;
      },
      _copyWidgets(source, target) {
        if (source.widgets && source.widgets.length > 0) {
          let w = source.widgets.shift();
          while (w) {
            target.widgets.push(w);
            w = source.widgets.shift();
          }
        }
      }
    }
  }
</script>

<style scoped>
  .ace-container {
    height: 600px;
  }
</style>
