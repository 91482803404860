<template>
  <div class="header-container">
    <div :class="axisType.type.toLocaleLowerCase()" class="row header-item no-gutters"
         v-for="(axisType, index) in axisTypes">
      <div class="col-2"
           @click.prevent="onContextChanged({ type: 'builderHeader', context: { config: axisType , data:axisCollRows[axisType.seriesLayoutBy] }})">
        <div class="header-item-container align-middle h-100 d-table p-2">
          <div class="header-item-wrapper d-table-cell align-middle">
            <div class="header-item-commands">
              <ul class="list-group list-group-horizontal list-unstyled">
                <li><span title="Switch axis" class="mr-2  text-muted"
                          style="font-size: 0.7rem;">
                   <i v-if="axisType.type!=='Filters'" class="fas" @click.prevent="switchAxis(axisType, index)"
                      :class="index===1?'fa-arrow-down':'fa-arrow-up'"></i>
                </span></li>
                <li><span class="title">{{ axisType.seriesLayoutBy }} - {{ axisType.axis }}</span></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div class="col-10 header-item-content">
        <draggable :group="{ name: 'properties', pull: true, put: true }" :list="axisCollRows[axisType.seriesLayoutBy]"
                   :move="onMove" @change="onChange($event, axisType, axisCollRows[axisType.seriesLayoutBy])"
                   class="header-item-container list-group list-group-horizontal" :id="axisType.type" tag="ul">
          <li :class="{active: currentContext.context && currentContext.context.guid===property.guid}"
              class="list-group-item" v-for="(property, index) in axisCollRows[axisType.seriesLayoutBy]"
              :key="property.guid">
            <ul class="list-group list-group-horizontal list-unstyled">
              <li>
                <span @click.prevent="removeProperty(axisCollRows[axisType.seriesLayoutBy], index)"
                      class="mr-2  text-muted"
                      style="font-size: 0.7rem;">
                   <i class="fas fa-times"></i>
                </span>
              </li>
              <li v-if="property.type==='metric' || property.type==='dimension'" @click.prevent="onContextChanged({ type: 'property', source: axisType, context: property })">
                <span>{{ property.title }}</span>
              </li>
              <li v-if="property.type==='metric' && property.subType!=='filter'">
                <drop-down class="nav-item dropdown" direction="none"
                           icon="dropdown"
                           position="left"
                           tag="div">
                  <a @click.prevent="updateMetricType(property,'SUM')" class="dropdown-item" href="">SUM</a>
                  <a @click.prevent="updateMetricType(property,'AVG')" class="dropdown-item" href="">AVG</a>
                  <a @click.prevent="updateMetricType(property,'COUNT')" class="dropdown-item" href="">COUNT</a>
                  <a @click.prevent="updateMetricType(property,'MIN')" class="dropdown-item" href="">MIN</a>
                  <a @click.prevent="updateMetricType(property,'MAX')" class="dropdown-item" href="">MAX</a>
                  <a @click.prevent="updateMetricType(property,'STD_DEV')" class="dropdown-item" href="">STD_DEV</a>

                </drop-down>
              </li>
<!--              <li v-if="property.type==='dimension'" @click.prevent="onContextChanged({ type: 'property',  source: axisType, context: property })">
                <span>{{ property.title }}</span>
              </li>-->
              <li v-if="property.subType==='filter' && property.type==='dimension'">
                <drop-down-checkboxes class="nav-item dropdown" direction="none"
                                      icon="dropdown"
                                      position="left"
                                      tag="div" :value="filteredItems(property, index, axisCollRows[axisType.seriesLayoutBy])"
                                      @input="onFilterSelectUpdate($event, property, index, axisCollRows[axisType.seriesLayoutBy])">
                </drop-down-checkboxes>
              </li>
              <li v-if="property.subType==='filter' && property.type==='metric'">
                <drop-down-filter class="nav-item dropdown" direction="none"
                                  icon="dropdown"
                                  position="left"
                                  tag="div" :value="filteredItems(property, index, axisCollRows[axisType.seriesLayoutBy])"
                                      @input="onFilterSelectUpdate($event, property, index, axisCollRows[axisType.seriesLayoutBy])">
                </drop-down-filter>
              </li>
            </ul>
          </li>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import DropDownFilter from "../ui/DropDownFilter";

export default {
  name: "BuilderHeader",
  components: {draggable, DropDownFilter},
  props: {
    data:Array,
    context: {
      type: Object,
      default: function(){
        return {};
      }
    }
  },
  data() {
    return {
      axisCollRows: {
        columns: [],
        rows: [],
        filters: []
      },
      axisTypes: [
        {type: 'Filters', axis: 'filters', seriesLayoutBy: 'filters'},
        {type: 'Dimensions', axis: 'xAxis', seriesLayoutBy: 'columns'},
        {type: 'Metrics', axis: 'yAxis', seriesLayoutBy: 'rows'}

      ],
      columns: [],
      rows: [],
      currentContext: this.context
    }
  },
  watch: {
    axisCollRows: {
      handler: function (newVal, oldVal) {
        const changed = {dimensions: newVal.columns, metrics: newVal.rows};
        console.log('axisCollRows changed', changed);
        this.$emit('calculated', changed);
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    onFilterSelectUpdate: function () {
      console.log('onFilterSelectUpdate', arguments);
    },
    filteredItems: function (property, index, list) {
      console.log('Data and property', this.data, property);
      const res = this.data.map(function (item) {
        return item[property.name];
      });

      property.filters = [];


      res.unshift('Select All');

      return Array.from(new Set(res));
    },
    switchAxis: function (axisType, index) {
      console.log('Switch axis', axisType, index);
      const columns = this.axisCollRows.columns;
      const rows = this.axisCollRows.rows;
      this.axisCollRows.rows = columns;
      this.axisCollRows.columns = rows;
    },
    onContextUpdated: function(context){

    },
    onContextChanged: function (context) {
      console.log('contextchanged', context);
      this.currentContext = context;
      this.$emit('contextchanged', context);
    },
    updateMetricType: function (property, metricType) {

        property.metricType = metricType;
        let metricTypeName = metricType.toLowerCase();
        metricTypeName = metricTypeName[0].toUpperCase() + metricType.substring(1).toLowerCase();
        property.title = metricTypeName + '_' + property.originalTitle;
        console.log('updated metric type and title', property, metricType);
        return property.metricType + '(' + property.originalTitle + ')';
    },
    removeProperty: function (collection, index) {
      collection.splice(index, 1);
    },
    /*  onMetricEnd: function (event) {
        console.log('Metric end', event);
      },*/
    /* onMetricChange: function (event) {
       /!*console.log('Metric change', event);
       if (event.added || event.moved) {
         if (event.element && !event.element.metricType) {
           event.element.metricType = 'SUM';
         }
       }*!/
     },*/

    onChange: function (changedItem, axisType, list) {
      console.log('onChange', changedItem, axisType, list);
      if (axisType.type === 'Filters' || axisType.type === 'Dimensions') {
        if (changedItem.removed) {
          return;
        }
        let newIndex = null;
        if (changedItem.added) {
          newIndex = changedItem.added.newIndex;
        } else if (changedItem.moved) {
          newIndex = changedItem.moved.newIndex;
        }
        if (axisType.type === 'Filters') {
          if (newIndex != null && list[newIndex]) {
            list[newIndex].subType = 'filter'
          }
        }

        let newItem = list[newIndex];
        let filtered = list.filter(function (item) {
          return item.name === newItem.name;
        });
        if (filtered && filtered.length > 1) {
          list.splice(newIndex, 1);
        }
      }
    },
    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
      console.log('onMove', evt, originalEvent);
      if ((evt.from.id === 'Dimensions' || evt.from.id === 'Metrics') && evt.to.id === 'Filters') {
        return false;
      } else if (evt.from.id === 'Filters' && (evt.to.id === 'Dimensions' || evt.to.id === 'Metrics')) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped>


</style>
