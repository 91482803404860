<template>
  <div class="expression-designer-board-container">
    <draggable :group="{ name: 'els',  put: true }" :move="onMoveInActivities"
               class="droppable-container d-inline-flex context-free"
               tag="div" v-model="elements">
      <div :key="elem.id" v-for="elem in elements" class="context-free">

        <div class="element ml-2">
          <component :id="elem.id" :is="resolveComponent(elem)"
                     @internalValueChanged="onInternalValueChanged($event, elem)"
                     :value="elem"></component>
          <b-popover :offset="popoverOffset(elem)" :target="elem.id" triggers="hover" placement="top">
            <element-toolbar @onAction="onToolbarAction($event, elem)" :element="elem"></element-toolbar>
          </b-popover>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import ExpressionFunction from "./elements/ExpressionFunction";
import ExpressionOperator from "./elements/ExpressionOperator";
import ExpressionToken from "./elements/ExpressionToken";
import ElementToolbar from "./elements/support/ElementToolbar";
import {BPopover} from 'bootstrap-vue'

export default {
  name: "ExpressionDesignerBoard",
  components: {ExpressionFunction, ExpressionOperator, ExpressionToken, ElementToolbar, BPopover},
  data() {
    return {
      elements: [],
      showToolbar: {}
    }
  },
  watch: {
    elements: {
      handler(newVal) {
        this.$emit('onChange', newVal);
      }
    }
  },
  methods: {
    popoverOffset(elem) {
      console.log('popoverOffset', elem);
      if (elem.type==='FN'){
        return -50;
      }
      return 0;
    },
    onToolbarAction($event, elem) {
      console.log('onToolbarAction', $event, elem);
      const index = this.elements.findIndex((item) => {
        return item.id === $event.element.id
      });
      if (index > -1) {
        this.elements.splice(index, 1);
      }
    },
    resolveComponent(elem) {
      console.log('elem', elem);
      switch (elem.type) {
        case 'FN':
          return 'ExpressionFunction';
        case 'OP':
          return 'ExpressionOperator';
        case 'TOKEN':
          return 'ExpressionToken';
      }
      return 'ExpressionToken';
    },
    onInternalValueChanged($event, elem) {
      console.log('on Internal value cahnged in parent', $event, elem);
      this.$set(elem, 'internalValue', $event);
    },
    onMoveInActivities({related, relatedContext, draggedContext}) {
      console.log('related', related);
      return true;
    },
    onHover(elem) {
      this.$set(this.showToolbar, elem.id, elem);
      console.log('onHover', elem, this.showToolbar);
    },
    onHoverOut(elem) {
      this.$set(this.showToolbar, elem.id, undefined);
      console.log('onHover out', elem, this.showToolbar);
    }
  }
}
</script>

<style scoped>
.droppable-container {
  min-width: 200px;
  min-height: 100px;
  border: 1px dotted #ef8157;
  padding: 5px 5px 20px 5px;
}
</style>
