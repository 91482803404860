<template>
  <div :class="selectedItem.guid===field.guid?'selected':''" class="toolbar" v-if="isDroppable">
    <div class="toolbar-wrapper">
      <div class="toolbar-container">

        <a href="" class="handle text-success" @click.prevent="" title="Use to drag component"><i class="fas fa-arrows-alt mr-4"></i></a>
        <a :title="minimized?'Maximize component':'Minimize component'" @click.prevent="minimize()"
           class="text-warning mr-2"
           href=""><i
          :class="minimized?'fas fa-plus':'fas fa-minus'"></i></a>
        <a @click.prevent="selectItem(field)" class="text-primary mr-2" href=""
           title="Select item to update settings"> <i
          class="fas fa-check"></i></a>
        <a @click.prevent="removeFormField(field)" class="text-danger mr-2" href="" title="Remove component"><i
          class="fas fa-trash"></i></a>
        <span class="text-muted small">{{guid}}</span> - <span
        class="text-muted small"> Type: {{field.type}} </span>
      </div>
    </div>
  </div>
</template>

<script>
  import findIndex from 'lodash/findIndex'
  import utils from '../../../utils/utils'

  export default {
    name: "SfComponentToolbar",
    props: {
      minimized: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      guid: {
        type: String
      },
      clonedDroppedComponents: {
        type: Array
      },
      selectedItem: {
        type: Object
      },
      field: {
        type: Object
      },
      isDroppable: {
        type: Boolean,
        default: function () {
          return false
        }
      }
    },
    data() {
      return {
        isMinimized: false
      }
    },
    created() {
      this.isMinimized = this.minimized;
    },
    beforeMount() {
      this.isMinimized = this.minimized;
    },
    methods: {
      minimize: function () {

    //    console.log('Click minimized', this.isMinimized, this.guid, this.field, this.clonedDroppedComponents);

        this.isMinimized = !this.isMinimized;
        this.$emit('isminimized', {status: this.isMinimized, guid: this.guid});
       // this.field.isMinimized = this.isMinimized;

      },
      selectItem: function (formField) {
       // console.log('Set Selected', formField);
        const cloned = utils.clone(formField);
        this.$schemaFormEditorStore.setSelected(cloned);
        this.$bus.$emit("selectedItem", cloned);
      }
      ,
      removeFormField: function (formField) {
      //  console.log('remove form field', formField);

        const guid = formField.guid;
        const cloneIdx = findIndex(this.clonedDroppedComponents, function (item) {
          return item.guid === guid;
        });
        if (cloneIdx > -1) {
          this.clonedDroppedComponents.splice(cloneIdx, 1);
          this.$emit('schemaChanged', this.clonedDroppedComponents);
        } else {
          console.error('There is no item with guid: ' + guid, formField, this.clonedDroppedComponents);
        }
      }
    }
  }
</script>

<style scoped>

  .toolbar {
    background-color: #f4f4f4;
  }

  .toolbar.selected {
    background-color: #f4e3bb;
  }

  .list-inline, .list-inline-item {
    margin-bottom: 0px;
  }

</style>
