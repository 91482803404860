import findIndex from "lodash/findIndex";

export default class EventBusSubscriptions {
  constructor() {

  }

  static addressWidgetHandlers = {}

  register(handler, widgetId){
    //console.log('Registering handler for widgetId: '+widgetId, handler);

    if (!EventBusSubscriptions.addressWidgetHandlers[handler.address]){
      EventBusSubscriptions.addressWidgetHandlers[handler.address] = [];
    }

    const indexOfItem = findIndex(EventBusSubscriptions.addressWidgetHandlers[handler.address] , { widgetId: widgetId} );

    if (indexOfItem === -1) {
      EventBusSubscriptions.addressWidgetHandlers[handler.address].push({
        widgetId: widgetId,
        handler: handler
      });
    }
    return this.size(handler.address)
  }

  exists(address){
    return EventBusSubscriptions.addressWidgetHandlers[address] && EventBusSubscriptions.addressWidgetHandlers[address].length>0?true:false;
  }

  static get(address){
    //console.log('in Static Get: '+address, EventBusSubscriptions.addressWidgetHandlers);
    return EventBusSubscriptions.addressWidgetHandlers[address];
  }

  size(address){
    const items = EventBusSubscriptions.addressWidgetHandlers[address];
    if (items){
      return items.length;
    }
  }

  unregister(address, id){ // when other widget is clicked - this is undefined???
    console.log('UnRegistering handler for widgetId: '+id, address);

    if (EventBusSubscriptions.addressWidgetHandlers[address]){
      const indexOfWidget = findIndex(EventBusSubscriptions.addressWidgetHandlers[address], {widgetId: id});
      if (indexOfWidget>-1){
        EventBusSubscriptions.addressWidgetHandlers[address].splice(indexOfWidget, 1);
      }
    }

    if (EventBusSubscriptions.addressWidgetHandlers[address] && EventBusSubscriptions.addressWidgetHandlers[address].length===0){
      delete EventBusSubscriptions.addressWidgetHandlers[address];
    }

    return this.size(address)
  }
}
