var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b(
      { staticClass: "dashboard-container", class: _vm.resolveTheme },
      "div",
      _vm.dataId,
      false
    ),
    [
      _c(
        "div",
        _vm._b({ staticClass: "toolbar-container" }, "div", _vm.dataId, false),
        [
          !_vm.dashboardCopy.hideTitle || _vm.isEditMode
            ? _c(
                "div",
                _vm._b(
                  { staticClass: "dashboard-title" },
                  "div",
                  _vm.dataId,
                  false
                ),
                [
                  _c("h3", [
                    _vm.isEditMode
                      ? _c("span", [_vm._v(_vm._s(_vm.dashid) + "  ")])
                      : _vm._e(),
                    _c("span", { key: _vm.count }, [
                      _vm._v(_vm._s(_vm.dashboardCopy.title)),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.options.editable
            ? _c(
                "div",
                {
                  staticClass: "dashboard-toolbar",
                  class: ["editable", { editing: _vm.isEditMode }],
                },
                [
                  _c("span", { staticClass: "icon-container" }, [
                    _vm.isEditMode
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title:
                                _vm.translate(
                                  "ADF_DASHBOARD_TITLE_TOOLTIP_ADD"
                                ) || "Add new widget",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addWidgetDialog()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-plus" })]
                        )
                      : _vm._e(),
                    _vm.isEditMode
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title:
                                _vm.translate("ADF_COMMON_EDIT_DASHBOARD") ||
                                "Edit Dashboard",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editDashboardDialog()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-cog" })]
                        )
                      : _vm._e(),
                    _vm.isEditMode
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title:
                                _vm.translate(
                                  "ADF_COMMON_EDIT_CUSTOM_WIDGETS"
                                ) || "Edit Custom Widgets",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editCustomWidgetsDialog()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-wrench" })]
                        )
                      : _vm._e(),
                    _vm.options.editable
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title: _vm.isEditMode
                                ? _vm.translate(
                                    "ADF_DASHBOARD_TITLE_TOOLTIP_SAVE"
                                  ) || "Save Changes"
                                : _vm.translate(
                                    "ADF_DASHBOARD_TITLE_TOOLTIP_EDIT_MODE"
                                  ) || "Edit",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleEditMode()
                              },
                            },
                          },
                          [
                            _c("i", {
                              class: {
                                "far fa-edit": !_vm.isEditMode,
                                "far fa-save": _vm.isEditMode,
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.isEditMode
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title:
                                _vm.translate(
                                  "ADF_DASHBOARD_TITLE_TOOLTIP_EXPORT"
                                ) || "Export Dashboard",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.exportDashboard()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-file-export" })]
                        )
                      : _vm._e(),
                    _vm.isEditMode
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title:
                                _vm.translate(
                                  "ADF_DASHBOARD_TITLE_TOOLTIP_IMPORT"
                                ) || "Import Dashboard",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.importDashboard()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-file-import" })]
                        )
                      : _vm._e(),
                    _vm.isEditMode
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title:
                                _vm.translate(
                                  "ADF_DASHBOARD_TITLE_TOOLTIP_IMPORT_WIDGET"
                                ) || "Import Widget",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.importWidget()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-upload" })]
                        )
                      : _vm._e(),
                    _vm.isEditMode
                      ? _c(
                          "a",
                          {
                            attrs: {
                              title:
                                _vm.translate(
                                  "ADF_DASHBOARD_TITLE_TOOLTIP_UNDO"
                                ) || "Undo Changes",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cancelEditMode()
                              },
                            },
                          },
                          [_c("i", { staticClass: "adf-flip fas fa-redo" })]
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "InsertWidgets",
          attrs: {
            scrollable: true,
            title:
              _vm.translate("ADF_DASHBOARD_TITLE_ADD_WIDGETS") || "Add Widgets",
            "hide-header-close": "",
            lazy: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-only": "",
            size: "xl",
          },
          on: {
            show: function ($event) {
              return _vm.loadWidgets()
            },
          },
        },
        [
          _c("vue-df-dashboard-add-widget", {
            attrs: {
              "dashboard-name": _vm.dashboardName,
              "dashboard-service-instance": _vm.dashboardService,
              dashid: _vm.dashid,
              "default-mapper": _vm.defaultMapper,
              "edit-mode": _vm.isEditMode,
              model: _vm.dashboardCopy,
              permissions: _vm.permissions,
              widgets: _vm.widgets,
            },
            on: {
              addwidget: function ($event) {
                return _vm.addWidget($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "EditDashboardModal",
          attrs: {
            scrollable: true,
            title:
              _vm.translate("ADF_DASHBOARD_TITLE_EDIT_DASHBOARD") ||
              "Edit Dashboard",
            "hide-header-close": "",
            lazy: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-only": "",
            size: "xl",
          },
        },
        [
          _c("vue-df-dashboard-edit", {
            attrs: { model: _vm.dashboardCopy, structures: _vm.structuresCopy },
            on: {
              dashboardcontextupdate: function ($event) {
                return _vm.onDashboardContextUpdated($event)
              },
              dashboardcssupdated: function ($event) {
                return _vm.onDashboardCssUpdated($event)
              },
              dashboardupdated: function ($event) {
                return _vm.onDashboardUpdated($event)
              },
              titleupdate: function ($event) {
                return _vm.onDashboardTitleUpdate($event)
              },
              hidetitleupdate: function ($event) {
                return _vm.onDashboardHideTitleUpdate($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "EditCustomWidgetsModal",
          attrs: {
            scrollable: true,
            title:
              _vm.translate("ADF_DASHBOARD_TITLE_EDIT_CUSTOM_WIDGETS") ||
              "Edit Custom Widgets",
            "hide-header-close": "",
            lazy: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-only": "",
            size: "xl",
          },
        },
        [
          _c("vue-df-dashboard-edit-custom-widget", {
            attrs: {
              "crud-service-name": _vm.crudServiceName,
              "dashboard-name": _vm.dashboardName,
              widgets: _vm.widgets,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "uploadModal",
          attrs: { title: "Upload Dashboard Definition", lazy: "" },
          on: { ok: _vm.onUploadInput },
        },
        [
          _c("b-form-file", {
            ref: "file-upload",
            staticClass: "mb-2",
            model: {
              value: _vm.uploadedFile,
              callback: function ($$v) {
                _vm.uploadedFile = $$v
              },
              expression: "uploadedFile",
            },
          }),
          _c(
            "b-form-checkbox",
            {
              model: {
                value: _vm.shouldMergeNewUpload,
                callback: function ($$v) {
                  _vm.shouldMergeNewUpload = $$v
                },
                expression: "shouldMergeNewUpload",
              },
            },
            [_vm._v("Should merge in")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "uploadWidgetModal",
          attrs: { title: "Upload Widget Definition", lazy: "" },
          on: { ok: _vm.onWidgetUploadInput },
        },
        [
          _c("b-form-file", {
            ref: "file-upload",
            staticClass: "mb-2",
            model: {
              value: _vm.uploadedFile,
              callback: function ($$v) {
                _vm.uploadedFile = $$v
              },
              expression: "uploadedFile",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        _vm._b(
          {
            key: _vm.count,
            staticClass: "dashboard",
            class: { edit: _vm.isEditMode },
          },
          "div",
          _vm.dataId,
          false
        ),
        _vm._l(_vm.dashboardCopy.rows, function (row, index) {
          return _c("vue-df-dashboard-row", {
            key: index,
            attrs: {
              "continuous-edit-mode": _vm.continuousEditMode,
              "crud-service-name": _vm.crudServiceName,
              "dashboard-name": _vm.dashboardName,
              "dashboard-service-instance": _vm.dashboardService,
              dashid: _vm.dashid,
              "default-mapper": _vm.defaultMapper,
              "edit-mode": _vm.isEditMode,
              "input-context": _vm.inputContext,
              model: _vm.dashboardCopy,
              options: _vm.options,
              permissions: _vm.permissions,
              row: row,
              "parent-widget-id": _vm.parentWidgetId,
              "parent-dashid": _vm.parentDashid,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }