<template>
  <!--v-if="stateViewConfig.show"-->
  <draggable :class="isDroppable?'droppable-container':''" :disabled="!isDroppable"
             :group="{ name: 'components', pull: true, put: true }" :handle="'.handle'"
             :list="innerList"
             @add="onAddActions" @change="onChangeActions" class="schema-form-actions-container"
             tag="div">
    <div :class="fieldForm.htmlClass" :style="fieldForm.style" class="btn-group schema-form-actions" role="group"
         v-if="shouldUpdate">
      <template :class="fieldForm.fieldHtmlClass" class="schema-form-actions-component"
                v-for="(item, index) in buttons">
        <sf-component-toolbar :cloned-dropped-components="cleanList" :field="item"
                              :guid="item.guid" :is-droppable="isDroppable"
                              :selected-item="selectedItem" @isminimized="onIsMinimized(item, $event)"
                              @schemaChanged="onToolbarSchemaChanged">
        </sf-component-toolbar>
        <component :field-form="item" :full-model="fullModel" :parent-field-form="fieldForm" :is="resolveFieldComponent(item)"
                   :schema-form-service="schemaFormService"
                   v-show="!(itemState.minimizedItems[item.guid])">
        </component>

      </template>
    </div>

  </draggable>
</template>

<script>
  import utils from '../../utils/utils'
  import droppableMixins from './droppableMixins'
  import buildMixins from './buildMixins'
  import findIndex from 'lodash/findIndex'
  import schemaFormElements from './schemaFormElements'
  import SfChangePosition from "./utilComponents/SfChangePosition";
  import minimizeMixin from './minimizeMixin'

  export default {
    name: 'SfActions',
    props: ['value', 'fieldSchema', 'fieldForm', 'fieldMapper', 'fullModel'],
    mixins: [droppableMixins, buildMixins, minimizeMixin],
    components: {
      SfChangePosition
    },
    created() {
      const that = this;
      if (this.isDroppable) {// only if we are in Edit mode
        this.$bus.$on('selectedItem', function (item) {
          that.onSelectedItem(item);
        });
      }
      delete this.fieldForm.key;
      delete this.fieldForm.schemaKey;
      delete this.fieldForm.schemaFieldKey;

      this.init();
    },
    data() {
      return {
        selectedItem: {},
        innerList: [],
        cleanList: [],
        shouldUpdate: true,
        allowed: ['submit', 'reset', 'button']
      }
    },

    computed: {
      buttons: function () {
        const that = this;
        return this.fieldForm.items.filter(function (item) {
          return that.resolveCondition(item, that.parentForm);
        });
        //return this.fieldForm.items;
      }
    },
    methods: {
      onArrayValuesChanged: function () {
        //this.updateFormItems();
      },
      init: function () {

        if (this.fieldForm.items) {
          const that = this;
          this.cleanList = []
          this.innerList = [];

          this.fieldForm.items.forEach(function (item) {
            const obj = utils.clone(item);
            obj.name = obj.type;
            that.cleanList.push(obj);
            that.innerList.push(obj);
          });
        }
      },
      synchronizeLists: function (fromList, toList) {
        // const that = this;
        const removedIndexes = [];
        for (let i = 0; i < toList.length; i++) {
          let item = toList[i];
          if (findIndex(fromList, function (cItem) {
            return cItem.guid === item.guid;
          }) === -1) {
            removedIndexes.push(i);
          }
        }
        removedIndexes.forEach(function (index) {
          toList.splice(index, 1);
        });
      },
      onToolbarSchemaChanged: function (schema) {
        console.log('On Toolbar change', schema);

        this.synchronizeLists(this.cleanList, this.innerList);
        this.updateActionItems();
      },
      resolveSchemaFormItem: function (item) {
        const element = utils.clone(schemaFormElements.elements[item.name]);
        element.form.guid = item.guid;
        return element;
      },
      updateActionItems: function () {
        this.shouldUpdate = false;
      //  console.log('Update Action items', this.cleanList);
        const that = this;
        // const preparedList = [];
        that.fieldForm.items = [];
        this.cleanList.forEach(function (item) {
          let schemaFormItem = that.resolveSchemaFormItem(item);
          schemaFormItem = Object.assign(schemaFormItem.form, item);
          that.fieldForm.items.push(schemaFormItem);
        });
        this.shouldUpdate = true;

        delete this.fieldForm.key;
        delete this.fieldForm.schemaKey;
        delete this.fieldForm.schemaFieldKey;

        this.$emit('internalSchemaChanged', {schemaFormComponent: that.fieldForm});
      },
      onEndActions: function (event) {
        console.log('Actions onEnd', event);
      },
      onChangeActions: function (event) {
        console.log('Actions onChange', event, this.cleanList, this.innerList);
        if (event.removed) {
          this.synchronizeLists(this.innerList, this.cleanList);
          this.updateActionItems();
        }
      },
      onAddActions: function (event) {
        console.log('Actions onAdd', event);

        const addedObjectIndex = findIndex(this.innerList, function (item) {
          return item.guid === event.item._underlying_vm_.guid; //TODO - is there better option???
        });

        const component = this.innerList[addedObjectIndex];

        if (this.allowed.indexOf(component.name) > -1) {
          this.cleanList.splice(addedObjectIndex, 0, component);
          this.updateActionItems();
        } else {
          this.innerList.splice(addedObjectIndex, 1);
        }
      },

    /*  resolveActions: function () {
        return this.fieldForm.items;
      }*/
    }
  }
</script>

<style scoped>

  .droppable-container .schema-form-actions, .schema-form-actions-component {
    width: 100%;
    display: block;
  }

  .droppable-container .schema-form-actions-component {
    border: 1px solid #d0fdb3;
  }


  .droppable-container .schema-form-actions-component {
    border: 1px dotted #e3f9eb;
  }

</style>
