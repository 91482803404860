<template>
  <b-modal :scrollable="true" :title="widget.title" @ok="handleEditConfirmed" lazy ref="modalEdit" size="full">
    <schema-form :form="schemaForm.form" :schema="schemaForm.schema"
                 ref="schemaForm" v-model="widgetDefinitionModel"></schema-form>
    {{ widgetDefinitionModel }}
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue'
import notifyMixin from './notifyMixin'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

export default {
  name: "VueDfDashboardWidgetSaveAs",
  components: {BModal},
  mixins: [notifyMixin],
  props: {
    crudServiceName: String,
    definition: Object,
    dashboardName: String,
    widget: Object,
    isEdit: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data() {
    return {
      editDefinition: {},
      widgetEditMode: false,
      widgetDefinitionModel: {},
      schemaForm: {
        schema: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              title: 'New Name',
              description: 'Enter new Widget type name'
            },
            type: {
              type: 'string',
              title: 'Type',
              description: 'Enter new Type for the widget.'
            },
            category: {
              type: 'string',
              title: 'Category',
              description: 'Enter Category name under which new Widget type will appear. If empty existing will be copied'
            },
            description: {
              type: 'string',
              title: 'Description',
              description: 'Enter new Description for the widget. If empty existing will be copied'
            },
            title: {
              type: 'string',
              title: 'Title',
              description: 'Enter new Title for the widget. If empty existing will be copied'
            },
            isPublic: {
              type: 'boolean',
              title: 'Is Public',
              description: 'Select to allow other users to use the widget'
            },
            editableSettings: {
              type: 'object',
              properties: {
                editable: {
                  type: 'boolean',
                  title: 'Editable',
                  description: 'If Editable is false, user will not be able to Edit widget properties, but only add to the Dashboard'
                },
                commonTabs: {
                  type: 'array',
                  title: 'Common Tabs',
                  description: 'Select one or more common Tabs to allow edit',
                  default: ['ON_LOAD', 'ON_SUBMIT', 'ON_EVENT', 'ON_WEBSOCKET', 'STYLING']
                },
                customTabs: {
                  type: 'array',
                  description: 'Enter Editable Tabs Index (number). Only specified tabs will be available for editing by the user',
                  title: "{{ value || 'Tab '+$index }}",
                  items: {
                    type: 'object',
                    properties: {
                      index: {
                        type: 'integer',
                        title: 'Index',
                        description: 'Enter index of the tab'
                      },
                      properties: {
                        type: 'array',
                        title: 'Properties',
                        description: 'Enter list of editable properties. If empty, all properties are editable'
                      }
                    }
                  }
                }
              }
            },
            ui: {
              type: 'object',
              title: 'UI configurable Tabs',
              properties: {
                tabs: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      title: {
                        type: 'string',
                        title: 'Title',
                        description: 'Tab title visible on the Edit UI for the widget user'
                      },
                      sourceType: {
                        type: 'string',
                        title: 'Source Tab Type',
                        description: 'Copy Tab configuration UI from existing widget'
                      },
                      sourceWidget: {
                        type: 'object',
                        title: 'Source Widget',
                        description: 'Copy from existing widget',
                        properties: {
                          widget: {
                            type: 'string',
                            title: 'Widget',
                            description: 'Select existing widget to copy UI configuration from'
                          },
                          tabs: {
                            type: 'array',
                            title: 'Tabs',
                            description: 'Define tabs and properties to copy from',
                            items: {
                              type: 'object',
                              properties: {
                                tabIndex: {
                                  type: 'integer',
                                  title: 'Tab Index',
                                  description: 'Define tab index to copy from',
                                  default: 0
                                },
                                propNames: {
                                  type: 'array',
                                  title: 'Property names',
                                  description: 'Define property names to copy from. Leave blank to copy all properties',
                                  items: {
                                    type: 'string'
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      advanced:{
                        type: 'object',
                        properties:{
                          schemaForm: {
                            type: 'object',
                            properties: {
                              schema: {
                                type: 'object',
                                properties: {}
                              },
                              form: {
                                type: 'array',
                                items: {
                                  type: 'object',
                                  properties: {}
                                }
                              }
                            }
                          },
                          useMapper: {
                            type: 'boolean',
                            title: 'Use Mapper?',
                            description: 'Select if you need to apply custom mapping, otherwise Designed UI will be applied to each underlying widget. This si suitable for simple composite widgets.'
                          },
                          mapper:{
                            type: 'array',
                            items:{
                              type: 'object',
                              properties:{
                                widget: {
                                  type: 'string',
                                  title: 'Widget',
                                  description:'Select widget to apply field mapper'
                                },
                                fieldMapper:{
                                  type: 'array',
                                  title: 'Field Mapper',
                                  description:'Field Mapper used to map fields between Designer and inner Widget',
                                  items:{
                                    type: 'object',
                                    properties:{
                                      designerFieldName:{
                                        type: 'string',
                                        title: 'Designer Field Name'
                                      },
                                      widgetFieldName:{
                                        type: 'string',
                                        title: 'Widget Field Name'
                                      }
                                    }
                                  }
                                }
                              }
                            }

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          required: ['name', 'type', 'category']
        },
        form: [
          {
            type: 'tabs',
            tabs: [
              {
                title: 'Common',
                items: [
                  {
                    type: 'section',
                    sectionContainerClass: "row",
                    sectionChildClass: "col",
                    items: [
                      'name', 'category', 'type', 'title', 'isPublic', 'editableSettings.editable'
                    ]
                  },
                  {
                    key: 'description',
                    type: 'textarea'
                  },
                  {
                    type: 'section',
                    condition: 'model.editableSettings.editable',
                    sectionType: 'fieldset',
                    title: 'Editable Tab Index Settings',
                    items: [
                      {
                        key: 'editableSettings.commonTabs',
                        type: 'select',
                        multiSelect: true,
                        titleMap: [
                          {name: 'On Load', value: 'ON_LOAD'},
                          {name: 'On Submit', value: 'ON_SUBMIT'},
                          {name: 'On Event', value: 'ON_EVENT'},
                          {name: 'On Websocket', value: 'ON_WEBSOCKET'},
                          {name: 'Styling', value: 'STYLING'}
                        ]
                      },
                      {
                        type: 'section',
                        sectionType: 'fieldset',
                        title: 'Custom Tabs',
                        items: [
                          {
                            key: 'editableSettings.customTabs',
                            type: 'tabarray',
                            title: "{{ 'Tab '+$index }}",
                            items: [
                              {
                                key: 'editableSettings.customTabs[].index'
                              },
                              {
                                key: 'editableSettings.customTabs[].properties',
                                type: 'tags'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                title: 'UI',
                items: [
                  {
                    type: 'fieldset',
                    key: 'ui',
                    items: [
                      {
                        key: 'ui.tabs',
                        type: 'tabarray',
                        title: "{{ value.title || 'Tab '+$index }}",
                        items: [
                          {
                            type: 'section',
                            items: [
                              {
                                type: 'section',
                                sectionContainerClass: "row",
                                sectionChildClass: "col",
                                items: [
                                  {
                                    key: 'ui.tabs[].title'
                                  },
                                  {
                                    key: 'ui.tabs[].sourceType',
                                    type: 'radiosinline',
                                    titleMap: [
                                      {
                                        name: 'Copy from Widget',
                                        value: 'COPY_FROM_WIDGET'
                                      },
                                      {
                                        name: 'Advanced',
                                        value: 'ADVANCED'
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            type: 'section',
                            condition: 'model.ui.tabs[form.arrayIndex].sourceType==="COPY_FROM_WIDGET"',
                            items: [
                              {
                                type: 'section',
                                items: [
                                  {
                                    key: 'ui.tabs[].sourceWidget',
                                    type: 'fieldset',
                                    items: [
                                      {
                                        type: 'section',
                                        items: [
                                          {
                                            key: 'ui.tabs[].sourceWidget.widget',
                                            type: 'select',
                                            options: {
                                              callback: this.getCurrentWidgetsTree
                                            }
                                          },
                                          {
                                            key: 'ui.tabs[].sourceWidget.tabs',
                                            type: 'tabarray',
                                            items: [
                                              {
                                                type: 'section',
                                                items: [
                                                  {
                                                    key: 'ui.tabs[].sourceWidget.tabs[].tabIndex'
                                                  },
                                                  {
                                                    key: 'ui.tabs[].sourceWidget.tabs[].propNames',
                                                    type: 'tags'
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            type: 'section',
                            condition: 'model.ui.tabs[form.arrayIndex].sourceType==="ADVANCED"',
                            items: [
                              {
                                type: 'fieldset',
                                key: 'ui.tabs[].advanced',
                                title: 'Advanced',
                                items: [
                                  {
                                    type: 'tabs',
                                    tabs:[
                                      {
                                        title:'Designed',
                                        items:[
                                          {
                                            key: 'ui.tabs[].advanced.schemaForm',
                                            type: 'schemaFormEditor',
                                            enableConfig: true,
                                            enableElements: true,
                                            editorClass: 'col',
                                            elementClass: 'col-2',
                                            configClass: 'col-3'
                                          }
                                        ]
                                      },
                                      {
                                        title: 'Mapper',
                                        items:[
                                          {
                                            key: 'ui.tabs[].advanced.useMapper'
                                          },
                                          {
                                            type: 'tabarray',
                                            key: 'ui.tabs[].advanced.mapper',
                                            title: "{{ value.widget || 'Tab '+$index }}",
                                            condition: 'model.ui.tabs[form.key[2]].advanced.useMapper',
                                            items:[
                                              {
                                                key: 'ui.tabs[].advanced.mapper[].widget',
                                                type: 'select',
                                                options: {
                                                  callback: this.getCurrentWidgetsTree
                                                }
                                              },
                                              {
                                                key:'ui.tabs[].advanced.mapper[].fieldMapper',
                                                type: 'array',
                                                items:[
                                                  {
                                                    type: 'section',
                                                    sectionContainerClass: "row",
                                                    sectionChildClass: "col",
                                                    items:[
                                                      {
                                                        key:'ui.tabs[].advanced.mapper[].fieldMapper[].designerFieldName'
                                                      },
                                                      {
                                                        key:'ui.tabs[].advanced.mapper[].fieldMapper[].widgetFieldName'
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }

                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  created() {
    if (this.isEdit) {
      this.widgetDefinitionModel = this.editDefinition;
    }
  },
  methods: {
    saveAs: function () {
      this.$widgetStore.vueJsonMerge(this.editDefinition, this.definition);
      this.widgetEditMode = true;
      this.$refs['modalEdit'].show();
    },
    createNewWidget: function () {
      console.log('Creating widget', this.definition, this.widgetDefinitionModel);
      let newWidget = Object.assign({}, this.definition);
      newWidget = Object.assign(newWidget, this.widgetDefinitionModel);
      newWidget.isTenantCreated = true;
      if (!newWidget.baseTypes) {
        newWidget.baseTypes = [];
      }
      newWidget.baseTypes.push(this.definition.type);

      if (this.$widgetStore.getWidgetByType(newWidget.type)) {
        this.errorMessage('Widget with type: "' + newWidget.type + '" already exists. Define a new type!')
      } else {
        newWidget.id = 'widget-' + this.$widgetStore.id();
        console.log('New Widget', newWidget);
        const that = this;
        this.saveWidget(newWidget, function () {
          that.handleAndClose(newWidget);
        });
      }
    },
    handleAndClose: function (newWidget) {
      const that = this;
      that.$widgetStore.widget(newWidget);
      that.widgetEditMode = false;
      that.$nextTick(() => {
        if (that.$refs['modalEdit'])
          that.$refs['modalEdit'].hide()
      })
    },
    deleteWidget: function (widget, next) {
      const that = this;
      this.$services['http'].deleteWidget(widget, function (result) {
        if (result) {
          delete that.$widgetStore.widgets[widget.type];
        }
        next(result);
      });

    },
    saveWidget: function (newWidget, next) {
      this.$services['http'].saveWidget(newWidget, function () {
        next();
      });
    },
    updateWidget: function () {
      const that = this;
      const newWidget = this.widgetDefinitionModel;
      this.saveWidget(newWidget, function () {
        that.$widgetStore.widgets[newWidget.type] = newWidget;
        that.widgetEditMode = false;
        that.$nextTick(() => {
          if (that.$refs['modalEdit'])
            that.$refs['modalEdit'].hide()
        })
      })
    },
    handleEditConfirmed: function (event) {
      event.preventDefault();

      const isValid = this.$refs.schemaForm.validateForm();
      console.log('Validated Save As form', isValid);
      if (isValid.valid) {
        const that = this;
        if (this.isEdit) {
          this.updateWidget();
        } else {
          this.createNewWidget();
        }
      } else {
        //console.error(isValid);
        this.errorMessage("Form not valid!");
      }
    },
    getDashboardsWidgetsTree: function (dashboards) {
      const that = this;
      const widgets = [];
      if (dashboards) {
        dashboards.forEach(function (dashboard) {
          console.log('inDashboard', dashboard);
          const dashName = dashboard.title ? dashboard.title : dashboard.dashid;
          dashboard.rows.forEach(function (row) {
            row.columns.forEach(function (column) {
              column.widgets.forEach(function (widget) {

                let tmpDashboard = find(widgets, { value: dashName });
                let found = true;
                if (isEmpty(tmpDashboard)){
                  tmpDashboard = {
                    name: dashName,
                    value: dashboard.dashid,
                    children:[],
                    data: dashboard
                  };
                  found = false;
                }

                const widgetName = widget.title || widget.id;
                let widgetObj = {
                  name: widgetName,
                  value: widget.id,
                  data: widget
                }
                if (!isEmpty(widget.dashboards)){
                  const widgetDashboards = that.getDashboardsWidgetsTree(widget.dashboards);
                  widgetObj.children = widgetDashboards;
                }
                tmpDashboard.children.push(widgetObj);

                if (!found)
                  widgets.push(tmpDashboard);
              });
            });
          });
        });
        console.log('inDashboard widgets', widgets);
        return widgets;
      }
    },
    resolveTitleMapFromWidgetsTree: function (widgets) {
      const titleMap = [];
      return titleMap;
    },
    getCurrentWidgetsTree: function () {

      const that = this;
      console.log('get widgets tree', this.editDefinition, this.widgetDefinitionModel);
      const titleMap = this.getDashboardsWidgetsTree(this.editDefinition.dashboards);
      //const titleMap = this.resolveTitleMapFromWidgetsTree(widgets);
      return titleMap;
    }
  }
}
</script>

<style scoped>

</style>
