<template>
  <div class="content">
    <div class="row" v-if="dashboardEditMode && isDashboardTypeEditable">
      <div class="col">
        <schema-form :form="typeForm" :schema="typeSchema" v-model="dashboardTypeModel"></schema-form>
        <hr/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <component :dashboard-edit-mode="dashboardEditMode" :dashboard-name="dashboardName"
                   :default-mapper="defaultMapper"
                   :disable-add="disableAdd" :disable-edit="disableEdit"
                   :is="resolveDashboardType"
                   :permissions="permissions"
                   :input-context="inputContext"
                   :dashboard-service-instance="dashboardServiceInstance"
                   :model="dashboardModel"
                   :parent-widget-id="parentWidgetId"
                   :parent-dashid="parentDashid"
                   ref="dashboardContainer"
                   :use-provided-model="true" crud-service-name="http">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
  import dashboardSharedMixin from "vue-dashboard-framework/src/components/vuedf/containers/dashboardSharedMixin";

  export default {
    name: "VueDfDashboardContent",
    mixins:[dashboardSharedMixin],
    props: {
      dashboardType: {
        type: String
      },
      isDashboardTypeEditable:{
        type: Boolean,
        default: function(){
          return true
        }
      },
      dashboardEditMode: {
        type: Boolean
      }
    },
    created(){
      if (this.dashboardType){
        if (this.dashboardTypeModel && this.dashboardTypeModel.dashboardType){
          this.dashboardTypeModel.dashboardType = this.dashboardType;
        }else{
          const obj = {
            dashboardType: this.dashboardType
          }
          this.dashboardTypeModel = Object.assign({},obj);
        }
      }
      const that = this;
      this.$dashboardBus.$on('DASHBOARD_SAVED::' + this.dashboardName, that.onDashboardSave);
    },
    beforeDestroy() {
      const that = this;
      this.$dashboardBus.$off('DASHBOARD_SAVED::' + this.dashboardName, that.onDashboardSave);
    },
    watch: {
      dashboardTypeModel: {
        handler(newVal, oldVal) {
          this.$emit('dashboardtypechange', newVal.dashboardType);
        },
        deep: true
      }
    },
    data() {
      return {
        dashboardTypeModel: {},
        typeSchema: {
          type: 'object',
          properties: {
            dashboardType: {
              type: 'string',
              title: 'Dashboard Type',
              enum: ['VueDfDashboardTabs', 'VueDfDashboardSingle'],
              default: 'VueDfDashboardSingle'
            }
          }
        },
        typeForm: [
          {
            key: 'dashboardType', type: 'radiosinline'
          }
        ]
      }
    },
    computed: {
      resolveDashboardType: function () {
        return this.dashboardTypeModel.dashboardType;
      },
      dashboardModel: function(){
        return this.model;
      }
    },
    methods: {
      onDashboardSave: function(dashboardModel, currentDashboardId){
       // console.log('VueDfDasboardView onDashboardSave', dashboardModel, currentDashboardId, this.model);
       // this.key = this.key +1;
        this.$emit('dashboardchanged', dashboardModel);
      }
    }
  }
</script>

<style scoped>

</style>
