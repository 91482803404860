import groupBy from "lodash/groupBy";

const groupedMixin = {
  data(){
    return {
      selectedGroup: undefined
    }
  },
  computed: {
    grouped: function () {
      if (this.titleMap && this.fieldForm.groupBy) {
        return groupBy(this.titleMap, this.fieldForm.groupBy);
      } else {
        return this.titleMap;
      }
    }
  },
  methods:{
    selectGroup: function (groupName) {
      this.selectedGroup = groupName;
    }
  }
}

export default groupedMixin
