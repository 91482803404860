var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "schema-form-actions-container",
      class: _vm.isDroppable ? "droppable-container" : "",
      attrs: {
        disabled: !_vm.isDroppable,
        group: { name: "components", pull: true, put: true },
        handle: ".handle",
        list: _vm.innerList,
        tag: "div",
      },
      on: { add: _vm.onAddActions, change: _vm.onChangeActions },
    },
    [
      _vm.shouldUpdate
        ? _c(
            "div",
            {
              staticClass: "btn-group schema-form-actions",
              class: _vm.fieldForm.htmlClass,
              style: _vm.fieldForm.style,
              attrs: { role: "group" },
            },
            [
              _vm._l(_vm.buttons, function (item, index) {
                return [
                  _c("sf-component-toolbar", {
                    attrs: {
                      "cloned-dropped-components": _vm.cleanList,
                      field: item,
                      guid: item.guid,
                      "is-droppable": _vm.isDroppable,
                      "selected-item": _vm.selectedItem,
                    },
                    on: {
                      isminimized: function ($event) {
                        return _vm.onIsMinimized(item, $event)
                      },
                      schemaChanged: _vm.onToolbarSchemaChanged,
                    },
                  }),
                  _c(_vm.resolveFieldComponent(item), {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.itemState.minimizedItems[item.guid],
                        expression: "!(itemState.minimizedItems[item.guid])",
                      },
                    ],
                    tag: "component",
                    attrs: {
                      "field-form": item,
                      "full-model": _vm.fullModel,
                      "parent-field-form": _vm.fieldForm,
                      "schema-form-service": _vm.schemaFormService,
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }