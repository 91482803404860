
import VueDfLeafletView from './VueDfLeafletView'
import VueDfLeafletEdit from './VueDfLeafletEdit'


let widget = {
  type: 'VueDfLeaflet',
  title: 'Interactive Geo Map',
  description: 'Interactive geo mapping',
  category: 'Geo',
  editComponent: 'VueDfLeafletEdit',
  viewComponent: 'VueDfLeafletView',
  hideTitle: false,
  editableSettings:{
    editable: true
  },
  frameless: false,
  data: {},
  config: {
    data: {}
  }
}

const vueLeafletPlugin = {

  install(Vue, options) {

    Vue.component(VueDfLeafletView.name, VueDfLeafletView);
    Vue.component(VueDfLeafletEdit.name, VueDfLeafletEdit);

    Vue.widgetStore.widget(widget);
  }
}

export default vueLeafletPlugin;
