<template>
  <div class="dashboards" ref="dashboardContainer">
    <vue-element-loading :active.sync="isLoading"
                         :can-cancel="false"
                         is-full-page="false"></vue-element-loading>

    <div :class="dashboardsContainerClass">
      <slot name="navigation" v-if="navigationPosition==='top'"></slot>
      <div :class="[dashboardsContentClass,'dashboards-content']">
        <template v-if="selectedDashboard">
          <vue-df-dashboard :categories="selectedDashboard.categories"
                            :continuous-edit-mode="selectedDashboard.continuousEditMode"
                            :crud-service-name="crudServiceName"
                            :dashboard-name="dashboardName"
                            :dashid="selectedDashboard.dashid"
                            :permissions="permissions"
                            :default-mapper="defaultMapper"
                            :edit-mode="selectedDashboard.editMode"
                            :include-widgets="includeWidgets"
                            :input-context="inputContext"
                            :model="selectedDashboard"
                            :options="options"
                            :structures="structures"
                            :use-provided-model="useProvidedModel"
                            v-if="guid"
                            :parent-widget-id="parentWidgetId"
                            :parent-dashid="parentDashid"
                            :dashboard-service-instance="dashboardServiceInstance"
                            v-on:dashboardchanged="dashboardChanged($event)"
                            v-on:toggleSavedDashboard="onSaveDashboard($event)"
          >
          </vue-df-dashboard>
        </template>
        <div v-if="!model || !model.length">
          <div class="text-center text-muted">There is no Dashboards. Please add new Dashboard.</div>
        </div>
      </div>
      <slot name="navigation" v-if="navigationPosition==='bottom'"></slot>
      <b-modal :ref="'uploadModal'" :title="'Upload Dashboard Definition'" @ok="onUploadInput" lazy>
        <b-form-file class="mb-2" ref="file-upload" v-model="uploadedFile"></b-form-file>
        <b-form-checkbox v-model="shouldMergeNewUpload">Should merge in</b-form-checkbox>
      </b-modal>

      <b-modal :ref="'shareDashboard'" :title="'Share Dashboard Link'" @ok="onShareDashboardLink"
               @shown="focusMyElement" lazy
               size="lg">
        <div class="input-group">
          <b-form-input ref="inputShareableLink" v-model="sharedDashboardLink"></b-form-input>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button"
                    v-clipboard="() => {$refs.inputShareableLink.setSelectionRange(0, sharedDashboardLink.length); return sharedDashboardLink; }">
              Copy!
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>

</template>

<script>

import VueDfDashboard from '../VueDfDashboard'
import dashboardSharedMixin from './dashboardSharedMixin'
import {BModal, BFormFile, BFormCheckbox, BFormInput} from 'bootstrap-vue'

export default {
  name: "VueDfDashboardContainer",
  mixins: [dashboardSharedMixin],
  components: {VueDfDashboard, BModal, BFormFile, BFormCheckbox, BFormInput},
  props: {
    dashboardsContainerClass: String,
    dashboardsContentClass: String,
    selectedDashboardIndex: {
      type: Number,
      default: function () {
        return 0;
      }
    },
    guid: Boolean,
    navigationPosition: {
      type: String,
      default: function () {
        return 'top';
      }
    }
  },
  watch: {
    selectedDashboardIndex: {
      handler(newVal, oldVal) {
        //  console.log('update in selected dashboard index', newVal)
        this.selectLocalDashboard();
      }
    }
  },
  data() {
    return {
      selectedDashboard: {},
      uploadedFile: null,
      shouldMergeNewUpload: false,
      sharedDashboardLink: null,
      shareableDashboardObj: null,
      dashboardAlreadyShared: false,
      loader: null,
      isLoading: true
    }
  },
  computed: {
    options: function () {
      return {
        maximizable: this.selectedDashboard.maximizable,
        refreshable: this.selectedDashboard.refreshable,
        collapsible: this.selectedDashboard.collapsible,
        editable: this.resolveEditable()
      }
    }
  },
  created: function () {
    const that = this;

    //  console.log('In Container created this.dashboardName', this.dashboardName)
    this.$dashboardBus.$on('DASHBOARD_LOADED::' + this.dashboardName, that.selectLocalDashboard);
    this.$dashboardBus.$on('DASHBOARD_ADDED::' + this.dashboardName, that.onAddedDashboard);

  },
  beforeDestroy() {
    const that = this;
    //console.log('desctroy this.dashboardName', this.dashboardName)
    this.$dashboardBus.$off('DASHBOARD_LOADED::' + this.dashboardName, that.selectLocalDashboard);
    this.$dashboardBus.$off('DASHBOARD_ADDED::' + this.dashboardName, that.onAddedDashboard);
  },
  methods: {
    onAddedDashboard: function (dashboardEvent) {
      console.log('onAddedDashboard', dashboardEvent, this.dashboardName);
      if (dashboardEvent.dashboardName === this.dashboardName) {
        // this.selectedDashboardIndex = this.model.length-1;
        this.onSaveDashboard(dashboardEvent.model);
      }
    },
    selectLocalDashboard: function () {

      this.isLoading = false;

      //  console.log('Selecting dashboard in Container: '+this.dashboardName, this.model, this.selectedDashboard, this.selectedDashboardIndex);
      if (this.model) {
        this.selectedDashboard = this.model[this.selectedDashboardIndex];
      }
    },
    focusMyElement: function () {
      console.log('focusMyElement', this.$refs.inputShareableLink);
      this.$refs.inputShareableLink.focus();
      this.$refs.inputShareableLink.setSelectionRange(0, this.sharedDashboardLink.length)
    },
    shareDashboard: function () {
      const dashId = this.selectedDashboard.dashid;
      console.log('sharing dashboard', dashId, this.selectedDashboard);
      const that = this;
      this.$services[this.crudServiceName].loadAllDashboardPreviewItems(dashId, null, function (response) {
        console.log('sharing dashboard response', response);
        if (response && response.length && response[0]) {
          that.shareableDashboardObj = {
            id: response[0]._id
          }
          that.dashboardAlreadyShared = true;
        } else {
          that.shareableDashboardObj = {
            id: that.generateUUID()
          }
          that.dashboardAlreadyShared = false;
        }
        that.sharedDashboardLink = document.location.protocol + '//' + document.location.host + '/df/' + that.shareableDashboardObj.id;//+'_'+dashId;
        that.$refs.shareDashboard.show();
      });

    },
    generateUUID: function () {
      let d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
      }
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    onShareDashboardLink: function (event) {
      // we need to save into DB or encode dashboardName, appKey and user to be able to retrieve from the backend
      console.log('onShareDashboardLink - event', event);
      const objectToStore = {
        _id: this.shareableDashboardObj.id,
        dashid: this.selectedDashboard.dashid,
        dashboardName: this.dashboardName
      }

      const that = this;
      if (that.dashboardAlreadyShared) {
        that.$notify({message: 'Link to shared dashboard is enabled'});
      } else {
        this.$services[this.crudServiceName].saveDashboardPreviewItem(objectToStore, function (response) {
          that.$notify({message: 'Link to shared dashboard is enabled'});
        });
      }
    },
    onUploadInput: function (event) {
      const that = this;
      this.$nextTick(function () {
        const reader = new FileReader();
        reader.onload = function (loadEvent) {
          const dashObj = JSON.parse(loadEvent.target.result);
          if (Array.isArray(dashObj)) {
            that.importMultipleDashboards(dashObj);
          } else {
            that.importSingleDashboards(dashObj);
          }
        }
        reader.readAsText(that.uploadedFile);
      });
    },
    importMultipleDashboards: function (dashImport) {
      const that = this;
      dashImport.forEach(function (item) {
        that.importSingleDashboards(item);
      });

      this.saveDashboard({model: this.model, index: this.selectedDashboardIndex});
      // this.$dashboardBus.$emit('DASHBOARD_LOADED::' + this.dashboardName);
    },
    importSingleDashboards: function (dashImport) {
      if (!this.shouldMergeNewUpload) {
        const dashid = 'dash-' + this.$widgetStore.id();
        dashImport.dashid = dashid;
        // dashImport.title = dashImport.title + '-1';

        let copy = {}
        this.$widgetStore.vueJsonMerge(copy, dashImport);
        this.model.push(copy);
      } else {
        let existingIndex = this.model.findIndex(function (item) {
          return item.dashid === dashImport.dashid;
        });
        let copy = {}
        this.$widgetStore.vueJsonMerge(copy, dashImport);

        if (existingIndex > -1) {
          this.model[existingIndex] = copy;
        } else {
          this.model.push(copy);
        }
      }
    },
    resolveEditable: function () {
      //   console.log('Resolve editable dashboard', this.disableEdit);
      return !this.disableEdit;
    },
    dashboardChanged: function (dashboard) {
      //   console.log('Dashboard changed', dashboard);
      this.$set(this.model, this.selectedDashboardIndex, dashboard);
      this.$set(this, 'selectedDashboard', this.model[this.selectedDashboardIndex]);
      //   console.log('Selected dashboard', this.selectedDashboard);
    },
    onSaveDashboard: function (event) {

      this.saveDashboard({model: this.model});
      //this.$dashboardBus.$emit('DASHBOARD_LOADED::' + this.dashboardName);
      this.$dashboardBus.$emit('DASHBOARD_SAVED::' + this.dashboardName, this.model, this.selectedDashboard.dashid);
    },
    saveDashboard: function (value) {
      const that = this;
      if (!this.useProvidedModel) {
        that.isLoading = true;
        console.log('Saving the model ' + that.dashboardName, that, value.model, that.isLoading);

        that.$services[that.crudServiceName].saveModels(that.dashboardName, value.model, function (res) {

          that.isLoading = false;
          console.log('SAVED the model ' + that.dashboardName, that, value.model, that.isLoading);
          // that.$dashboardBus.$emit('DASHBOARD_LOADED::' + that.dashboardName);
          that.selectLocalDashboard();
        });
      }
    },
    saveCurrentDashboard: function () {
      this.saveDashboard({model: this.model});
      this.$dashboardBus.$emit('DASHBOARD_SAVED::' + this.dashboardName, this.model, this.selectedDashboard.dashid);
    }
  }
}
</script>

<style scoped>

</style>
