<template>
  <div :data-active-color="activeColor"
       :data-color="backgroundColor"
       class="app-sidebar">
    <div class="logo">
      <component :is="homeComponent" :to="homeLink" @click.prevent="onHomeClick"
                 class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="logo" alt="">
        </div>
      </component>
      <component :is="homeComponent" :to="homeLink"
                 @click.prevent="onHomeClick" class="simple-text logo-normal">
        {{ title }}
      </component>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot>
      </slot>
      <ul class="nav">
        <slot name="links">
          <vue-df-app-sidebar-item :app-config="appConfig" :config="item" :key="item.title + index"
                                   :parent="null"
                                   :selected-route-config="selectedRouteConfig" @selected="onSelect"
                                   v-for="(item, index) in resolveItems()">
            <vue-df-app-sidebar-item :app-config="appConfig" :config="subItem" :key="subItem.title + index"
                                     :parent="item"
                                     :selected-route-config="selectedRouteConfig" @selected="onSelect"
                                     v-for="(subItem, index) in resolveSubItems(item)">
            </vue-df-app-sidebar-item>
          </vue-df-app-sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
  import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'
  import cloneDeep from 'lodash/cloneDeep'
  import componentMixin from '../../componentMixin';
  import permissionMixin from "../permissionMixin";

  export default {
    name: 'VueDfAppSidebar',
    mixins: [componentMixin, permissionMixin],
    provide() {
      return {
        autoClose: this.config.autoClose
      }
    },
    computed: {
      appDashboardName: function () {
        return this.appConfig.appDashboardName;
      },
      backgroundColor: function () {
        return this.config.backgroundColor || 'black';
      },
      activeColor: function () {
        return this.config.activeColor || 'primary';
      },
      logo: function () {
        return this.config.logo || '/img/logo.png'
      },
      title: function () {
        return this.config.title || this.appDashboardName
      }
    },
    methods: {
      onSelect: function (selected) {
    //    console.log('OnSelected in AppSidbar', selected);
        this.$emit('selected', selected);
      },
      selectFirstByRoute: function () {
        const metaDef = this.$route.meta.def;
        this.onSelect(metaDef);
      },
      selectFirst: function () {
        if (!this.appConfig.isComponentMode) { // this should only work if we edit component in design time (e.g. componentMode===true)
          this.selectFirstByRoute();
          return;
        }
        if (this.items && this.items.length > 0) {
          const first = this.items[0];
          let selected = null;
          if (first.itemType === 'DROP_DOWN') {
            if (first.itemDropDownSettings && first.itemDropDownSettings.items && first.itemDropDownSettings.items.length > 0) {
              selected = {item: first.itemDropDownSettings.items[0], parent: first};
              first.collapsed = true;
            }
          } else {
            selected = {item: first, parent: null};
          }
          if (selected) {
            this.onSelect(selected);
          }
        }
      },
      async initScrollBarAsync() {
        const PerfectScroll = await import('perfect-scrollbar')
        PerfectScroll.initialize(this.$refs.sidebarScrollArea)
      }
    },
    mounted() {
      this.selectFirst();
      this.$nextTick(function () {
        if (this.$refs.sidebarScrollArea)
          this.initScrollBarAsync();
      })
    },
    beforeDestroy() {
      if (this.$vueDfAppSidebar.showSidebar) {
        this.$vueDfAppSidebar.showSidebar = false
      }
    }
  }

</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display: none;
    }
  }

  .dashboard-simple .app-sidebar {
    position: fixed;
  }
</style>
