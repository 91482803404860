import vueUtils from "../../utils/vueUtils";
import ObjectPath from "../../utils/ObjectPath";
import jsnotevil from "jsnotevil";
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

const titleMapBaseMixin = {
  data() {
    return {
      titleMap: [],
      valueKey: this.fieldForm.valueKey || 'value',
      labelKey: this.fieldForm.labelKey || 'name',
      internalValue: this.value
    }
  },
  async created() {
    const that = this;

    await this.resolveSelectOptions();
    if (this.$bus) {
      this.$bus.$on('__lookupEvent', that.onEvent);
    }

    //console.log('On title schemaService subscribe', this.schemaFormService, this.fieldForm.key);
    if (this.schemaFormService) {// is it better to subscribe on specific multiple events???
      this.schemaFormService.subscribe(this.fieldForm.key, that.onEvent);
    }
  },
  beforeDestroy() {
    const that = this;
    if (this.$bus) {
      this.$bus.$off('__lookupEvent', that.onEvent);
    }
    if (this.schemaFormService) {
      this.schemaFormService.unsubscribe(this.fieldForm.key, that.onEvent);
    }
  },
  watch: {
    internalValue: {
      handler(newVal, oldVal) {
        const obj = {}
        obj[this.valueKey] = newVal;
        this.onInputChanged(obj, oldVal);
      }
    }
  },
  methods: {
    isMatching: function (targetKey) {
      return isEqual(sortBy(targetKey), sortBy(cloneDeep(this.fieldForm.key)));
    },
    onEvent: async function (context) {
    //  console.log('onEvent IS ? Matched On Lookup Event', context, this.fieldForm.key);
      if (this.isMatching(context.targetKey)) {
    //    console.log('Matched On Lookup Event', context);
        if (context.eventName === 'reload') {
          await this.resolveSelectOptions();
        } else if (context.eventName === 'remove') {
          this.$set(this, 'titleMap', []);
          this.$set(this, 'internalTitleMap', []);
          this.$set(this, 'tmpTitleMap', []);
          vueUtils.vueSet(this.fullModel, cloneDeep(this.fieldForm.key), null);
        } else if (context.eventName==='set'){
      //    console.log('Evaluated set payload', context, this.fieldForm.key);
          vueUtils.vueSet(this.fullModel, cloneDeep(this.fieldForm.key), context.payload);
        }
      }
    },
    resolveSelectOptions: async function () {
      //  console.log('Resolving select options', this.fieldForm);
      if (this.fieldForm.titleMap) {
       // this.titleMap = this.fieldForm.titleMap;
        this.$set(this, 'titleMap', this.fieldForm.titleMap);
      } else if (this.fieldForm.options) {
        if (this.fieldForm.options.callback) {
          try {
            await this.resolveFromCallback();
          } catch (e) {
            console.error('Error in select callback', e);
          }
        } else if (this.fieldForm.options.fn) {
          try {
            await this.resolveFromFunction();
          } catch (e) {
            console.error('Error in select fn', e);
          }
        } else if (this.fieldForm.options.callbackFunction) {
          try {
            await this.resolveFromCallbackFunction();
          } catch (e) {
            console.error('Error in select callbackFunction', e);
          }
        }
      }
    },
    resolveFromCallbackFunction: async function () {
      const callbackFunction = this.fieldForm.options.callbackFunction;
      const fnName = callbackFunction.name || callbackFunction;
      const that = this;
      const fn = function (result) {
        that.$set(that, 'titleMap', result);
      }
      const parentMethod = this.lookupMethodOnParents(this, fnName, '');
      if (parentMethod) {
        const ctx = {
          console: console,
          rest: this.$http,
          set: this.$set,
          key: ObjectPath.dotted(this.fieldForm.schemaKey),
          fieldForm: this.fieldForm,
          fullModel: this.fullModel,
          _meta: {
            route: that.$route ? that.$route : null,
            appConfig: that.$appConfig ? that.$appConfig.appConfig : null,
            user: that.$userService ? that.$userService.getCurrentUser() : null
          },
          fn: fn
        };

        const result = parentMethod(ctx);
        await this.resolveTitleMapFromFunctionCall(result, this.localValue);
      } else {
        console.warn('Cannot find method on parent context: ' + fnName);
      }
    },
    resolveFromFunction: async function () {
      let fn = this.fieldForm.options.fn;

      fn = new Function("return " + fn)();

      await this.resolveTitleMapFromFunctionCall(fn, this.localValue);
    },
    resolveFromCallback: async function () {
      let fn = this.fieldForm.options.callback;
      if (typeof (fn) === 'string') {
        fn = this.lookupMethodOnParents(this, fn);
      }
      // call sync to load titleMap
      await this.resolveTitleMapFromFunctionCall(fn, this.localValue);
    },
    resolvePromise: async function (promise, ...args) {
      const that = this;
      let result = await promise(args);
      await that.resolveTitleMapValue(result);
    },
    resolveTitleMapFromFunctionCall: async function (result, localValue) {
      const that = this;
      if (!result) {
        return;
      }
      if (result instanceof Function) {
        let val = result(that, localValue);
          console.log('Function result val: ', val);
        if (typeof (val) === 'string') {
          try {
            val = JSON.parse(val);
            await that.resolveTitleMapValue(val);
          } catch (e) {
            console.error(e);
          }
        } else if (val instanceof Promise) {
          await this.resolvePromise(result, that, localValue);
        } else if (typeof (val) === 'object') {
          //that.titleMap = val;
          await that.resolveTitleMapValue(val);
        }
      } else if (result instanceof Promise) {
        result.then(function (res) {
          console.log('Promise result val: ', res);
          that.resolveTitleMapValue(res);
        });
      } else if (typeof (result) === 'string') {
        try {
          console.log('string result val: ', result);
          result = JSON.parse(result);
          await that.resolveTitleMapValue(result);
        } catch (e) {
          console.log('Function not valid JSON object', e);
        }
      } else if (typeof (result) === 'object') {
        // console.log('object result val: ', result);
        await that.resolveTitleMapValue(result);
      }
    },
    resolveTitleMapValue: async function (value) {
      const resultExpression = this.fieldForm.options.resultExpression;
      if (resultExpression) {
        const res = this.$jsulator.evaluate(resultExpression, value);
        this.$set(this, 'titleMap', res);
      } else {
        this.$set(this, 'titleMap', value);
      }
    },
    onInputChanged: function (val, oldVal) {
      //console.log('input changed', val, oldVal);

      const tmpVal = val[this.valueKey];

      if (tmpVal === null || tmpVal === undefined) {
        this.localValue = undefined;
      } else {
        this.localValue = tmpVal;
      }
      if (this.fieldForm.onChange) {
        const onChange = this.fieldForm.onChange;
        this.onChangeObject(onChange, tmpVal, oldVal, this.fullModel, this.fieldForm);
      }
    },
    onChangeString: function (onChangeFnStr, val, fullModel, fieldForm) {
      const fn = new Function("return " + onChangeFnStr)();
      fn(this, val, fullModel, fieldForm);
    },
    onChangeFunction: function (onChangeFn, val, fullModel, fieldForm) {
      onChangeFn(val, fullModel, fieldForm);
    },
    _resolveValueToCopyOnChange: function (val, item) {
      // this should be re-implemented in parent component if needed
      return undefined;
    },
    _prepareKeyForChange: function (itemKey, val, oldVal, fullModel, fieldForm) {
      let keyArray = itemKey;
      if (!Array.isArray(itemKey)) {
        keyArray = ObjectPath.parse(itemKey);
      }
      let tmp = [];
      keyArray.forEach(function (k) {
        if (k.indexOf('form.key[') > -1) {
          const val = jsnotevil.safeEval(k, {form: fieldForm});
          tmp.push(val);
        } else {
          tmp.push(k);
        }
      });
      return tmp;
    },
    onChangeObject: function (onChangeObj, val, oldVal, fullModel, fieldForm) {
      //  console.log('onChange object', val, oldVal);

      const that = this;
      if (onChangeObj.evaluate) {
        onChangeObj.evaluate.forEach(function (item) {
          jsnotevil.safeEval(item, {form: fieldForm, model: fullModel, val: val, oldVal: oldVal});
        });
      }
      if (onChangeObj.publish) {
        onChangeObj.publish.forEach(function (item) {
          // console.log('Publish event - formBus', item, that.formBus, val, oldVal, fieldForm);
          const targetKey = that._prepareKeyForChange(item.targetKey, val, oldVal, fullModel, fieldForm);

          that.schemaFormService.publishEvent(targetKey, item.name, {val: val, oldVal: oldVal});
        });
      }

      if (onChangeObj.remove) {
        onChangeObj.remove.forEach(function (item) {
          console.log('onChangeObject - remove', item, val, oldVal, fullModel, fieldForm);
          const key = that._prepareKeyForChange(item, val, oldVal, fullModel, fieldForm);
          vueUtils.vueDelete(fullModel, ObjectPath.parse(item));
        });
      }
      if (onChangeObj.copyTo) {
        onChangeObj.copyTo.forEach(function (item) {
          let copyToItem = item;
          if (copyToItem.indexOf('{') > -1 && copyToItem.indexOf('}') > -1) {
            copyToItem = JSON.parse(copyToItem);
          }

          let targetKey = copyToItem.target || copyToItem;
          if (null === val || val === undefined) {
            vueUtils.vueDelete(fullModel, ObjectPath.parse(targetKey));
          } else {
            const tmpVal = that._resolveValueToCopyOnChange(val, copyToItem);
            if (tmpVal) {
              vueUtils.vueSet(fullModel, ObjectPath.parse(targetKey), tmpVal);
            } else {
              vueUtils.vueSet(fullModel, ObjectPath.parse(targetKey), val);
            }
          }
        });
      }
      const context = {
        val: val,
        oldVal: oldVal,
        fieldForm: fieldForm,
        bus: this.$bus,
        console: console,
        rest: this.$http
      };
      if (onChangeObj.callback) {
        if (typeof (onChangeObj.callback) === 'string') {
          const fn = this.lookupMethodOnParents(this, onChangeObj.callback, '');
          if (fn) {
            // console.log('executing lookup method', val, oldVal);
            fn(context);
          }
        } else if (typeof (onChangeObj.callback) === 'function') {
          onChangeObj.callback(context);
        }
      }
      if (onChangeObj.fn) {
        if (typeof (onChangeObj.fn) === 'string') {
          const fn = new Function("return " + onChangeObj.fn)();
          fn(this, context);
        } else if (typeof (onChangeObj.fn) === 'function') {
          onChangeObj.fn(this, context);
        }
      }
    }
  }
}

export default titleMapBaseMixin
