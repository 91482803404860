<template>
  <div class="expression-token-container">
    <span @dblclick.prevent="focusField()" v-if="!editField">
      {{ viewValue }}
    </span>
    <input ref="inputField" class="field-value form-control" @keyup.enter="blurField()" v-if="editField"
           @focus="focusField()" @blur="blurField()"
           v-model="internalValue" :type="resolveInputType">
  </div>
</template>

<script>
import elementsMixin from "./elementsMixin";

export default {
  name: "ExpressionToken",
  mixins: [elementsMixin],
  data() {
    return {
      editField: false,
      viewValue: this.value.internalValue || this.value.symbol
    }
  },
  watch: {
    internalValue: {
      handler(newVal) {
        console.log('interlanVal cjanged', newVal);
        if (newVal === '' || newVal === undefined || newVal === null) {
          this.viewValue = this.value.symbol;
        } else {
          this.viewValue = this.resolveValue(newVal);
        }
        this.$emit('internalValueChanged', this.viewValue);
      }
    }
  },
  computed: {
    resolveInputType() {
      return this.value.name === 'NUMBER' ? 'number' : 'text';
    }
  },
  methods: {
    resolveValue(newVal) {

      let val = newVal; //this.value.internalValue;
      if (this.value.name === 'OPEN_BRACKETS' || this.value.name === 'CLOSE_BRACKETS') {
        val = this.value.symbol;
      } else if (val !== undefined && val !== null && val !== '') {

        switch (this.value.name) {
          case 'LITERAL':
            val = val ? '\'' + val + '\'' : this.value.symbol;
            break;
          case 'LOOKUP_LITERAL':
            val = val ? '$' + val + '$' : this.value.symbol;
            break;
        }
      }
      console.log('resolve value', val, this.value);
      return val;
    },
    isReadOnly() {
      return this.value.name === 'OPEN_BRACKET' || this.value.name === 'CLOSE_BRACKET';
    },
    focusField() {
      console.log('isReadonlu', this.isReadOnly());
      if (!this.isReadOnly()) {
        this.editField = true;
        const that = this;
        this.$nextTick(() => {
          that.$refs.inputField.focus();
          console.log('field clicked', that.editField);
        });
      }
    },
    blurField() {
      this.editField = false;
    }
  }
}
</script>

<style scoped>

</style>
