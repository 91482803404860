var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "component-base",
    { attrs: { "field-form": _vm.fieldForm } },
    [
      _c("vue-slider", {
        attrs: {
          min: _vm.fieldForm.min,
          max: _vm.fieldForm.max,
          interval: _vm.fieldForm.interval,
          minRange: _vm.fieldForm.minRange,
          maxRange: _vm.fieldForm.maxRange,
          disabled: _vm.fieldForm.disabled,
          data: _vm.fieldForm.data,
          dataValue: _vm.fieldForm.dataValue,
          dataLabel: _vm.fieldForm.dataLabel,
          direction: _vm.fieldForm.direction,
          clickable: _vm.fieldForm.readonly,
          railStyle: _vm.fieldForm.railStyle,
          processStyle: _vm.fieldForm.processStyle,
          tooltipStyle: _vm.fieldForm.tooltipStyle,
          stepStyle: _vm.fieldForm.stepStyle,
          stepActiveStyle: _vm.fieldForm.stepActiveStyle,
          labelStyle: _vm.fieldForm.labelStyle,
          labelActiveStyle: _vm.fieldForm.labelActiveStyle,
          dotStyle: _vm.fieldForm.dotStyle,
          marks: _vm.fieldForm.marks,
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }