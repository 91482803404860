import VueDfWidgetGeneratorView from './VueDfWidgetGeneratorView'
import VueDfWidgetGeneratorEdit from './VueDfWidgetGeneratorEdit'

let widget = {
  type: 'VueDfWidgetGenerator',
  title: 'Widget Generator',
  description: 'Generate Widgets',
  category: 'Data',
  editComponent: 'VueDfWidgetGeneratorEdit',
  viewComponent: 'VueDfWidgetGeneratorView',
  dashboards:[],
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfWidgetGeneratorView.name, VueDfWidgetGeneratorView)
    Vue.component(VueDfWidgetGeneratorEdit.name, VueDfWidgetGeneratorEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
