import Vue from 'vue'

const invalidKey = /^\d|[^a-zA-Z0-9_]/gm
const intKey = /^\d+$/

function isNumberLike(value) {
  return String(value).match(/^\d+$/);
}


function toPath(pathString) {
  if (Array.isArray(pathString)) return pathString
  if (typeof pathString === 'number') return [pathString]
  pathString = String(pathString)

  // taken from lodash - https://github.com/lodash/lodash
  let pathRx = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(\.|\[\])(?:\4|$))/g
  let pathArray = []

  pathString.replace(pathRx, (match, number, quote, string) => {
    pathArray.push(
      quote
        ? string
        : number !== undefined
        ? Number(number)
        : match
    )
    return pathArray[pathArray.length - 1]
  })
  return pathArray
}

function hasOwnProperty(object, property) {
  return Object.prototype.hasOwnProperty.call(object, property)
}

function isObjectLike(object) {
  return typeof object === 'object' && object !== null
}

function isSimpleItemLike(object) {
  if (!isObjectLike(object) && !Array.isArray(object))
    return true;
  return false;
}


function vueSet(obj, path, value) {
  if (null===obj || undefined===obj){
    return;
  }
  let fields = Array.isArray(path)
    ? path
    : toPath(path)
  let prop = fields.shift()

  if (!fields.length) return Vue.set(obj, prop, value)
  if (!hasOwnProperty(obj, prop) || obj[prop] === null) {
    const objVal = fields.length >= 1 && isNumberLike(fields[0])
      ? []
      : {}
    Vue.set(obj, prop, objVal)
  }
  vueSet(obj[prop], fields, value)
}

function vueDelete(targetObj, key) {
  if (!targetObj || !key)
    return targetObj;

  let prop = Array.isArray(key) ? key.shift() : key;
  if (key.length === 0) {

    if (targetObj[prop]) {
      if (Array.isArray(targetObj[prop])) {
        Vue.delete(targetObj, prop);
      } else {
        Vue.delete(targetObj, prop);
      }
    }
  } else {
    targetObj = targetObj[prop];
    vueDelete(targetObj, key);
  }
}

function vueSplice(targetObject, key, index, number) {
  if (!targetObject || !key)
    return targetObject;

  let prop = key.shift();
  if (key.length === 0) {
    if (targetObject[prop]) {
      if (Array.isArray(targetObject[prop])) {
        targetObject[prop].splice(index, number);
      }
    }
  } else {
    targetObject = targetObject[prop];
    vueSplice(targetObject, key, index, number);
  }
}

function  vueMerge(targetObj, obj) {
  if (!obj)
    return;
  if (!targetObj) {
    targetObj = Array.isArray(obj) ? [] : {};
  }

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i += 1) {
      vueMerge(targetObj[i], obj[i]);
    }
  } else {
    for (const prop in obj) {
      const o = obj[prop];

      let targetO = targetObj[prop];
      if (!targetO) {
        Vue.set(targetObj, prop, o);
      } else {

        if (Array.isArray(o)) {
          for (let i = 0; i < o.length; i += 1) {
            let innerO = o[i];
            let targetInnerO = targetO[i];

            if (isSimpleItemLike(innerO)) {
              if (targetInnerO == null || targetInnerO == undefined) {
                targetO.push(innerO);
              } else {
                targetO[i] = o[i];
              }
            } else {
              vueMerge(targetInnerO, innerO);
            }

          }
        } else if (isObjectLike(o)) {
          vueMerge(targetO, o);
        } else {
          Vue.set(targetObj, prop, o);
        }
      }
    }
  }
}

export default {vueSet, vueMerge, vueDelete, vueSplice}
