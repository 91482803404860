import {directive} from 'vue-clickaway'
import VueDfDropdown from "./components/VueDfDropdown";

import Clipboard from './copyToClipboard'
import extend from 'lodash/extend';
import utils from './utils'
import VueDfDashboard from './VueDfDashboard'
import VueDfDashboardTabs from './containers/dashboards/VueDfDashboardTabs'
import VueDfDashboardSingle from './containers/dashboards/VueDfDashboardSingle'
import VueDfDashboardViewer from './containers/VueDfDashboardViewer'
import VueDfDashboardSimple from './containers/VueDfDashboardSimple'
import httpMixinService from "./mixins/services/httpMixinService";
import webSocketMixinService from "./mixins/services/webSocketMixinService";
import WidgetComponentViewMixinsServiceLoader from "./mixins/widgetComponentViewMixinsServiceLoader";

let idStore = {
  cid: 0
}
export let WidgetStore = {
  structures: {},
  widgets: {},
  vueMerge: function (target, source) {
    return utils.vueMerge(target, source);
  },
  vueJsonMerge: function (target, source) {
    return utils.vueJsonMerge(target, source);
  },
  getObject: function (obj) {
    return utils.getObject(obj);
  },
  widget: function (widget) {
    const type = widget.type;

    let def = utils.vueMerge({}, {reload: false, frameless: false});
    utils.vueMerge(def, widget);

    if (widget.config.edit) {
      let edit = utils.vueMerge({}, {
        editReload: true,
        immediate: false
      });
      extend(edit, widget.config.edit);
      widget.config.edit = edit;
    }
    /*if(this.widgets[type]){
      throw new Error('There is already widget with type: '+type);
    }*/

    this.widgets[type] = widget;
  },
  getWidgetByType: function(type){
    return this.widgets[type];
  },
  id() {
    return new Date().getTime() + '-' + (++idStore.cid);
  },
  idEquals: function (id, other) {
    // use toString, because old ids are numbers
    return ((id) && (other)) && (id.toString() === other.toString());
  }
}

const vueDfPlugin = {

  install(Vue, options) {
    if (options && options.structures) {
      WidgetStore.structures = options.structures
    }

    if (options && options.widgets) {
      WidgetStore.widgets = options.widgets
    }

    Vue.widgetStore = WidgetStore;

    Object.defineProperty(Vue.prototype, '$widgetStore', {
      get() {
        return WidgetStore; //this.$root.widgetStore
      }
    })

    Vue.use(WidgetComponentViewMixinsServiceLoader);
    Vue.use(httpMixinService);
    Vue.use(webSocketMixinService);

    Vue.directive('click-outside', directive)
    Vue.component(VueDfDropdown.name, VueDfDropdown)

    Vue.component(VueDfDashboard.name, VueDfDashboard)
    Vue.component(VueDfDashboardTabs.name, VueDfDashboardTabs)
    Vue.component(VueDfDashboardViewer.name, VueDfDashboardViewer)
    Vue.component(VueDfDashboardSingle.name, VueDfDashboardSingle)
    Vue.component(VueDfDashboardSimple.name, VueDfDashboardSimple)

    Vue.use(Clipboard)
  }
}

export default vueDfPlugin
