var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-container" },
    _vm._l(_vm.axisTypes, function (axisType, index) {
      return _c(
        "div",
        {
          staticClass: "row header-item no-gutters",
          class: axisType.type.toLocaleLowerCase(),
        },
        [
          _c(
            "div",
            {
              staticClass: "col-2",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onContextChanged({
                    type: "builderHeader",
                    context: {
                      config: axisType,
                      data: _vm.axisCollRows[axisType.seriesLayoutBy],
                    },
                  })
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header-item-container align-middle h-100 d-table p-2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header-item-wrapper d-table-cell align-middle",
                    },
                    [
                      _c("div", { staticClass: "header-item-commands" }, [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "list-group list-group-horizontal list-unstyled",
                          },
                          [
                            _c("li", [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 text-muted",
                                  staticStyle: { "font-size": "0.7rem" },
                                  attrs: { title: "Switch axis" },
                                },
                                [
                                  axisType.type !== "Filters"
                                    ? _c("i", {
                                        staticClass: "fas",
                                        class:
                                          index === 1
                                            ? "fa-arrow-down"
                                            : "fa-arrow-up",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.switchAxis(
                                              axisType,
                                              index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(
                                  _vm._s(axisType.seriesLayoutBy) +
                                    " - " +
                                    _vm._s(axisType.axis)
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "col-10 header-item-content" },
            [
              _c(
                "draggable",
                {
                  staticClass:
                    "header-item-container list-group list-group-horizontal",
                  attrs: {
                    group: { name: "properties", pull: true, put: true },
                    list: _vm.axisCollRows[axisType.seriesLayoutBy],
                    move: _vm.onMove,
                    id: axisType.type,
                    tag: "ul",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChange(
                        $event,
                        axisType,
                        _vm.axisCollRows[axisType.seriesLayoutBy]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.axisCollRows[axisType.seriesLayoutBy],
                  function (property, index) {
                    return _c(
                      "li",
                      {
                        key: property.guid,
                        staticClass: "list-group-item",
                        class: {
                          active:
                            _vm.currentContext.context &&
                            _vm.currentContext.context.guid === property.guid,
                        },
                      },
                      [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "list-group list-group-horizontal list-unstyled",
                          },
                          [
                            _c("li", [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 text-muted",
                                  staticStyle: { "font-size": "0.7rem" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeProperty(
                                        _vm.axisCollRows[
                                          axisType.seriesLayoutBy
                                        ],
                                        index
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fas fa-times" })]
                              ),
                            ]),
                            property.type === "metric" ||
                            property.type === "dimension"
                              ? _c(
                                  "li",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onContextChanged({
                                          type: "property",
                                          source: axisType,
                                          context: property,
                                        })
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(property.title))])]
                                )
                              : _vm._e(),
                            property.type === "metric" &&
                            property.subType !== "filter"
                              ? _c(
                                  "li",
                                  [
                                    _c(
                                      "drop-down",
                                      {
                                        staticClass: "nav-item dropdown",
                                        attrs: {
                                          direction: "none",
                                          icon: "dropdown",
                                          position: "left",
                                          tag: "div",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateMetricType(
                                                  property,
                                                  "SUM"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("SUM")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateMetricType(
                                                  property,
                                                  "AVG"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("AVG")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateMetricType(
                                                  property,
                                                  "COUNT"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("COUNT")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateMetricType(
                                                  property,
                                                  "MIN"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("MIN")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateMetricType(
                                                  property,
                                                  "MAX"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("MAX")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateMetricType(
                                                  property,
                                                  "STD_DEV"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("STD_DEV")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            property.subType === "filter" &&
                            property.type === "dimension"
                              ? _c(
                                  "li",
                                  [
                                    _c("drop-down-checkboxes", {
                                      staticClass: "nav-item dropdown",
                                      attrs: {
                                        direction: "none",
                                        icon: "dropdown",
                                        position: "left",
                                        tag: "div",
                                        value: _vm.filteredItems(
                                          property,
                                          index,
                                          _vm.axisCollRows[
                                            axisType.seriesLayoutBy
                                          ]
                                        ),
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onFilterSelectUpdate(
                                            $event,
                                            property,
                                            index,
                                            _vm.axisCollRows[
                                              axisType.seriesLayoutBy
                                            ]
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            property.subType === "filter" &&
                            property.type === "metric"
                              ? _c(
                                  "li",
                                  [
                                    _c("drop-down-filter", {
                                      staticClass: "nav-item dropdown",
                                      attrs: {
                                        direction: "none",
                                        icon: "dropdown",
                                        position: "left",
                                        tag: "div",
                                        value: _vm.filteredItems(
                                          property,
                                          index,
                                          _vm.axisCollRows[
                                            axisType.seriesLayoutBy
                                          ]
                                        ),
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onFilterSelectUpdate(
                                            $event,
                                            property,
                                            index,
                                            _vm.axisCollRows[
                                              axisType.seriesLayoutBy
                                            ]
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }