var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fieldFormArray
    ? _c(
        "div",
        {
          staticClass: "clearfix schema-form-tabarray",
          class:
            "schema-form-tabarray-" +
            (_vm.fieldForm.tabType || "left") +
            " " +
            (_vm.fieldForm.htmlClass || ""),
        },
        [
          _c("div", { staticClass: "row tabarray-container" }, [
            !_vm.fieldForm.tabType || _vm.fieldForm.tabType !== "right"
              ? _c(
                  "div",
                  {
                    staticClass: "tabarray-menu",
                    class: _vm.resolveTabClass(),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "nav-tabs-navigation verical-navs" },
                      [
                        _c("div", { staticClass: "nav-tabs-wrapper" }, [
                          _c(
                            "ul",
                            {
                              staticClass:
                                "nav flex-column nav-stacked nav-tabs nav-tabs-left flex-nowrap",
                              attrs: {
                                "aria-orientation": "vertical",
                                role: "tablist",
                              },
                            },
                            [
                              _vm._l(_vm.arrayValues, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "nav-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelectItem(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("sf-change-position", {
                                      attrs: {
                                        "array-values": _vm.arrayValues,
                                        "current-index": index,
                                        "current-item": item,
                                      },
                                      on: {
                                        arrayupdated: _vm.onArrayValuesChanged,
                                      },
                                    }),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "tabbed nav-link",
                                        class: {
                                          active: _vm.selectedTab === index,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "nav-text" },
                                          [_vm._v(_vm._s(_vm.tabTitles[index]))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "li",
                                {
                                  staticClass: "nav-item",
                                  attrs: {
                                    disabled:
                                      _vm.resolveMaxItems(_vm.fieldForm) <=
                                        (_vm.arrayValues.length || 0) ||
                                      _vm.isDroppable,
                                    hidden:
                                      _vm.fieldForm.readonly ||
                                      _vm.fieldForm.add === null,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.appendToArray(
                                        _vm.resolveMaxItems(_vm.fieldForm)
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link btn btn-sm",
                                      class:
                                        _vm.fieldForm.btnStyle &&
                                        _vm.fieldForm.btnStyle.add
                                          ? _vm.fieldForm.btnStyle.add
                                          : "btn-primary",
                                      attrs: {
                                        disabled: _vm.isDroppable,
                                        href: "#",
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-plus" }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.fieldForm.add || "Add") +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  _vm.fieldForm.contentClass || "col",
                  "tabarray-content",
                ],
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab-content",
                    class: _vm.fieldForm.fieldHtmlClass,
                    attrs: { role: "tabpanel" },
                  },
                  [
                    _vm.arrayValues && _vm.arrayValues.length > 0
                      ? [
                          _c(
                            "div",
                            {
                              key: _vm.resolveArrayStringifiedKey(
                                _vm.selectedTab,
                                _vm.selectedTab
                              ),
                              staticClass: "tab-pane clearfix",
                              class: [
                                "tab-" +
                                  _vm.selectedTab +
                                  " index-" +
                                  _vm.selectedTab,
                                { active: true },
                              ],
                            },
                            [
                              _c("builder-component", {
                                attrs: {
                                  "field-mapper": _vm.fieldMapper,
                                  form: _vm.resolveMergedForm(_vm.selectedTab),
                                  "full-model": _vm.fullModel,
                                  "is-droppable": _vm.isDroppable,
                                  "merged-form": _vm.resolveMergedForm(
                                    _vm.selectedTab
                                  ),
                                  "parent-form": _vm.resolveParentForm(
                                    _vm.selectedTab
                                  ),
                                  "parent-index": _vm.selectedTab,
                                  "schema-form-service": _vm.schemaFormService,
                                  schema: _vm.resolveFieldSchema(
                                    _vm.selectedTab
                                  ),
                                  value: _vm.resolveSelectedValue(
                                    _vm.selectedTab
                                  ),
                                },
                                on: {
                                  fullModelChanged: _vm.onFullModelChanged,
                                  internalSchemaChanged:
                                    _vm.onInternalSchemaChanged,
                                  schemaChanged: _vm.onSchemaArrayChanged,
                                },
                              }),
                              _c("div", {
                                directives: [
                                  {
                                    name: "sf-key",
                                    rawName: "v-sf-key",
                                    value: {
                                      fieldForm: _vm.fieldForm,
                                      parentIndex: _vm.parentIndex,
                                      index: _vm.selectedTab,
                                      fieldValue:
                                        _vm.arrayValues[_vm.selectedTab],
                                    },
                                    expression:
                                      "{ 'fieldForm': fieldForm, 'parentIndex': parentIndex, 'index': selectedTab, 'fieldValue':arrayValues[selectedTab] }",
                                  },
                                ],
                              }),
                              !_vm.fieldForm.readonly ||
                              !_vm.fieldForm.remove === null
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm pull-right",
                                      class:
                                        (_vm.fieldForm.btnStyle &&
                                          _vm.fieldForm.btnStyle.remove) ||
                                        "btn-default",
                                      attrs: {
                                        disabled: _vm.isDroppable,
                                        type: "button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteFromArray(
                                            _vm.arrayValues[_vm.selectedTab],
                                            _vm.selectedTab
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "glyphicon glyphicon-trash",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.fieldForm.remove || "Remove"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c("builder-component", {
                            attrs: {
                              "field-mapper": _vm.fieldMapper,
                              form: [],
                              "full-model": _vm.fullModel,
                              "is-droppable": _vm.isDroppable,
                              "merged-form": [],
                              schema: {},
                              value: {},
                            },
                            on: {
                              fullModelChanged: _vm.onFullModelChanged,
                              internalSchemaChanged:
                                _vm.onInternalSchemaChanged,
                              schemaChanged: _vm.onSchemaArrayChanged,
                            },
                          }),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ]),
          _vm.validationError
            ? _c("div", { staticClass: "invalid-feedback error-text" }, [
                _vm._v(_vm._s(_vm.validationError)),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "text-muted small help-block",
              class: _vm.fieldForm.descriptionHtmlClass,
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.fieldForm.description) },
              }),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }