var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDroppable
    ? _c(
        "div",
        {
          staticClass: "toolbar",
          class: _vm.selectedItem.guid === _vm.field.guid ? "selected" : "",
        },
        [
          _c("div", { staticClass: "toolbar-wrapper" }, [
            _c("div", { staticClass: "toolbar-container" }, [
              _c(
                "a",
                {
                  staticClass: "handle text-success",
                  attrs: { href: "", title: "Use to drag component" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-arrows-alt mr-4" })]
              ),
              _c(
                "a",
                {
                  staticClass: "text-warning mr-2",
                  attrs: {
                    title: _vm.minimized
                      ? "Maximize component"
                      : "Minimize component",
                    href: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.minimize()
                    },
                  },
                },
                [
                  _c("i", {
                    class: _vm.minimized ? "fas fa-plus" : "fas fa-minus",
                  }),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "text-primary mr-2",
                  attrs: { href: "", title: "Select item to update settings" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectItem(_vm.field)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-check" })]
              ),
              _c(
                "a",
                {
                  staticClass: "text-danger mr-2",
                  attrs: { href: "", title: "Remove component" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeFormField(_vm.field)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-trash" })]
              ),
              _c("span", { staticClass: "text-muted small" }, [
                _vm._v(_vm._s(_vm.guid)),
              ]),
              _vm._v(" - "),
              _c("span", { staticClass: "text-muted small" }, [
                _vm._v(" Type: " + _vm._s(_vm.field.type) + " "),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }