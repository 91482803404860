<template>
  <div class="schema-form-builder-inner">
    <component :class="[fieldForm.htmlClass]"
               :component-data="{ field: fieldForm }"
               :field-form="fieldForm"
               :field-mapper="fieldMapper"
               :field-schema="fieldForm" :full-model="fullModel"
               :parent-form="parentForm"

               :schema-form-service="schemaFormService"
               :is="resolveFieldComponent(fieldForm)" :is-droppable="isDroppable" :parent-index="parentIndex"
               :value="resolveValue(value, fieldForm)" @fullModelChanged="onFullModelChanged"
               @input="onInput($event, fieldForm)" @internalSchemaChanged="onInternalChildSchemaChanged"
               v-if="fieldForm.schemaFieldKey"></component>

    <component :class="[fieldForm.htmlClass]"
               :component-data="{ field: fieldForm }"
               :field-form="fieldForm" :field-mapper="fieldMapper"
               :field-schema="fieldForm" :full-model="fullModel"
               :is="resolveFieldComponent(fieldForm)" :is-droppable="isDroppable"
               :parent-index="parentIndex"
               :parent-form="parentForm"

               :schema-form-service="schemaFormService"
               :value="resolveValue(value, fieldForm)" @fullModelChanged="onFullModelChanged"
               @input="onKeylessInput($event, fieldForm)" @internalSchemaChanged="onInternalChildSchemaChanged"
               v-if="!fieldForm.schemaFieldKey"></component>
  </div>
</template>

<script>
  import buildMixins from './buildMixins'
  import droppableMixins from './droppableMixins'
  import builderComponentMixins from "./builderComponentMixins";

  export default {
    name: "BuilderInnerComponent",
    props: ['fieldForm'],
    mixins: [builderComponentMixins, buildMixins, droppableMixins],
    methods: {
      onInternalChildSchemaChanged(schemaFormEvt) {
        this.$emit('internalSchemaChanged', schemaFormEvt);
      }
    }

  }
</script>

<style scoped>
.schema-form-builder-inner {
  height: 100%;
}


</style>
