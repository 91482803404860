const formBusMixin = {
  props:{
    formBus: Object
  },
  created() {
    if (this.formBus) {
      const that = this;
      this.$bus.$on('formEvent', that.onEvent);
    }
  },
  beforeDestroy() {
    if (this.formBus) {
      const that = this;
      this.$bus.$off('formEvent', that.onEvent);
    }
  },
  computed:{

  },
  methods:{
    reloadComponent(key){

    },
    sendEvent(name, payload){

    },
    onEvent(payload){

    }
  }
}

export default formBusMixin;
