<template>
  <div>
    <v-runtime-template :template="template"></v-runtime-template>
  </div>
</template>

<script>
  import VRuntimeTemplate from "v-runtime-template";
  import componentMixin from './componentMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
  import titleMapMixins from "./titleMapMixins"
  import findIndex from 'lodash/findIndex'

  export default {
    name: "SfTemplateList",
    components: {VRuntimeTemplate},
    mixins: [componentMixin, simpleComponentMergeInMixin, titleMapMixins],
    data() {
      return {
        template: '<div></div>',
        selected: undefined
      }
    },
    created() {
      //console.log('Template List fieldForm', this.fieldForm, this.titleMap);
      if (this.fieldForm.template) {
        this.template = this.fieldForm.template;
      } else {

      }
    },
    async mounted() {
      const that = this;
      //await this.resolveSelectOptions();
      console.log('title map', that.titleMap)
      this.$nextTick(function () {
        that.setSelected();
        that.selectDefault();
      })
    },
    methods: {
      setSelected: function () {
        if (this.localValue) {
          this.selected = this.localValue;
        }
      },
      selectDefault: function () {
        if (this.fieldForm.groupBy) {
          const grouped = this.grouped;
          if (this.selected) {
            // find first item related to SELECTED and use this.fieldForm.groupBy to get GroupName
            const filter = {};
            filter[this.valueKey] = this.selected;
            const index = findIndex(this.titleMap, filter); //{ value: this.selected}
            const item = this.titleMap[index];
            this.selectGroup(item[this.fieldForm.groupBy]);
            const objVal = {};
            objVal[this.valueKey] = this.selected;
            this.onInputChanged(objVal, undefined);
          } else {
            this.selectGroup(Object.keys(grouped)[0]);
          }
        }
      },
      onClickReset: function (event) {
        if (this.selected === event.target.value) {
          this.selected = undefined;
          this.localValue = undefined;
        }
      },
      onChangeEvent: function (event) {
        this.localValue = event.target.value;
        this.selected = event.target.value;
      },
    }
  }
</script>

<style scoped>

</style>
