const componentMixin = {
  props:{
    inputContext: {
      type: Object
    },
    layout:{
      type: Object
    },
    appConfig:{
      type: Object
    },
    config:{
      type: Object
    },
    securityConfig:{
      type: Object
    },
    userMenuConfig:{
      type: Object
    },
    selectedRouteConfig:{
      type: Object
    }
  },
  data(){
   return {
     homeRouteName: '/Home'
   }
  },
  computed: {
    routeEnabled: function(){
      return this.isRouteEnabled(this.selectedRouteConfig);
    },
    routeSecurity: function(){
      if (this.selectedRouteConfig && this.selectedRouteConfig.item){
        return this.selectedRouteConfig.item.security;
      }
    },
    routePermissions: function(){
      if (this.routeSecurity && this.routeSecurity.permissions){
        return this.routeSecurity.permissions;
      }
    },
    enabled: function(){ // TODO - refactor  - this is related only to Links (sidebar, navbar or userbar) - not for Route
      if(this.config && this.config.security && (this.config.security.enabled!==undefined || this.config.security.enabled!==null)){
        return this.config.security.enabled;
      }
      return true;
    },
    homeComponent: function () {
      if (this.appConfig.isComponentMode) {
        return 'a';
      } else {
        return 'router-link'
      }
    },
    homeLink: function () {
      if (this.appConfig.isComponentMode === true) {
        return '#';
      } else {
        return {name: this.homeRouteName}
      }
    }
  },
  methods:{
    isDefined: function(item){
      return null!==item || item!==undefined;
    },
    isRouteEnabled: function(selectedRouteConfig){
   //   console.log('isRoute enabled: ', selectedRouteConfig);
      if (selectedRouteConfig && selectedRouteConfig.item && selectedRouteConfig.item.security){
        const enabled = selectedRouteConfig.item.security.enabled;
        if (enabled!==null && enabled!==undefined){
          return enabled;
        }
      }
      return true;
    },
    onHomeClick: function(){
      const that = this;
  //    console.log("Home clicked", this.config, this.$router.options.routes);
      this.$router.options.routes.forEach(function(route){
        if (route.children){
          route.children.forEach(function(subRoute){
            if (subRoute.name===that.homeRouteName){
              that.$emit('selected', subRoute.meta.def);
            }
          });
        }
      })
    },
    camelToKebab: function(str){
      return str
        .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
        .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
        .toLowerCase();
    }
  }
}

export default componentMixin;
