<template>
  <div class="row">
    <div class="col-2">
      <label>Elements</label>
      <div class="expression-designer-container">
        <expression-elements></expression-elements>
      </div>
    </div>
    <div class="col">
      <label>Designer</label>
      <expression-designer-board @onChange="onExpressionChanged"></expression-designer-board>
    </div>
    <div class="col-3">
      Expression
      <pre>
        {{ expressionObj }}
      </pre>
    </div>
  </div>
</template>

<script>
import ExpressionElements from "./ExpressionElements";
import ExpressionDesignerBoard from "./ExpressionDesignerBoard";

export default {
  name: "ExpressionDesigner",
  components: {ExpressionDesignerBoard, ExpressionElements},
  data() {
    return {
      expressionObj: null
    }
  },
  methods: {
    onExpressionChanged(expressionObj) {
      this.expressionObj = expressionObj;
      console.log('expressionObj', expressionObj);
    }
  }
}
</script>

<style scoped>

</style>
