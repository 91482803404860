<template>
  <div>
    <schema-form :schema="resolveSchema()" :form="resolveForm()" v-model="dataModel"
                 @validationResult="onValidationResult"></schema-form>
  </div>
</template>

<script>

  import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'

  export default {
    name: "VueDfIntegrationDataSourceView",
    mixins: [widgetComponentViewMixins],
    data() {
      return {

      }
    },
    methods:{
      resolveSchema: function () {
        return this.model.config.data.schemaForm ? this.model.config.data.schemaForm.schema : {};
      },
      resolveForm: function () {
        return this.model.config.data.schemaForm ? this.model.config.data.schemaForm.form : [];
      },
      onValidationResult: function (value) {
        //  console.log(value);
        //  console.log(this.dataModel);
        this.validationResult = value;

        if (value.valid) {
          this.onSubmit(this.dataModel);
        } else {
          this.notifyVueMessage('Form is not valid', 'bottom', 'right', 'danger', 10000);
        }
      }
    }
  }
</script>

<style scoped>

</style>
