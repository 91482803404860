<template>
  <div class="element-toolbar">
    <i title="Remove element" @click="removeElement()" class="fas fa-trash"></i>
  </div>
</template>

<script>
export default {
  name: "ElementToolbar",
  props: {
    element: Object
  },
  data() {
    return {}
  },
  methods: {
    removeElement() {
      this.$emit('onAction', {action: 'remove', element: this.element})
    }
  }
}
</script>

<style scoped>
.element-toolbar {

}
</style>
