var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-form-array" },
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _c(
          "ol",
          { staticClass: "list-group" },
          _vm._l(_vm.arrayValues, function (fieldValue, index) {
            return _c(
              "li",
              {
                key: _vm.resolveStringifiedArrayValueKey(fieldValue, index),
                staticClass: "list-group-item list-group-item-action",
                class: _vm.fieldForm.fieldHtmlClass,
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !(
                          _vm.fieldForm.readonly ||
                          _vm.fieldForm.remove === null
                        ),
                        expression:
                          "!(fieldForm.readonly || fieldForm.remove === null)",
                      },
                    ],
                    staticClass: "close pull-right",
                    staticStyle: { position: "relative", "z-index": "20" },
                    attrs: {
                      href: "",
                      disabled:
                        _vm.resolveMinItems(_vm.fieldForm) >=
                          _vm.arrayValues.length || _vm.isDroppable,
                      type: "button",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteFromArray(fieldValue, index)
                      },
                    },
                  },
                  [
                    !_vm.isDroppable
                      ? _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("×"),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "sr-only" }, [_vm._v("Close")]),
                  ]
                ),
                _c("builder-component", {
                  attrs: {
                    "full-model": _vm.fullModel,
                    "schema-form-service": _vm.schemaFormService,
                    "parent-index": index,
                    "parent-form": _vm.resolveParentForm(index),
                    "merged-form": _vm.resolveMergedForm(index),
                    "is-droppable": _vm.isDroppable,
                    schema: _vm.resolveFieldSchema(index),
                    form: _vm.resolveMergedForm(index),
                    value: fieldValue,
                    "field-mapper": _vm.fieldMapper,
                  },
                  on: {
                    fullModelChanged: _vm.onFullModelChanged,
                    schemaChanged: _vm.onSchemaArrayChanged,
                    internalSchemaChanged: _vm.onInternalSchemaChanged,
                  },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "sf-key",
                      rawName: "v-sf-key",
                      value: {
                        fieldForm: _vm.fieldForm,
                        index: index,
                        fieldValue: fieldValue,
                      },
                      expression:
                        "{ 'fieldForm': fieldForm, 'index': index, 'fieldValue':fieldValue }",
                    },
                  ],
                }),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "clearfix", staticStyle: { padding: "10px" } },
          [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !(
                      _vm.fieldForm.readonly || _vm.fieldForm.add === null
                    ),
                    expression:
                      "!(fieldForm.readonly || fieldForm.add === null)",
                  },
                ],
                staticClass: "btn pull-right",
                class:
                  (_vm.fieldForm.btnStyle && _vm.fieldForm.btnStyle.add) ||
                  "btn-primary",
                attrs: {
                  href: "",
                  disabled:
                    _vm.resolveMaxItems(_vm.fieldForm) <=
                      _vm.arrayValues.length || _vm.isDroppable,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.appendToArray(_vm.resolveMaxItems(_vm.fieldForm))
                  },
                },
              },
              [
                _c("i", { staticClass: "glyphicon glyphicon-plus" }),
                _vm._v(
                  "\n        " + _vm._s(_vm.fieldForm.add || "Add") + "\n      "
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }