<template>
  <div>
    <schema-form :form="form" :schema="schema" v-model="model.config.data"></schema-form>
  </div>
</template>

<script>
  import jsnotevil from "jsnotevil";
  import Vue from 'vue'
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
  import cloneDeep from 'lodash/cloneDeep'
  import commonEditMixin from '../commonEditMixin'

  export default {
    name: "VueDfIntegrationWidgetEdit",
    mixins: [widgetComponentEditMixins, commonEditMixin],
    data() {
      return {
        api: {},
        authWindowHandler: undefined,
        intervalHandler: undefined,
        schema: {},
        form: [],
        schemaFormCustom: {
          schemaProperties: {
            sourceType:{
              type: 'string',
              title: 'Source Type',
              enum:['QuerySource', 'DataSource'],
              default: 'QuerySource',
              description: "Choose Source Type. Using <strong>DataSource</strong> as source type you will be able to connect multiple Visual elements while with <strong>QuerySource</strong> you will get predefined Visualisation"
            },
            dataSource:{
              type: 'string',
              title: 'DataSource',
              description:'Select data source'
            },
            source: {
              type: 'string',
              title: 'Source',
              description: 'Select integration source'
            },
            configuration: {
              type: 'object',
              properties: {}
            }
          },
          formItem: {
            title: 'Sources',
            fieldHtmlClass: 'p-2',
            items: [
              {
                type: 'section',
                sectionContainerClass: 'row',
                sectionChildClass: 'col',
                items: [
                  {
                    type: 'help',
                    childClass: 'col-4 col-xs-2 my-auto',
                    description: '<h5><div>Select and configure <strong>source</strong> for Integration widget</div></h5>',
                  },
                  {
                    type: 'select',
                    key:'sourceType'
                  },
                  {
                    type: "select",
                    key: "source",
                    name: "select",
                    childClass: "col col-xs-2",
                    leafImage: "sourceTypeImage",
                    branchImage: "typeCategoryImage",
                    valueKey: 'sourceType',
                    labelKey: 'sourceType',
                    groupBy: "typeCategory",
                    onChange: {
                      remove: ['authAccount']
                    },
                    options: {
                      callback: 'loadSources',
                      resultExpression: '$result$'
                    },
                    condition:'model.sourceType==="QuerySource"'
                  },
                  {
                    type: "select",
                    key: "dataSource",
                    name: "select",
                    childClass: "col col-xs-2",
                    leafImage: "sourceTypeImage",
                    branchImage: "typeCategoryImage",
                    valueKey: 'sourceType',
                    labelKey: 'sourceType',
                    groupBy: "typeCategory",
                    onChange: {
                      remove: ['authAccount']
                    },
                    options: {
                      callback: 'loadSources',
                      resultExpression: '$result$'
                    },
                    condition:'model.sourceType==="DataSource"'
                  }
                ]
              },
              {
                type: 'help',
                notitle: true,
                description: '<hr />'
              }
            ]
          }
        },
        formConfigContainerDefault: {
          type: 'section',
          sectionContainerClass: 'row',
          sectionChildClass: 'col',
          items: []
        }
      }
    },
    watch: {
      'model.config.data.source': {
        handler(newVal, oldVal) {
          console.log('Source changed', newVal, oldVal);
          this.onSelectedSource(newVal, oldVal);
        },
        immediate: true
      }
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    methods: {
      loadAuthAccounts: function (arg) {
        const sourceName = this.model.config.data.source;
        const that = this;

        const urlToLoad = this.$appConfig.appConfig.apiBase +'/v1/integrationdashboard/accounts/auth/' + sourceName;
        this.$http.get(urlToLoad)
          .then(function (result) {
            const res = result.data.result;
            if (arg && arg.fn) {
              arg.fn(res);
            } else {
              const localForm = cloneDeep(that.form);
              let items = localForm[0].tabs[0].items[2].items[0].items;
              let authItemsFormItem = items[0];
              authItemsFormItem.guid = that.generateUUID();
              that.$set(authItemsFormItem, 'titleMap', res);
              items.splice(0, 1, authItemsFormItem);
              that.form = localForm;
            }
          })
          .catch(function (err) {
            console.error(err);
          });

      },
      addNewAccount: function (options, event, fieldForm) {
        console.log('onAddNewAccount', this.model.config.data, options, event, fieldForm,  this.$appConfig);
        // open URL in new Window
        const sourceName = this.model.config.data.source;
       // const currentUser = this.$userService.getCurrentUser();
        const token = this.$auth.getToken();
        // console.log('Current User', currentUser, token);
        const urlToOpen = this.$appConfig.appConfig.integrationAuthHost+'/auth/' + sourceName + '?token=' + token;

        const that = this;
        this.authWindowHandler = window.open(urlToOpen, 'Integration ' + sourceName + ' Login', 'resizable,height=560,width=770');
        this.intervalHandler = setInterval(function () {
          if (that.authWindowHandler && that.authWindowHandler.closed) {
            window.clearInterval(that.intervalHandler);
            that.intervalHandler = undefined;
            that.authWindowHandler = undefined;
            that.loadAuthAccounts();
          }
        }, 1000);
      },
      /*onValidationResult: function (validationResult) {
        // validated form - show errors if any
        if (validationResult.valid) {

        } else {

        }
      },*/
      handleValidation(definition){
        if (definition && definition.validation) {
          this.model.config.validation = definition.validation;
        }
      },
      handleDefinitionScript(definition) {
        this.api = {};
        if (definition && definition.script) {
          const scriptObjectContainingFunctions = definition.script;
          const func = jsnotevil.Function(scriptObjectContainingFunctions);

         // const boundFunc = func.bind(this);
          console.log('func OBJ parsed', func, this);

          const objApi = func();
          for (const fn in objApi) {
           const boundFunc = objApi[fn].bind({ model: this.model});
           console.log('Binding to API', fn, boundFunc);
            this.$set(this.api, fn, boundFunc);
          }
          console.log('OBJ parsed', objApi, this.api);
        }
      },
      mergeLoadedSchemaForm(definition) {
        const schemaForm = this.getSchemaForSourceMerged();
        if (definition) {
          const that = this;
          const schema = Vue.observable(definition.definition.schema);
          const form = Vue.observable(definition.definition.form);

          for (const prop in schema.properties) {
            this.$set(schemaForm.schema.properties, prop, schema.properties[prop]);
          }
          //this.$set(schemaForm.schema.properties, 'configuration', schema);

          that.formConfigContainerDefault.items = [];
          form.forEach(function (item) {
            that.formConfigContainerDefault.items.push(item);
          });

          const formItems = schemaForm.form[0].tabs[0].items;
          if (formItems.length === 2) {
            formItems.push(this.formConfigContainerDefault);
          } else {
            formItems.pop();
            formItems.push(this.formConfigContainerDefault);
          }

          console.log('Loaded definition', definition);
          this.handleDefinitionScript(definition);
          this.handleValidation(definition);
        }
        this.$set(this, 'schema', schemaForm.schema);
        this.$set(this, 'form', schemaForm.form);
      },
      onSelectedSource: async function (val, oldValue, schemaKey, fieldForm) {
        const that = this;
        console.log('execOnChangeFn', val, oldValue, schemaKey, fieldForm);
        if (val) {
          // check if selected is same and if not, clear the configuration
          if (oldValue && oldValue !== val) {
            that.$set(that.model.config.data, 'configuration', Object.assign({}));
          }
          const definitionUrl = this.$appConfig.appConfig.apiBase +'/v1/integrationdashboard/configuration/source/' + val;
          that.$http.get(definitionUrl).then((res) => {
            const def = res.data.result;
            console.log('res.data.result', def);
            that.mergeLoadedSchemaForm(def);
          }).catch((err) => {
            console.error(err);
          });
        } else {
          that.$set(that.model.config.data, 'configuration', Object.assign({}));
        }
      },

      getSchemaForSourceMerged() {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.form[0].tabs.splice(0, 2);
        schemaFormSourceDestination.form[0].tabs.splice(1, 1);

        for (const prop in this.schemaFormCustom.schemaProperties) {
          schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
        }

        schemaFormSourceDestination.form[0].tabs.unshift(cloneDeep(this.schemaFormCustom.formItem));
        return schemaFormSourceDestination;
      },
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSchemaForSourceMerged();
        this.schemaForm = schemaFormSourceDestination;
        this.schema = schemaFormSourceDestination.schema;
        this.form = schemaFormSourceDestination.form;
      }
    }
  }
</script>

<style scoped>

</style>
