const dropMixins = {
  methods:{
    onElementMove(forbiddenElementId, {related, relatedContext, draggedContext}) {
      console.log('relatedContext', related, relatedContext.component.$el.id);
      const allowedIn = this.containedInClassList(related.classList, ['free']);

      return relatedContext.component.$el.id !== forbiddenElementId && allowedIn;
    },
    containedInClassList(list, allowedInContext) {
      if (!allowedInContext) return false;
      const some = allowedInContext.some((item) => list.contains(`context-${item}`));
      console.log('some', list, allowedInContext, some);
      return some;
    }
  }

}

export default dropMixins;
