var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      tag: "component",
      staticClass: "dropdown",
      class: [{ show: _vm.isOpen }],
      attrs: { "aria-expanded": _vm.isOpen, "aria-haspopup": "true" },
      on: { click: _vm.toggleDropDown },
    },
    [
      _vm._t(
        "title",
        function () {
          return [
            _c(
              "a",
              {
                staticClass: "dropdown-toggle nav-link",
                class: { "no-caret": _vm.hideArrow },
                attrs: { "data-toggle": "dropdown" },
              },
              [
                _c("i", { class: _vm.icon }),
                _c("span", { staticClass: "no-icon" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]
            ),
          ]
        },
        { isOpen: _vm.isOpen }
      ),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          class: [
            { "dropdown-menu-right": _vm.position === "right" },
            { show: _vm.isOpen },
          ],
          attrs: { "x-placement": _vm.directionAttribute },
        },
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }