<template>
  <div class="widget-debug card p-4">
    <div class="widget-debug-header">
      <button @click="toggleShow()" class="btn btn-sm">Debug</button>
    </div>
    <div class="widget-debug-body" v-if="show">
      <ul class="list-unstyled">
        <li v-for="item in model">
          {{ item }}
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
  export default {
    name: "VueDfWidgetDebugView",
    data() {
      return {
        show: false
      }
    },
    props: {
      model: {type: Array}
    },
    methods: {
      toggleShow: function () {
        this.show = !this.show;
      }
    }
  }
</script>

<style scoped>

</style>
