const structures = {
  '12': {
    rows: [{
      columns: [{
        styleClass: 'col'
      }]
    }]
  },
  '6-6': {
    rows: [{
      columns: [{
        styleClass: 'col-md-6 col-xs-12'
      }, {
        styleClass: 'col-md-6 col-xs-12'
      }]
    }]
  },
  '5-7': {
    rows: [{
      columns: [{
        styleClass: 'col-md-5 col-xs-12'
      }, {
        styleClass: 'col-md-7 col-xs-12'
      }]
    }]
  },
  '4-8': {
    rows: [{
      columns: [{
        styleClass: 'col-md-4 col-xs-12'
      }, {
        styleClass: 'col-md-8 col-xs-12'
      }]
    }]
  },
  '8-4': {
    rows: [{
      columns: [{
        styleClass: 'col-md-8 col-xs-12'
      }, {
        styleClass: 'col-md-4 col-xs-12'
      }]
    }]
  },
  '7-5': {
    rows: [{
      columns: [{
        styleClass: 'col-md-7 col-xs-12'
      }, {
        styleClass: 'col-md-5 col-xs-12'
      }]
    }]
  },
  '9-3': {
    rows: [{
      columns: [{
        styleClass: 'col-md-9 col-xs-12'
      }, {
        styleClass: 'col-md-3 col-xs-12'
      }]
    }]
  },
  '3-9': {
    rows: [{
      columns: [{
        styleClass: 'col-md-3 col-xs-12'
      }, {
        styleClass: 'col-md-9 col-xs-12'
      }]
    }]
  },
  '2-10': {
    rows: [{
      columns: [{
        styleClass: 'col-md-2 col-xs-12'
      }, {
        styleClass: 'col-md-10 col-xs-12'
      }]
    }]
  },
  '4-4-4': {
    rows: [{
      columns: [{
        styleClass: 'col-md-4 col-xs-12'
      }, {
        styleClass: 'col-md-4 col-xs-12'
      },
        {
          styleClass: 'col-md-4 col-xs-12'
        }]
    }]
  },
  '3-3-3-3': {
    rows: [{
      columns: [{
        styleClass: 'col-md-3 col-xs-12'
      }, {
        styleClass: 'col-md-3 col-xs-12'
      },
        {
          styleClass: 'col-md-3 col-xs-12'
        },
        {
          styleClass: 'col-md-3 col-xs-12'
        }
      ]
    }]
  },
  '2-2-2-2-2-2': {
    rows: [{
      columns: [{
        styleClass: 'col-md-2 col-xs-12'
      }, {
        styleClass: 'col-md-2 col-xs-12'
      },
        {
          styleClass: 'col-md-2 col-xs-12'
        },
        {
          styleClass: 'col-md-2 col-xs-12'
        },
        {
          styleClass: 'col-md-2 col-xs-12'
        },
        {
          styleClass: 'col-md-2 col-xs-12'
        }
      ]
    }]
  },
  '12/4-4-4': {
    rows: [{
      columns: [{
        styleClass: 'col-md-12'
      }]
    }, {
      columns: [{
        styleClass: 'col-md-4 col-xs-12'
      }, {
        styleClass: 'col-md-4 col-xs-12'
      }, {
        styleClass: 'col-md-4 col-xs-12'
      }]
    }]
  },
  '12/6-6': {
    rows: [{
      columns: [{
        styleClass: 'col-md-12'
      }]
    }, {
      columns: [{
        styleClass: 'col-md-6 col-xs-12'
      }, {
        styleClass: 'col-md-6 col-xs-12'
      }]
    }]
  },
  '12/3-3-3-3': {
    rows: [{
      columns: [{
        styleClass: 'col-md-12'
      }]
    }, {
      columns: [{
        styleClass: 'col-md-3 col-xs-6'
      }, {
        styleClass: 'col-md-3 col-xs-6'
      }, {
        styleClass: 'col-md-3 col-xs-6'
      }, {
        styleClass: 'col-md-3 col-xs-6'
      }
      ]
    }]
  },
  '12/6-6/12': {
    rows: [{
      columns: [{
        styleClass: 'col-md-12'
      }]
    }, {
      columns: [{
        styleClass: 'col-md-6 col-xs-12'
      }, {
        styleClass: 'col-md-6 col-xs-12'
      }]
    }, {
      columns: [{
        styleClass: 'col-md-12'
      }]
    }]
  },
  '3-9 (12/6-6)': {
    rows: [{
      columns: [
        {
          styleClass: 'col-md-3 col-xs-12'
        },
        {
          styleClass: 'col-md-9 col-xs-12',
          rows: [
            {
              columns: [
                {
                  styleClass: 'col-md-12'
                }
              ]
            },
            {
              columns: [
                {
                  styleClass: 'col-md-6 col-xs-12'
                },
                {
                  styleClass: 'col-md-6 col-xs-12'
                }
              ]
            }
          ]
        }
      ]
    }]
  }
}
export default structures
