var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
        _c(
          "div",
          {
            staticClass: "form-checkboxes-container",
            class: [_vm.isValid ? "" : "is-invalid"],
          },
          _vm._l(_vm.titleMap, function (checkbox) {
            return _c("div", { staticClass: "form-check form-check-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.titleMapValues,
                    expression: "titleMapValues",
                  },
                ],
                staticClass: "form-check-input",
                class: [_vm.fieldForm.fieldHtmlClass],
                attrs: {
                  readonly: _vm.fieldForm.readonly,
                  required: _vm.fieldForm.required,
                  type: "checkbox",
                },
                domProps: {
                  value: checkbox[_vm.valueKey],
                  checked: Array.isArray(_vm.titleMapValues)
                    ? _vm._i(_vm.titleMapValues, checkbox[_vm.valueKey]) > -1
                    : _vm.titleMapValues,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.titleMapValues,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = checkbox[_vm.valueKey],
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.titleMapValues = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.titleMapValues = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.titleMapValues = $$c
                    }
                  },
                },
              }),
              _c("label", { staticClass: "form-check-label my-auto" }, [
                _vm._v(_vm._s(checkbox[_vm.labelKey])),
              ]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }