function isInt(n) {
  return n % 1 === 0;
}

function processArray(data, parentProperty) {
  let items = {};
  let form = [];

  if (data && data.length > 0 && data[0]) {
    const firstRow = data[0];
    const schemaForm = processObject(firstRow, parentProperty);
    items = schemaForm.schema;
    form = schemaForm.form;
  }
  return {items: items, form: form};
}

function resolveKey(prop, parentProperty, isArray) {
  return parentProperty ? (isArray ? parentProperty + '[].' + prop : parentProperty + '.' + prop) : prop;
}

function generateUUID() {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

function processObject(data, parentProperty) {
  const schema = {
    type: 'object',
    properties: {}
  };
  const form = [];

  for (const prop in data) {
    const value = data[prop];
    if (value === null || undefined) {
      // default it is String
      schema.properties[prop] = {
        type: 'string',
        title: prop,
        description: 'Description for ' + prop
      }
      form.push({
        key: resolveKey(prop, parentProperty, false),
        guid: generateUUID()
      })
    } else {
      if (Array.isArray(value)) {
        const propArray = processArray(value, resolveKey(prop, parentProperty, true));
        //if (propArray) {
        schema.properties[prop] = {
          type: 'array',
          title: prop,
          description: 'Description for ' + prop,
          items: propArray.items
        }
        /*propArray.form.forEach(function (item) {
          form.push(item);
        });*/
        const formArray = {
          key: resolveKey(prop, parentProperty, false),
          title: "{{ 'Tab '+$index }}",
          description: 'Description for ' + prop,
          type: 'tabarray',
          items: propArray.form,
          guid: generateUUID()
        }
        form.push(formArray);
        //}
      } else if (typeof value === 'object') {
        const propObject = processObject(value, resolveKey(prop, parentProperty, false));
        if (propObject) {
          schema.properties[prop] = propObject.schema;

          const formItem = {
            key: resolveKey(prop, parentProperty, false),
            title: prop,
            description: 'Description for ' + prop,
            type: 'fieldset',
            items: propObject.form,
            guid: generateUUID()
          };
          form.push(formItem);
        }
      } else if (typeof value === 'number') {

        schema.properties[prop] = {
          type: isInt(value) ? 'integer' : 'number',
          title: prop,
          description: 'Description for ' + prop
        }
        form.push({
          key: resolveKey(prop, parentProperty, false),
          guid: generateUUID()
        });
      } else if (typeof value === 'boolean') {
        schema.properties[prop] = {
          type: 'boolean',
          title: prop,
          description: 'Description for ' + prop
        }
        form.push({
          key: resolveKey(prop, parentProperty, false),
          guid: generateUUID()
        });
      } else {
        schema.properties[prop] = {
          type: 'string',
          title: prop,
          description: 'Description for ' + prop
        }
        form.push({
          key: resolveKey(prop, parentProperty, false),
          guid: generateUUID()
        });
      }
    }
  }
  return {schema: schema, form: form};
}

function generate(data) {
  const result = processObject(data, null);
  return result;
}

function generateFormFromSchemaArray(schemaItems, parentPropertyKey, parentType) {
  let items = [];
  if (schemaItems) {
    if (Array.isArray(schemaItems)) {
      //TODO - how to handle array
    } else {
      if (schemaItems.type && schemaItems.type === 'object') {
        const forItems = generateFormFromSchemaProperties(schemaItems.properties, parentPropertyKey, parentType);
        items = forItems;
      } else if (schemaItems.type === 'string') {

      }
    }
  } else {

  }

  return items;
}

function getFullKey(item, prop, parentPropertyKey, parentType) {

  const fullKey = parentPropertyKey ? parentPropertyKey + (parentType === 'array' ? '[].' : '.') + prop : prop
  return fullKey;
}

function generateFromSingleProperty(item, prop, parentPropertyKey, parentType) {
  if (prop.indexOf('.') > -1) { // don't allow properties with . in the key
    return null;
  }
  if (item.type) {
    const obj = {
      key: getFullKey(item, prop, parentPropertyKey, parentType),
      guid: generateUUID()
    }
    switch (item.type) {
      case 'array':
        const items = generateFormFromSchemaArray(item.items, obj.key, 'array');
        obj.items = items;
        obj.title = "{{ 'Tab '+$index }}"
        if (item.items) {
          obj.type = 'tabarray';
        } else {
          obj.type = 'tags';
        }

        //obj.key = obj.key.substring(0, obj.key.length - 2);
        break;
      case 'object':
        obj.type = 'fieldset';
        if (item.properties) {
          const items = generateFormFromSchemaProperties(item.properties, obj.key, 'object');
          obj.items = items;
        }
        break;
      case 'boolean':
        obj.type = 'checkbox';
        break;
      case 'number':
      case 'integer':
        if (item.format && item.format === 'int32') {
          obj.type = 'integer'
        } else {
          obj.type = 'number'
        }
        break;
      default:
        if (item.enum) {
          obj.type = 'select';
        } else {
          obj.type = 'text';
        }
    }

    return obj;
  }
  return null;
}

function generateFormFromSchemaProperties(schemaProperties, parentPropertyKey, parentType) {
  const formItems = [];
  for (let prop in schemaProperties) {
    const item = schemaProperties[prop];
    const obj = generateFromSingleProperty(item, prop, parentPropertyKey, parentType);
    if (obj) {
      formItems.push(obj);
    } else {
      delete schemaProperties[prop];
    }
  }

  return formItems;
}

function generateFromSchema(schema) {
  console.log('generate from schema', schema);
  let form = [];
  if (schema && schema.properties) {
    const generatedFromProps = generateFormFromSchemaProperties(schema.properties, null);
    form = generatedFromProps;
  }

  return form;
}

export default {generate, generateFromSchema, generateUUID}
