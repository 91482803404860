const viewMixinServices = {
  services: {},
  add: function (name, service) {
    this.services[name] = service;
  },
  _isDefinedFunction(fn) {
    return fn && (typeof (fn) === typeof (Function));
  },
  async _fnForEach(fn, param1, param2){
    for (const p in this.services) {
      const service = this.services[p];
      if (this._isDefinedFunction(service[fn])) {
        await service[fn](param1, param2);
      }
    }
  },
  async onCreated(componentThis) {
    await this._fnForEach('onCreated', componentThis);
  },
  async onDestroyed(componentThis){
    await this._fnForEach('onDestroyed', componentThis);
  },
  async onComponentEvent(eventName, eventData) {
    await this._fnForEach('onComponentEvent', eventName, eventData);
  },
  async loadMetaContextObject(context){
    await this._fnForEach('loadMetaContextObject', context);
  },
  async executeService(name, definition, eventContext){
    this.services[name].executeService(definition, eventContext);
  }
}

const WidgetComponentViewMixinsServiceLoader = {
  install(Vue, options) {

    if (options && options.viewMixinServices) {
      options.viewMixinServices.forEach((service) => {
        viewMixinServices.add(service.name, service);
      });
    }

    Vue.viewMixinServices = viewMixinServices;

    Object.defineProperty(Vue.prototype, '$viewMixinServices', {
      get: function () {
        return viewMixinServices;
      }
    })
  }
}

export default WidgetComponentViewMixinsServiceLoader;
