var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chart-type-editor" },
    [
      _vm.schema
        ? _c("schema-form", {
            attrs: { schema: _vm.schema, form: _vm.form },
            model: {
              value: _vm.data,
              callback: function ($$v) {
                _vm.data = $$v
              },
              expression: "data",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }