

const chartDataUtils  = {
  groupBy: function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },
  uniqueBy: function(arr, fn) {
    var unique = {};
    var distinct = [];
    arr.forEach(function (x) {
      var key = fn(x);
      if (!unique[key]) {
        distinct.push(key);
        unique[key] = true;
      }
    });
    return distinct;
  },
  projectObject: function(arr, columns){
    return arr.map((item)=>{
      const obj ={};
      columns.forEach((col)=>{
        obj[col]=item[col];
      });
      return obj;
    });
  },
  projectArray: function(arr, columns){
    return arr.map((item)=>{
      const obj = [];
      columns.forEach((col)=>{
        obj.push(item[col]);
      });
      return obj;
    });
  },
  toArray: function(objArray){
    if (objArray){
      const parentArray = [];
      const header = [];
      parentArray.push(header);
      let index = 0;
      objArray.forEach(function(obj){
        if (obj){
          const tmp = [];
          for (const prop in obj){
            if (index===0) {
              if (header.indexOf(prop) === -1) {
                header.push(prop);
              }
            }
            tmp.push(obj[prop]);
          }
          parentArray.push(tmp);
        }
      });
      return parentArray;
    }
  }
}


export default chartDataUtils
