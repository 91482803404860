<template>
  <component-base v-bind:field-form="fieldForm">
    <div :class="[isValid ? '': 'is-invalid']">
      <datetime :auto="true" :input-class="{'form-control': true, 'bg-white': true, 'is-invalid': !isValid}"
                :readonly="fieldForm.readonly"
                :required="fieldForm.required"
                class="input-group"
                type="date" v-model="localDate">
        <div class="input-group-append" slot="after">
          <button @click="clearInput()" class="btn btn-outline-secondary" title="Clear date" type="button">Clear
          </button>
        </div>
      </datetime>
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage }}</small>
    </div>
  </component-base>
</template>
<!--https://mariomka.github.io/vue-datetime/-->

<script>
import componentMixin from './componentMixins'
import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
import {Datetime} from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: "SfDate",
  mixins: [componentMixin, simpleComponentMergeInMixin],
  components: {
    Datetime
  },
  data() {
    return {
      localDate: undefined
    }
  },
  watch: {
    localDate: function (newVal, oldVal) {
      this.localValue = newVal;
    }
  },
  created() {
    if (this.localValue) {
      this.localDate = this.localValue;
    } else if (this.fieldForm.schema && this.fieldForm.schema.default) {
      this.localDate = this.fieldForm.schema.default;
    }
  },
  methods: {
    clearInput: function () {
      this.localDate = undefined;
    }
  }
}
</script>

<style scoped>
.vdatetime.input-group {
  margin-bottom: 0px;
}

</style>
