var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integration-widget-view vld-parent" },
    [
      !_vm.isLoading
        ? _c("vue-df-integration-component", {
            ref: "integrationComponent",
            attrs: {
              dashboardEditMode: _vm.dashboardEditMode,
              "data-model": _vm.uiData || undefined,
              id: _vm.id,
              "is-maximized": _vm.isMaximized,
              maximizedArea: _vm.maximizedArea,
              model: _vm.model,
              "ui-definition": _vm.uiConfig || undefined,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }