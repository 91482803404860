var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { attrs: { onsubmit: "return false;", role: "form" } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                attrs: {
                  for: "dashboardTitle",
                  text: _vm.translate("ADF_COMMON_TITLE"),
                },
              },
              [_vm._v("Title")]
            ),
            _c("input", {
              staticClass: "form-control",
              attrs: { id: "dashboardTitle", required: "", type: "text" },
              domProps: { value: _vm.copy.title },
              on: {
                input: function ($event) {
                  return _vm.onTitleUpdate($event)
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "col-1" }, [
          _c("div", { staticClass: "form-group float-left" }, [
            _c("label", [_vm._v("Hide Title?")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copy.hideTitle,
                  expression: "copy.hideTitle",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.copy.hideTitle)
                  ? _vm._i(_vm.copy.hideTitle, null) > -1
                  : _vm.copy.hideTitle,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.copy.hideTitle,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.copy, "hideTitle", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.copy,
                            "hideTitle",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.copy, "hideTitle", $$c)
                    }
                  },
                  function ($event) {
                    return _vm.onHideTitleUpdate($event)
                  },
                ],
              },
            }),
          ]),
        ]),
      ]),
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { title: "Common" } }, [
            _c("div", { staticClass: "form-group p-2" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.copy.maximizable,
                      expression: "copy.maximizable",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.copy.maximizable)
                      ? _vm._i(_vm.copy.maximizable, null) > -1
                      : _vm.copy.maximizable,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.copy.maximizable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.copy,
                                "maximizable",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.copy,
                                "maximizable",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.copy, "maximizable", $$c)
                        }
                      },
                      _vm.onCheckChanged,
                    ],
                  },
                }),
                _c("span", [_vm._v(" Maximizable")]),
              ]),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.copy.collapsible,
                      expression: "copy.collapsible",
                    },
                  ],
                  staticClass: "ml-3",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.copy.collapsible)
                      ? _vm._i(_vm.copy.collapsible, null) > -1
                      : _vm.copy.collapsible,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.copy.collapsible,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.copy,
                                "collapsible",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.copy,
                                "collapsible",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.copy, "collapsible", $$c)
                        }
                      },
                      _vm.onCheckChanged,
                    ],
                  },
                }),
                _c("span", [_vm._v(" Collapsible")]),
              ]),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.copy.refreshable,
                      expression: "copy.refreshable",
                    },
                  ],
                  staticClass: "ml-3",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.copy.refreshable)
                      ? _vm._i(_vm.copy.refreshable, null) > -1
                      : _vm.copy.refreshable,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.copy.refreshable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.copy,
                                "refreshable",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.copy,
                                "refreshable",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.copy, "refreshable", $$c)
                        }
                      },
                      _vm.onCheckChanged,
                    ],
                  },
                }),
                _c("span", [_vm._v(" Refreshable")]),
              ]),
            ]),
            _c("div", { staticClass: "form-group p-2" }, [
              _c(
                "label",
                {
                  attrs: {
                    text: _vm.translate("ADF_EDIT_DASHBOARD_STRUCTURE_LABEL"),
                  },
                },
                [_vm._v("Structure")]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.splitedStructures, function (structureColumn) {
                  return _c(
                    "div",
                    { staticClass: "col-lg-4 col-xs-12" },
                    _vm._l(structureColumn, function (structure, key) {
                      return _c("div", { staticClass: "mb-2 mt-2" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-2" }, [
                            _c("label", [
                              _c("input", {
                                attrs: {
                                  model: _vm.copy.structure,
                                  name: "structure",
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm.copy.structure === key,
                                  value: key,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeStructure(key, structure)
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-9",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeStructure(key, structure)
                                },
                              },
                            },
                            [
                              _c("vue-df-dashboard-structure-preview", {
                                attrs: {
                                  selected: _vm.copy.structure === key,
                                  structure: structure,
                                  title: key,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("b-tab", { attrs: { title: "Context" } }, [
            _c("div", { staticClass: "form-group ace-container row mt-1" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("vue-ace-editor", {
                    attrs: {
                      config: _vm.aceConfig,
                      content: _vm.contextContent,
                      height: "100%",
                      position: "absolute",
                      width: "97%",
                    },
                    on: {
                      "change-content": function ($event) {
                        return _vm.onAceValueChanged($event)
                      },
                      input: function ($event) {
                        return _vm.onAceValueChanged($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("b-tab", { attrs: { title: "Widgets Settings" } }, [
            _c(
              "div",
              { staticClass: "ace-container" },
              [
                _c("schema-form", {
                  attrs: {
                    schema: _vm.widgetsConfigSchema,
                    form: _vm.widgetsConfigForm,
                    value: _vm.widgetsConfigModel,
                  },
                  on: { validationResult: _vm.onValidationSchemaConfigResult },
                }),
              ],
              1
            ),
          ]),
          _c("b-tab", { attrs: { title: "Theme" } }, [
            _c("div", { staticClass: "form-group ace-container row mt-1" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("vue-df-dashboard-theme", {
                    on: { input: _vm.onDashboardThemeModelChange },
                    model: {
                      value: _vm.dashboardThemeModel,
                      callback: function ($$v) {
                        _vm.dashboardThemeModel = $$v
                      },
                      expression: "dashboardThemeModel",
                    },
                  }),
                  _c("vue-ace-editor", {
                    attrs: {
                      config: _vm.aceCssConfig,
                      content: _vm.cssContent,
                      height: "100%",
                      position: "absolute",
                      width: "97%",
                    },
                    on: {
                      "change-content": function ($event) {
                        return _vm.onCssAceValueChanged($event)
                      },
                      input: function ($event) {
                        return _vm.onCssAceValueChanged($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }