import VueDfIntegrationDataSource from './components/VueDfIntegrationDataSource'
import VueDfIntegrationWidget from './components/VueDfIntegrationWidget/'
import VueDfIntegrationDataWidget from './components/VueDfIntegrationDataWidget'


const vueDfIntegrationApp = {
  install(Vue, options) {

    if (!Vue.__vueDfIntegrationRegistered) {
      Vue.use(VueDfIntegrationDataSource)
      Vue.use(VueDfIntegrationWidget)
      Vue.use(VueDfIntegrationDataWidget)
      Vue.__vueDfIntegrationRegistered = true;
    }

  }
};

export default vueDfIntegrationApp;
