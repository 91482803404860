<template>
  <div class="integration-widget-view vld-parent">
<!--    <vue-element-loading :active.sync="isLoading"
                         :can-cancel="false"
                         is-full-page="false"></vue-element-loading>-->
    <vue-df-integration-component :dashboardEditMode="dashboardEditMode"
                                  :data-model="uiData || undefined" :id="id"
                                  :is-maximized="isMaximized"
                                  :maximizedArea="maximizedArea"
                                  :model="model"
                                  :ui-definition="uiConfig || undefined"
                                  ref="integrationComponent"
                                  v-if="!isLoading"
    />
  </div>
</template>

<script>
  import widgetComponentViewMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentViewMixins'

  export default {
    name: "VueDfIntegrationWidgetView",
    mixins: [widgetComponentViewMixins],
    data() {
      return {
        data: {},
        uiDefinition: {},
        isLoading: true
      }
    },
    computed: {
      uiConfig: function () {
        return this.uiDefinition;
      },
      uiData: function () {
        return this.data;
      }
    },
    created() {
      const that = this;
      this.loadResult();
    },
    watch:{
      isLoading:{
        handler(newVal){
          this.$emit('loading', newVal);
        },
        immediate: true
      },
      dataModel: {
        handler(newVal, oldVal){
         // console.log('NewWal integration', newVal, oldVal, this.model.config.data);
          if (newVal && this.model.config.data.configuration){
            this.model.config.data.configuration = Object.assign(this.model.config.data.configuration, newVal);
            //console.log('new config', this.model.config.data.configuration);
            const isValid = this.checkValidation(this.model.config.data.configuration);
            if (isValid) {
              this.isLoading = true;
              this.loadResult();
            }else{
              this.isLoading = false;
            }
          }
        },
        deep: true
      }
    },
    methods: {
      checkValidation: function(item){
        if (this.model.config.validation && this.model.config.validation.length>0){
          for(let i=0;i<this.model.config.validation.length;i++) {
          //  console.log('Validating', this.model.config.validation[i], item);
            if (this.model.config.validation[i].expression) {
              const boolVal = this.$jsulator.evaluate(this.model.config.validation[i].expression, item);
              if (!boolVal){
                return false;
              }
            }
          }
        }
        return true;
      },
      loadResult: function () {
        const that = this;
        const isValid = this.checkValidation(this.model.config.data.configuration);
        if (isValid) {
        //  console.log('Valid - loading result');
          this.loadUIDefinition(function (uiDefinition) {
            that.loadData(function (data) {
          //    console.log('Loaded data', uiDefinition.result, data);
              if (uiDefinition && uiDefinition.result) {
                that.$set(that, 'uiDefinition', uiDefinition.result);
              }
              if (data) {
                that.$set(that, 'data', data);
              }
              that.isLoading = false;
            });
          });
        }else{
          that.isLoading = false;
        }
      },
      loadUIDefinition: function (next) {
        const that = this;
        const urlToLoad = this.$appConfig.appConfig.apiBase + '/v1/integrationdashboard/view/config';
        this.$http.post(urlToLoad, this.model.config.data)
          .then(function (result) {
            const uiDefinition = result.data;
            if (next) {
              next(uiDefinition);
            }
          }).catch(function (err) {
          console.error(err);
          that.isLoading = false;
        });
      },
      loadData: function (next) {
        const that = this;
        const urlToLoad = this.$appConfig.appConfig.apiBase + '/v1/integrationdashboard/view';
        this.$http.post(urlToLoad, this.model.config.data)
          .then(function (result) {
            const res = result.data;
            if (next) {
              next(res);
            }
          }).catch(function (err) {
          that.isLoading = false;
          console.error(err);
        });
      }
    }
  }
</script>

<style scoped>
  .integration-widget-view {
    height: 100%;
  }
</style>
