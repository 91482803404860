var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("schema-form", {
        attrs: { schema: _vm.resolveSchema(), form: _vm.resolveForm() },
        on: { validationResult: _vm.onValidationResult },
        model: {
          value: _vm.dataModel,
          callback: function ($$v) {
            _vm.dataModel = $$v
          },
          expression: "dataModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }