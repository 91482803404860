import Vue from 'vue'

class HttpRestApiHandler {
  constructor(options) {
    this.ref = options.ref;
    this.dataModel = options.dataModel;
  }

  async loadMetaContextObject(context) {
    try {
      const user = await this.ref.$auth.getUser('etl');
      context.user = user;
      context.userToken = this.ref.$auth.getToken();

    } catch (e) {
      // console.error('No auth user', {message: e});
    }
  }

  async executeService(def, eventContext) {
    //console.log('Executing api request', def, eventContext);
    if (def) {
      const that = this;
      if (def.url && def.httpMethod) {

        if (eventContext)
          eventContext.definition = def;

        const httpObject = {};
        let url = that.ref.resolveContextObject(def.url, eventContext, 'string');
        if (!url) {
          url = def.url;
        }
        if (httpObject.query) {
          const resolvedQuery = that.ref.resolveContextObject(httpObject.query, eventContext, 'string');
          if (resolvedQuery) {
            url = url + '?' + resolvedQuery;
          }
        }

        httpObject.url = url;

        if (def.headers) {
          const resolvedContextHeaders = that.ref.resolveContextObject(def.headers, eventContext, 'string');
          if (resolvedContextHeaders) {
            const preparedHeaders = that._prepareHttpHeaders(resolvedContextHeaders);
            if (preparedHeaders) {
              httpObject.headers = preparedHeaders;
            }
          }
        }

        httpObject.method = def.httpMethod.toLowerCase();
        const isDownload = def.download;

        switch (def.httpMethod) {
          case 'GET':
            if (isDownload)
              httpObject.responseType = 'blob'; // important
            break;
          case 'POST':
            if (def.body) {
              let contextBody = that.ref.resolveContextObject(def.body, eventContext);
              if (def.contentType && def.contentType === 'formData') {
                const formData = new FormData();
                for (const prop in contextBody) {
                  formData.append(prop, contextBody[prop]);
                }
                contextBody = formData;
                httpObject.headers['Content-Type'] = 'multipart/form-data';
              }
              httpObject.data = contextBody;

            }
            break;
          case 'PUT':
            if (def.body) {
              const contextBody = that.ref.resolveContextObject(def.body, eventContext);
              httpObject.data = contextBody;
            }
            break;
          case 'DELETE':
            break;
        }

        Vue.axios(httpObject)
          .then(
            function (response) {

             /* if (isDownload) {
                let blob = new Blob([response.data], {}),  url = window.URL.createObjectURL(blob);
                window.open(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions

              }*/

              if (isDownload){

                //console.log('isDownload', isDownload, response);

                /*const FILE = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));

                const docUrl = document.createElement('x');
                docUrl.href = FILE;
                docUrl.setAttribute('download', 'file.pdf');
                document.body.appendChild(docUrl);
                docUrl.click();*/
                let blob = new Blob([response.data], { type: response.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.headers['file-name'] || 'file';
                link.click()
              }

              if (def.responseEvents) {
                that._handleHttpResponse(response, def, eventContext)
              }
            },
            function (error) {
              if (def.responseEvents) {
                that._handleHttpResponse(error, def, eventContext);
              }
            })
          .catch(function (error) {
            if (def.responseEvents) {
              that._handleHttpResponse(error, def, eventContext);
            }
            console.error(error);
          })

      }
    }
  }

  _prepareHttpHeaders(headers) {
    if (headers) {
      const preparedHeaders = {};
      if (headers.indexOf(';') > -1) {
        const splitBySemi = headers.split(';');
        if (splitBySemi) {
          splitBySemi.forEach(function (item) {
            const splitByEqual = item.split('=');
            preparedHeaders[splitByEqual[0].trim()] = splitByEqual[1].trim();
          });
        }
      } else {
        const splitByEqual = headers.split('=');
        preparedHeaders[splitByEqual[0].trim()] = splitByEqual[1].trim();
      }
      return preparedHeaders;
    }
  }

  async _handleHttpResponse(response, def, eventContext) {
    const events = def.responseEvents.events;
    // console.log('Executing http response', def, eventContext);
    if (events) {
      const that = this;
      // console.log('HTTP Response: ' + JSON.stringify(response));
      events.forEach(function (eventObj) {
        if (eventObj && eventObj.eventType) {
          if (response && response.status) {
            if (response.status >= 200 && response.status < 300) {
              if (eventObj.eventType === 'OK') {
                if (eventObj && eventObj.eventName) {
                  const event = {
                    eventName: eventObj.eventName,
                    context: that.ref.resolveContextObject(eventObj.contextObject, {
                      data: that.ref.resolveDataFromObject(response),
                      definition: eventObj
                    })
                  }
                  that.ref.publishEvent(event.eventName, event);
                }
              }
            } else if (response.status >= 401 && response.status < 408) {
              if (eventObj.eventType === 'SECURITY_ISSUE') {
                if (eventObj && eventObj.eventName) {
                  const event = {
                    eventName: eventObj.eventName,
                    context: that.ref.resolveContextObject(eventObj.contextObject, {
                      data: that.ref.resolveDataFromObject(response),
                      definition: eventObj
                    })
                  }
                  that.ref.publishEvent(event.eventName, event);
                }
              }
            } else {
              if (eventObj.eventType === 'ERROR') {
                if (eventObj && eventObj.eventName) {
                  const event = {
                    eventName: eventObj.eventName,
                    context: that.ref.resolveContextObject(eventObj.contextObject, {
                      data: that.ref.resolveDataFromObject(response),
                      definition: eventObj
                    })
                  }
                  that.ref.publishEvent(event.eventName, event);
                }
              }
            }
          } else {
            console.error('Error in HTTP request', response, def);
            if (eventObj.eventType === 'ERROR') {
              if (eventObj && eventObj.eventName) {
                const event = {
                  eventName: eventObj.eventName,
                  context: that.ref.resolveContextObject(eventObj.contextObject, {
                    data: that.ref.resolveDataFromObject(response),
                    definition: eventObj
                  })
                }
                that.ref.publishEvent(event.eventName, event);
              }
            }
          }
        } else {
          console.error('There is no defined response events for the request', response, def);
        }
      });
    }
  }
}

export default HttpRestApiHandler;
