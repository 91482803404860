<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" @validationResult="onValidationResult"
               v-model="model.config.data"></schema-form>
</template>

<script>
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
  import commonEditMixin from '../commonEditMixin'
  import jsnotevil from "jsnotevil";

  export default {
    name: "VueDfIntegrationDataSourceEdit",
    mixins: [widgetComponentEditMixins, commonEditMixin],
    data() {
      return {
        schemaForm: {
          schema: {},
          form: []
        },
        schemaFormCustom: {
          schemaProperties: {
            dataSources:{
              type: 'array',
              items:{
                type: 'object',
                properties: {
                  name:{
                    type: 'string',
                    title: 'Data Source Name',
                    description:'Enter data source name used to reference withing widgets'
                  },
                  queries: {
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        name: {
                          type: 'string',
                          title: 'Name',
                          description: 'Define name to reference this series name'
                        },
                        series:{
                          type: 'array',
                          items:{
                            type: 'object',
                            properties:{
                              queryType: {
                                type: 'string',
                                title: 'Query Type',
                                description: 'Select query type'
                              },
                              query: {
                                type: 'string',
                                title: 'Query Source',
                                description: 'Select predefined query to load prepared data'
                              },
                              operation:{
                                type: 'string',
                                title: 'Operation',
                                description: 'Select mathematical operation to combine next value source',
                                enum: ['+', '-', '/', '*', '%']
                              }
                            }
                          }
                        }
                      }
                    },
                    title: 'Queries',
                    description: 'Select list of queries to include into datasource'
                  }
                }
              }
            },
            filter:{
              type: 'object',
              properties:{
                schemaType: {
                  type: 'string',
                  title: 'Schema Type',
                  description: 'Select schema type to define visible interface',
                  enum: ['Simple', 'Advanced'],
                  default: 'Simple'
                },
                schemaForm: {
                  type: 'object',
                  title: 'Form Editor',
                  description: 'Use drag and drop features to build the form',
                  default: {
                    schema: {},
                    form: []
                  },
                  properties: {}
                }
              }
            }
          },
          formItem: {
            title: 'Data Source Editor',
            items: [
              {
                type: 'section',
                sectionType: 'fieldset',
                title: 'Configuration',
                items: [
                  {
                    type: 'tabs',
                    tabs: [
                      {
                        title: 'Data Sources',
                        items: [
                          {
                            key: 'dataSources',
                            title: "{{ value.name || 'Tab '+$index }}",
                            type: 'tabarray',
                            items:[
                              {
                                key: 'dataSources[].name'
                              },
                              {
                                type: 'section',
                                sectionType: 'fieldset',
                                title: 'Metrics',
                                items:[
                                  {
                                    key: 'dataSources[].queries',
                                    type: 'tabarray',
                                    title: "{{ value.name || 'Tab '+$index }}",
                                    items:[
                                      {
                                        key: 'dataSources[].queries[].name'
                                      },
                                      {
                                        type: 'section',
                                        sectionType: 'fieldset',
                                        title: 'Data mashup',
                                        items: [
                                          {
                                            key: 'dataSources[].queries[].series',
                                            type: 'tabarray',
                                            title: "{{ (value.queryType + (value.operation||'')) || 'Tab '+$index }}",
                                            items: [
                                              {
                                                key: 'dataSources[].queries[].series[].queryType',
                                                type: 'select',
                                                leafImage: "sourceTypeImage",
                                                branchImage: "typeCategoryImage",
                                                valueKey: 'sourceType',
                                                labelKey: 'sourceType',
                                                groupBy: "typeCategory",
                                                options: {
                                                  callback: 'loadSources',
                                                  resultExpression: '$result$'
                                                },
                                                onChange: {
                                                  evaluate: [
                                                    'delete model.dataSources[form.key[1]].queries[form.key[3]].series[form.key[5]].query'
                                                  ],
                                                  publish: [
                                                    {name: 'reload', targetKey: 'dataSources["form.key[1]"].queries["form.key[3]"].series["form.key[5]"].query'}
                                                  ],
                                                  remove: [
                                                    'dataSources["form.key[1]"].queries["form.key[3]"].series["form.key[5]"].query'
                                                  ]
                                                }
                                              },
                                              {
                                                key: 'dataSources[].queries[].series[].query',
                                                type: 'select',
                                                condition: 'model.dataSources[form.key[1]].queries[form.key[3]].series[form.key[5]].queryType',
                                                valueKey: '_id',
                                                labelKey: 'name',
                                                groupBy: 'category',
                                                options: {
                                                  callbackFunction: 'loadQueriesByQueryType',
                                                  resultExpression: '$result$'
                                                }
                                              },
                                              {
                                                key: 'dataSources[].queries[].series[].operation',
                                                type:'radiosinline',
                                                fieldHtmlClass: 'mr-1 align-middle',
                                                labelClass:'font-weight-bold align-middle',

                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]

                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        title: 'UI Filters',
                        items: [
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'User Interface',
                            items: [
                              {
                                key: 'filter.schemaType',
                                type: 'radiosinline'
                              },
                              {
                                key: 'filter.schemaForm',
                                condition: 'model.filter.schemaType==="Advanced"',
                                type: 'schemaFormEditor',
                                enableConfig: true,
                                enableElements: true,
                                editorClass: 'col',
                                elementClass: 'col-2',
                                configClass: 'col-3'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      }
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    methods: {
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.schema.properties = this.schemaFormCustom.schemaProperties;
        schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);
        this.schemaForm = schemaFormSourceDestination;

      },
      onValidationResult: function (value) {
      },
      loadQueriesByQueryType: function (context) {
        console.log('loadQueriesByQueryType, context', context);
        const val = jsnotevil.safeEval('model.dataSources[form.key[1]].queries[form.key[3]].series[form.key[5]].queryType', {
          model: context.fullModel,
          form: context.fieldForm
        });
        console.log('context', context, val);
        if (val) {
          context.rest.get(context._meta.appConfig.apiBase + '/v1/integrationdashboard/queries/source/' + val)
            .then(function (response) {
              if (response && response.data && response.data.result) {
                const result = response.data.result;
                context.fn(result);
              }
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>
