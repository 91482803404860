<template>
  <div>
    <metric-dimension-builder></metric-dimension-builder>
  </div>
</template>

<script>

import MetricDimensionBuilder from "../components/MetricDimensionComponents/MetricDimensionBuilder";
export default {
  name: "MetricDimensionBuilderView",
  components:{MetricDimensionBuilder}
}
</script>

<style scoped>

</style>
