var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-form-builder-inner" },
    [
      _vm.fieldForm.schemaFieldKey
        ? _c(_vm.resolveFieldComponent(_vm.fieldForm), {
            tag: "component",
            class: [_vm.fieldForm.htmlClass],
            attrs: {
              "component-data": { field: _vm.fieldForm },
              "field-form": _vm.fieldForm,
              "field-mapper": _vm.fieldMapper,
              "field-schema": _vm.fieldForm,
              "full-model": _vm.fullModel,
              "parent-form": _vm.parentForm,
              "schema-form-service": _vm.schemaFormService,
              "is-droppable": _vm.isDroppable,
              "parent-index": _vm.parentIndex,
              value: _vm.resolveValue(_vm.value, _vm.fieldForm),
            },
            on: {
              fullModelChanged: _vm.onFullModelChanged,
              input: function ($event) {
                return _vm.onInput($event, _vm.fieldForm)
              },
              internalSchemaChanged: _vm.onInternalChildSchemaChanged,
            },
          })
        : _vm._e(),
      !_vm.fieldForm.schemaFieldKey
        ? _c(_vm.resolveFieldComponent(_vm.fieldForm), {
            tag: "component",
            class: [_vm.fieldForm.htmlClass],
            attrs: {
              "component-data": { field: _vm.fieldForm },
              "field-form": _vm.fieldForm,
              "field-mapper": _vm.fieldMapper,
              "field-schema": _vm.fieldForm,
              "full-model": _vm.fullModel,
              "is-droppable": _vm.isDroppable,
              "parent-index": _vm.parentIndex,
              "parent-form": _vm.parentForm,
              "schema-form-service": _vm.schemaFormService,
              value: _vm.resolveValue(_vm.value, _vm.fieldForm),
            },
            on: {
              fullModelChanged: _vm.onFullModelChanged,
              input: function ($event) {
                return _vm.onKeylessInput($event, _vm.fieldForm)
              },
              internalSchemaChanged: _vm.onInternalChildSchemaChanged,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }