// https://pipeline.mediumra.re/nav-side-kanban-board.html?note-name=fsdf&note-description=gs#
// https://sortablejs.github.io/Vue.Draggable/#/two-lists
import VueDfKanbanEdit from "./VueDfKanbanEdit";
import VueDfKanbanView from "./VueDfKanbanView";

let widget = {
  type: 'VueDfKanban',
  title: 'Kanban',
  description: 'Manage Kanban dashboard widget',
  category: 'Data',
  editComponent: 'VueDfKanbanEdit',
  viewComponent: 'VueDfKanbanView',
  dashboards:[],
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfKanbanView.name, VueDfKanbanView)
    Vue.component(VueDfKanbanEdit.name, VueDfKanbanEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
