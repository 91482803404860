var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integration-container" },
    [
      _c(_vm.componentType, {
        tag: "component",
        attrs: {
          dashboardEditMode: _vm.dashboardEditMode,
          "data-model": _vm.dataModel,
          id: _vm.id,
          maximizedArea: _vm.maximizedArea,
          "is-maximized": _vm.isMaximized,
          model: _vm.model,
          "ui-definition": _vm.uiDefinition,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }