<template>
  <button v-if="!fieldForm.hidden"  class="btn" :style="fieldForm.style"
          :class="[fieldForm.btnStyle || 'btn-default']"
          type="reset">
    <span v-if="fieldForm.icon" :class="fieldForm.icon"></span>{{fieldForm.title}}
  </button>
</template>

<script>
  export default {
    name: "SfResetButton",
    props:['fieldForm'],
    methods:{
      onReset(e){
        console.log('Reset clicked',e, this);
        for (const p in this.fullModel){
          delete this.fullModel[p];
        }
      }
    }
  }
</script>

<style scoped>

</style>
