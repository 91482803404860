<template>
  <div>
    <bpmn-editor properties-panel="properties-panel-parent"></bpmn-editor>
  </div>
</template>

<script>
import BpmnEditor from "vue-diagram/src/bpmn/BpmnEditor";
export default {
  name: "BpmnEditorView",
  components:{BpmnEditor}
}
</script>

<style scoped>

</style>
