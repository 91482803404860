var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "schema-show-builder-component",
      class: _vm.isDroppable ? "droppable-container" : "",
      attrs: {
        disabled: !_vm.isDroppable,
        group: { name: "components", pull: true, put: true },
        handle: ".handle",
        list: _vm.clonedDroppedComponents,
        move: _vm.onMove,
        tag: "div",
      },
      on: { add: _vm.onAdd, change: _vm.onChange, end: _vm.onEnd },
    },
    _vm._l(_vm.asyncComputedSchemaForm, function (field, index) {
      return _c(
        "div",
        {
          key: _vm.resolveStringifiedKey(field, index),
          ref: _vm.resolveStringifiedKey(field),
          refInFor: true,
          staticClass: "schema-builder-component",
          style: _vm.resolveVisible(field),
        },
        [
          _vm.isDroppable
            ? _c("sf-component-toolbar", {
                attrs: {
                  "cloned-dropped-components": _vm.clonedDroppedComponents,
                  field: field,
                  guid: field.guid,
                  "is-droppable": _vm.isDroppable,
                  "selected-item": _vm.selectedItem,
                },
                on: {
                  isminimized: function ($event) {
                    return _vm.onIsMinimized(field, $event)
                  },
                  schemaChanged: _vm.onToolbarSchemaChanged,
                },
              })
            : _vm._e(),
          _c("builder-inner-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.itemState.minimizedItems[field.guid],
                expression: "!(itemState.minimizedItems[field.guid])",
              },
            ],
            key: _vm.resolveStringifiedKey(field, index),
            attrs: {
              "component-data": { field: field },
              "field-form": field,
              "field-mapper": _vm.fieldMapper,
              "field-schema": field,
              "full-model": _vm.fullModel,
              "is-droppable": _vm.isDroppable,
              "parent-form": _vm.resolveParent(field),
              "parent-index": _vm.parentIndex,
              "schema-form-service": _vm.schemaFormService,
              value: _vm.resolveValue(_vm.value, field),
            },
            on: {
              fullModelChanged: _vm.onFullModelChanged,
              input: function ($event) {
                return _vm.onInput($event, field)
              },
              internalSchemaChanged: _vm.onInternalChildSchemaChanged,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }