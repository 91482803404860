import VueDfApp from './components/VueDfApp'
import VueDfDashboard from './components/VueDfDashboard'
import VueDfWidgetGenerator from './components/VueDfWidgetGenerator'
import VueDfKanban from './components/VueDfKanban'
//import VueDfLuckySheet from './components/VueDfLuckySheet'
import VueDfLeaflet from './components/VueDfLeaflet'

const vueDashboardFrameworkPluginApp = {
  install(Vue, options) {

    if (!Vue.__vueDfAppRegistered) {
      Vue.use(VueDfApp)
      Vue.use(VueDfDashboard)
      Vue.use(VueDfWidgetGenerator)
      Vue.use(VueDfKanban)
      Vue.use(VueDfLeaflet)
     // Vue.use(VueDfLuckySheet)
      Vue.__vueDfAppRegistered = true;
    }

  }
};

export default vueDashboardFrameworkPluginApp;
