<script src="components/UIComponents/SidebarPlugin/index.js"></script>
<template>
  <schema-form :form="resolvedForm" :schema="resolvedSchema" v-model="model.config.data"></schema-form>
</template>

<script>
  import widgetComponentEditMixins from 'vue-dashboard-framework/src/components/vuedf/mixins/widgetComponentEditMixins'
  import widgetMixin from 'vue-dashboard-framework/src/components/vuedf/widgetMixin'
  import routeMixin from './components/routeMixin'

  export default {
    name: "VueDfAppEdit",
    props: ['crudServiceName'],
    mixins: [widgetComponentEditMixins, widgetMixin, routeMixin],
    data() {
      return {
        vueDfAppServiceName: 'appHttpService',
        schemaFormCustom: {
          schemaProperties: {
            applicationName: {
              type: 'string',
              title: 'Application Name',
              description: 'Enter name of the Application'
            },
            existingApplication: {
              type: 'string',
              title: 'Existing Applications',
              description: 'Load from existing applications'
            },
            htmlClass: {
              type: 'string',
              title: 'Main App Class',
              description: 'Define main app CSS class'
            },
            style: {
              type: 'string',
              title: 'Main App Style',
              description: 'Define main app CSS style'
            },
            css: {
              type: 'string',
              title: 'CSS',
              description: 'Enter CSS for the Application'
            },
            applicationContext: {
              type: 'string',
              title: 'Context',
              description: 'Define application wide context that can be used in expression when building URLs or other variables'
            },
            layoutSettings: {
              type: 'object',
              properties: {
                layoutType: {
                  type: 'string',
                  title: 'Layout Type',
                  description: 'Choose layout type. NavBar can be on top of sidebar or can be right of the sidebar'
                },
                htmlClass: {
                  type: 'string',
                  title: 'Class',
                  description: 'Define CSS class'
                },
                style: {
                  type: 'string',
                  title: 'Style',
                  description: 'Define CSS style'
                },
                backgroundColor: {
                  type: 'string',
                  title: 'Background Color',
                  description: 'Enter color by name of by hex number'
                },
                excludeSidebar: {
                  type: 'boolean',
                  title: 'Exclude Sidebar',
                  description: 'Should we exclude sidebar?'
                },
                excludeNavBar: {
                  type: 'boolean',
                  title: 'Exclude NavBar',
                  description: 'Should we exclude NavBar?'
                },
                excludeFooter: {
                  type: 'boolean',
                  title: 'Exclude Footer',
                  description: 'Should we exclude Footer?'
                },
                sidebarSettings: {
                  type: 'object',
                  properties: {
                    title: {
                      type: 'string',
                      title: 'Title',
                      description: 'Define title on sidebar. If not provided we\'ll use Application Name'
                    },
                    logo:{
                      type: 'string',
                      title: 'Logo url',
                      description: 'Enter Logo url'
                    },
                    htmlClass: {
                      type: 'string',
                      title: 'Class',
                      description: 'Define CSS class'
                    },
                    style: {
                      type: 'string',
                      title: 'Style',
                      description: 'Define CSS style'
                    },
                    backgroundColor: {
                      type: 'string',
                      title: 'Background Color',
                      description: 'Define background color - name or hex style'
                    },
                    activeColor: {
                      type: 'string',
                      title: 'Active Color',
                      description: 'Define color of the active item'
                    },
                    sidebarWidth: {
                      type: 'string',
                      title: 'Sidebar width',
                      description: 'Define sidebar width in percentage or strict style in px;'
                    },
                    autoClose: {
                      type: 'boolean',
                      title: 'Auto Close',
                      description: 'Should sidebar auto-close?'
                    },
                    items: {
                      type: 'array',
                      title: 'Items',
                      description: 'Define Sidebar items',
                      items: {
                        type: 'object',
                        properties: {
                          itemType: {
                            type: 'string',
                            title: 'Item Type',
                            description: 'Define type of the Item',
                            enum: ['LINK', 'DROP_DOWN', 'FORM'],
                            default: 'LINK'
                          },
                          itemClass: {
                            type: 'string',
                            title: 'Item Class',
                            description: 'Define CSS class for the Item'
                          },
                          itemStyle: {
                            type: 'string',
                            title: 'Item Style',
                            description: 'Define CSS style for the item'
                          },
                          disableEdit: {
                            type: 'boolean',
                            title: 'Disable Edit',
                            default: true,
                            description: 'Disable editing of dashboard UI components'
                          },
                          disableAdd: {
                            type: 'boolean',
                            title: 'Disable Add',
                            default: true,
                            description: 'Disable adding dashboard UI components and new dashboards'
                          },
                          itemDropDownSettings: {
                            type: 'object',
                            properties: {
                              items: {
                                type: 'array',
                                items: {
                                  type: 'object',
                                  properties: {
                                    itemType: {
                                      type: 'string',
                                      title: 'Item Type',
                                      description: 'Define type of the Item',
                                      enum: ['LINK'],
                                      default: 'LINK'
                                    },
                                    itemClass: {
                                      type: 'string',
                                      title: 'Item Class',
                                      description: 'Define CSS class for the Item'
                                    },
                                    itemStyle: {
                                      type: 'string',
                                      title: 'Item Style',
                                      description: 'Define CSS style for the item'
                                    },
                                    disableEdit: {
                                      type: 'boolean',
                                      title: 'Disable Edit',
                                      default: true,
                                      description: 'Disable editing of dashboard UI components'
                                    },
                                    disableAdd: {
                                      type: 'boolean',
                                      title: 'Disable Add',
                                      default: true,
                                      description: 'Disable adding dashboard UI components and new dashboards'
                                    },
                                    name: {
                                      type: 'string',
                                      title: 'Name',
                                      description: 'Unique name of the item'
                                    },
                                    title: {
                                      type: 'string',
                                      title: 'Title',
                                      description: 'Title of the link'
                                    },
                                    icon: {
                                      type: 'string',
                                      title: 'Icon',
                                      description: 'Define the Icon for the item'
                                    },
                                    titleClass: {
                                      type: 'string',
                                      title: 'Title Class',
                                      description: 'Define CSS class for the title'
                                    },
                                    titleStyle: {
                                      type: 'string',
                                      title: 'Title Style',
                                      description: 'Define CSS style for the title'
                                    },
                                    link: {
                                      type: 'string',
                                      title: 'Link',
                                      description: 'Define link to open route'
                                    },
                                    linkTarget: {
                                      type: 'string',
                                      title: 'Link Target',
                                      description: 'Define link target to open the new window or open in the current',
                                      enum: ['CURRENT', 'NEW_WINDOW'],
                                      default: 'CURRENT'
                                    },
                                    linkClass: {
                                      type: 'string',
                                      title: 'Link Class',
                                      description: 'Define CSS class for the link'
                                    },
                                    linkStyle: {
                                      type: 'string',
                                      title: 'Link Style',
                                      description: 'Define CSS style for the link'
                                    },
                                    itemTemplate: {
                                      type: 'string',
                                      title: 'Item Template',
                                      description: 'Define Item template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    },
                                    titleTemplate: {
                                      type: 'string',
                                      title: 'Title Template',
                                      description: 'Define Title template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    },
                                    linkTemplate: {
                                      type: 'string',
                                      title: 'Link Template',
                                      description: 'Define Link template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    }
                                  }
                                }
                              }
                            }
                          },
                          itemFormSettings: {
                            type: 'object',
                            properties: {
                              schemaForm: {
                                type: 'object',
                                title: 'Form Editor',
                                description: 'Use drag and drop features to build the form',
                                default: {
                                  schema: {},
                                  form: []
                                },
                                properties: {}
                              }
                            }
                          },
                          title: {
                            type: 'string',
                            title: 'Title',
                            description: 'Title of the link'
                          },
                          name: {
                            type: 'string',
                            title: 'Name',
                            description: 'Unique name of the item'
                          },
                          icon: {
                            type: 'string',
                            title: 'Icon',
                            description: 'Define the Icon for the item'
                          },
                          titleClass: {
                            type: 'string',
                            title: 'Title Class',
                            description: 'Define CSS class for the title'
                          },
                          titleStyle: {
                            type: 'string',
                            title: 'Title Style',
                            description: 'Define CSS style for the title'
                          },
                          link: {
                            type: 'string',
                            title: 'Link',
                            description: 'Define link to open route'
                          },
                          linkTarget: {
                            type: 'string',
                            title: 'Link Target',
                            description: 'Define link target to open the new window or open in the current',
                            enum: ['CURRENT', 'NEW_WINDOW'],
                            default: 'CURRENT'
                          },
                          linkClass: {
                            type: 'string',
                            title: 'Link Class',
                            description: 'Define CSS class for the link'
                          },
                          linkStyle: {
                            type: 'string',
                            title: 'Link Style',
                            description: 'Define CSS style for the link'
                          },
                          itemTemplate: {
                            type: 'string',
                            title: 'Item Template',
                            description: 'Define Item template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          },
                          titleTemplate: {
                            type: 'string',
                            title: 'Title Template',
                            description: 'Define Title template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          },
                          linkTemplate: {
                            type: 'string',
                            title: 'Link Template',
                            description: 'Define Link template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          }

                        }
                      }
                    }
                  }
                },
                navBarSettings: {
                  type: 'object',
                  properties: {
                    title: {
                      type: 'string',
                      title: 'Title',
                      description: 'Define title of the NavBar'
                    },
                    htmlClass: {
                      type: 'string',
                      title: 'Class',
                      description: 'Define CSS class'
                    },
                    style: {
                      type: 'string',
                      title: 'Style',
                      description: 'Define CSS style'
                    },
                    backgroundColor: {
                      type: 'string',
                      title: 'Background Color'
                    },
                    items: {
                      type: 'array',
                      title: 'Items',
                      description: 'Define NavBar items',
                      items: {
                        type: 'object',
                        properties: {
                          itemType: {
                            type: 'string',
                            title: 'Item Type',
                            description: 'Define type of the Item',
                            enum: ['LINK', 'DROP_DOWN', 'FORM'],
                            default: 'LINK'
                          },
                          itemClass: {
                            type: 'string',
                            title: 'Item Class',
                            description: 'Define CSS class for the Item'
                          },
                          itemStyle: {
                            type: 'string',
                            title: 'Item Style',
                            description: 'Define CSS style for the item'
                          },
                          icon: {
                            type: 'string',
                            title: 'Icon',
                            description: 'Define the Icon for the item'
                          },
                          disableEdit: {
                            type: 'boolean',
                            title: 'Disable Edit',
                            default: true,
                            description: 'Disable editing of dashboard UI components'
                          },
                          disableAdd: {
                            type: 'boolean',
                            title: 'Disable Add',
                            default: true,
                            description: 'Disable adding dashboard UI components and new dashboards'
                          },
                          itemDropDownSettings: {
                            type: 'object',
                            properties: {
                              items: {
                                type: 'array',
                                items: {
                                  type: 'object',
                                  properties: {
                                    itemType: {
                                      type: 'string',
                                      title: 'Item Type',
                                      description: 'Define type of the Item',
                                      enum: ['LINK'],
                                      default: 'LINK'
                                    },
                                    itemClass: {
                                      type: 'string',
                                      title: 'Item Class',
                                      description: 'Define CSS class for the Item'
                                    },
                                    itemStyle: {
                                      type: 'string',
                                      title: 'Item Style',
                                      description: 'Define CSS style for the item'
                                    },
                                    disableEdit: {
                                      type: 'boolean',
                                      title: 'Disable Edit',
                                      default: true,
                                      description: 'Disable editing of dashboard UI components'
                                    },
                                    disableAdd: {
                                      type: 'boolean',
                                      title: 'Disable Add',
                                      default: true,
                                      description: 'Disable adding dashboard UI components and new dashboards'
                                    },
                                    dashboard: {
                                      type: 'object',
                                      properties: {
                                        dashboardName: {
                                          type: 'string'
                                        },
                                        dashboardType: {
                                          type: 'string'
                                        }
                                      }
                                    },
                                    name: {
                                      type: 'string',
                                      title: 'Name',
                                      description: 'Unique name of the item'
                                    },
                                    title: {
                                      type: 'string',
                                      title: 'Title',
                                      description: 'Title of the link'
                                    },
                                    icon: {
                                      type: 'string',
                                      title: 'Icon',
                                      description: 'Define the Icon for the item'
                                    },
                                    titleClass: {
                                      type: 'string',
                                      title: 'Title Class',
                                      description: 'Define CSS class for the title'
                                    },
                                    titleStyle: {
                                      type: 'string',
                                      title: 'Title Style',
                                      description: 'Define CSS style for the title'
                                    },
                                    link: {
                                      type: 'string',
                                      title: 'Link',
                                      description: 'Define link to open route'
                                    },
                                    linkTarget: {
                                      type: 'string',
                                      title: 'Link Target',
                                      description: 'Define link target to open the new window or open in the current',
                                      enum: ['CURRENT', 'NEW_WINDOW'],
                                      default: 'CURRENT'
                                    },
                                    linkClass: {
                                      type: 'string',
                                      title: 'Link Class',
                                      description: 'Define CSS class for the link'
                                    },
                                    linkStyle: {
                                      type: 'string',
                                      title: 'Link Style',
                                      description: 'Define CSS style for the link'
                                    },
                                    itemTemplate: {
                                      type: 'string',
                                      title: 'Item Template',
                                      description: 'Define Item template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    },
                                    titleTemplate: {
                                      type: 'string',
                                      title: 'Title Template',
                                      description: 'Define Title template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    },
                                    linkTemplate: {
                                      type: 'string',
                                      title: 'Link Template',
                                      description: 'Define Link template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    }
                                  }
                                }
                              }
                            }
                          },
                          itemFormSettings: {
                            type: 'object',
                            properties: {
                              schemaForm: {
                                type: 'object',
                                title: 'Form Editor',
                                description: 'Use drag and drop features to build the form',
                                default: {
                                  schema: {},
                                  form: []
                                },
                                properties: {}
                              }
                            }
                          },
                          name: {
                            type: 'string',
                            title: 'Name',
                            description: 'Unique name of the item'
                          },
                          title: {
                            type: 'string',
                            title: 'Title',
                            description: 'Title of the link'
                          },
                          dashboard: {
                            type: 'object',
                            properties: {
                              dashboardName: {
                                type: 'string'
                              },
                              dashboardType: {
                                type: 'string'
                              }
                            }
                          },
                          titleClass: {
                            type: 'string',
                            title: 'Title Class',
                            description: 'Define CSS class for the title'
                          },
                          titleStyle: {
                            type: 'string',
                            title: 'Title Style',
                            description: 'Define CSS style for the title'
                          },
                          link: {
                            type: 'string',
                            title: 'Link',
                            description: 'Define link to open route'
                          },
                          linkTarget: {
                            type: 'string',
                            title: 'Link Target',
                            description: 'Define link target to open the new window or open in the current',
                            enum: ['CURRENT', 'NEW_WINDOW'],
                            default: 'CURRENT'
                          },
                          linkClass: {
                            type: 'string',
                            title: 'Link Class',
                            description: 'Define CSS class for the link'
                          },
                          linkStyle: {
                            type: 'string',
                            title: 'Link Style',
                            description: 'Define CSS style for the link'
                          },
                          itemTemplate: {
                            type: 'string',
                            title: 'Item Template',
                            description: 'Define Item template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          },
                          titleTemplate: {
                            type: 'string',
                            title: 'Title Template',
                            description: 'Define Title template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          },
                          linkTemplate: {
                            type: 'string',
                            title: 'Link Template',
                            description: 'Define Link template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          }
                        }
                      }
                    }
                  }
                },
                footerSettings: {
                  type: 'object',
                  properties: {
                    htmlClass: {
                      type: 'string',
                      title: 'Class',
                      description: 'Define CSS class'
                    },
                    style: {
                      type: 'string',
                      title: 'MenuBar Style',
                      description: 'Define CSS style'
                    },
                    backgroundColor: {
                      type: 'string',
                      title: 'Background Color'
                    },
                    footerTemplate: {
                      type: 'string',
                      title: 'Footer Template',
                      description: 'Define Footer template'
                    }
                  }
                },
                userMenuSettings: {
                  type: 'object',
                  properties: {
                    show: {
                      type: 'boolean',
                      title: 'Show User Menu',
                      description: 'Enable showing User Menu settings',
                      default: true
                    },
                    placement: {
                      type: 'string',
                      title: 'Placement',
                      description: 'Choose where to show the User menu',
                      enum: ['NAVBAR', 'SIDEBAR'],
                      default: 'NAVBAR'
                    },
                    htmlClass: {
                      type: 'string',
                      title: 'Class',
                      description: 'Define CSS class'
                    },
                    style: {
                      type: 'string',
                      title: 'Style',
                      description: 'Define CSS style'
                    },
                    icon: {
                      type: 'string',
                      title: 'Icon',
                      description: 'Define icon name'
                    },
                    items: {
                      type: 'array',
                      title: 'Items',
                      description: 'Define Sidebar items',
                      items: {
                        type: 'object',
                        properties: {
                          itemType: {
                            type: 'string',
                            title: 'Item Type',
                            description: 'Define type of the Item',
                            enum: ['LINK', 'DROP_DOWN', 'FORM'],
                            default: 'LINK'
                          },
                          itemClass: {
                            type: 'string',
                            title: 'Item Class',
                            description: 'Define CSS class for the Item'
                          },
                          itemStyle: {
                            type: 'string',
                            title: 'Item Style',
                            description: 'Define CSS style for the item'
                          },
                          disableEdit: {
                            type: 'boolean',
                            title: 'Disable Edit',
                            default: true,
                            description: 'Disable editing of dashboard UI components'
                          },
                          disableAdd: {
                            type: 'boolean',
                            title: 'Disable Add',
                            default: true,
                            description: 'Disable adding dashboard UI components and new dashboards'
                          },
                          itemDropDownSettings: {
                            type: 'object',
                            properties: {
                              items: {
                                type: 'array',
                                items: {
                                  type: 'object',
                                  properties: {
                                    itemType: {
                                      type: 'string',
                                      title: 'Item Type',
                                      description: 'Define type of the Item',
                                      enum: ['LINK'],
                                      default: 'LINK'
                                    },
                                    itemClass: {
                                      type: 'string',
                                      title: 'Item Class',
                                      description: 'Define CSS class for the Item'
                                    },
                                    itemStyle: {
                                      type: 'string',
                                      title: 'Item Style',
                                      description: 'Define CSS style for the item'
                                    },
                                    disableEdit: {
                                      type: 'boolean',
                                      title: 'Disable Edit',
                                      default: true,
                                      description: 'Disable editing of dashboard UI components'
                                    },
                                    disableAdd: {
                                      type: 'boolean',
                                      title: 'Disable Add',
                                      default: true,
                                      description: 'Disable adding dashboard UI components and new dashboards'
                                    },
                                    name: {
                                      type: 'string',
                                      title: 'Name',
                                      description: 'Unique name of the item'
                                    },
                                    title: {
                                      type: 'string',
                                      title: 'Title',
                                      description: 'Title of the link'
                                    },
                                    icon: {
                                      type: 'string',
                                      title: 'Icon',
                                      description: 'Define the Icon for the item'
                                    },
                                    titleClass: {
                                      type: 'string',
                                      title: 'Title Class',
                                      description: 'Define CSS class for the title'
                                    },
                                    titleStyle: {
                                      type: 'string',
                                      title: 'Title Style',
                                      description: 'Define CSS style for the title'
                                    },
                                    link: {
                                      type: 'string',
                                      title: 'Link',
                                      description: 'Define link to open route'
                                    },
                                    linkTarget: {
                                      type: 'string',
                                      title: 'Link Target',
                                      description: 'Define link target to open the new window or open in the current',
                                      enum: ['CURRENT', 'NEW_WINDOW'],
                                      default: 'CURRENT'
                                    },
                                    linkClass: {
                                      type: 'string',
                                      title: 'Link Class',
                                      description: 'Define CSS class for the link'
                                    },
                                    linkStyle: {
                                      type: 'string',
                                      title: 'Link Style',
                                      description: 'Define CSS style for the link'
                                    },
                                    itemTemplate: {
                                      type: 'string',
                                      title: 'Item Template',
                                      description: 'Define Item template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    },
                                    titleTemplate: {
                                      type: 'string',
                                      title: 'Title Template',
                                      description: 'Define Title template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    },
                                    linkTemplate: {
                                      type: 'string',
                                      title: 'Link Template',
                                      description: 'Define Link template. You can use regular HTML or you can use v-bootstrap Vue components.'
                                    }
                                  }
                                }
                              }
                            }
                          },
                          itemFormSettings: {
                            type: 'object',
                            properties: {
                              schemaForm: {
                                type: 'object',
                                title: 'Form Editor',
                                description: 'Use drag and drop features to build the form',
                                default: {
                                  schema: {},
                                  form: []
                                },
                                properties: {}
                              }
                            }
                          },
                          title: {
                            type: 'string',
                            title: 'Title',
                            description: 'Title of the link'
                          },
                          name: {
                            type: 'string',
                            title: 'Name',
                            description: 'Unique name of the item'
                          },
                          icon: {
                            type: 'string',
                            title: 'Icon',
                            description: 'Define the Icon for the item'
                          },
                          titleClass: {
                            type: 'string',
                            title: 'Title Class',
                            description: 'Define CSS class for the title'
                          },
                          titleStyle: {
                            type: 'string',
                            title: 'Title Style',
                            description: 'Define CSS style for the title'
                          },
                          link: {
                            type: 'string',
                            title: 'Link',
                            description: 'Define link to open route'
                          },
                          linkTarget: {
                            type: 'string',
                            title: 'Link Target',
                            description: 'Define link target to open the new window or open in the current',
                            enum: ['CURRENT', 'NEW_WINDOW'],
                            default: 'CURRENT'
                          },
                          linkClass: {
                            type: 'string',
                            title: 'Link Class',
                            description: 'Define CSS class for the link'
                          },
                          linkStyle: {
                            type: 'string',
                            title: 'Link Style',
                            description: 'Define CSS style for the link'
                          },
                          itemTemplate: {
                            type: 'string',
                            title: 'Item Template',
                            description: 'Define Item template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          },
                          titleTemplate: {
                            type: 'string',
                            title: 'Title Template',
                            description: 'Define Title template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          },
                          linkTemplate: {
                            type: 'string',
                            title: 'Link Template',
                            description: 'Define Link template. You can use regular HTML or you can use v-bootstrap Vue components.'
                          }

                        }
                      }
                    }
                  }
                }
              }
            },
            dashboardsSettings: {
              type: 'object',
              properties: {
                allowAllWidgets: {
                  type: 'boolean',
                  default: true,
                  title: 'Allow adding All widgets?',
                  description: 'Select if all widgets allowed to be added to Dashboards'
                },
                allowedWidgets: {
                  type: 'array',
                  title: 'Allowed Widgets',
                  description: 'Select allowed widgets to be added to Dashboards based on Role permission expression',
                  items: {
                    type: 'object',
                    properties: {
                      widgetType: {
                        title: 'Widget Type',
                        description: 'Select widget type',
                        type: 'array',
                        items: {
                          type: 'string'
                        }
                      },
                      permissionExpression: {
                        title: 'Permission expression',
                        description: 'Enter Permission expression. If empty it will be allowed to all users',
                        type: 'string'
                      }
                    }
                  }
                },
                editableAllWidgets: {
                  type: 'boolean',
                  default: false,
                  title: 'Allow editing widgets?',
                  description: 'Select if all widgets allowed to be edited'
                },
                editableWidgets: {
                  type: 'array',
                  title: 'Editable Widgets',
                  description: 'Enabled widgets per User Role permissions',
                  items: {
                    type: 'object',
                    properties: {
                      widgetType: {
                        title: 'Widget Type',
                        description: 'Select widget type',
                        type: 'array',
                        items: {
                          type: 'string'
                        }
                      },
                      permissionExpression: {
                        title: 'Permission expression',
                        description: 'Enter Permission expression. If empty it will be allowed to all users',
                        type: 'string'
                      }
                    }
                  }
                }
              }
            },
            securitySettings: {
              type: 'object',
              properties: {
                routePermissions: {
                  type: 'array',
                  title: 'Permissions',
                  description: 'Define Roles and access permissions to access routes of the application. If not defined then access is granted to the Route',
                  items: {
                    type: 'object',
                    properties: {
                      routes: {
                        type: 'string',
                        title: 'Routes',
                        description: 'Select multiple Routes to define permissions'
                      },
                      rolePermissions: {
                        type: 'array',
                        title: 'Role List',
                        description: 'Roles and permissions - if empty access is permitted and defaults per Route applied',
                        items:{
                          type:'object',
                          properties:{
                            roles:{
                              type: 'string',
                              title:'Roles',
                              description: 'List of comma separated Roles',
                            },
                            enabled:{
                              type: 'boolean',
                              title: 'Enabled',
                              description: 'Select to disable/enable access and visibility of the Route. If disabled if will not be shown in any menu.',
                              default: true
                            },
                            permissions: {
                              type: 'object',
                              properties: {

                                disableEdit: {
                                  type: 'boolean',
                                  title: 'Disable Edit',
                                  default: true,
                                  description: 'Disable editing of dashboard UI components'
                                },
                                disableAdd: {
                                  type: 'boolean',
                                  title: 'Disable Add',
                                  default: true,
                                  description: 'Disable adding dashboard UI components and new dashboards'
                                },
                                disableCopyAllWidgets: {
                                  type: 'boolean',
                                  title: 'Disable Copy',
                                  default: true,
                                  description: 'Disable copy existing widgets'
                                },
                                allowAllWidgets: {
                                  type: 'boolean',
                                  default: true,
                                  title: 'Allow adding all widgets?',
                                  description: 'Select if all widgets allowed to be added to Dashboards'
                                },
                                allowedWidgets: {
                                  type: 'array',
                                  title: 'Allowed Widgets',
                                  description: 'Select allowed widgets to be added to Dashboards based on Role permission expression',
                                  items: {
                                    type: 'object',
                                    properties: {
                                      widgetType: {
                                        title: 'Widget Type',
                                        description: 'Select widget type',
                                        type: 'array',
                                        items: {
                                          type: 'string'
                                        }
                                      }
                                    }
                                  }
                                },
                                editableAllWidgets: {
                                  type: 'boolean',
                                  default: true,
                                  title: 'Allow editing all widgets?',
                                  description: 'Select if all widgets allowed to be edited'
                                },
                                editableWidgets: {
                                  type: 'array',
                                  title: 'Editable Widgets',
                                  description: 'Enabled widgets per User Role permissions',
                                  items: {
                                    type: 'object',
                                    properties: {
                                      widgetType: {
                                        title: 'Widget Type',
                                        description: 'Select widget type',
                                        type: 'array',
                                        items: {
                                          type: 'string'
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                    }
                  }
                }
              }
            }
          },
          formItem: {
            title: 'Application Definition',
            items: [
              {
                type: 'tabs',
                tabs: [

                  {
                    title: 'Common Settings',
                    items: [
                      {
                        type: 'section',
                        sectionContainerClass: "row",
                        sectionChildClass: "col",
                        items: [
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Application Settings',
                            items: [
                              {
                                key: 'existingApplication',
                                type: 'select',
                                options: {
                                  callback: this.getExistingApplications
                                },
                                onChange: {
                                  callback: this.onExistingAppChange
                                }
                              },
                              {
                                type: 'section',
                                sectionContainerClass: "row",
                                sectionChildClass: "col",
                                items: [
                                  {
                                    type: 'section',
                                    sectionContainerClass: "row",
                                    items: [
                                      {
                                        key: 'applicationName',
                                        childClass: 'col-10'
                                      },
                                      {
                                        type: 'button',
                                        title: 'Save',
                                        childClass: 'col-2',
                                        childStyle: 'margin:auto',
                                        onClick: {
                                          callback: this.saveApplication
                                        }
                                      }
                                    ]
                                  }

                                ]
                              }
                            ]
                          },
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Styling',
                            items: [
                              'htmlClass',
                              {
                                key: 'style',
                                type: 'textarea'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    title: 'Layout Settings',
                    items: [
                      {
                        type: 'section',
                        sectionContainerClass: "row",
                        sectionChildClass: "col",
                        items: [
                          {
                            type: 'section',
                            items: [
                              {
                                key: 'layoutSettings.layoutType',
                                type: 'radios',
                                titleMap: [
                                  {name: 'Sidebar Left - NavBar Right', value: 'sLeftNTop'},
                                  {name: 'Top NavBar - Sidebar left below', value: 'nTopSLeft'}
                                ]
                              },
                              {
                                type: 'help',
                                description: '<div class="mb-3"><hr  /></div>'
                              },
                              {
                                type: 'section',
                                sectionType: 'fieldset',
                                title: 'Exclude',
                                sectionContainerClass: "row",
                                sectionChildClass: "col",
                                items: [
                                  'layoutSettings.excludeSidebar', 'layoutSettings.excludeNavBar', 'layoutSettings.excludeFooter'
                                ]
                              }
                            ]
                          },
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Styling',
                            items: [
                              'layoutSettings.htmlClass', {key: 'layoutSettings.style', type: 'textarea'},
                              'layoutSettings.backgroundColor'
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    title: 'Sidebar Settings',
                    items: [
                      {
                        type: 'section',
                        items: [
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Sidebar Styling',
                            items: [
                              {
                                type: 'section',
                                sectionContainerClass: "row",
                                sectionChildClass: "col",
                                items: [
                                  {
                                    type: 'section',
                                    items: [
                                      'layoutSettings.sidebarSettings.title',
                                      'layoutSettings.sidebarSettings.logo',
                                      'layoutSettings.sidebarSettings.autoClose',
                                      {
                                        type: 'help',
                                        description: '<hr />'
                                      },
                                      {
                                        type: 'section',
                                        sectionContainerClass: "row",
                                        sectionChildClass: "col",
                                        items: [
                                          'layoutSettings.sidebarSettings.backgroundColor', 'layoutSettings.sidebarSettings.activeColor', 'layoutSettings.sidebarSettings.sidebarWidth'
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    type: 'section',
                                    items: [
                                      'layoutSettings.sidebarSettings.htmlClass',
                                      {
                                        key: 'layoutSettings.sidebarSettings.style',
                                        type: 'ace',
                                        style: 'min-height:250px',
                                        config:{
                                          lang: 'css'
                                        }
                                      }
                                    ]
                                  }

                                ]
                              }
                            ]
                          },
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Items',
                            items: [
                              {
                                key: 'layoutSettings.sidebarSettings.items',
                                type: 'tabarray',
                                add: 'New',
                                remove: 'Delete',
                                btnStyle: {
                                  remove: 'btn-danger'
                                },
                                title: "{{ value.name || 'Tab '+$index }}",
                                items: [
                                  {
                                    type: 'section',
                                    sectionContainerClass: "row",
                                    sectionChildClass: "col",
                                    items: [
                                      {
                                        key: 'layoutSettings.sidebarSettings.items[].itemType', type: 'radiosinline'
                                      },
                                      'layoutSettings.sidebarSettings.items[].name',
                                      'layoutSettings.sidebarSettings.items[].title',
                                      'layoutSettings.sidebarSettings.items[].icon',
                                    ]
                                  },
                                  {
                                    type: 'section',
                                    sectionContainerClass: "row",
                                    sectionChildClass: "col",
                                    items: [
                                      'layoutSettings.sidebarSettings.items[].link',
                                      'layoutSettings.sidebarSettings.items[].linkTarget',
                                      'layoutSettings.sidebarSettings.items[].disableEdit',
                                      'layoutSettings.sidebarSettings.items[].disableAdd',
                                    ]
                                  },
                                  {
                                    type: 'tabs',
                                    tabs: [
                                      {
                                        title: 'Item settings',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Item Styling',
                                                items: [
                                                  'layoutSettings.sidebarSettings.items[].itemClass',
                                                  {
                                                    key: 'layoutSettings.sidebarSettings.items[].itemStyle',
                                                    type: 'ace',
                                                    style: 'min-height:250px',
                                                    config:{
                                                      lang: 'css'
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Item Template',
                                                items: [
                                                  {
                                                    key: 'layoutSettings.sidebarSettings.items[].itemTemplate',
                                                    type: 'ace',
                                                    style: 'min-height:300px;',
                                                    config: {lang: 'html'}
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        title: 'Title settings',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Title Styling',
                                                items: [
                                                  'layoutSettings.sidebarSettings.items[].titleClass',
                                                  {
                                                    key: 'layoutSettings.sidebarSettings.items[].titleStyle',
                                                    type: 'ace',
                                                    style: 'min-height:205px',
                                                    config:{
                                                      lang: 'css'
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Title Template',
                                                items: [
                                                  {
                                                    key: 'layoutSettings.sidebarSettings.items[].titleTemplate',
                                                    type: 'ace',
                                                    style: 'min-height:300px;',
                                                    config: {lang: 'html'}
                                                  }
                                                ]
                                              }

                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        title: 'Link settings',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Link Styling',
                                                items: [
                                                  'layoutSettings.sidebarSettings.items[].linkClass',
                                                  {
                                                    key: 'layoutSettings.sidebarSettings.items[].linkStyle',
                                                    type: 'ace',
                                                    style: 'min-height:205px',
                                                    config:{
                                                      lang: 'css'
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Link Template',
                                                items: [
                                                  {
                                                    key: 'layoutSettings.sidebarSettings.items[].linkTemplate',
                                                    type: 'ace',
                                                    style: 'min-height:300px;',
                                                    config: {lang: 'html'}
                                                  }
                                                ]
                                              }]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    type: 'section',
                                    condition: "model.layoutSettings.sidebarSettings.items[form.arrayIndex].itemType!=='LINK'",
                                    items: [
                                      {
                                        type: 'section',
                                        sectionType: 'fieldset',
                                        title: 'Dropdown Settings',
                                        condition: "model.layoutSettings.sidebarSettings.items[form.arrayIndex].itemType==='DROP_DOWN'",
                                        items: [
                                          {
                                            type: 'section',
                                            items: [
                                              {
                                                key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items',
                                                type: 'tabarray',
                                                add: 'New',
                                                remove: 'Delete',
                                                btnStyle: {
                                                  remove: 'btn-danger'
                                                },
                                                title: "{{ value.name || 'Tab '+$index }}",
                                                items: [
                                                  {
                                                    type: 'section',
                                                    sectionContainerClass: "row",
                                                    sectionChildClass: "col",
                                                    items: [
                                                      {
                                                        key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].itemType',
                                                        type: 'radiosinline'
                                                      },
                                                      'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].name',
                                                      'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].title',
                                                      'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].icon'
                                                    ]
                                                  },
                                                  {
                                                    type: 'section',
                                                    sectionContainerClass: "row",
                                                    sectionChildClass: "col",
                                                    items: [
                                                      'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].link',
                                                      'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].linkTarget',
                                                      'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].disableEdit',
                                                      'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].disableAdd',
                                                    ]
                                                  },
                                                  {
                                                    type: 'tabs',
                                                    tabs: [
                                                      {
                                                        title: 'Item settings',
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionContainerClass: "row",
                                                            sectionChildClass: "col",
                                                            items: [
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Item Styling',
                                                                items: [
                                                                  'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].itemClass',
                                                                  {
                                                                    key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].itemStyle',
                                                                    type: 'ace',
                                                                    style: 'min-height:205px',
                                                                    config:{
                                                                      lang: 'css'
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Item Template',
                                                                items: [
                                                                  {
                                                                    key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].itemTemplate',
                                                                    type: 'ace',
                                                                    style: 'min-height:300px;',
                                                                    config: {lang: 'html'}
                                                                  }
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        title: 'Title settings',
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionContainerClass: "row",
                                                            sectionChildClass: "col",
                                                            items: [
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Title Styling',
                                                                items: [
                                                                  'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].titleClass',
                                                                  {
                                                                    key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].titleStyle',
                                                                    type: 'ace',
                                                                    style: 'min-height:205px',
                                                                    config:{
                                                                      lang: 'css'
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Title Template',
                                                                items: [
                                                                  {
                                                                    key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].titleTemplate',
                                                                    type: 'ace',
                                                                    style: 'min-height:300px;',
                                                                    config: {lang: 'html'}
                                                                  }
                                                                ]
                                                              }

                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        title: 'Link settings',
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionContainerClass: "row",
                                                            sectionChildClass: "col",
                                                            items: [
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Link Styling',
                                                                items: [
                                                                  'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].linkClass',
                                                                  {
                                                                    key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].linkStyle',
                                                                    type: 'ace',
                                                                    style: 'min-height:205px',
                                                                    config:{
                                                                      lang: 'css'
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Link Template',
                                                                items: [
                                                                  {
                                                                    key: 'layoutSettings.sidebarSettings.items[].itemDropDownSettings.items[].linkTemplate',
                                                                    type: 'ace',
                                                                    style: 'min-height:300px;',
                                                                    config: {lang: 'html'}
                                                                  }
                                                                ]
                                                              }]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]

                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        type: 'section',
                                        sectionType: 'fieldset',
                                        title: 'Form Settings',
                                        condition: "model.layoutSettings.sidebarSettings.items[form.arrayIndex].itemType==='FORM'",
                                        items: [
                                          {
                                            key: 'layoutSettings.sidebarSettings.items[].itemFormSettings.schemaForm',
                                            type: 'schemaFormEditor',
                                            enableConfig: true,
                                            enableElements: true,
                                            editorClass: 'col',
                                            elementClass: 'col-2',
                                            configClass: 'col-3'
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    title: 'NavBar Settings',
                    items: [
                      {
                        type: 'section',
                        items: [
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'NavBar Styling',
                            items: [
                              {
                                type: 'section',
                                sectionContainerClass: "row",
                                items: [
                                  {
                                    type: 'section',
                                    childClass: 'col-4',
                                    items: [
                                      {
                                        type: 'section',
                                        items: [
                                          'layoutSettings.navBarSettings.title',
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: 'col',
                                            items: [
                                              'layoutSettings.navBarSettings.htmlClass', 'layoutSettings.navBarSettings.backgroundColor'
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    key: 'layoutSettings.navBarSettings.style',
                                    childClass: 'col-8',
                                    type: 'ace',
                                    style: 'min-height:135px',
                                    config:{
                                      lang: 'css'
                                    }
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Items',
                            items: [
                              {
                                key: 'layoutSettings.navBarSettings.items',
                                type: 'tabarray',
                                add: 'New',
                                remove: 'Delete',
                                btnStyle: {
                                  remove: 'btn-danger'
                                },
                                title: "{{ value.name || 'Tab '+$index }}",
                                items: [
                                  {
                                    type: 'section',
                                    sectionContainerClass: "row",
                                    sectionChildClass: "col",
                                    items: [
                                      {
                                        key: 'layoutSettings.navBarSettings.items[].itemType', type: 'radiosinline'
                                      },
                                      'layoutSettings.navBarSettings.items[].name',
                                      'layoutSettings.navBarSettings.items[].title',
                                      'layoutSettings.navBarSettings.items[].icon'
                                    ]
                                  },
                                  {
                                    type: 'section',
                                    sectionContainerClass: "row",
                                    sectionChildClass: "col",
                                    items: [
                                      'layoutSettings.navBarSettings.items[].link',
                                      'layoutSettings.navBarSettings.items[].linkTarget',
                                    ]
                                  },
                                  {
                                    type: 'tabs',
                                    tabs: [
                                      {
                                        title: 'Item settings',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Item Styling',
                                                items: [
                                                  'layoutSettings.navBarSettings.items[].itemClass',
                                                  {
                                                    key: 'layoutSettings.navBarSettings.items[].itemStyle',
                                                    type: 'ace',
                                                    style: 'min-height:205px',
                                                    config:{
                                                      lang: 'css'
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Item Template',
                                                items: [
                                                  {
                                                    key: 'layoutSettings.navBarSettings.items[].itemTemplate',
                                                    type: 'ace',
                                                    style: 'min-height:300px;',
                                                    config: {lang: 'html'}
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        title: 'Title settings',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Title Styling',
                                                items: [
                                                  'layoutSettings.navBarSettings.items[].titleClass',
                                                  {
                                                    key: 'layoutSettings.navBarSettings.items[].titleStyle',
                                                    type: 'ace',
                                                    style: 'min-height:205px',
                                                    config:{
                                                      lang: 'css'
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Title Template',
                                                items: [
                                                  {
                                                    key: 'layoutSettings.navBarSettings.items[].titleTemplate',
                                                    type: 'ace',
                                                    style: 'min-height:300px;',
                                                    config: {lang: 'html'}
                                                  }
                                                ]
                                              }

                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        title: 'Link settings',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Link Styling',
                                                items: [
                                                  'layoutSettings.navBarSettings.items[].linkClass',
                                                  {
                                                    key: 'layoutSettings.navBarSettings.items[].linkStyle',
                                                    type: 'ace',
                                                    style: 'min-height:205px',
                                                    config:{
                                                      lang: 'css'
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                type: 'section',
                                                sectionType: 'fieldset',
                                                title: 'Link Template',
                                                items: [
                                                  {
                                                    key: 'layoutSettings.navBarSettings.items[].linkTemplate',
                                                    type: 'ace',
                                                    style: 'min-height:300px;',
                                                    config: {lang: 'html'}
                                                  }
                                                ]
                                              }]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    type: 'section',
                                    condition: "model.layoutSettings.navBarSettings.items[form.arrayIndex].itemType!=='LINK'",
                                    items: [
                                      {
                                        type: 'section',
                                        sectionType: 'fieldset',
                                        title: 'Dropdown Settings',
                                        condition: "model.layoutSettings.navBarSettings.items[form.arrayIndex].itemType==='DROP_DOWN'",
                                        items: [
                                          {
                                            type: 'section',
                                            items: [
                                              {
                                                key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items',
                                                type: 'tabarray',
                                                add: 'New',
                                                remove: 'Delete',
                                                btnStyle: {
                                                  remove: 'btn-danger'
                                                },
                                                title: "{{ value.name || 'Tab '+$index }}",
                                                items: [
                                                  {
                                                    type: 'section',
                                                    sectionContainerClass: "row",
                                                    sectionChildClass: "col",
                                                    items: [
                                                      {
                                                        key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].itemType',
                                                        type: 'radiosinline'
                                                      },
                                                      'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].name',
                                                      'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].title',
                                                      'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].icon'
                                                    ]
                                                  },
                                                  {
                                                    type: 'section',
                                                    sectionContainerClass: "row",
                                                    sectionChildClass: "col",
                                                    items: [
                                                      'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].link',
                                                      'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].linkTarget',
                                                    ]
                                                  },
                                                  {
                                                    type: 'tabs',
                                                    tabs: [
                                                      {
                                                        title: 'Item settings',
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionContainerClass: "row",
                                                            sectionChildClass: "col",
                                                            items: [
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Item Styling',
                                                                items: [
                                                                  'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].itemClass',
                                                                  {
                                                                    key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].itemStyle',
                                                                    type: 'ace',
                                                                    style: 'min-height:205px',
                                                                    config:{
                                                                      lang: 'css'
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Item Template',
                                                                items: [
                                                                  {
                                                                    key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].itemTemplate',
                                                                    type: 'ace',
                                                                    style: 'min-height:300px;',
                                                                    config: {lang: 'html'}
                                                                  }
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        title: 'Title settings',
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionContainerClass: "row",
                                                            sectionChildClass: "col",
                                                            items: [
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Title Styling',
                                                                items: [
                                                                  'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].titleClass',
                                                                  {
                                                                    key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].titleStyle',
                                                                    type: 'ace',
                                                                    style: 'min-height:205px',
                                                                    config:{
                                                                      lang: 'css'
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Title Template',
                                                                items: [
                                                                  {
                                                                    key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].titleTemplate',
                                                                    type: 'ace',
                                                                    style: 'min-height:300px;',
                                                                    config: {lang: 'html'}
                                                                  }
                                                                ]
                                                              }

                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        title: 'Link settings',
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionContainerClass: "row",
                                                            sectionChildClass: "col",
                                                            items: [
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Link Styling',
                                                                items: [
                                                                  'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].linkClass',
                                                                  {
                                                                    key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].linkStyle',
                                                                    type: 'ace',
                                                                    style: 'min-height:205px',
                                                                    config:{
                                                                      lang: 'css'
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                type: 'section',
                                                                sectionType: 'fieldset',
                                                                title: 'Link Template',
                                                                items: [
                                                                  {
                                                                    key: 'layoutSettings.navBarSettings.items[].itemDropDownSettings.items[].linkTemplate',
                                                                    type: 'ace',
                                                                    style: 'min-height:300px;',
                                                                    config: {lang: 'html'}
                                                                  }
                                                                ]
                                                              }]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]

                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        type: 'section',
                                        sectionType: 'fieldset',
                                        title: 'Form Settings',
                                        condition: "model.layoutSettings.navBarSettings.items[form.arrayIndex].itemType==='FORM'",
                                        items: [
                                          {
                                            key: 'layoutSettings.navBarSettings.items[].itemFormSettings.schemaForm',
                                            type: 'schemaFormEditor',
                                            enableConfig: true,
                                            enableElements: true,
                                            editorClass: 'col',
                                            elementClass: 'col-2',
                                            configClass: 'col-3'
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    title: 'Footer Settings',
                    items: [
                      {
                        type: 'section',
                        sectionContainerClass: "row",
                        items: [
                          {
                            type: 'section',
                            childClass: 'col-4',
                            items: [
                              'layoutSettings.footerSettings.htmlClass', 'layoutSettings.footerSettings.backgroundColor',
                              {key: 'layoutSettings.footerSettings.style', type: 'ace',
                                style: 'min-height:220px',
                                config:{
                                  lang: 'css'
                                }}
                            ]
                          },
                          {
                            key: 'layoutSettings.footerSettings.footerTemplate',
                            childClass: 'col-8',
                            type: 'ace', style: 'min-height:400px;', config: {lang: 'html'}
                          }
                        ]
                      }

                    ]
                  },
                  {
                    title: 'User Menu Settings',
                    items: [
                      {
                        type: 'section',
                        sectionType: 'fieldset',
                        title: 'Common settings',
                        items: [
                          {
                            type: 'section',
                            sectionContainerClass: 'row',
                            sectionChildClass: 'col',
                            items: [
                              {
                                key: 'layoutSettings.userMenuSettings.show',
                                htmlClass: 'mt-4 pt-1',
                                fieldHtmlClass: 'mb-2'
                              },
                              {
                                key: 'layoutSettings.userMenuSettings.placement',
                                type: 'radiosinline'
                              },
                              'layoutSettings.userMenuSettings.icon',
                              'layoutSettings.userMenuSettings.htmlClass',
                              {
                                key:'layoutSettings.userMenuSettings.style',
                                type: 'ace',
                                style: 'min-height:205px',
                                config:{
                                  lang: 'css'
                                }
                              }

                            ]
                          }
                        ]
                      },
                      {
                        type: 'section',
                        sectionType: 'fieldset',
                        title: 'Items',
                        items: [
                          {
                            key: 'layoutSettings.userMenuSettings.items',
                            type: 'tabarray',
                            add: 'New',
                            remove: 'Delete',
                            btnStyle: {
                              remove: 'btn-danger'
                            },
                            title: "{{ value.name || 'Tab '+$index }}",
                            items: [
                              {
                                type: 'section',
                                sectionContainerClass: "row",
                                sectionChildClass: "col",
                                items: [
                                  {
                                    key: 'layoutSettings.userMenuSettings.items[].itemType', type: 'radiosinline'
                                  },
                                  'layoutSettings.userMenuSettings.items[].name',
                                  'layoutSettings.userMenuSettings.items[].title',
                                  'layoutSettings.userMenuSettings.items[].icon',
                                ]
                              },
                              {
                                type: 'section',
                                sectionContainerClass: "row",
                                sectionChildClass: "col",
                                items: [
                                  'layoutSettings.userMenuSettings.items[].link',
                                  'layoutSettings.userMenuSettings.items[].linkTarget',
                                  'layoutSettings.userMenuSettings.items[].disableEdit',
                                  'layoutSettings.userMenuSettings.items[].disableAdd',
                                ]
                              },
                              {
                                type: 'tabs',
                                tabs: [
                                  {
                                    title: 'Item settings',
                                    items: [
                                      {
                                        type: 'section',
                                        sectionContainerClass: "row",
                                        sectionChildClass: "col",
                                        items: [
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Item Styling',
                                            items: [
                                              'layoutSettings.userMenuSettings.items[].itemClass',
                                              {
                                                key: 'layoutSettings.userMenuSettings.items[].itemStyle',
                                                type: 'ace',
                                                style: 'min-height:205px',
                                                config:{
                                                  lang: 'css'
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Item Template',
                                            items: [
                                              {
                                                key: 'layoutSettings.userMenuSettings.items[].itemTemplate',
                                                type: 'ace',
                                                style: 'min-height:300px;',
                                                config: {lang: 'html'}
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    title: 'Title settings',
                                    items: [
                                      {
                                        type: 'section',
                                        sectionContainerClass: "row",
                                        childClass: "col",
                                        items: [
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Title Styling',
                                            items: [
                                              'layoutSettings.userMenuSettings.items[].titleClass',
                                              {
                                                key: 'layoutSettings.userMenuSettings.items[].titleStyle',
                                                type: 'ace',
                                                style: 'min-height:205px',
                                                config:{
                                                  lang: 'css'
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Title Template',
                                            items: [
                                              {
                                                key: 'layoutSettings.userMenuSettings.items[].titleTemplate',
                                                type: 'ace',
                                                style: 'min-height:300px;',
                                                config: {lang: 'html'}
                                              }
                                            ]
                                          }

                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    title: 'Link settings',
                                    items: [
                                      {
                                        type: 'section',
                                        sectionContainerClass: "row",
                                        childClass: "col",
                                        items: [
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Link Styling',
                                            items: [
                                              'layoutSettings.userMenuSettings.items[].linkClass',
                                              {
                                                key: 'layoutSettings.userMenuSettings.items[].linkStyle',
                                                type: 'ace',
                                                style: 'min-height:205px',
                                                config:{
                                                  lang: 'css'
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Link Template',
                                            items: [
                                              {
                                                key: 'layoutSettings.userMenuSettings.items[].linkTemplate',
                                                type: 'ace',
                                                style: 'min-height:300px;',
                                                config: {lang: 'html'}
                                              }
                                            ]
                                          }]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                type: 'section',
                                condition: "model.layoutSettings.userMenuSettings.items[form.arrayIndex].itemType!=='LINK'",
                                items: [
                                  {
                                    type: 'section',
                                    sectionType: 'fieldset',
                                    title: 'Dropdown Settings',
                                    condition: "model.layoutSettings.userMenuSettings.items[form.arrayIndex].itemType==='DROP_DOWN'",
                                    items: [
                                      {
                                        type: 'section',
                                        items: [
                                          {
                                            key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items',
                                            type: 'tabarray',
                                            add: 'New',
                                            remove: 'Delete',
                                            btnStyle: {
                                              remove: 'btn-danger'
                                            },
                                            title: "{{ value.name || 'Tab '+$index }}",
                                            items: [
                                              {
                                                type: 'section',
                                                sectionContainerClass: "row",
                                                childClass: "col",
                                                items: [
                                                  {
                                                    key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].itemType',
                                                    type: 'radiosinline'
                                                  },
                                                  'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].name',
                                                  'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].title',
                                                  'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].icon'
                                                ]
                                              },
                                              {
                                                type: 'section',
                                                sectionContainerClass: "row",
                                                childClass: "col",
                                                items: [
                                                  'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].link',
                                                  'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].linkTarget',
                                                  'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].disableEdit',
                                                  'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].disableAdd',
                                                ]
                                              },
                                              {
                                                type: 'tabs',
                                                tabs: [
                                                  {
                                                    title: 'Item settings',
                                                    items: [
                                                      {
                                                        type: 'section',
                                                        sectionContainerClass: "row",
                                                        childClass: "col",
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionType: 'fieldset',
                                                            title: 'Item Styling',
                                                            items: [
                                                              'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].itemClass',
                                                              {
                                                                key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].itemStyle',
                                                                type: 'ace',
                                                                style: 'min-height:205px',
                                                                config:{
                                                                  lang: 'css'
                                                                }
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            type: 'section',
                                                            sectionType: 'fieldset',
                                                            title: 'Item Template',
                                                            items: [
                                                              {
                                                                key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].itemTemplate',
                                                                type: 'ace',
                                                                style: 'min-height:300px;',
                                                                config: {lang: 'html'}
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  {
                                                    title: 'Title settings',
                                                    items: [
                                                      {
                                                        type: 'section',
                                                        sectionContainerClass: "row",
                                                        childClass: "col",
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionType: 'fieldset',
                                                            title: 'Title Styling',
                                                            items: [
                                                              'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].titleClass',
                                                              {
                                                                key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].titleStyle',
                                                                type: 'ace',
                                                                style: 'min-height:205px',
                                                                config:{
                                                                  lang: 'css'
                                                                }
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            type: 'section',
                                                            sectionType: 'fieldset',
                                                            title: 'Title Template',
                                                            items: [
                                                              {
                                                                key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].titleTemplate',
                                                                type: 'ace',
                                                                style: 'min-height:300px;',
                                                                config: {lang: 'html'}
                                                              }
                                                            ]
                                                          }

                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  {
                                                    title: 'Link settings',
                                                    items: [
                                                      {
                                                        type: 'section',
                                                        sectionContainerClass: "row",
                                                        childClass: "col",
                                                        items: [
                                                          {
                                                            type: 'section',
                                                            sectionType: 'fieldset',
                                                            title: 'Link Styling',
                                                            items: [
                                                              'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].linkClass',
                                                              {
                                                                key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].linkStyle',
                                                                type: 'ace',
                                                                style: 'min-height:205px',
                                                                config:{
                                                                  lang: 'css'
                                                                }
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            type: 'section',
                                                            sectionType: 'fieldset',
                                                            title: 'Link Template',
                                                            items: [
                                                              {
                                                                key: 'layoutSettings.userMenuSettings.items[].itemDropDownSettings.items[].linkTemplate',
                                                                type: 'ace',
                                                                style: 'min-height:300px;',
                                                                config: {lang: 'html'}
                                                              }
                                                            ]
                                                          }]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]

                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    type: 'section',
                                    sectionType: 'fieldset',
                                    title: 'Form Settings',
                                    condition: "model.layoutSettings.userMenuSettings.items[form.arrayIndex].itemType==='FORM'",
                                    items: [
                                      {
                                        key: 'layoutSettings.userMenuSettings.items[].itemFormSettings.schemaForm',
                                        type: 'schemaFormEditor',
                                        enableConfig: true,
                                        enableElements: true,
                                        editorClass: 'col',
                                        elementClass: 'col-2',
                                        configClass: 'col-3'
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    title: 'CSS Settings',
                    items: [
                      {
                        key: 'css',
                        type: 'ace',
                        style: 'min-height:500px;',
                        config: {
                          lang: 'css'
                        }
                      }
                    ]
                  },
                  {
                    title: 'Dashboards Settings',
                    items: [
                      {
                        type: 'section',
                        sectionContainerClass: "row",
                        sectionChildClass: "col-6",
                        items: [
                          {
                            type: 'section',
                            sectionContainerClass: "row",
                            sectionChildClass: "col",
                            items: ['dashboardsSettings.allowAllWidgets', 'dashboardsSettings.editableAllWidgets']
                          }
                        ]
                      },
                      {
                        type: 'section',
                        sectionContainerClass: "row",
                        sectionChildClass: "col",
                        items: [
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Allowed widgets',
                            condition: 'model.dashboardsSettings.allowAllWidgets',
                            items: [
                              {
                                key: 'dashboardsSettings.allowedWidgets',
                                type: 'tabarray',
                                title: "{{ value.permissionExpression || 'Tab '+$index }}",
                                items: [
                                  'dashboardsSettings.allowedWidgets[].permissionExpression',
                                  {
                                    notitle: true,
                                    key: 'dashboardsSettings.allowedWidgets[].widgetType',
                                    groupBy: 'category',
                                    type: 'select',
                                    rows: 10,
                                    multiSelect: true,
                                    options: {
                                      callback: () => this.getWidgets()
                                    }
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            type: 'section',
                            sectionType: 'fieldset',
                            title: 'Editable widgets',
                            condition: 'model.dashboardsSettings.editableAllWidgets',
                            items: [
                              {
                                key: 'dashboardsSettings.editableWidgets',
                                type: 'tabarray',
                                title: "{{ value.permissionExpression || 'Tab '+$index }}",
                                items: [
                                  'dashboardsSettings.editableWidgets[].permissionExpression',
                                  {
                                    notitle: true,
                                    key: 'dashboardsSettings.editableWidgets[].widgetType',
                                    groupBy: 'category',
                                    type: 'select',
                                    rows: 10,
                                    multiSelect: true,
                                    options: {
                                      callback: () => this.getWidgets()
                                    }
                                  }

                                ]
                              }

                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    title: 'Security Settings',
                    items: [
                      {
                        type: 'section',
                        sectionContainerClass: "row",
                        sectionChildClass: "col",
                        items: [
                          {
                            type: 'section',
                            sectionContainerClass: "row",
                            sectionChildClass: "col",
                            items: [
                              {
                                type: 'tabarray',
                                key: 'securitySettings.routePermissions',
                                title: "{{ value.routes.join(', ') || 'Tab '+$index }}",
                                items: [
                                  {
                                    key: 'securitySettings.routePermissions[].routes',
                                    type: 'select',
                                    branchNodesDisabled: true,
                                    multiSelect: true,
                                    flat: false,
                                    options: {
                                      callback: () => this.getRoutes()
                                    }
                                  },
                                  {
                                    key: 'securitySettings.routePermissions[].rolePermissions',
                                    title: "{{ value.roles || 'Tab '+$index }}",
                                    type: 'tabarray',
                                    items:[
                                      'securitySettings.routePermissions[].rolePermissions[].roles',
                                      'securitySettings.routePermissions[].rolePermissions[].enabled',
                                      {
                                        type: 'help',
                                        description:'<hr />'
                                      },
                                      {
                                        type: 'section',
                                        sectionContainerClass: "row",
                                        sectionChildClass: "col",
                                        condition: 'model.securitySettings.routePermissions[parentForm.arrayIndex].rolePermissions[form.arrayIndex].enabled',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              'securitySettings.routePermissions[].rolePermissions[].permissions.disableEdit',
                                              'securitySettings.routePermissions[].rolePermissions[].permissions.disableAdd',
                                              'securitySettings.routePermissions[].rolePermissions[].permissions.disableCopyAllWidgets',
                                            ]
                                          },
                                          {
                                            type: 'section',
                                            sectionContainerClass: "row",
                                            sectionChildClass: "col",
                                            items: [
                                              {
                                                key: 'securitySettings.routePermissions[].rolePermissions[].permissions.allowAllWidgets',
                                                condition: '!model.securitySettings.routePermissions[parentForm.arrayIndex].rolePermissions[form.arrayIndex].permissions.disableAdd',
                                              },
                                              {
                                                key: 'securitySettings.routePermissions[].rolePermissions[].permissions.editableAllWidgets',
                                                condition: '!model.securitySettings.routePermissions[parentForm.arrayIndex].rolePermissions[form.arrayIndex].permissions.disableEdit',
                                              }

                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        type: 'section',
                                        sectionContainerClass: "row",
                                        sectionChildClass: "col",
                                        condition: 'model.securitySettings.routePermissions[parentForm.arrayIndex].rolePermissions[form.arrayIndex].enabled',
                                        items: [
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Allowed widgets',
                                            condition: '!model.securitySettings.routePermissions[parentForm.arrayIndex].rolePermissions[form.arrayIndex].permissions.allowAllWidgets',
                                            items: [
                                              {
                                                key: 'securitySettings.routePermissions[].rolePermissions[].permissions.allowedWidgets',
                                                type: 'tabarray',
                                                title: "{{ value.permissionExpression || 'Tab '+$index }}",
                                                items: [
                                                  {
                                                    notitle: true,
                                                    key: 'securitySettings.routePermissions[].rolePermissions[].permissions.allowedWidgets[].widgetType',
                                                    groupBy: 'category',
                                                    type: 'select',
                                                    rows: 10,
                                                    multiSelect: true,
                                                    options: {
                                                      callback: () => this.getWidgets()
                                                    }
                                                  }
                                                ]
                                              }
                                            ]
                                          },
                                          {
                                            type: 'section',
                                            sectionType: 'fieldset',
                                            title: 'Editable widgets',
                                            condition: '!model.securitySettings.routePermissions[parentForm.arrayIndex].rolePermissions[form.arrayIndex].permissions.editableAllWidgets',
                                            items: [
                                              {
                                                key: 'securitySettings.routePermissions[].rolePermissions[].permissions.editableWidgets',
                                                type: 'tabarray',
                                                title: "{{ value.permissionExpression || 'Tab '+$index }}",
                                                items: [
                                                  {
                                                    notitle: true,
                                                    key: 'securitySettings.routePermissions[].rolePermissions[].permissions.editableWidgets[].widgetType',
                                                    groupBy: 'category',
                                                    type: 'select',
                                                    rows: 10,
                                                    multiSelect: true,
                                                    options: {
                                                      callback: () => this.getWidgets()
                                                    }
                                                  }

                                                ]
                                              }

                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                    ]
                  },
                  {
                    title: 'Application Context',
                    items: [
                      {
                        key: 'applicationContext',
                        type: 'ace',
                        style: 'min-height:500px;',
                        config: {
                          lang: 'json'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      }
    },
    created() {
      console.log('VueDfEdit created', this.model.config.data);
    },
    beforeMount() {
      this.mergeSchemaFormWithCustom();
    },
    watch:{
      'model.config.data.securitySettings':{
        handler(newVal, oldVal){
          if(newVal!==oldVal){
            console.log('Update security settings', this.model.config.data.layoutSettings);
            this.mergeSecuritySettingsIntoRouteItem(newVal);
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      mergeSecuritySettingsIntoRouteItem: function(securitySettings){
        /*if(securitySettings){
          this.mergeSecuritySettingsIntoLayoutRouteItem('sidebarSettings', securitySettings);
          this.mergeSecuritySettingsIntoLayoutRouteItem('navBarSettings', securitySettings);
          this.mergeSecuritySettingsIntoLayoutRouteItem('userMenuSettings', securitySettings);
        }*/
      },
      mergeSecuritySettingsIntoLayoutRouteItem: function(settingsName, securitySettings){

      },
      getRoutes: function () {
        const preparedMatchedRouteItems = [];

        console.log('Preparing matched routes');

        const sidebarChildren = this.prepareMatchedRoutes('sidebarSettings');
        if (sidebarChildren && sidebarChildren.length>0) {
          preparedMatchedRouteItems.push({
              value: 'Sidebar',
              name: 'Sidebar',
              children: sidebarChildren
            }
          )
        }
        const navbarChildren = this.prepareMatchedRoutes('navBarSettings');
        if (navbarChildren && navbarChildren.length>0) {
          preparedMatchedRouteItems.push({
              value: 'NavBar',
              name: 'NavBar',
              children: navbarChildren
            }
          )
        }
        const userMenuChildren = this.prepareMatchedRoutes('userMenuSettings');
        if (userMenuChildren && userMenuChildren.length>0) {
          preparedMatchedRouteItems.push({
              value: 'User Menu',
              name: 'User Menu',
              children: userMenuChildren
            }
          )
        }

        console.log('Resolved matched routes', preparedMatchedRouteItems);

        return preparedMatchedRouteItems;
      },
      prepareMatchedRoutes: function (settingsName) {
        const preparedMatchedRouteItems = [];
        const items = this.getItems(settingsName);
        if (items && items.length > 0) {
          items.forEach(function (item) {
            const obj = {};
            const children = [];
            if (item.itemDropDownSettings && item.itemDropDownSettings.items && item.itemDropDownSettings.items.length > 0) {
              item.itemDropDownSettings.items.forEach(function (childItem) {
                  const childObj = {
                    name: childItem.name,
                    value: item.name+"=>"+childItem.name
                  };
                children.push(childObj);
              });
            }

            obj.name = item.name;
            obj.value = item.name;
            if (children.length > 0) {
              obj.children = children;
            }
            preparedMatchedRouteItems.push(obj);

          });
        }
        return preparedMatchedRouteItems;
      },
      getItems: function (settingsName) {
        if (this.model.config.data.layoutSettings && this.model.config.data.layoutSettings[settingsName]) {
          return this.model.config.data.layoutSettings[settingsName].items || [];
        }
      },
      saveApplication: function (fieldForm, event) {
        console.log('Save Application', this.model.config.data, fieldForm, event);
      },
      onExistingAppChange: function (context) {
        console.log('On Select changed Edit App', context);
        if(context.val){
          const that = this;
          const id = context.val;
          that.$services[that.vueDfAppServiceName].loadApplication(id, function(model){
            console.log('model', model);

            const faundDashAppWidget = that.$jsulator.evaluate('$dashboard.rows[0].columns[0].widgets[0]$', model);
            console.log('faundDashAppWidget', faundDashAppWidget);
            if(faundDashAppWidget){
              that.$set(that.model.config, 'data', faundDashAppWidget.config.data);
            }
          });
        }
      },
      getExistingApplications: function () {
        const that = this;
        const promise = new Promise(function (resolve, reject) {
          that.$services[that.vueDfAppServiceName].loadApplications(JSON.stringify({ applicationName: 1, appRootPath: 1 }), function (models) {
            const titleMap = [];
            if (models) {
              models.forEach(function (item) {
                const obj = {name: item.applicationName, value: item.appRootPath};
                titleMap.push(obj);
              });
            }
            resolve(titleMap);
          });
        });

        return promise;
      },
      mergeSchemaFormWithCustom: function () {
        const schemaFormSourceDestination = this.getSourceDestinationSchemaForm();
        schemaFormSourceDestination.form[0].tabs.splice(1, 1);

        for (const prop in this.schemaFormCustom.schemaProperties) {
          schemaFormSourceDestination.schema.properties[prop] = this.schemaFormCustom.schemaProperties[prop];
        }
        schemaFormSourceDestination.form[0].tabs.splice(0, 0, this.schemaFormCustom.formItem);

        this.schemaForm = schemaFormSourceDestination;

        console.log('Merged with custom schema');
      }
    }
  }
</script>

<style scoped>

</style>
