import utils from '../../utils/utils'
import ObjectPath from "../../utils/ObjectPath";

class ConditionResolver {
  constructor(model, form, parentForm, schemaFormService) {
    this.model = model;
    this.form = form;
    this.parentForm = parentForm;
    this.schemaFormService = schemaFormService;
  }

  evaluate(condition) {
    const scope = this._getScope();
    const evaluated = utils.safeEval(condition, scope);
    return evaluated;
  }

  _componentVisitor(key, component) {
    console.log('Entering visitor', key, component);
    if (component.getKey !== undefined) {
      console.log('Found getKey component', component, component.getKey());
    }

    console.log('Entering for $children', key, component, component.$children);

    if (component.$children && component.$children.length) {
      let foundControl = null;
      for (let i = 0; i < component.$children.length; i++) {
        const child = component.$children[i];
        const found = this._componentVisitor(key, child);
        console.log('found something', found, key, i, component);

        if(found){
          foundControl = found;
        }
      }
      console.log('We found: ', foundControl, key, component, component.$children);
    } else {
      console.log('No children', key, component, component.$children);

      return null;
    }
  }

  _getRawData(key) {
    const parsedKey = ObjectPath.parse(key);
    console.log('Im in SchemaForm getRaw method', key, parsedKey, this);
    if (parsedKey) {

      const schemaForm = this.schemaFormService.getSchemaForm();
      //const rootBuilder  =  schemaForm.$children[0];

      const component = this._componentVisitor(parsedKey, schemaForm);
      console.log('Found component', component, schemaForm, key);
    }

    return {
      data: "yes"
    }
  }

  _getScope() {
    const bound = this._getRawData.bind(this);
    const scope = {model: this.model, form: this.form, parentForm: this.parentForm, rawData: bound};
    return scope;
  }

}

export default ConditionResolver;
