import VueDfIntegrationDataSourceView from './VueDfIntegrationDataSourceView'
import VueDfIntegrationDataSourceEdit from './VueDfIntegrationDataSourceEdit'

let widget = {
  type: 'VueDfIntegrationDataSource',
  title: 'Integration Data Source',
  hideTitle: true,
  description: 'Configure Integration Data Source widget',
  editComponent: 'VueDfIntegrationDataSourceEdit',
  viewComponent: 'VueDfIntegrationDataSourceView',
  category: 'Integrations',
  frameless: true,
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfIntegrationDataSourceView.name, VueDfIntegrationDataSourceView)
    Vue.component(VueDfIntegrationDataSourceEdit.name, VueDfIntegrationDataSourceEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
