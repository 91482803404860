var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled
    ? _c(
        _vm.resolveComponent(),
        {
          tag: "component",
          attrs: {
            "selected-route-config": _vm.selectedRouteConfig,
            "app-config": _vm.appConfig,
            parent: _vm.parent,
            config: _vm.resolvedConfig,
          },
          on: { selected: _vm.onSelect },
        },
        [
          _vm._l(_vm.$slots, function (_, name) {
            return _vm._t(name, null, { slot: name })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }