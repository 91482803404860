<template>
  <component v-if="enabled" :selected-route-config="selectedRouteConfig" :app-config="appConfig"
             :parent="parent" :is="resolveComponent()" :config="resolvedConfig" @selected="onSelect">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </component>
</template>

<script>
  import componentMixin from '../../componentMixin';
  export default {
    name: "VueDfAppSidebarItem",
    mixins:[componentMixin],
    props: { parent: Object},
    computed:{
      resolvedConfig: function(){
        return this.config;
      }
    },
    methods: {
      onSelect: function(selected){
        this.$emit('selected', selected);
      },
      resolveComponent: function () {
        const itemType = this.config.itemType;
        let sidebarItem = 'vue-df-app-sidebar-link-item';
        switch (itemType) {
          case 'FORM':
            sidebarItem = 'vue-df-app-sidebar-form-item';
            break;
          case 'TEMPLATE':
            sidebarItem = 'vue-df-app-sidebar-template-item';
            break;
        }
        return sidebarItem;
      }
    }
  }
</script>

<style scoped>

</style>
