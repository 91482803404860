<template>
  <div class="pl-4 pr-4">
    <v-runtime-template :template="template"></v-runtime-template>
  </div>
</template>

<script>
  import componentMixin from '../../componentMixin';
  import VRuntimeTemplate from "v-runtime-template";
  export default {
    name: "VueDfAppSidebarTemplateItem",
    mixins:[componentMixin],
    components:{ VRuntimeTemplate},
    data() {
      return {
        template: undefined
      }
    },
    computed: {
      templateSettings: function () {
        return this.config.itemTemplateSettings || {};
      }
    },
    methods: {
      onValidationResult: function (event) {
        console.log('Event in form', event);
      }
    }
  }
</script>

<style scoped>

</style>
