<template>
  <div class="property-editor">
    <schema-form :schema="schema" :form="form" v-model="data"></schema-form>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
export default {
  name: "PropertyEditor",
  props: {
    value: Object
  },
  data() {
    return {
      data: {},
      schema: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            title: 'Title'
          }
        }
      },
      form: [
        {
          key: 'title',
          labelLeft: true,
          condensed: true
        }
      ]
    }
  },
  watch:{
    data:{
      handler(newVal, oldVal){
        if (!isEqual(newVal, this.value.context)){
          this.value.context = Object.assign({}, newVal);
        }
      },
      deep: true
    },
    value:{
      handler(newVal, oldVal){
        if (newVal && newVal.context && !isEqual(newVal.context, this.data)){
          this.data = newVal.context
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
