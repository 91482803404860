import utils from '../../utils/utils'
import directives from './directives'

const componentFieldMixins = {
  props: ['value', 'fieldSchema', 'fieldForm', 'fieldMapper', 'fullModel', 'parentIndex', 'parentForm', 'parentComponent', 'schemaFormService'],
  methods: {
    getDefaultByType() {

    },
    getKey() {
      return this.fieldForm ? this.fieldForm.key : null;
    },
    resolveStringifiedKey: function (field) {
      if (!field)
        return;

      if (field.guid) {
        return field.guid;
      } else if (field.schemaFieldKey) {
        return field.schemaFieldKey;
      } else if (field.title) {
        return field.title;
      }
    },

    resolvePlaceholder: function (fieldForm) {
      return fieldForm.placeholder ? fieldForm.placeholder : ''
    },
    validateFieldValue: function (localValue) {
   //   console.log('Validate field ', localValue, this.fieldForm.required);
      if ((localValue === undefined || localValue === null) && this.fieldForm.required === undefined) {
        //this.isValid = true;
      } else {
        const validationResult = utils.validate(this.fieldForm, localValue, '');
     //   console.log('Validation result', validationResult, localValue, this.fieldForm);
        if (validationResult && !validationResult.valid) {
          //  console.log('validationResult', validationResult);
          this.validationError = validationResult;
          this.isValid = false;
        } else {
          this.validationError = undefined;
          this.isValid = true;
        }
      }
    },
    convertType: function (localValue) {
      if (localValue === undefined || localValue === null)
        return localValue;

      if (this.fieldSchema && this.fieldSchema.schema && typeof localValue !== this.fieldSchema.schema.type) {
      //  console.log('localValue type "' + (typeof localValue) + '" fieldType: "' + this.fieldSchema.schema.type + '"', localValue);
        /*if (this.fieldSchema.schema.type==='string' && typeof localValue==='object'){
          return JSON.stringify(localValue);
        }*/
        if (this.fieldSchema.schema.type === 'integer') {
          return parseInt(localValue);
        } else if (this.fieldSchema.schema.type === 'integer') {
          return Number(localValue);
        } else if (this.fieldSchema.schema.type === 'boolean') {
          return (localValue === 'true' || localValue === 'TRUE');
        }
      }
      return localValue;
    }
  },
  data() {
    return {
      localValidationError: null,
      isLocalValid: true,
      isDirty: false
    }
  },
  beforeMount() {
    let local = this.localValue;
    // console.log('Local val', local, this.fieldForm.schemaFieldKey);
    if ((local === null || local === undefined) && this.fieldForm.schema && this.fieldForm.schema.default) {
     // console.log('Local val', local, this.fieldForm.schema.default);
      const converted = this.convertType(this.fieldForm.schema.default);
      this.localValue = converted;
   //   console.log('Local val', local, this.localValue, this.fieldForm.schema.default);
    }
  },
  directives: {},
  computed: {
    validationError: {
      get() {
        return this.localValidationError;
      },
      set(val) {
        this.localValidationError = val;
      }
    },
    isValid: {
      get() {
        return this.isLocalValid;
      },
      set(val) {
        this.isLocalValid = val;
      }
    },
    localValue: {
      get() {
        let val = this.value;
        this.validateFieldValue(val);
        return val;
      },
      set(localValue) {
        this.isDirty = true;
        if (localValue === null || localValue === undefined || localValue === '')
          localValue = undefined;

        this.validateFieldValue(localValue);

        this.$emit('input', localValue);
        this.isDirty = false;
      }
    }
  }
};

if (directives && directives.length) {
  for (let i = 0; i < directives.length; i++) {
    const directive = directives[i];
    componentFieldMixins.directives[directive.name] = directive.code;
  }
}

export default componentFieldMixins
