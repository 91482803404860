var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card1 chart-panel-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "card1-body" }, [
      _c(
        "div",
        { staticClass: "card1-body-container" },
        [
          _c(
            "vertical-accordion",
            [
              _c("accordion-item", {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_vm._v("\n            Chart Editor\n          ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("div", [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: { click: _vm.toRender },
                              },
                              [_vm._v("Render")]
                            ),
                          ]),
                          _vm.renderChart
                            ? _c(
                                "div",
                                { staticClass: "pt-4 chart-container" },
                                [
                                  _c("vue-df-e-chart-wrapper", {
                                    ref: "chartWrapper",
                                    attrs: {
                                      allowedResizeDirections:
                                        _vm.allowedResizeDirections,
                                      "chart-config": _vm.chartConfig,
                                      dataset: _vm.chartDataset,
                                      dimensions: _vm.chartDimensions,
                                      group: _vm.group,
                                      height: 300,
                                      "manual-update": _vm.manualUpdate,
                                      resizable: true,
                                      theme: _vm.theme,
                                    },
                                    on: { onresize: _vm.onResize },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("pre", [
                            _vm._v(
                              "                " +
                                _vm._s(_vm.resizeData) +
                                "\n              "
                            ),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("accordion-item", {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n            Chart Definition Editor\n          "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "chart-config-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "draft-toolbar" },
                              [
                                _c("sf-save-named-content", {
                                  attrs: {
                                    "draft-content":
                                      _vm.advancedModel.chartConfig,
                                    "component-name": "pageView",
                                    "draft-namespace": "chartDesigner",
                                  },
                                  on: {
                                    ondraftselected: _vm.onDraftSelected,
                                    onremovedraft: _vm.onRemovDraft,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("schema-form", {
                              attrs: {
                                form: _vm.advancedForm,
                                schema: _vm.advancedSchema,
                              },
                              on: { validationResult: _vm.onAdvancedSchema },
                              model: {
                                value: _vm.advancedModel,
                                callback: function ($$v) {
                                  _vm.advancedModel = $$v
                                },
                                expression: "advancedModel",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "card1-footer" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card1-header" }, [
      _c("h6", { staticClass: "card1-title" }, [_vm._v("Chart")]),
      _c("small", [
        _c("p", { staticClass: "size1 text-muted card-category" }, [
          _vm._v("Chart generated from data and properties."),
        ]),
      ]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }