import componentUtils from '../utils/componentUtils'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from "lodash/groupBy";
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

const widgetComponentEditMixins = {
  props: ['model', "permissions", "dashboardServiceInstance", 'crudServiceName', 'inputContext'],
  data() {
    return {
      schemaForm: {
        schema: {},
        form: []
      }
    }
  },
  mounted() {
    //const allexistingWidgetsOnDashboard = this.dashboardServiceInstance.getAll();
  },
  computed: {
    resolvedSchema: function () {
      return this.schemaForm.schema;
    },
    resolvedForm: function () {
      return this.schemaForm.form;
    }
  },
  methods: {
    onBeforeEditOk(editDefinition){
      console.log('on close editDefinition', editDefinition);
    },
    getWidgetDefinitions: function () {
      const widgetStore = this.$widgetStore;
      console.log('getWidgetDefinitions', widgetStore);
      // get allowed from widget store by type
      const grouped = groupBy(widgetStore.widgets, 'category');
      const titleMap = [];
      for (let group in grouped) {
        const obj = {
          value: group,
          name: group,
          children: grouped[group].map(function (item) {
            return {name: item.title ? item.title : '(no title)' + '-' + item.type, value: item.type}
          })
        }
        titleMap.push(obj);
      }
      return titleMap;

    },
    getWidgetInstancesIncludingParent() {
      const widgetInstances = this.dashboardServiceInstance.getAllIncludingParent();
      // all widget instances on dashboard child and parent
      console.log('getWidgetInstancesIncludingParent', widgetInstances);
      return widgetInstances;
    },
    getAllCreatedModalWidgetInstances() {
      // get all widgets created as Modal Widgets
      console.log('getAllCreatedModalWidgetInstances', arguments);
      const that = this;
      return new Promise((resolve, reject) => {
        that.$services[that.crudServiceName].loadWidgetModalInstances({}, function (response) {
          console.log('Loaded loadWidgetModalInstances', response);
          const titleMap = [];
          if (response) {
            const grouped = groupBy(response, 'category');
            for (let group in grouped) {
              const obj = {
                value: group,
                name: group,
                children: grouped[group].map(function (item) {
                  return {
                    name: item.title ? item.title : '(no title)' + '-' + item.id,
                    value: item.id
                  }
                })
              }
              titleMap.push(obj);
            }
          }
          resolve(titleMap);
        });
      });

    },
    findParentRef(component, ref) {
      if (component.$refs && component.$refs[ref]) {
        return component.$refs[ref];
      } else {
        return this.findParentRef(component.$parent, ref);
      }
    },
    createModalWidgetByType(options, event, field, fullModel, context) {
      // create Modal widget based on selected type
      console.log('createModalWidgetByType', options, event, field, fullModel, context);
      const refModal = this.findParentRef(this, 'widgetInstanceModal');
      if (refModal) {
        const obj = {options, field, fullModel, context};
        refModal.setWidgetModalOptions(obj, 'create', function(){
          refModal.showEditModal();
        });

      }
    },
    editModalWidgetInstanceByType(options, event, field, fullModel, context) {
      console.log('editModalWidgetInstanceByType', options, event, field, fullModel, context);
      if (options && context.data) {

        const refModal = this.findParentRef(this, 'widgetInstanceModal');
        if (refModal) {
          const obj = {options, field, fullModel, context};
          refModal.setWidgetModalOptions(obj, 'edit', function(){
            refModal.showEditModal();
          });

        }
      }
    },
    deleteModalWidgetInstanceByType(options, event, field, fullModel, context) {
      console.log('deleteModalWidgetInstanceByType', options, event, field, fullModel, context);
      if (options && context.data) {
        const value = context.data[options.fieldName];
        const refModal = this.findParentRef(this, 'widgetInstanceModal');
        if (refModal) {
          const obj = {options, field, fullModel, context};
          refModal.deleteWidgetInstance(obj);
        }
      }
    },
    getWidgetsByType: function () {
      const widgetTypes = this.dashboardServiceInstance.getAllTypes();
      const titleMap = [];
      if (widgetTypes) {
        widgetTypes.forEach(function (item) {
          titleMap.push({
            name: item,
            value: item
          });
        });
      }
      return titleMap;
    },

    getWidgetsByInstance: function () {
      const allExistingWidgetsOnDashboard = this.dashboardServiceInstance.getAll();
      const titleMap = [];
      if (allExistingWidgetsOnDashboard) {
        const allAsArray = Object.keys(allExistingWidgetsOnDashboard).map(function (k) {
          return allExistingWidgetsOnDashboard[k]
        });
        const grouped = groupBy(allAsArray, 'type');
        for (let group in grouped) {
          const obj = {
            value: group,
            name: group,
            children: grouped[group].map(function (item) {
              return {name: item.title ? item.title : '(no title)' + '-' + item.id, value: item.id}
            })
          }
          titleMap.push(obj);
        }
      }
      return titleMap;
    },
    updateEditableParts(schemaFormSourceDestination) {
      if (this.model.editableSettings && this.model.editableSettings.editable && this.model.editableSettings.editableParts && this.model.editableSettings.editableParts.length > 0) {
        const tmp = [];

        this.model.editableSettings.editableParts.forEach(function (tabIndex) {
          tmp.push(schemaFormSourceDestination.form[0].tabs[tabIndex]);
        });
        schemaFormSourceDestination.form[0].tabs = tmp;
      }
      return schemaFormSourceDestination;
    },
    _recursivelyFilterEditableProperties: function (tab, properties) {
      if (!tab.items || !tab.items.length) {
        return;
      }

      if (!properties) {
        return;
      }

      // TODO: is it better to go through all properties and find it in Tabs and child???
      for (let i = 0; i < tab.items.length; i++) {
        const tabItem = tab.items[i];
        console.log('tabItem.key', tab, tabItem, tabItem.key, properties);

        if (typeof tabItem === 'string') {
          const tabItemKey = tabItem;
          const indexOf = properties.indexOf(tabItem);
          if (indexOf === -1) {
            tab.items[i] = {
              key: tabItemKey,
              condition: 'false===true'
            }
          }
        } else if (!isEmpty(tabItem.key)) {
          const indexOf = properties.indexOf(tabItem.key);

        }
      }
    },
    filterCustomEditable: function (tabs) {
      if (!this.model.isTenantCreated) {
        return;
      }
      console.log('filter custom TenantCreated editableSettings', tabs, this.model.editableSettings);
      if (!this.model.editableSettings || !tabs) {
        return;
      }
      const customEditableTabs = this.model.editableSettings.customTabs;

      for (let i = 0; i < tabs.length; i++) {
        if (!tabs[i]._id) {

          const customTab = find(customEditableTabs, {index: i});
          console.log('found custom by index: ' + i, customTab);

          if (!customTab) {
            tabs[i].condition = 'false===true';
          } else {
            // tabs[i].condition = 'true===true';
            const editableProperties = customTab.properties;
            this._recursivelyFilterEditableProperties(tabs[i], editableProperties);
          }

        }
      }
    },
    filterEditable: function (tabs) {
      console.log('filter TenantCreated editableSettings', tabs, this.model.editableSettings);
      if (!this.model.editableSettings) {
        return;
      }
      const commonEditableTabs = this.model.editableSettings.commonTabs;
      if (tabs) {
        for (let i = 0; i < tabs.length; i++) {
          if (tabs[i]._id) {
            if (!commonEditableTabs || commonEditableTabs.indexOf(tabs[i]._id) === -1) {
              tabs[i].condition = 'false===true'
            } else {
              //  tabs[i].condition = 'true===true'
            }
          }
        }
      }
    },
    getSourceDestinationSchemaForm: function () {
      const cloned = cloneDeep(componentUtils.sourceDestinationSchemaForm);
      if (this.model.isTenantCreated) {
        console.log('Filtering and preparing', this.model);
        this.filterEditable(cloned.form[0].tabs);
      }
      return cloned;
    },
    generateUUID: function () {
      let d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
      }
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
  }
};
export default widgetComponentEditMixins
