<template>
  <div :class="containerClass" class="form-container">
    <form :class="formClass" class="schema-form" novalidate ref="form" role="form"
          v-on:reset.prevent="onReset" v-on:submit.prevent="onSubmit">
      <legend v-if="schema && schema.title">{{schema.title}}</legend>
      <builder-component :field-mapper="resolveFieldMapper()" :full-model="value"
                         :is-droppable="isDroppable"
                         :schema-form-service="schemaFormService"
                         :form-bus="formBus"
                         :merged-form="mergedForm" :value="value"
                         @fullModelChanged="onFullModelChanged"
                         @internalSchemaChanged="onInternalSchemaChanged"
                         @schemaChanged="onSchemaChanged"></builder-component>
    </form>
  </div>
</template>

<script>
  import utils from '../../utils/utils'
  import BuilderComponent from './BuilderComponent'
  import droppableMixins from './droppableMixins'
  import schemaFormElements from './schemaFormElements'
  import forEach from 'lodash/forEach'
  import merge from 'lodash/merge'
  import ObjectPath from "../../utils/ObjectPath";
  import Vue from 'vue';
  import SchemaFormService from './schemaFormService'

  export default {
    name: "SchemaForm",
    components: {
      BuilderComponent
    },
    mixins: [droppableMixins],
    props: {
      containerClass: { type: String},
      formClass: { type: String},
      schema: {type: Object},
      form: {type: Array},
      value: {type: Object}
    },
    data() {
      return {
        internalValue: {},
        internalSchema: {},
        internalForm: [],
        formBus: null,
        schemaFormService: null
      }
    },
    created(){
     // console.log('Schema created', this.internalSchema, this.schema)
      this.formBus = new Vue();
      this.schemaFormService = new SchemaFormService(this);

    },
    beforeDestroy() {
      this.formBus = undefined;
    },
    mount(){
      //console.log('Schema mounted', this.internalSchema, this.schema)
    },
    watch:{
      originalSchemaForm:{
        handler(newVal, oldVal){
          if (newVal !== oldVal){
            this.internalSchema = utils.clone(this.schema);
            this.internalForm = utils.clone(this.form);
        //    console.log('originalSchemaForm changed', newVal, oldVal);
          }
        },
        deep: true,
        immediate: true
      }
    },
    computed:{
      originalSchemaForm: function(){
        return  {
          schema: this.schema,
          form: this.form
        }
      },
      mergedForm: function(){
        return this.resolveSchemaFormDft();
      }
    },
    methods: {
      onFullModelChanged: function (fullModelChanged) {
        console.log('SCHEWMA FORM ON FULL MODEL CHANGEd', fullModelChanged, this.value);
        //this.$emit('input', Object.assign({}, cloneDeep(fullModelChanged)));//this.value);
      },
      onReset: function () {
        this.$emit('input', Object.assign({}));
      },
      resolveFieldMapper: function () {
        return this.$defaultMapper;
      },
      recursivelyCleanForm: function (form) {
        const that = this;
        forEach(form, function (item) {
          if (item.type === 'fieldset' || item.type === 'tabs' || item.type === 'section' || item.type === 'array' || item.type === 'tabarray') {
            delete item.schema;
          }
          //  delete item.schema;
          //  item.key=item.schemaFieldKey;
          delete item.schemaFieldKey;
          delete item.schemaKey;

          if (Array.isArray(item.key)) {
            item.key = ObjectPath.dotted(item.key);
          }

          if (item.items) {
            that.recursivelyCleanForm(item.items);
          }
          if (item.tabs) {
            that.recursivelyCleanForm(item.tabs);
          }
        });
      },
      onInternalSchemaChanged: function (schemaArray) {
        //console.log('SchemaForm - inernalSchamaChanged - start', utils.clone(schemaArray), utils.clone(this.clonedDroppedComponents));
        let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);

        let schema = {
          type: 'object',
          properties: utils.clone(schemaAndForm.schema.properties)
        }

        let form = merge([], utils.clone(schemaAndForm.form));
        // this.recursivelyCleanForm(form);
        //console.log('SchemaForm - inernalSchamaChanged - end', utils.clone(schemaArray), utils.clone(this.clonedDroppedComponents), utils.clone(schema), utils.clone(form));

        this.$emit('internalSchemaChanged', {schema: schema, form: form});
      },
      onSchemaChanged: function (schemaArray) {

        // builder will always return Array of schema and form
        let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);

        let schema = {
          type: 'object',
          properties: utils.clone(schemaAndForm.schema.properties)
        }

        let form = merge({}, utils.clone(schemaAndForm.form));
        this.$emit('schemaChanged', {schema: schema, form: form});
      },
      resolveSchemaFormDft: function () {
        let start = performance.now();
        let schema = this.internalSchema; //utils.clone(this.schema);
        let form = this.internalForm;//utils.clone(this.form);
        if (this.isDroppable){
          // TODO - maybe here we shold clone real schema again???
        }
      //  console.log('Running schema resolveSchemaFormDft');

        if (schema && Object.keys(schema).length) {
          const merged = utils.mergeForm(schema, form, {}, {});
          //console.log('Cloning schema and form in: ' + (performance.now() - start) + ' ms', schema, merged, utils.clone(this.value), utils.clone(this.internalValue));
          return merged;
        }
      },
      validateForm: function(){
        const copy = JSON.parse(JSON.stringify(this.value));
        const isFormValid = utils.validateMultipleBySchema(this.schema, copy);
        return isFormValid;

      },
      onSubmit: function (event) {
      //  const copy = JSON.parse(JSON.stringify(this.value));

        const isFormValid = this.validateForm(); //utils.validateMultipleBySchema(this.schema, copy);
      //  console.log('OnSubmit form', event, isFormValid);
        if (event.target.checkValidity() === false || !isFormValid.valid) {
      //    console.log('Preventing onSubmit');
          event.preventDefault();
          event.stopPropagation();

          this.$emit('validationResult', isFormValid);

          return false
        }
        //console.log('Validation result', isFormValid);
        this.$emit('validationResult', isFormValid);
        return true;
      }
    }
  }
</script>

<style scoped>

</style>
