import merge from 'lodash/merge'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import vueUtils from './vueUtils'
import lastIndexOf from 'lodash/lastIndexOf'
/*import indexOf from 'lodash/indexOf'*/
import utils from './utils'
import ObjectPath from './ObjectPath'
import schemaFormElements from '../components/schemaForm/schemaFormElements'

const mergeInComponentUtils = {
  updateChildPropertiesKeyRecursively: function (form) {
    const that = this;
    forEach(form, function (item, index) {

      if (item.key && Array.isArray(item.key)) {
        // mergeInUtils.updateChildPropertiesRecursively
        const newKey = ObjectPath.dotted(item.key);
        item.key = newKey;

      }
      if (item.items && item.items.length > 0) {
        that.updateChildPropertiesKeyRecursively(item.items);
      }
    });
  },
  prepareMergedForm: function (schema, form) {
    let schemaCopy = utils.clone(schema);
    let formCopy = utils.clone(form);

    if (schemaCopy && Object.keys(schemaCopy).length) {
      const merged = utils.mergeForm(schemaCopy, formCopy, {}, {});
      return merged;
    }
  },

  __resolveLeafKey1: function (item, parent) {
    if (!item.key)
      return;

    let itemInFormKey = item.key;
    if (Array.isArray(itemInFormKey)) {
      return itemInFormKey[itemInFormKey.length - 1];
    }

    if (parent) {
      switch (parent.type) {
        case 'fieldset':
        case 'section':
          itemInFormKey = itemInFormKey.substring(lastIndexOf(itemInFormKey, '.') + 1);
          break;
        case 'array':
          itemInFormKey = itemInFormKey.substring(lastIndexOf(itemInFormKey, '.') + 1);
          break;
      }
    }
    return itemInFormKey;
  },
  __resolveFullKey1: function (item, parent) {
    console.log('__resolveFullKey', item, parent);
    let itemSchemaFieldKey = item.schemaFieldKey ? item.schemaFieldKey : item.key;
    if (itemSchemaFieldKey && parent && parent.key) {
      switch (parent.type) {
        case 'fieldset':
        case 'section':
          itemSchemaFieldKey = parent.key + '.' + itemSchemaFieldKey;
          break;
        case 'array':
        case 'tabarray':
          itemSchemaFieldKey = parent.key + '[].' + itemSchemaFieldKey;
          break;
      }
    }

    return itemSchemaFieldKey;
  },
  resolveParentKey(parent) {
    if (parent) {
      let key = parent.key;
      switch (parent.type) {
        case 'tabarray':
        case 'array':
          key += ('[].');
          break;
        case 'fieldset':
          key += '.';
          break;
      }
      return key;
    }
  },
  updateItem(from, to) {
    for (const p in to) {
      delete to[p];
    }

    for (const p in from) {
      to[p] = from[p];
    }
  },
  updateItemsRecursively(items, newItemKey, previousItemKey) {
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.key) {
          let tmpKey = item.key;
          if (Array.isArray(item.key)) {
            tmpKey = ObjectPath.dotted(item.key);
          }
          const tmp = tmpKey.split(previousItemKey).join(newItemKey);
          item.key = tmp;
        } else {
          if (item.items) {
            this.updateItemsRecursively(item.items, newItemKey, previousItemKey);
          } else if (item.tabs) {
            this.updateItemsRecursively(item.tabs, newItemKey, previousItemKey);
          }
        }
      }
    }
  },
  updateChildItemsRecursively(item, previousItemKey) {
    if (item.items) {
      this.updateItemsRecursively(item.items, item.key, previousItemKey);
    } else if (item.tabs) {
      this.updateItemsRecursively(item.tabs, item.key, previousItemKey);
    }
  },
  findByGuid(items, item, parent) {
    const that = this;
    for (let i = 0; i < items.length; i++) {
      let itemInForm = items[i];
      if (item.guid === itemInForm.guid) {
        const previousItemKey = utils.clone(itemInForm).key;
        // console.log('Before update - Found item, existing, newUpdated, parentKey', utils.clone(itemInForm), utils.clone(item), utils.clone(parent));
        that.updateItem(item, itemInForm);
        const parentKey = that.resolveParentKey(parent);
        if (item.type !== 'section' && item.type !== 'tabs') {
          itemInForm.key = parentKey ? (parentKey + item.key) : item.key;
        }
        //  console.log('After update - Found item, existing, newUpdated, parentKey', utils.clone(itemInForm), utils.clone(item), utils.clone(parent));
        that.updateChildItemsRecursively(itemInForm, previousItemKey);
        //   console.log('After update child items - Found item, existing, newUpdated, parentKey', utils.clone(itemInForm), utils.clone(item), utils.clone(parent));
      } else {
        if (itemInForm.items) {
          that.findByGuid(itemInForm.items, item, itemInForm.key ? {
            type: itemInForm.type,
            key: itemInForm.key
          } : parent)
        } else if (itemInForm.tabs) {
          that.findByGuid(itemInForm.tabs, item, itemInForm.key ? {
            type: itemInForm.type,
            key: itemInForm.key
          } : parent);
        }
      }
    }
  },
  updateSchemaFormRecursively(item, schema, form) {
    const that = this;

    this.findByGuid(form, item);
    const start = performance.now();

    const newSchema = this.recreateSchema(schema, form, item);

    console.log('newSchemaRecreated in: ' + (performance.now() - start) + ' ms', newSchema);

    return {schema: newSchema, form: form};
  },
  recreateSchema(schema, form, item) {
    const newSchema = {
      type: 'object', properties: {}
    }
    console.log('Called from recreateSchema', utils.clone(schema), utils.clone(form), utils.clone(item));
    this.updateSchemaRecursively(newSchema, form, schema, item);
    return newSchema;
  },
  resolveLeafKey(item) {
    if (item.key) {
      let tmp = item.key;
      if (!Array.isArray(item.key)) {
        tmp = ObjectPath.parse(item.key);
      }
      return tmp[tmp.length - 1];
    }
  },
  resolveCurrentSchemaFormItem(schema, item){
    if(!schema || !item || !item.key)
      return undefined;

    const key = item.key;
    if (key.indexOf('.')===-1){
      return this.resolveCurrentSchemaForKey(schema,key);
    }else{
      const splitted = key.split('.');
      if (splitted){
        let currentItemSchema = schema;
        let counter = -1;
       // let tmp = {};
        while(++counter<=splitted.length-1){
          let currentKey = splitted[counter];
          if (currentKey.indexOf('[')>-1) {
            currentKey = currentKey.substring(0, currentKey.indexOf('['));
          }
          currentItemSchema = this.resolveCurrentSchemaForKey(currentItemSchema, currentKey);
          //tmp[currentKey]=currentItemSchema;
          if(!currentItemSchema){
            return undefined;
          }
        }
        return currentItemSchema;
      }
    }
  },
  resolveCurrentSchemaForKey(schema, key) {
    if (!schema || !key)
      return undefined;
    if (schema.properties) {
      return schema.properties[key];
    } else if (schema.items && schema.items.properties && schema.items.properties[key]) {
      return schema.items.properties[key];
    }else if (schema.items && schema.items[key]){
      return schema.items[key];
    }
  },
  updateTabSchemaRecursively(schema, tabs, existingSchema, updatedItem) {
    console.log('Updating updateTabSchemaRecursively', schema, tabs, existingSchema, updatedItem);

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];

      const tmpSchema = {//schema? Object.assign({}, utils.clone(schema)) : {
        type: 'object',
        properties: {}
      }
      //const tmpKey = this.resolveLeafKey(tabs);
      //const currentExistingSchema = this.resolveCurrentSchemaForKey(existingSchema, tmpKey);
      if (tab.items && tab.items.length > 0) {
        this.updateSchemaRecursively(tmpSchema, tab.items, existingSchema, updatedItem);
        for (const p in tmpSchema.properties) {
          schema.properties[p] = tmpSchema.properties[p];
        }
      }
    }
  },
  updateSchemaRecursively(schema, items, existingSchema, updatedItem) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      //  // utils.clone(schema) || {

    //  console.log('updating schema recursively after item update from config Applied', utils.clone(item), utils.clone(schema));

      const tmpSchema = {//schema? Object.assign({}, utils.clone(schema)) : {
        type: 'object',
        properties: {}
      }
      const tmpKey = this.resolveLeafKey(item);
      if (!schema.required) {
        schema.required = [];
      }

      const currentExistingSchema = this.resolveCurrentSchemaFormItem(existingSchema, item);//resolveCurrentSchemaForKey(existingSchema, tmpKey);
      switch (item.type) {
        case 'tabarray':
        case 'array':
          this.updateSchemaRecursively(tmpSchema, item.items, existingSchema, updatedItem);
          if (item.schema) {
            schema.properties[tmpKey] = {};
            for (const p in item.schema) {
              schema.properties[tmpKey][p] = item.schema[p];
            }
            if (!isEmpty(tmpSchema.properties))
              schema.properties[tmpKey].items = tmpSchema;
          } else {
            if (currentExistingSchema){
              currentExistingSchema.items = tmpSchema;
              schema.properties[tmpKey] = currentExistingSchema;
            }else{
              schema.properties[tmpKey] = {
                type: 'array',
                items: tmpSchema
              }
            }
          }
          break;
        case 'fieldset':
          this.updateSchemaRecursively(tmpSchema, item.items, existingSchema, updatedItem);// currentExistingSchema
          if (item.schema) {
            schema.properties[tmpKey] = {};
            for (const p in item.schema) {
              schema.properties[tmpKey][p] = item.schema[p];
            }
            if (!isEmpty(tmpSchema.properties))
              schema.properties[tmpKey].properties = tmpSchema.properties;
          } else {
            schema.properties[tmpKey] = tmpSchema;
          }
          break;
        case 'tabs':
          this.updateTabSchemaRecursively(tmpSchema, item.tabs, existingSchema, updatedItem);
          for (const p in tmpSchema.properties) {
            schema.properties[p] = tmpSchema.properties[p];
          }
          for(const p in tmpSchema){
            if (p!=='properties'){
              schema[p]=tmpSchema[p];
            }
          }
          if (tmpSchema.required && tmpSchema.required.length){
            tmpSchema.required.forEach(function(requiredItemKey){
              if (schema.required.indexOf(requiredItemKey)===-1){
                schema.required.push(requiredItemKey);
              }
            });
          }
          break;
        case 'section':
          this.updateSchemaRecursively(tmpSchema, item.items, existingSchema, updatedItem);
          for (const p in tmpSchema.properties) {
            schema.properties[p] = tmpSchema.properties[p];
          }
          if (tmpSchema.required && tmpSchema.required.length){
            tmpSchema.required.forEach(function(requiredItemKey){
              if (schema.required.indexOf(requiredItemKey)===-1){
                schema.required.push(requiredItemKey);
              }
            });
          }
          break;
        case 'tags':
          schema.properties[tmpKey] = this.recreateItemSchema(item, item.schemaFieldKey === tmpKey ? null : currentExistingSchema);
          break;
        case 'actions':
        case 'submit':
        case 'reset':
        case 'button':
          console.log('dont recreate for type: '+item.type, item);
          break;
        default:
          if(item.type==='help' && tmpKey===undefined){
            console.log('Help item', item, tmpKey, tmpSchema);
          }else {
            if (tmpKey) {
              schema.properties[tmpKey] = this.recreateItemSchema(item, item.schemaFieldKey === tmpKey ? null : currentExistingSchema);
            } else if (item.items) { // this is Tab case but there is no type==='tab'
              console.log('THERE IS NO KEY BUT THERE ARE ITEMS', utils.clone(item));
            }

            const itemRequired = item.schema && item.schema.required || item.required;
            if (itemRequired && item.key) {
              let simpleKey = item.key;
              if (item.key.indexOf('.') > -1) {
                const tmp = item.key.split('.');
                simpleKey = tmp[tmp.length - 1];
              }

              if (schema.required.indexOf(simpleKey) === -1) {
                schema.required.push(simpleKey);
              }
            }
          }
          break;
      }
    }
  },
  recreateItemSchema(item, currentExistingSchema) {
    if (currentExistingSchema)
      return utils.clone(currentExistingSchema);

    if (item.schema)
      return item.schema;

    const tmpSchema = {};
    const moveKeys = ['description', 'title', 'maxlength', 'minlength', 'readonly', 'hidden', 'minimum', 'maximum', 'default', 'pattern', 'validationMessage', 'enum']

    tmpSchema.key = this.resolveLeafKey(item);
    tmpSchema.type = schemaFormElements.elements[item.type].schema.type;

    for (const p in item) {
      if (moveKeys.indexOf(p) > -1) {
        tmpSchema[p] = item[p]
      }
    }
    item.schema = tmpSchema;
    return tmpSchema;

  },
  updateSchemaForm1: function (item, schema, form, parent) {

    const that = this;
    let found = false;
    for (let i = 0; i < form.length; i++) {
      let itemInForm = form[i];
      if (item.guid === itemInForm.guid) {
        found = true;

        let itemInFormKey = that.__resolveLeafKey1(itemInForm, parent);

        if (schema && itemInFormKey) { // schema is not defined for Section, Tabs, Actions
          for (let prop in schema.properties) {
            if (prop === itemInFormKey) {
              const currProperty = schema.properties[itemInFormKey];
              delete schema.properties[itemInFormKey];

              // let itemSchemaFieldKey = that.__resolveFullKey(item, parent);
              schema.properties[item.schemaFieldKey] = merge({}, currProperty, item.schema);
            }
          }
        }

        let items = omit(item, ['key', 'schema', 'schemaFieldKey', 'schemaKey']);

        itemInForm = merge({}, itemInForm, items)

        const key = that.__resolveFullKey1(item, parent);
        console.log('Resolved full key: ', key, item, parent, itemInForm);
        if (key)
          itemInForm.key = key;

        form[i] = itemInForm;
      }
    }
    if (!found) {
      console.log('Not found item in form', item, form);
      for (let i = 0; i < form.length; i++) {
        let itemInForm = form[i];
        if (itemInForm.items) {
          if (itemInForm.key && schema && schema.properties)
            that.updateSchemaForm1(item, schema.properties[itemInForm.key], itemInForm.items, itemInForm);
          else
            that.updateSchemaForm1(item, schema, itemInForm.items, itemInForm);
        }
      }
    }
    return {schema, form};
  }

  ,
  updateSchemaForm_old: function (item, schema, form) {
    const that = this;
    // we can use _.get to get object from path and _.set to set value into object
    forEach(form, function (itemInForm) {
      if (itemInForm.guid == item.guid) {
        const itemInformKey = itemInForm.key;
        // this is form

        forEach(schema.properties, function (value, key) {
          if (key === itemInformKey) {
            // this is schemaProperty
            value = vueUtils.vueMerge(value, item.schema);
          }
        });

        itemInForm = vueUtils.vueMerge(itemInForm, omit(item, ['key', 'schema', 'schemaFieldKey', 'schemaKey']))

      } else {
        // try to find inside items or tabs, depending of the type
        if (itemInForm.items) {
          //  that.updateSchemaForm(item, )
        }
      }
    });

    return {schema, form};
  }
}

export default mergeInComponentUtils;
