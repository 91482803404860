var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dashboardEditMode || _vm.widgetSettings.widgetVisible
    ? _c(
        "div",
        { style: _vm.computedStyle, attrs: { id: "leaflet-container" } },
        [
          _c(
            "l-map",
            {
              ref: "leafletMap",
              attrs: {
                zoom: _vm.geoModel.commons.zoom,
                center: _vm.geoModel.commons.center,
              },
            },
            [
              _c("l-tile-layer", { attrs: { url: _vm.url } }),
              _vm._l(_vm.geoModel.polyLines, function (polyline) {
                return _vm.linesReady
                  ? _c("l-polyline", {
                      key: polyline.name,
                      attrs: {
                        "lat-lngs": polyline.lines,
                        color: polyline.color,
                      },
                    })
                  : _vm._e()
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }