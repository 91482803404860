<template>
  <div>
    <v-runtime-template :template="template"></v-runtime-template>
  </div>
</template>

<script>
  import VRuntimeTemplate from "v-runtime-template";
  import componentMixin from './componentMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'
  import titleMapMixins from "./titleMapMixins";

  export default {
    name: "SfTemplate",
    components: {VRuntimeTemplate},
    mixins: [componentMixin, simpleComponentMergeInMixin],
    data() {
      return {
        template: '<div></div>'
      }
    },
    created() {
      if (this.fieldForm.template) {
        this.template = this.fieldForm.template;
      }
    },
    async mounted() {
      const that = this;
      this.$nextTick(function () {
        that.selectDefault();
      })
    },
    methods:{
      selectDefault: function () {

      }
    }
  }
</script>

<style scoped>

</style>
