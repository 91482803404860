var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "dashboardContainer", staticClass: "dashboards" },
    [
      _c("vue-element-loading", {
        attrs: {
          active: _vm.isLoading,
          "can-cancel": false,
          "is-full-page": "false",
        },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "div",
        { class: _vm.dashboardsContainerClass },
        [
          _vm.navigationPosition === "top" ? _vm._t("navigation") : _vm._e(),
          _c(
            "div",
            { class: [_vm.dashboardsContentClass, "dashboards-content"] },
            [
              _vm.selectedDashboard
                ? [
                    _vm.guid
                      ? _c("vue-df-dashboard", {
                          attrs: {
                            categories: _vm.selectedDashboard.categories,
                            "continuous-edit-mode":
                              _vm.selectedDashboard.continuousEditMode,
                            "crud-service-name": _vm.crudServiceName,
                            "dashboard-name": _vm.dashboardName,
                            dashid: _vm.selectedDashboard.dashid,
                            permissions: _vm.permissions,
                            "default-mapper": _vm.defaultMapper,
                            "edit-mode": _vm.selectedDashboard.editMode,
                            "include-widgets": _vm.includeWidgets,
                            "input-context": _vm.inputContext,
                            model: _vm.selectedDashboard,
                            options: _vm.options,
                            structures: _vm.structures,
                            "use-provided-model": _vm.useProvidedModel,
                            "parent-widget-id": _vm.parentWidgetId,
                            "parent-dashid": _vm.parentDashid,
                            "dashboard-service-instance":
                              _vm.dashboardServiceInstance,
                          },
                          on: {
                            dashboardchanged: function ($event) {
                              return _vm.dashboardChanged($event)
                            },
                            toggleSavedDashboard: function ($event) {
                              return _vm.onSaveDashboard($event)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              !_vm.model || !_vm.model.length
                ? _c("div", [
                    _c("div", { staticClass: "text-center text-muted" }, [
                      _vm._v(
                        "There is no Dashboards. Please add new Dashboard."
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm.navigationPosition === "bottom" ? _vm._t("navigation") : _vm._e(),
          _c(
            "b-modal",
            {
              ref: "uploadModal",
              attrs: { title: "Upload Dashboard Definition", lazy: "" },
              on: { ok: _vm.onUploadInput },
            },
            [
              _c("b-form-file", {
                ref: "file-upload",
                staticClass: "mb-2",
                model: {
                  value: _vm.uploadedFile,
                  callback: function ($$v) {
                    _vm.uploadedFile = $$v
                  },
                  expression: "uploadedFile",
                },
              }),
              _c(
                "b-form-checkbox",
                {
                  model: {
                    value: _vm.shouldMergeNewUpload,
                    callback: function ($$v) {
                      _vm.shouldMergeNewUpload = $$v
                    },
                    expression: "shouldMergeNewUpload",
                  },
                },
                [_vm._v("Should merge in")]
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "shareDashboard",
              attrs: { title: "Share Dashboard Link", lazy: "", size: "lg" },
              on: { ok: _vm.onShareDashboardLink, shown: _vm.focusMyElement },
            },
            [
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("b-form-input", {
                    ref: "inputShareableLink",
                    model: {
                      value: _vm.sharedDashboardLink,
                      callback: function ($$v) {
                        _vm.sharedDashboardLink = $$v
                      },
                      expression: "sharedDashboardLink",
                    },
                  }),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard",
                            value: () => {
                              _vm.$refs.inputShareableLink.setSelectionRange(
                                0,
                                _vm.sharedDashboardLink.length
                              )
                              return _vm.sharedDashboardLink
                            },
                            expression:
                              "() => {$refs.inputShareableLink.setSelectionRange(0, sharedDashboardLink.length); return sharedDashboardLink; }",
                          },
                        ],
                        staticClass: "btn btn-outline-secondary",
                        attrs: { type: "button" },
                      },
                      [_vm._v("\n            Copy!\n          ")]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }