<template>
  <div class="integration-template" v-if="template">
    <v-runtime-template :template="template"></v-runtime-template>
  </div>
</template>

<script>
  import commonIntegrationMixin from "./commonIntegrationMixin";
  import VRuntimeTemplate from "v-runtime-template";
  import jsnotevil from "jsnotevil";


  export default {
    name: "VueDfIntegrationTemplateComponent",
    components: {VRuntimeTemplate},
    mixins: [commonIntegrationMixin],
    data() {
      return {
        template: undefined,
        jsApi: undefined,
        data: undefined
      }
    },
    watch: {
      uiDefinition: {
        handler(newVal, oldValue) {
          this.initTemplates(newVal);
        },
        deep: true,
        immediate: true
      },
      dataModel: {
        handler(newVal, oldValue) {
          this.data = newVal;
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      loader(component) {
        return () => import(`${component}`);
      },
      initTemplates(newVal) {
        const that = this;
        this.initJavascript(newVal, function () {
          that.template = newVal.templateDefinition;
        });
      },
      initJavascript(newVal, next) {
        const tmpScript = newVal.templateScript;
        if (tmpScript) {
          this.handleScript(tmpScript, next);
        } else {
          next();
        }
      },
      handleScript(script, next) {
        const that = this;
        const contextSafe = {}
        this.jsApi = this.compileFunctions(script, contextSafe);
        console.log('jsApi', this.jsApi);
        next();
      },
      compileFunctions: function (funcStr, context) {
        if (funcStr) {
          const func = jsnotevil.Function('context', funcStr);
          const objApi = func(context);
          if (objApi) {
            const tmpObjApi = {};
            for (let fnName in objApi) {
              const fn = objApi[fnName];
              fn.bind(context);
              tmpObjApi[fnName] = function (event) {
                return fn(context, event);
              }
            }
            return tmpObjApi;
          }
          return objApi;
        } else {
          return {}
        }
      }
    }
  }
</script>

<style scoped>

</style>
