<template>
  <div>

    <b-tabs>
      <b-tab title="DataSources">
        <data-source-designer></data-source-designer>
      </b-tab>
      <b-tab title="Chart Designer">
        <chart-designer></chart-designer>
      </b-tab>
      <b-tab title="Visual Designer">
        <visual-designer-editor></visual-designer-editor>
      </b-tab>
      <b-tab title="Accordion Designer">
        <button @click="++count">Render</button>
        <vertical-accordion :key="count">
          <accordion-item>
            <template v-slot:header>
              <span>Query Definition List</span>
            </template>
            <template v-slot>
              Klipfolio uses pre-created DataSources for each SourceType (Facebook, Twitter) - they uses direct HTTPs requests (not libraries) - and each DataSource has it's visualisation
            </template>
          </accordion-item>
          <accordion-item visible>
            <template v-slot:header>
              <span>Data Loader</span>
            </template>
            <template v-slot>
              Some accordion text 2
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:header>
              <span>Post Processor</span>
            </template>
            <template v-slot>
              Some accordion text 3
            </template>
          </accordion-item>
        </vertical-accordion>
      </b-tab>
      <b-tab title="Expression Designer">
        <expression-designer></expression-designer>
      </b-tab>
    </b-tabs>
    <h3>Explanation</h3>
    <p>
      Data source designer:<br />
      - <strong>Select a Query</strong> - Query should be already defined <br />
      &nbsp;  - Option to Load Data (execute Query - e.g. if Query already defined it will execute Post Processor) <br />
      - There could be one or more queries for the Data source <br />
      &nbsp;  - If Data Source combines multiple queries from different SourceTypes it will be executed each separately (for loading experience) <br />
      &nbsp;  - Loaded data can be Tabular or Hierarchical (find appropriate Component to show the data) <br />
      - From Loaded data prepare <strong>Post Processor</strong> (expression language as for Query API Post Processor) <br />
      &nbsp;  - Loaded data can be Tabular or Hierarchical (find appropriate Component to show the data) <br />
      - <strong>Create Visualisation</strong>
      &nbsp;  - Choose appropriate <strong>Visualisation Type</strong> or <br />
      &nbsp;      - Visualisation Types should be already defined - each Visualisation Type define it's own Interface - e.g. template, configurable series, xAxis, yAxis and other properties <br />
      &nbsp;  - Create Visualisation in <strong>Advanced Mode</strong> as Javascript (as currently in Query definition) <br />
      - There could be multiple <strong>Visualisation Types</strong> defined for this DataSource so User can choose when configuring the Widget and Dashboard <br />
      - <strong>Filters</strong> <br />
      &nbsp;  - Filter can be: <br />
      &nbsp;&nbsp;  - in Dashboard context (global to dashboard) <br />
      &nbsp;&nbsp;  - in Widget context <br />
      &nbsp;  - User should be able to choose from "allowed" filters to be shown on Dashboards <br />
    </p>
  </div>
</template>

<script>
  import ExpressionDesigner from "./expressionDesigner/ExpressionDesigner";
  const AccordionItem = ()=>import("./accordion/AccordionItem")
  const VerticalAccordion =()=>import( './accordion/VerticalAccordion');
  import {BModal, BTabs, BTab} from 'bootstrap-vue'
  const VisualDesignerEditor = ()=>import( "./visualDesigner/VisualDesignerEditor")
  const DataSourceDesigner = ()=>import('./dataSourceDesigner/DataSourceDesigner')
  const ChartDesigner = () => import('./chartDesigner/ChartDesigner')
  export default {
    name: "VisualisationDesigner",
    components: {
      ExpressionDesigner,
      VisualDesignerEditor,
      AccordionItem, BModal, BTabs, BTab, ChartDesigner, DataSourceDesigner, VerticalAccordion},
    data(){
      return {
        count: 0
      }
    }
  }
</script>

<style scoped>

</style>
