import VueDfDashboardView from './VueDfDashboardView'
import VueDfDashboardEdit from './VueDfDashboardEdit'

let widget = {
  type: 'VueDfDashboard',
  title: 'Dashboard',
  description: 'Displays additional dashboard',
  category: 'Data',
  editComponent: 'VueDfDashboardEdit',
  viewComponent: 'VueDfDashboardView',
  dashboards:[],
  editableSettings:{
    editable: true
  },
  data:{},
  config: {
    data:{}
  }
}

const vueDfPlugin = {
  install(Vue, options) {
    Vue.component(VueDfDashboardView.name, VueDfDashboardView)
    Vue.component(VueDfDashboardEdit.name, VueDfDashboardEdit)

    Vue.widgetStore.widget(widget);
  }
}
export default vueDfPlugin
