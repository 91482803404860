<template>
  <div class="chart-builder-context-container  p-2 mt-2 mr-1">
    <strong>Config</strong>
    <hr />
    <component v-if="currentComponentEditor && currentConfig" :is="currentComponentEditor" :value="currentConfig" />
  </div>

</template>

<script>
import BuilderHeaderEditor from "./context/BuilderHeaderEditor";
import ChartConfigEditor from "./context/ChartConfigEditor";
import ChartTypeEditor from "./context/ChartTypeEditor";
import PropertyEditor from "./context/PropertyEditor";

export default {
  name: "ChartBuilderContext",
  components:{BuilderHeaderEditor, ChartConfigEditor, ChartTypeEditor, PropertyEditor},
  props: {
    value: Object
  },
  data(){
    return {
      currentConfig: null,
      currentComponentEditor: null
    }
  },
  watch:{
    value: {
      handler(newVal, oldVal){
        if(newVal!==oldVal){
          this.updateConfig(newVal);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods:{
    updateConfig(newVal){
    //  console.log('Config builder Context updated', newVal);
      this.currentConfig = newVal;
      if ((newVal!==undefined || newVal!==null) && newVal.type){
        let type = newVal.type[0].toUpperCase()+newVal.type.substring(1);
        this.currentComponentEditor = type+'Editor';
      }
    }/*,
    onUpdateConfig(updateConfig){
      console.log('Updated config', updateConfig);
    }*/
  }
}
</script>

<style scoped>

</style>
