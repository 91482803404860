<template>
  <div class="help-container">
    <label class="control-label" v-bind:class="fieldForm.labelHtmlClass"
           v-if="shouldShowTitle">{{ fieldForm.title }}</label>
    <div :class="fieldForm.fieldHtmlClass" :style="fieldForm.style" class="helpvalue schema-form-helpvalue small"
         v-html="helpValue"></div>
  </div>
</template>

<script>
import componentMixin from './componentMixins'
import simpleComponentMergeInMixin from './simpleComponentMerginMixin'

export default {
  name: "SfHelp",
  mixins: [componentMixin, simpleComponentMergeInMixin],
  computed: {
    helpValue: function () {
      return this.fieldForm.helpvalue || this.fieldForm.description || this.value;
    },
    shouldShowTitle: function () {
      if (this.fieldForm.notitle) {
        return !this.fieldForm.notitle;
      }
      return true;
    }
  }
}
</script>

<style scoped>

</style>
