<template>
    <component-base v-bind:field-form="fieldForm">
      <input v-if="fieldForm.isLazy" v-bind:placeholder="resolvePlaceholder(fieldForm)" class="form-control"
             :required="fieldForm.required" :type="resolveType()" :class="[isValid ? '': 'is-invalid']"
             v-model.lazy="localValue" :readonly="fieldForm.readonly">

      <input v-if="!fieldForm.isLazy" v-bind:placeholder="resolvePlaceholder(fieldForm)" class="form-control"
             :required="fieldForm.required" :type="resolveType()" :class="[isValid ? '': 'is-invalid']"
             v-model="localValue" :readonly="fieldForm.readonly">
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
    </component-base>

</template>

<script>
  import componentMixin from './componentMixins'
  import droppableMixins from './droppableMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'

  export default {
    name: "SfText",
    mixins: [componentMixin, droppableMixins, simpleComponentMergeInMixin],
    methods: {
      resolveType: function(){
        return this.fieldForm.type?this.fieldForm.type:"text";
      }
    }
  }
</script>

<style scoped>

</style>
