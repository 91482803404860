const vueDfMixin = {
  data(){
    return {
      guid: null,
      dashboardsModel: [],
      selectedDashboardIndex: 0,
      selectedDashboard: undefined
    }
  },
  created: function(){
    const that = this;
    if (this.model) {
      this.dashboardsModel = this.model;
    }
  //  console.log('Created this.dashboardName', this.dashboardName, this.dashboardsModel)
    this.$dashboardBus.$on('DASHBOARD_LOADED::'+this.dashboardName, that.selectCurrentDashboard);
  },
  beforeDestroy(){
    const that = this;
 //   console.log('Destroyed this.dashboardName', this.dashboardName, this.dashboardsModel)
    this.$dashboardBus.$off('DASHBOARD_LOADED::'+this.dashboardName, that.selectCurrentDashboard);
  },
  methods: {
    selectCurrentDashboard: function(){
      this.selectDashboard(this.selectedDashboardIndex);
    },
    selectDashboard: function (index) {
    //  console.log('Start selecting dashboard: '+index);
      this.guid = false;
      const that = this;
      this.$nextTick(function () {
        if (that.dashboardsModel) {
          that.selectedDashboardIndex = index;
          that.selectedDashboard = that.dashboardsModel[index];
          that.guid = true;
        //  console.log('End selecting dashboard: ' + index);
        }
        if (this.selectedDashboardIndex===index){
         // console.log('selecting dashboard same index', this.id, this.selectedDashboardIndex, index, this.dashboardServiceInstance, this.selectedDashboard);
        }
      })
    }
  }
}

export default vueDfMixin
