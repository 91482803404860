import vueUtils from '../../utils/vueUtils'
import schemaFormElements from './schemaFormElements'
import forEach from 'lodash/forEach'
import ObjectPath from "../../utils/ObjectPath";

const containerMixin = {
  methods: {
    onFullModelChanged: function (changedModel) {
      console.log('fullModelChanged emit field: '+this.fieldForm.schemaFieldKey, this.fieldForm);
      this.$emit('fullModelChanged', changedModel);
    },
    updateChildKeysRecursively: function (parent, items, connector) {
      const that = this;
      forEach(items, function (item) {
       // console.log('Before New Key - existing: '+item.key, item, parent, connector);

        let newKey = item.key;
        connector = parent.type==='array' || parent.type==='tabarray'?'[].':'.';

        if (Array.isArray(item.key)) {
          newKey = parent.key + connector + item.key[item.key.length - 1];
        } else {
          newKey = parent.key + (item.schemaFieldKey ? connector + item.schemaFieldKey : ''); //newKey.substring(newKey.indexOf(parent.key))
        }
        let parentType = parent.type;
        if (item.key) {
          item.key = newKey;
          parentType = item.type;
        }
      //  console.log('New Key: '+item.key, item, parent, connector);

        if (item.items && item.items.length > 0) {
          that.updateChildKeysRecursively({key: newKey, type: parentType}, item.items, connector);
        } else if (item.tabs && item.tabs.length > 0) {
          that.updateChildKeysRecursively({key: newKey, type: parentType}, item.tabs, connector);
        }
      });
    },
    mergeInContainerLeafItem: function (item, connector) {

      if (this.fieldForm.guid === item.guid) {
        console.log('Received in Container item', item, this.fieldForm);

      /*  this.fieldForm.title = this.fieldForm.schema.title;//|| this.fieldForm.title;
        this.fieldForm.description = this.fieldForm.schema.description;*/

        if (item.schema.title)
          this.fieldForm.title = item.schema.title;

        if (item.schema.description)
          this.fieldForm.description = item.schema.description;

        for (const p in item) {
          if (p !== 'items') {
            vueUtils.vueSet(this.fieldForm, p, item[p]);
          }
        }

        this.updateChildKeysRecursively(this.fieldForm, this.fieldForm.items, connector);

        console.log('Received in Container item - After Merge', item, this.fieldForm);

        this.$emit('internalSchemaChanged', {schemaFormComponent: this.fieldForm});
      }
    },
    updateFirstItemsKeyRecursively: function (fieldForm, schemaArray, connector) {

      if (!schemaArray || schemaArray.length === 0) {
        const schema = {
          type: 'object',
          properties: {}
        }
        return {schema: schema, form: []};
      }

      let schemaAndForm = schemaFormElements.mergeSchemaFormBuilderArray(schemaArray);
      let builderFormArray = schemaAndForm.form;
      let builderSchema = schemaAndForm.schema;

      const that = this;
      let childSchemaAndForm = null;
      let tmpSchema = {
        type: 'object',
        properties: {}
      };
      let hasProperty = false;
      forEach(builderFormArray, function (formItem) {
        let parentToItemsAndTabs = fieldForm;
        if (formItem.key) {

          let fieldFormKey = fieldForm.key;
          if (Array.isArray(fieldForm.key)){
            fieldFormKey = ObjectPath.dotted(fieldForm.key);
          }
          if (!formItem.key.startsWith(fieldFormKey)) {
            connector = fieldForm.type==='array' || fieldForm.type==='tabarray'?'[].':'.';
            formItem.key = fieldFormKey + connector + formItem.key;
          }
          hasProperty = true;
          tmpSchema.properties[formItem.schemaFieldKey] = formItem.schema;

          parentToItemsAndTabs = formItem;
        }

        if (formItem.key==='undefined'){
          console.error('form Item key undefined', formItem, schemaAndForm, schemaArray);
        }

        if (formItem.items && formItem.items.length > 0) {
          childSchemaAndForm = that.updateFirstItemsKeyRecursively(parentToItemsAndTabs, formItem.items,connector);
          formItem.items = childSchemaAndForm.form;

          if (tmpSchema.properties[parentToItemsAndTabs.schemaFieldKey]){
            if (formItem.type==='array' || formItem.type==='tabarray'){
              tmpSchema.properties[parentToItemsAndTabs.schemaFieldKey].items=childSchemaAndForm.schema;
            }
            else{
              if (tmpSchema.properties[parentToItemsAndTabs.schemaFieldKey]){
                for (const p in childSchemaAndForm.schema){
                  tmpSchema.properties[parentToItemsAndTabs.schemaFieldKey][p]= childSchemaAndForm.schema[p];
                }
              }else{
                tmpSchema.properties[parentToItemsAndTabs.schemaFieldKey]=childSchemaAndForm.schema;
              }

            }
          }else{
            for (const prop in childSchemaAndForm.schema.properties) {
              hasProperty = true;
              tmpSchema.properties[prop] = childSchemaAndForm.schema.properties[prop];
            }
          }

        } else if (formItem.tabs && formItem.tabs.length > 0) {
          childSchemaAndForm = that.updateFirstItemsKeyRecursively(parentToItemsAndTabs, formItem.tabs, connector);
          formItem.tabs = childSchemaAndForm.form;
          if (tmpSchema.properties[parentToItemsAndTabs.schemaFieldKey]){
            tmpSchema.properties[parentToItemsAndTabs.schemaFieldKey].items=childSchemaAndForm.schema;
          }else {
            for (const prop in childSchemaAndForm.schema.properties) {
              hasProperty = true;
              tmpSchema.properties[prop] = childSchemaAndForm.schema.properties[prop];
            }
          }
        }
      });
      if (hasProperty) {
        builderSchema.properties = tmpSchema.properties;
      }
      return schemaAndForm;
    }
  }
}


export default containerMixin
