
const vueEventBus = {

  install: function (Vue, options) {
    const bus = new Vue();
    Vue.bus = bus;

    Object.defineProperty(Vue.prototype, '$bus', {
      get: function () {
        return bus
      }
    })
  }
}


export default vueEventBus
