<template>
  <div>
    <component-base v-bind:field-form="fieldForm">
      <textarea v-if="fieldForm.isLazy" :required="fieldForm.required" :style="fieldForm.style"
                :placeholder="resolvePlaceholder(fieldForm)" :class="[isValid ? '': 'is-invalid']" class="form-control"
                v-model.lazy="localValue" :rows="fieldForm.rows" :cols="fieldForm.cols"
                :readonly="fieldForm.readonly"></textarea>
      <textarea v-if="!fieldForm.isLazy" :required="fieldForm.required" :style="fieldForm.style"
                :placeholder="resolvePlaceholder(fieldForm)" :class="[isValid ? '': 'is-invalid']" class="form-control"
                v-model="localValue" :rows="fieldForm.rows" :cols="fieldForm.cols"
                :readonly="fieldForm.readonly"></textarea>
      <small class="text-danger" v-if="!isValid">{{ fieldForm.validationMessage}}</small>
    </component-base>
  </div>
</template>

<script>
  import componentMixin from './componentMixins'
  import simpleComponentMergeInMixin from './simpleComponentMerginMixin'

  export default {
    name: "SfTextArea",
    mixins: [componentMixin, simpleComponentMergeInMixin]
  }
</script>

<style scoped>

</style>
