import EventBus from 'vertx3-eventbus-client'
import findIndex from 'lodash/findIndex'
import EventBusSubscriptions from './EventBusSubscriptions'

// https://github.com/eraga/vue-vertx3-eventbus-client
export default class EventBusWrapper {
  constructor(Vue, conf) {
    this.config = conf;
    this.Vue = Vue;
    this.init();
  }

  init() {
    this.config = this.config || {}
    this.config.schema = this.config.schema || window.location.protocol
    this.config.host = this.config.host || window.location.hostname
    this.config.port = this.config.port || window.location.port
    this.config.path = this.config.path || ''
    this.config.options = this.config.options || {}

    this.address = window.location.protocol + '//' + this.config.host + ':' + this.config.port + this.config.path

    this.hooks = []
   /* this.handlersToReg = []
    this.handlersToUnReg = []*/

    this.eventBusSubscriptions = new EventBusSubscriptions();

    //  this.addressWidgetHandlers = {}
  }

  connect(isAuthRequired) {
    const that = this;
    return new Promise((resolve, reject) => {

      if (that.eb && that.eb.state === EventBus.OPEN) {
        resolve({state: this.eb.state});
      }
      if (isAuthRequired) {
        if (!that.isAuthenticated || that.eb.state !== EventBus.OPEN) {
          console.log('Request Axios registration')
          that.Vue.axios.post(that.address + that.config.registerPath, {}, {withCredentials: true}) // we'll use POST to send authenticated User to the backend and store it in the session
            .then((response) => {
              that.connectEventBus(function (err, state) {
                if (err) {
                  that.connected = false;
                  that.isAuthenticated = false;
                  reject(err);
                } else {
                  that.connected = true;
                  that.isAuthenticated = true;
                  resolve(state);
                }
              });

            })
            .catch(function (e) {
              console.error(e);
              that.connected = false;
              that.isAuthenticated = false;

              reject(e);
            });
        } else {
          resolve({state: this.eb.state});
        }

      } else {
        that.connectEventBus(function (err, state) {
          if (err) {
            that.connected = false;
            that.isAuthenticated = false;
            reject(err);
          } else {
            that.isAuthenticated = true;
            that.connected = true;
            resolve(state);
          }
        });
      }
    });
  }

  instance(isAuthRequired) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (that.eb && that.eb.state === EventBus.OPEN) {
        resolve(that.eb);
      } else {
        that.connect(isAuthRequired).then((state) => {
          if (that.eb) {
            if (that.eb.state === EventBus.OPEN) {
              resolve(that.eb);
            }else if (that.eb.state === EventBus.CONNECTING){
              setTimeout(function(){
                resolve(that.eb);
              },1000);
            }
          } else {
            reject();
          }
        }).catch((error) => {
          reject(error);
        });
      }
    });

  }

  connectEventBus(next) {
    const that = this;
    try {
      this.config.options.cookie = true;
      console.log('Creating WS EventBus');
      this.eb = new EventBus(this.address, this.config.options);
      this.eb.onopen = function () {
        console.log('EventBus connected and opened');
        this.connected = true;
        that.isAuthenticated = true;
        next(null, {state: that.eb.state});
      }

      this.eb.onclose = function () {
        console.log('eb closed!!!')
       /* this.handlersToReg = []
        this.handlersToUnReg = []*/
        this.connected = false;
        this.isAuthenticated = false;
        this.eventBusSubscriptions = new EventBusSubscriptions();
      }

    } catch (e) {
      this.connected = false;
      this.isAuthenticated = false;
      console.error('Error in ConnectEventBus', e);
      next(e, {state: that.eb.state});
    }
  }

  regHandlers(handler, widgetId) {
   // console.log('WS Register handler', handler, widgetId);

    const registrationExistsOnAddress = this.eventBusSubscriptions.exists(handler.address);
    const registeredCount = this.eventBusSubscriptions.register(handler, widgetId);

    if (!registrationExistsOnAddress) {
      console.log('We should register: ' + registeredCount + ' handler: ' + handler);
      this.eb.registerHandler(handler.address, handler.headers, EventBusWrapper._handlerCallback)
    }

  }

  static _handlerCallback = function (err, message) {
   // console.log('received raw message', message);
    if (err) {
      console.warn('WS Error', err);
    } else {
      const reg = EventBusSubscriptions.get(message.address);
      if (reg) {
        reg.forEach((item) => {
          item.handler.callback(err, message);
        });
      }
    }
  }

  unRegHandlers(handler, widgetId) {
   // console.log('WS UnRegister handler', handler, widgetId);

    const registeredCount = this.eventBusSubscriptions.unregister(handler.address, widgetId);

    if (undefined === registeredCount || registeredCount === 0) {
      console.log('There is no more widgets on address: ' + handler.address + ' and we will unregister address and callback')
      this.eb.unregisterHandler(handler.address, handler.headers, EventBusWrapper._handlerCallback)
    }
  }

  ebOnOpen() {
    const _that = this;
    this.hooks.forEach((hook) => {
      hook.hook(hook.context, _that.eb)
    })

   // this.handlersToReg.forEach(this.regHandlers)
   // this.handlersToUnReg.forEach(this.unRegHandlers)
  }

  safeExecuteHook(hook, context) {
    if (this.eb.state === EventBus.OPEN) {
      hook(context, eb)
    } else {
      this.hooks.push({
        hook: hook,
        context: context,
      })
      this.eb.onopen = this.ebOnOpen
    }
  }

  /* TODO - this is needed if VUE component directly add Handler not via our implmenetation
    addHandlers(context) {
      if (!context.$options['eventbus'].handlers) {
        return
      }
      let handlers = context.$options['eventbus'].handlers

      console.log('WS adding handlers', this.regHandlers, handlers)

      if (this.eb.state === EventBus.OPEN) {
        handlers.forEach(this.regHandlers)
      } else {
        this.handlersToReg = this.handlersToReg.concat(handlers)
        this.eb.onopen = this.ebOnOpen
      }
    }

    removeHandlers(context) {
      if (!context.$options['eventbus'].handlers) {
        return
      }
      let handlers = context.$options['eventbus'].handlers

      if (this.eb.state === EventBus.OPEN) {
        handlers.forEach(this.unRegHandlers)
      } else {
        this.handlersToUnReg = this.handlersToUnReg.concat(handlers)
        this.eb.onopen = this.ebOnOpen
      }
    }*/

  runLifecycleHook(context, hookName) {
    if (!context.$options['eventbus'].lifecycleHooks) {
      return
    }

    if (context.$options['eventbus'].lifecycleHooks[hookName]) {
      this.safeExecuteHook(context.$options['eventbus'].lifecycleHooks[hookName], context)
    }
  }
}
