var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expression-token-container" }, [
    !_vm.editField
      ? _c(
          "span",
          {
            on: {
              dblclick: function ($event) {
                $event.preventDefault()
                return _vm.focusField()
              },
            },
          },
          [_vm._v("\n    " + _vm._s(_vm.viewValue) + "\n  ")]
        )
      : _vm._e(),
    _vm.resolveInputType === "checkbox" && _vm.editField
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          ref: "inputField",
          staticClass: "field-value form-control",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.internalValue)
              ? _vm._i(_vm.internalValue, null) > -1
              : _vm.internalValue,
          },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.blurField()
            },
            focus: function ($event) {
              return _vm.focusField()
            },
            blur: function ($event) {
              return _vm.blurField()
            },
            change: function ($event) {
              var $$a = _vm.internalValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.internalValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.internalValue = $$c
              }
            },
          },
        })
      : _vm.resolveInputType === "radio" && _vm.editField
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          ref: "inputField",
          staticClass: "field-value form-control",
          attrs: { type: "radio" },
          domProps: { checked: _vm._q(_vm.internalValue, null) },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.blurField()
            },
            focus: function ($event) {
              return _vm.focusField()
            },
            blur: function ($event) {
              return _vm.blurField()
            },
            change: function ($event) {
              _vm.internalValue = null
            },
          },
        })
      : _vm.editField
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          ref: "inputField",
          staticClass: "field-value form-control",
          attrs: { type: _vm.resolveInputType },
          domProps: { value: _vm.internalValue },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.blurField()
            },
            focus: function ($event) {
              return _vm.focusField()
            },
            blur: function ($event) {
              return _vm.blurField()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.internalValue = $event.target.value
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }