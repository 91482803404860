var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("schema-form", {
    attrs: { form: _vm.resolvedForm, schema: _vm.resolvedSchema },
    on: { validationResult: _vm.onValidationResult },
    model: {
      value: _vm.model.config.data,
      callback: function ($$v) {
        _vm.$set(_vm.model.config, "data", $$v)
      },
      expression: "model.config.data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }