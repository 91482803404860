var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("component-base", { attrs: { "field-form": _vm.fieldForm } }, [
    _c(
      "div",
      { staticClass: "picker-group", class: [_vm.isValid ? "" : "is-invalid"] },
      [
        _c("date-range-picker", {
          ref: "picker",
          staticClass: "picker-input",
          attrs: {
            autoApply: _vm.fieldForm.autoApply,
            "control-container-class": "reportrange-text",
            dateFormat: _vm.fieldForm.dateFormat,
            linkedCalendars: _vm.fieldForm.linkedCalendars || true,
            disabled: _vm.fieldForm.disabled || _vm.fieldForm.readOnly,
            maxDate: _vm.fieldForm.maxDate,
            minDate: _vm.fieldForm.minDate,
            opens: _vm.fieldForm.opens || "center",
            showDropdowns: _vm.fieldForm.showDropdowns || false,
            showWeekNumbers: _vm.fieldForm.showWeekNumbers || false,
            singleDatePicker: _vm.fieldForm.singleDatePicker || false,
            timePicker: _vm.fieldForm.timePicker || false,
            timePicker24Hour: _vm.fieldForm.timePicker24Hour || false,
          },
          model: {
            value: _vm.dateRange,
            callback: function ($$v) {
              _vm.dateRange = $$v
            },
            expression: "dateRange",
          },
        }),
        _c("div", { staticClass: "picker-button" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline-secondary",
              attrs: { title: "Clear date", type: "button" },
              on: {
                click: function ($event) {
                  return _vm.clearInput()
                },
              },
            },
            [_vm._v("Clear")]
          ),
        ]),
        !_vm.isValid
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.fieldForm.validationMessage)),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }