var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.fieldForm.hidden
    ? _c(
        "button",
        {
          staticClass: "btn",
          class: [_vm.fieldForm.btnStyle || "btn-default"],
          style: _vm.fieldForm.style,
          attrs: { type: "reset" },
        },
        [
          _vm.fieldForm.icon
            ? _c("span", { class: _vm.fieldForm.icon })
            : _vm._e(),
          _vm._v(_vm._s(_vm.fieldForm.title) + "\n"),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }