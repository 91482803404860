<template>

  <draggable :class="isDroppable?'droppable-container':''" :disabled="!isDroppable"
             :group="{ name: 'components', pull: true, put: true }" :handle="'.handle'"
             :list="clonedDroppedComponents"
             :move="onMove" @add="onAdd" @change="onChange" @end="onEnd" class="schema-show-builder-component"
             tag="div">
    <div :key="resolveStringifiedKey(field, index)" :ref="resolveStringifiedKey(field)"
         :style="resolveVisible(field)"
         class="schema-builder-component" v-for="(field, index) in asyncComputedSchemaForm">

      <sf-component-toolbar :cloned-dropped-components="clonedDroppedComponents" :field="field" :guid="field.guid"
                            :is-droppable="isDroppable" :selected-item="selectedItem"
                            @isminimized="onIsMinimized(field, $event)" @schemaChanged="onToolbarSchemaChanged"
                            v-if="isDroppable">
      </sf-component-toolbar>
      <builder-inner-component
        :key="resolveStringifiedKey(field, index)"
        :component-data="{ field: field }"
        :field-form="field"
        :field-mapper="fieldMapper"
        :field-schema="field"
        :full-model="fullModel" :is-droppable="isDroppable" :parent-form="resolveParent(field)"
        :parent-index="parentIndex"

        :schema-form-service="schemaFormService"
        :value="resolveValue(value, field)"
        @fullModelChanged="onFullModelChanged" @input="onInput($event, field)"
        @internalSchemaChanged="onInternalChildSchemaChanged"
        v-show="!(itemState.minimizedItems[field.guid])"
      />
    </div>
  </draggable>

</template>

<script>
  import BuilderInnerComponent from "./BuilderInnerComponent";
  import utils from '../../utils/utils'
  import findIndex from 'lodash/findIndex'
  import builderComponentMixins from './builderComponentMixins'
  import buildMixins from './buildMixins'
  import droppableMixins from './droppableMixins'
  import mergeInComponentUtils from '../../utils/mergeInComponentUtils'
  import minimizeMixin from './minimizeMixin'
  import formBusMixin from './formBusMixin'

  export default {
    name: "BuilderComponent",
    mixins: [builderComponentMixins, buildMixins, droppableMixins, minimizeMixin, formBusMixin],
    components: {
      BuilderInnerComponent
    },
    data() {
      return {
        clonedDroppedComponents: [],
        droppedCalculatedMergedForm: [],
        internalCalculatedMergedSchemaForm: [],
        selectedItem: {},
        asyncMergedForm: []
      }
    },
    computed: {
      asyncComputedSchemaForm: function () {
        return this.evaluateComputedSchemaForm();
      },
      computedMergedForm: function () {
        const that = this;
       // console.log('computedMergedForm', this.mergedForm);
        const filtered = this.mergedForm.filter(function (item) {
          return that.resolveCondition(item, that.parentForm);
        });
        //console.log('computedMergedForm filtered', filtered);
        return filtered;
      },
      computedAsyncMergedForm: function () {
        const that = this;
        return this.asyncMergedForm.filter(function (item) {
          return that.resolveCondition(item, that.parentForm);
        });
      }
    },
    methods: {
      resolveParent: function (field) {
        //field.parentForm = this.parentForm;
        return {
          parentForm: this.parentForm,
          arrayIndex: field.arrayIndex
        }
      },
      evaluateComputedSchemaForm: function () {
        // console.log('async computed schema');//, utils.clone(this.form));
        const that = this;
        if (this.isDroppable) {
          return that.computedAsyncMergedForm;
        } else {
          return that.computedMergedForm;
        }
      },
      onToolbarSchemaChanged: function (schema) {
        //  console.log('On Toolbar change');

        this.$emit('internalSchemaChanged', this.clonedDroppedComponents);
      },
      onInternalChildSchemaChanged: function (schemaFormEvt) {
        //  console.log('Received onInternalChildSchemaChanged', utils.clone(schemaFormEvt), utils.clone(this.clonedDroppedComponents));

        const schemaFormComponent = schemaFormEvt.schemaFormComponent;
        const componentType = schemaFormEvt.componentType;
        if (componentType) {
          switch (componentType) {
            case 'tabs':

              break;
          }
        }

        let computedMergedForm = this.clonedDroppedComponents;
        const idx = findIndex(computedMergedForm, function (item) {
          const found = item.guid === schemaFormComponent.guid
          return found;
        });
        if (idx > -1) {
          this.clonedDroppedComponents[idx] = schemaFormComponent;

          //console.log('Received onInternalChildSchemaChanged - ended', utils.clone(schemaFormEvt), utils.clone(this.clonedDroppedComponents));

          this.$emit('internalSchemaChanged', this.clonedDroppedComponents);
        } else {
          console.warn("There is no item in builder list: ", schemaFormEvt);
        }
      },
      updateChildPropertiesKeyRecursively: function (form) {
        mergeInComponentUtils.updateChildPropertiesKeyRecursively(form);
      },
      computeMergedForm: function () {// this is used
        const that = this;

        if (that.isDroppable && that.mergedForm) {
          if (that.clonedDroppedComponents && that.mergedForm.length !== this.clonedDroppedComponents.length) {
            //  console.log('Update cloned dropped components', this.clonedDroppedComponents, this.mergedForm);
            let cloned = utils.clone(this.mergedForm);
            that.updateChildPropertiesKeyRecursively(cloned);
            this.clonedDroppedComponents = cloned;
          }
          if (that.mergedForm && this.clonedDroppedComponents && that.mergedForm.length === this.clonedDroppedComponents.length) {

            that.$nextTick(function () {
              // console.log('computeMergedForm - start', utils.clone(that.mergedForm), utils.clone(that.clonedDroppedComponents));

              for (let i = 0; i < that.clonedDroppedComponents.length; i++) {
                const internalField = that.clonedDroppedComponents[i];
                const mergedField = that.mergedForm[i];
                if (mergedField) {
                  if (internalField.schemaFieldKey !== mergedField.schemaFieldKey) {
                    console.warn('Fields not same', internalField, mergedField);
                  }
                  mergedField.guid = internalField.guid;
                  mergedField.schema = internalField.schema;
                }
              }
              that.asyncMergedForm = that.mergedForm;

              return that.mergedForm;
            });
            that.asyncMergedForm = that.mergedForm;
            return that.mergedForm;
          } else {
            that.asyncMergedForm = that.mergedForm;
            return that.mergedForm;
          }
        } else {
          this.asyncMergedForm = this.mergedForm;
          return this.mergedForm;
        }
      }
    },
    watch: {
      mergedForm: {
        handler(newVal, oldVal) {
          //  console.log('MergedForm changed')
          if (this.isDroppable) {
            // console.log('MergedForm changed')
            if (newVal !== oldVal)
              this.computeMergedForm();
          }
        },
        deep: true
      }
    },
    beforeMount() {
      this.computeMergedForm();
      //console.log('Builder component mounted', this.asyncComputedSchemaForm);
    },
    created() {
    //  console.log('BUILDER created: '+this.mergedForm?this.mergedForm.schemaFieldKey:' NO FIELD FORM ');
      const that = this;
      if (this.isDroppable) {// only if we are in Edit mode
        // console.log('Bulder component created');
        this.$bus.$on('selectedItem', this.onSelectedItem);
      }
    },
    beforeDestroy: function () {
      if (this.isDroppable)
        this.$bus.$off('selectedItem', this.onSelectedItem);
    }
  }
</script>

<style scoped>


  .droppable-container .schema-builder-component {
    border: 1px solid #d0fdb3;
  }

  .droppable-container .schema-builder-component .schema-form-section.row {

  }

  .droppable-container .schema-builder-component .schema-form-section.col {
    border: 1px dotted #e3f9eb;
  }

</style>
